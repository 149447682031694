export default function getValidationCards(flags: string): string {
  switch (flags) {
    case 'visa':
      return '2';
    case 'mastercard':
      return '1';
    case 'discover':
      return '11';
    case 'amex':
      return '3';
    case 'jcb':
      return '10';
    case 'dinersclub':
      return '7';
    case 'maestro':
      return '13';
    case 'laser':
      return '';
    case 'unionpay':
      return '';
    case 'elo':
      return '5';
    case 'hipercard':
      return '4';
    default:
      return '';
  }
}

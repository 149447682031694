import moment from 'moment';

export function revertDate(inputValue: string): string {
  if (inputValue && inputValue !== '') {
    const day = inputValue.split('/')[0];
    const month = inputValue.split('/')[1];
    const year = inputValue.split('/')[2];

    return `${year}-${`${month}`.slice(-2)}-${`${day}`.slice(-2)}`;
  }
  return '';
}

export function isValidDate(pDate: string, pFormat: string): boolean {
  const date = moment(pDate, pFormat, true);
  return date.isValid();
}

export function isValidTime(pTime: string, pFormat: string): boolean {
  const time = moment(pTime, pFormat, true);
  return time.isValid();
}

export function formatDate(pDate: Date, pFormat: string): string {
  const date = moment(pDate).format(pFormat);

  return date;
}

export function dateToStr(pDate: Date | null, format: string): string {
  if (pDate) {
    const date = moment(pDate).format(format);
    return date;
  }
  return '';
}

export function formatDateToString(pDate: string, format: string): string {
  try {
    const date = moment(pDate).format(format);
    return date;
  } catch (err: any) {
    return '';
  }
}

export function strToDate(pDate: string, format: string): Date {
  return moment(pDate, format).toDate();
}

export function formatStrToDate(pDate: string, pFormat: string): Date | null {
  if (pDate) {
    const date = moment(pDate, pFormat).toDate();
    return date;
  }
  return null;
}

export function formatStrToMinutes(
  pTimer: string,
  pFormat: string,
  pMyOrder: number
) {
  if (pMyOrder > 0) {
    const MyTime = moment(pTimer, pFormat);
    const duration = moment.duration({
      seconds: MyTime.seconds(),
      minutes: MyTime.minutes(),
      hours: MyTime.hours(),
    });

    let temp = duration.asSeconds();
    temp *= pMyOrder;

    const result = parseInt(
      moment.duration(temp, 'seconds').asMinutes().toString(),
      10
    );

    return result.toString();
  }

  return '0';
}

export function handleDifferenceYears(date: any, value: number): boolean {
  return moment().diff(moment(date), 'years') > value;
}

export function personAge(date: string) {
  const nascimento = moment().diff(moment(date), 'years');
  return nascimento;
}

// duration deve receber o tempo em milissegundos
export function msToClock(duration: number) {
  // const milliseconds = Math.round((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return `${`00${hours}`.slice(-2)}:${`00${minutes}`.slice(
    -2
  )}:${`00${seconds}`.slice(-2)}`;
}

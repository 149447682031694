import React, { useEffect, useState } from 'react';
import { CardVideo, Video } from './styled';
import DrLoading from '../dr-loading';

interface VideoHostProps {
  id: number;
  localStream: MediaStream | undefined;
  urlImg: string;
  isLoading?: boolean;
}

const VideoHost: React.FC<VideoHostProps> = (props: VideoHostProps) => {
  const { id, localStream, urlImg, isLoading } = props;

  return (
    <CardVideo>
      <Video
        videoType="localVideo"
        videoStream={localStream}
        id={id}
        muted={false}
        avatar={urlImg}
        videoStyles={{ visibility: isLoading ? 'hidden' : undefined }}
      />
      {isLoading && <DrLoading customLoading />}
    </CardVideo>
  );
};

VideoHost.defaultProps = {
  isLoading: false,
};

export default VideoHost;

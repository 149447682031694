import styled from 'styled-components';

export const Ratings = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;

  .stars {
    font-size: 60px;
    .MuiRating-iconEmpty {
      color: #e5e5e5;
    }
  }
`;

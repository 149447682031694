import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrConfirmationRegistration from '../../components/dr-confirmation-registration';
import { BoxContainer, Box } from '../../styles/box';

const ResendEmail: React.FC = () => (
  <BoxContainer height="auto">
    <Container className="mt-3">
      <Row>
        <Col xl={5} className="mb-4 p-4">
          <Box>
            <DrConfirmationRegistration />
          </Box>
        </Col>
        <Col xl={7}>
          <DrHeaderWellcome />
          <Banner />
        </Col>
      </Row>
    </Container>
    <Footer />
  </BoxContainer>
);

export default ResendEmail;

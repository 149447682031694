import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/dr-input';
import HelmetComponent from '../../../components/dr-helmet-component';
import { scrollPositionTop, verifyErrorYup } from '../../../utils/bibli';
import { Fields } from '../../../styles/fields';
import { Field } from '../../../styles/field';
import DrButtonLoading from '../../../components/dr-button-loading';
import Button from '../../../components/dr-button';

interface PasswordData {
  nova_senha: string;
  confirma_senha: string;
}

interface IdCPF {
  cpf: string;
}

const DrBoxRecoverPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { params } = useRouteMatch<IdCPF>();
  const { addToast } = useToast();
  const { signOut, requestAPI, sendError } = useAuth();
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      history.push('/dashboard');
      return;
    }
    scrollPositionTop();
    addToast({
      type: 'success',
      title: 'Verificação concluída!',
      description: 'Preencha os campos para definir sua nova senha.',
    });
  }, []);

  const handleSubmit = useCallback(async (data: PasswordData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nova_senha: Yup.string()
          .required(
            'Senha deve conter no mínimo 6 caracteres contendo letras minúsculas, maiúsculas e números.'
          )

          .matches(
            /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/g,
            'Senha deve conter no mínimo 6 caracteres contendo letras minúsculas, maiúsculas e números.'
          )
          .trim(),
        confirma_senha: Yup.string()
          .when('nova_senha', {
            is: (val) => !!val.length,
            then: Yup.string().required('Confirmação de senha é obrigatória'),
            otherwise: Yup.string(),
          })
          .oneOf(
            [Yup.ref('nova_senha')],
            'Senha e confirmação de senha precisam ser iguais.'
          )
          .trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await requestAPI({
        method: 'PUT',
        url: `tmUsuarioRedefinirSenha/${params.cpf}/${location.state}`,
        body: data,
        isPublic: true,
      });

      setLoading(false);

      addToast({
        type: 'success',
        title: 'Senha atualizada com sucesso!',
        description: 'Faça o login com a sua nova senha',
      });

      signOut();

      history.push('/');
    } catch (err: any) {
      sendError(err);
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  }, []);
  const handleEnter = useCallback(() => {}, []);

  return (
    <>
      <HelmetComponent title="Redefinir Senha" />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <Fields>
            <Row>
              <Col xl={12}>
                <Field>
                  <Input
                    icon
                    name="nova_senha"
                    showDisplayPass
                    label="Nova Senha"
                    customType="password"
                    placeholder="Senha"
                  />
                </Field>
              </Col>
              <Col xl={12}>
                <Field>
                  <Input
                    icon
                    name="confirma_senha"
                    label="Confirmar Senha"
                    customType="password"
                    placeholder="Confirme a nova senha"
                    onKeyDown={handleEnter}
                  />
                </Field>
              </Col>
            </Row>
            {/* <Row className=" justify-content-center">
              <Col xl={6}>
                <DrButtonLoading
                  loading={isLoading}
                  type="submit"
                  title="Salvar"
                  color="pink"
                />
              </Col>
              <Col xl={6}>
                <Button
                  disabled={!!isLoading}
                  type="button"
                  title="Voltar"
                  color="dark-grey"
                  href="/sign-in"
                />
              </Col>
            </Row> */}
            <Row className="justify-content-center pb-3">
              <Col xl={12}>
                <DrButtonLoading
                  loading={isLoading}
                  type="submit"
                  title="Redefinir"
                  color="blue"
                />
              </Col>
              <Col xl={12} className="pt-3">
                <Button
                  type="button"
                  title="Voltar"
                  color="dark-grey"
                  href="/sign-in"
                  disabled={isLoading}
                  onClick={() => {
                    signOut();
                  }}
                />
              </Col>
            </Row>
          </Fields>
        </Container>
      </Form>
    </>
  );
};

export default DrBoxRecoverPassword;

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const styleStr = css`
  text-decoration: none;
  color: #3f56a5;

  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      color: #ed145b;
    }
  }
`;

export const LinkStyled = styled(Link)`
  ${styleStr}
`;

export const AnchorStyled = styled.a`
  ${styleStr}
`;

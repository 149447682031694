import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import DrLoading from '../dr-loading';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { scrollPositionTop } from '../../utils/bibli';

export interface DrCardLoadingProps {
  minHeight?: string;
  className?: string;
  title?: string;
}

const DrCardLoading: React.FC<DrCardLoadingProps> = ({
  minHeight,
  className,
  title,
}: DrCardLoadingProps) => {
  useEffect(() => {
    scrollPositionTop();
  }, []);
  return (
    <Container className="pt-3">
      <CardBlank definedSize={minHeight} className={`container ${className}`}>
        <PageTitle color="#000000">{title}</PageTitle>
        <DrLoading customLoading ratio={1.3} />
      </CardBlank>
    </Container>
  );
};

DrCardLoading.defaultProps = {
  minHeight: '60vh',
  className: '',
};

export default DrCardLoading;

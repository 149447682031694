import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Loading from '../../components/dr-loading';
import { useAuth } from '../../hooks/auth';
import { Container, Content, Button, Protocol } from './styled';
import { useToast } from '../../hooks/toast';
import Header from '../../components/dr-header';

import HelmetComponent from '../../components/dr-helmet-component';

interface ProtocolType {
  idatendimento_protocolo_sol_doc: number;
  idpessoa_fisica: number;
  ds_protocolo: string;
  data_fim: string;
  data_inicio: string;
  data_protocolo: string;
  cd_protocolo: string;
  ie_estagio: number;
  ie_protocolo_tipo: number;
  ds_file: string;
  dt_insert: number;
  dt_update: number;
  nm_pessoa: string;
  ds_protocolo_tipo: string;
  ds_estagio: string;
}

interface IdSolicitation {
  id: string;
}

const ProtocolDatas: React.FC = () => {
  const { requestAPI, sendError } = useAuth();

  const { addToast } = useToast();
  const { params } = useRouteMatch<IdSolicitation>();
  const [isLoading, setLoading] = useState(true);
  const [protocol, setProtocol] = useState<ProtocolType>({} as ProtocolType);

  useEffect(() => {
    async function ini() {
      try {
        const resp = await requestAPI({
          method: 'GET',
          url: `/atendimentoProtocoloSolDoc/${params.id}`,
        });
        setProtocol(resp);
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    }

    ini();
  }, []);

  return (
    <>
      <HelmetComponent title="Dados do Protocolo" />
      <Header />

      <Container>
        <Content>
          {isLoading ? (
            <Loading mensagem="Aguarde enquando os dados são carregados" />
          ) : (
            <>
              <h1>Dados do Protocolo</h1>

              <Protocol>
                <div>
                  <div className="dados-protocol">
                    <h2 className="title-protocol">Descrição:</h2>
                    <h2 className="descript-protocol">
                      {protocol.ds_protocolo}
                    </h2>
                  </div>
                  <div className="dados-protocol">
                    <h2 className="title-protocol">Tipo:</h2>
                    <h2 className="descript-protocol">
                      {protocol.ds_protocolo_tipo}
                    </h2>
                  </div>
                  <div className="dados-protocol">
                    <h2 className="title-protocol">Data de início:</h2>
                    <h2 className="descript-protocol">
                      {protocol.data_inicio}
                    </h2>
                  </div>
                  <div className="dados-protocol">
                    <h2 className="title-protocol">Data do fim:</h2>
                    <h2 className="descript-protocol">{protocol.data_fim}</h2>
                  </div>
                  <div className="dados-protocol">
                    <h2 className="descript-protocol">{protocol.ds_file}</h2>
                  </div>
                  <div className="dados-protocol">
                    <h2 className="title-protocol">Código do protocolo:</h2>
                    <h2 className="descript-protocol">
                      {protocol.cd_protocolo}
                    </h2>
                  </div>
                </div>
              </Protocol>
              <div className="buttons">
                <Link to="/request-protocol">
                  <Button type="button">Voltar</Button>
                </Link>
              </div>
            </>
          )}
        </Content>
      </Container>
    </>
  );
};
export default ProtocolDatas;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import DrCardLoading from '../../components/dr-card-loading';
import { useAuth, AttendanceDataTypes } from '../../hooks/auth';
import {
  attendanceOpened,
  scrollPositionTop,
  handleNextScreenAttendanceOpened,
} from '../../utils/bibli';
import CardTypesCare from './card-types-care';
import { useIsMounted } from '../../hooks/is-mounted';
import DrModal from '../../components/dr-modal';
import { useToast } from '../../hooks/toast';
import { userRequirements } from '../../utils/validations';

interface Horario {
  idestabelecimento_hora_func: number;
  hr_inicial: string;
  hr_final: string;
}
interface Atendimento {
  horario: Horario[];
  ie_aberto: string;
  msg: string;
}

export interface attendanceTypesType {
  atendimento: Atendimento;
  ds_button_acao: string;
  ds_informacoes: string;
  ds_tipo_atendimento: string;

  ds_especialidade: string;
  idespecialidade: string;
  idconvenio: any;
  idestabelecimento_tipo_atendimento: number;
  idperfil: number;
  idsetor_atendimento: number;
  ie_categoria_atend: string;
  ie_classificacao_agenda: string;
  ie_clinica: number;
  ie_dependente: string;
  ie_telemedicina: string;
  ie_tipo_agenda: number;
  ie_tipo_atendimento: string;
  nr_seq_apresent: number;
  qtd_idade_max: number;
  qtd_idade_min: number;
  situacao: string;
  nr_dias_restante_ret: number | undefined;
}

interface modalInt {
  showModal: boolean;
  title?: string;
}

const AttendanceTypes: React.FC = () => {
  const {
    requestAPI,
    attendance,
    setAttendanceData,
    user,
    sendError,
    applicationData,
  } = useAuth();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [attendanceTypes, setAttendanceTypes] = React.useState<
    attendanceTypesType[]
  >([]);
  const [modalLogin, setModalLogin] = useState({ showmodal: false } as any);
  const isMountedRef = useIsMounted();
  const [modal, setModal] = React.useState<modalInt>({
    showModal: false,
  });
  const { addToast } = useToast();
  const respAttendanceRef = React.useRef<any>();

  useEffect(() => {
    scrollPositionTop();
  }, []);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  function getTypes() {
    requestAPI({
      method: 'GET',
      url: '/tmTipoAtendimento',
    })
      .then(async (resp) => {
        if (attendance && attendance.singleType) {
          await handleSingleType(attendance);
        } else if (resp.length === 1) {
          if (!user && resp[0].ie_tipo_atendimento === '3') {
            setModalLogin({ showModal: true });
            setLoading(false);
            return;
          }
          await handleSingleType(resp[0]);
        } else if (
          attendance &&
          attendance?.tipos_atendimento?.length &&
          (attendance?.ds_button_acao || attendance?.idespecialidade)
        ) {
          const result: any = [];
          attendance.tipos_atendimento.forEach((tipo: any) => {
            const atendimento = resp.find(
              (response: any) =>
                response.idestabelecimento_tipo_atendimento === tipo
            );
            if (atendimento) {
              result.push(atendimento);
            }
            if (result.lenght && result) {
              if (attendance?.tipos_atendimento?.length === 1) {
                handleSingleType(result[0], true);
              }
            } else {
              setAttendanceTypes(result);
            }
          });
        } else {
          setAttendanceTypes(resp);
        }

        if (isMountedRef.current) {
          setLoading(false);
        }
      })
      .catch((error: any) => {
        sendError(error);
        if (isMountedRef.current) {
          history.push('/dashboard');
        }
      });
  }
  useEffect(() => {
    async function getData() {
      if (
        attendance &&
        attendance.idestabelecimento &&
        (attendance.ie_retorno === 'S' ||
          (attendance.nr_dias_restante_ret &&
            attendance.nr_dias_restante_ret >= 0))
      ) {
        await attendanceOpened({
          patient: attendance as AttendanceDataTypes,
          respAttendanceRef,
          setAttendanceData,
          setModal,
          setLoading,
          requestAPI,
          addToast,
          isMountedRef,
          history,
          user,
          userRequirements,
          fromReturn: true,
          attendance,
          applicationData,
        });
      } else {
        getTypes();
      }
    }
    getData();
    if (history.action === 'POP' && attendance && attendance.origem_cartao) {
      history.push('/dashboard/');
    }
  }, []);

  async function handleSingleType(
    data: AttendanceDataTypes,
    noReplace?: boolean
  ) {
    const {
      idestabelecimento_tipo_atendimento,
      idespecialidade,
      idconvenio,
      ie_telemedicina,
      ds_epecialidade,
      idperfil,
      ie_clinica,
      ie_categoria_atend,
      ie_classificacao_agenda,
      ie_tipo_atendimento,
      qtd_idade_max,
      qtd_idade_min,
      idsetor_atendimento,
      ie_tipo_agenda,
      ie_dependente,
    } = data;

    const attendanceData = {
      ie_dependente,
      idestabelecimento_tipo_atendimento,
      idespecialidade,
      idconvenio,
      ie_telemedicina,
      ds_epecialidade,
      idperfil,
      ie_clinica,
      ie_categoria_atend,
      ie_classificacao_agenda,
      ie_tipo_atendimento,
      qtd_idade_max,
      qtd_idade_min,
      idsetor_atendimento,
      ie_tipo_agenda,
      singleType: true,
    };

    if (!idespecialidade) {
      delete attendanceData.idespecialidade;
    }

    if (data && data.atendimento && data.atendimento.msg) {
      addToast({
        type: 'error',
        title: 'Atenção!',
        description:
          data && data.atendimento && data.atendimento.msg
            ? `${data.atendimento.msg}`
            : `Não foi possível obter o atendimento`,
      });
      return;
    }

    await setAttendanceData(attendanceData, !noReplace)
      .then(() => {
        if (user && history.action !== 'POP') {
          history.push({
            pathname: '/select-to-attendance',
          });
        } else if (history.action === 'POP') {
          history.push({
            pathname: '/dashboard',
          });
        } else {
          history.push({
            pathname: `/select-specialty/eletivo`,
          });
        }
      })
      .catch((error) => {
        sendError(error);
      });
  }

  return (
    <>
      <Header />
      {isLoading ? (
        <DrCardLoading />
      ) : (
        <>
          <DrModal
            controllerModal={[modalLogin.showModal, setModalLogin]}
            title="Atenção"
            buttons={{
              confirm: {
                title: 'Login/Cadastro',
                onClick: () => {
                  history.push('/sign-in');
                },
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => {
                  history.push('/dashboard');
                  setModalLogin({ showModal: false });
                },
              },
            }}
            content={<h4>Necessário realizar Login ou Cadastro!</h4>}
          />
          <DrModal
            controllerModal={[modal.showModal, setModal]}
            title={modal.title || ''}
            buttons={{
              confirm: {
                onClick: () =>
                  handleNextScreenAttendanceOpened({
                    respAttendanceRef,
                    history,
                    ie_tipo_atendimento: attendance.ie_tipo_atendimento,
                    isMountedRef,
                    fromReturn: false,
                  }),
              },
              cancel: {
                onClick: () => {
                  getTypes();
                  setModal({ showModal: false });
                },
              },
            }}
          />
          <CardTypesCare
            setModalLogin={setModalLogin}
            attendanceTypes={attendanceTypes}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default AttendanceTypes;

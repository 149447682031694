import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  CardConfig,
  CardConfigBody,
  CardConfigIcon,
  CardConfigTitle,
  CardConfigP,
  CardConfigI,
} from './styled';

import DrIconFont from '../dr-icon-font';

interface CardConfigProps {
  title: string;
  description: string;
  icon: string;
  state?: any;
  path: string;
}

const DrCardConfig: React.FC<CardConfigProps> = (props) => {
  const { title, description, icon, path, state } = props;

  const obj = { pathname: `${path}`, state };

  return (
    <Col lg={4} xl={3} className="mb-3">
      <CardConfig>
        <Link to={obj}>
          <CardConfigIcon>
            <CardConfigI>
              <DrIconFont name={icon} />
            </CardConfigI>
          </CardConfigIcon>
          <CardConfigBody>
            <CardConfigTitle>{title}</CardConfigTitle>
            <CardConfigP>{description}</CardConfigP>
          </CardConfigBody>
        </Link>
      </CardConfig>
    </Col>
  );
};
export default DrCardConfig;

import React from 'react';

import styled, {
  css,
  FlattenSimpleInterpolation,
  keyframes,
} from 'styled-components';

import { Row } from 'react-bootstrap';
import DrIcon from '../dr-icon-font';
import DrCardEquip from '../dr-card-equip';
import DrLoading from '../dr-loading';

interface PropOther {
  other?: boolean;
}

interface PropActive {
  active?: boolean;
}
interface PropBlink {
  blink?: boolean;
}
interface BCBodyProps {
  active?: boolean;
  cssExternal: FlattenSimpleInterpolation;
}

interface BCLargerIconProps {
  active?: boolean;
  blink?: boolean;
}

interface BCRowFooterMsgProps {
  isFile?: boolean;
}

interface SetKeyframeBlinkParams {
  isColor?: boolean;
  isCardEquip?: boolean;
}

interface SetBlinkParams extends SetKeyframeBlinkParams {
  blink: boolean | undefined;
}

export const BoxChat = styled.div`
  max-width: 370px;
  width: 100%;
`;

const blinkIconChatBackgr = keyframes`
0% {
  background-color: #3f56a5;
}
50% {
  background-color: #3f56a5;
}
51% {
  background-color: #ed145b;
}
100% {
  background-color: #ed145b;
}
`;
const blinkIconChatColor = keyframes`
0% {
  color: #3f56a5;
}
50% {
  color: #3f56a5;
}
51% {
  color: #ed145b;
}
100% {
  color: #ed145b;
}
`;

const blinkIconCardEqui = keyframes`
0% {
  color: #03bbd7;
}
50% {
  color: #03bbd7;
}
51% {
  color: #ed145b;
}
100% {
  color: #ed145b;
}
`;

function setKeyframeBlink({ isColor, isCardEquip }: SetKeyframeBlinkParams) {
  if (isColor) {
    return blinkIconChatColor;
  }

  if (isCardEquip) {
    return blinkIconCardEqui;
  }

  return blinkIconChatBackgr;
}

function setBlink({ blink, ...rest }: SetBlinkParams) {
  const keyframe = setKeyframeBlink({ ...rest });

  return (
    blink &&
    css`
      animation: ${keyframe} 1.5s linear infinite;
    `
  );
}

export const BCLargerIcon = styled.div<BCLargerIconProps>`
  cursor: pointer;
  position: relative;
  margin-right: 90px;
  z-index: 999;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#ed145b' : '#3f56a5')};

  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      background-color: #ed145b !important;
    }

    &:before {
      @media (hover: hover) {
        color: #ed145b !important;
      }
    }
  }

  &:before {
    @media (hover: hover) {
      transition: all 0.2s ease-in-out;
    }

    position: absolute;
    content: attr(data-title);
    max-width: 110px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: ${({ active }) => (active ? '#ed145b' : '#3f56a5')};
    top: 50%;
    transform: translateY(-50%);
    right: -90px;

    ${({ blink }) => setBlink({ blink, isColor: true })}
  }

  ${({ blink }) => setBlink({ blink })}
`;

const DrIconSmallIcon = styled(DrIcon).attrs<PropActive & PropBlink>(
  ({ active }) => ({
    color: active ? '#ed145b !important' : undefined,
  })
)<PropActive & PropBlink>`
  ${({ blink }) => setBlink({ blink, isCardEquip: true })}
`;

export const BCSmallerIcon = styled(DrCardEquip).attrs<PropActive & PropBlink>(
  ({ active, blink }) => ({
    icon: <DrIconSmallIcon name="bubble-chat" active={active} blink={blink} />,
  })
)<PropActive & PropBlink>`
  position: relative;
  cursor: pointer;

  // @media (min-width: 576px) {
  //   left: 5em;
  // }

  &:hover i {
    @media (hover: hover) {
      color: #ed145b !important;
    }
  }
`;

export const BCDrIconOpChat = styled(DrIcon).attrs(() => ({
  name: 'bubble-chat',
}))`
  color: #fff;
  font-size: 48px;
`;

export const BCBody = styled.div<BCBodyProps>`
  position: ${({ active }) => active && 'absolute'};
  display: ${({ active }) => !active && 'none'};
  left: 0;
  left: 0;
  bottom: 120px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 30px 1px rgb(0 0 0 / 5%);
  padding: 40px 0px 95px;
  height: ${({ active }) => (active ? '70vh' : '0px')};
  opacity: ${({ active }) => (active ? '1' : '0')};

  /*transition: all 0.2s ease-in-out;*/
  width: 70vw;
  z-index: 101;

  @media (min-width: 576px) {
    width: 30rem;
  }

  ${({ cssExternal }) => cssExternal && cssExternal}
`;

export const BCClose = styled.span`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  height: 21px;
  width: 21px;
`;

export const BCDrIconClose = styled(DrIcon).attrs(() => ({
  name: 'close',
}))`
  font-size: 20px;
  color: #777;

  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      color: #ed145b;
    }
  }
`;

export const BCPara = styled.p<PropOther>`
  overflow-wrap: break-word;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${({ other }) => (other ? '#777' : '#fff')};

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

export const BCFile = styled.a<PropOther>`
  font-size: 16px;
  font-weight: 500;
  display: block;
  cursor: pointer;
  color: ${({ other }) => (other ? '#777' : '#fff')};
  &:hover {
    @media (hover: hover) {
      color: ${({ other }) => (other ? '#777' : '#fff')};
    }
  }
`;

export const BCDrIconFile = styled(DrIcon).attrs(() => ({
  name: 'file',
}))`
  display: inline-block;
  margin-right: 10px;
  font-size: 38px;
`;

export const BCFooter = styled.span<PropOther>`
  font-size: 0.719rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ other }) => (other ? '#777' : '#fff')};

  @media (min-width: 576px) {
    font-size: 14px;
  }
`;

export const BCBubble = styled.div.attrs((props: PropOther) => ({
  other: props.other,
  backgrounColor: props.other ? '#f1f1f1' : '#3f56a5',
  colorStyle: props.other ? '#777' : '#fff',
}))`
  position: relative;
  padding: 5px 10px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ backgrounColor }) => backgrounColor};

  ${({ other }) =>
    other
      ? css`
          border-bottom-right-radius: 5px;
        `
      : css`
          border-bottom-left-radius: 5px;
        `}

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom-width: 10px;
    border-bottom-style: solid;
    border-bottom-color: ${({ backgrounColor }) => backgrounColor};

    ${({ other }) =>
      other
        ? css`
            left: -10px;
          `
        : css`
            right: -10px;
          `}
  }
`;

export const BCInput = styled.textarea`
  appearance: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  color: #000;
  display: block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  line-height: 14px;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  resize: none;
  background-color: transparent;

  border-radius: 3px;
  border: solid 2px #e5e5e5;
  width: 100%;
  font-size: 18px;
  color: #777 !important;
  opacity: 1;
  min-height: 54px;
  vertical-align: middle;
  padding: 0;

  padding: 10px 70px 10px 10px;
  height: 60px;
  line-height: 1.3em;
`;

export const BCLabelFile = styled.label`
  display: unset;
  margin: unset;
`;

export const BCDrIconAttach = styled(DrIcon).attrs(() => ({
  name: 'attachment',
}))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 28px;
  color: #777;
  right: 50px;
  cursor: pointer;

  &:hover {
    @media (hover: hover) {
      color: #ed145b;
    }
  }
`;

export const BCButtonChat = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: transparent;
  border: 0;
  right: 20px;

  &:hover i {
    @media (hover: hover) {
      color: #3f56a5;
    }
  }
`;

export const BCDrIconSend = styled(DrIcon).attrs(() => ({
  name: 'send',
}))`
  font-size: 28px;
  color: #777;
`;

export const BCCardChat = styled.div`
  height: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const BCCardMessages = styled.div`
  overflow-y: scroll;
  padding-right: 20px;
  flex: 1 0 0;
`;

export const DivSendingMsg = styled.div`
  position: relative;
  display: flex;
  margin-top: 1rem;
`;

export const LoadingSendMsg = styled(DrLoading).attrs({
  customLoading: true,
  notCentralize: true,
  ratio: 0.4,
  drColor: '#777',
  maisColor: '#777',
})`
  margin-left: 0.5rem;
`;

export const BCMsg = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
`;

export const BCRowFooterMsg = styled(Row)<BCRowFooterMsgProps>`
  margin: 10px 0 0;
  padding: 5px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const BCAnchor = styled.a<PropOther>`
  overflow-wrap: break-word;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${({ other }) => (other ? '#777' : '#fff')};

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

import styled from 'styled-components';

export const CheckboxDepedents = styled.input`
  position: absolute;
  margin-left: 0;
  height: 30px;
  width: 30px;
  right: 30px;
  bottom: 60px;

  z-index: 5;
`;

import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { useAuth } from '../../hooks/auth';
import DrCancelSchedule from './dr-cancel-schedule';

const CancelSchedule: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { attendance } = useAuth();

  return (
    <>
      <Header />
      <DrCancelSchedule cancelData={attendance} />
      <Footer />
    </>
  );
};
export default CancelSchedule;

import styled from 'styled-components';

export const TextBanner = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #3f56a5;
  font-weight: 700;
  font-size: 28px;
`;

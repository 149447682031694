import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import HelmetComponent from '../../components/dr-helmet-component';
import NewsDetails from '../../components/dr-news-details';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { scrollPositionTop } from '../../utils/bibli';

interface IdRoute {
  id: string;
}

const NewsPage: React.FC = () => {
  const { params } = useRouteMatch<IdRoute>();

  useEffect(() => {
    scrollPositionTop();
  }, []);

  return (
    <>
      <HelmetComponent title="Notícias" />
      <Header />
      <Container>
        <NewsDetails code={params.id} />
      </Container>
      <Footer />
    </>
  );
};

export default NewsPage;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrIcon from '../../components/dr-icon-font';
import DrBoxRecoverPassword from './dr-box-recover-password';
import { BoxContainer, Box } from '../../styles/box';

const RecoverPassword: React.FC = () => (
  <BoxContainer>
    <Container className="container-geral recover-password">
      <Row>
        <Col xl={5} className="mb-4 p-4">
          <Box>
            <DrBox
              iconColor="#ed145b"
              icon={<DrIcon name="padlock" color="#ed145b" />}
              title="Redefinir Senha"
              titleColor="#fcaf17"
            >
              <DrBoxRecoverPassword />
            </DrBox>
          </Box>
        </Col>
        <Col xl={7}>
          <Box>
            <DrHeaderWellcome />
            <Banner />
          </Box>
        </Col>
      </Row>
      <Footer />
    </Container>
  </BoxContainer>
);

export default RecoverPassword;

import styled from 'styled-components';
import { darken } from 'polished';

export interface ActiveProps {
  active?: boolean;
}
export const BoxFicha = styled.div<ActiveProps>`
  border: solid 2px #e5e5e5;
  border-radius: 5px;
  padding: 20px;
  overflow: hidden;
  background-color: ${(props) => (props.active ? '#f1f1f1' : '#ffffff')};
  transition: all 0.2s ease-in-out;
  @media (max-width: 1199px) {
    .d-flex {
      justify-content: center !important;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 767px) {
    .d-flex {
      flex-direction: column;
      justify-content: center !important;
      margin-bottom: 15px;
      .baixar {
        margin-left: 0 !important;
        margin-top: 15px;
      }
    }
  }
`;
export const BoxFichaTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  margin: 0;
`;

export const BoxFichaHeader = styled.div``;
export const BoxFichaBody = styled.ul`
  margin: 0;
  padding: 0;
`;
export const Li = styled.li`
  position: relative;
  padding: 0px 0;
  :first-child {
    padding-top: 40px;
    ::before {
      top: 20px;
    }
  }
  ::before {
    content: '';
    position: absolute;
    height: 2px;
    width: calc(100% + 40px);
    background-color: #e5e5e5;
    top: 0;
    left: -20px;
    z-index: 5;
  }
`;
export const Container = styled.div`
  position: relative;
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 853px;
    border-radius: 4px;

    .blue-button {
      border: 2px solid #4156a6;
      height: 45px;
      cursor: default;
      color: #4156a6;
    }

    .blue {
      border: 2px solid #4156a6;
      width: 50px;
      margin: auto;
      color: #4156a6;
      &:hover {
        color: #ffffff;
        background: ${darken(0.08, '#4156a6')};
      }
    }

    .red-button {
      border: 2px solid #9f1e49;
      height: 45px;
      cursor: default;
      color: #9f1e49;
      svg path {
        stroke: #9f1e49;
      }
    }

    .red {
      border: 2px solid #9f1e49;
      width: 50px;
      margin: auto;
      color: #9f1e49;
      svg path {
        stroke: #9f1e49;
      }
      &:hover {
        color: #ffffff;
        background: ${darken(0.08, '#9f1e49')};
      }
    }

    .green-button {
      border: 2px solid #62bb46;
      color: #62bb46;
      height: 45px;
      cursor: default;
      svg path {
        stroke: #62bb46;
      }
    }

    .green {
      border: 2px solid #62bb46;
      width: 50px;
      margin: auto;
      color: #62bb46;
      svg path {
        stroke: #62bb46;
      }
      &:hover {
        color: #ffffff;
        background: ${darken(0.08, '#62bb46')};
      }
    }

    .orange-button {
      border: 2px solid #f58220;
      color: #f58220;
      height: 45px;
      cursor: default;
      svg path {
        stroke: #f58220;
      }
    }

    .orange {
      border: 2px solid #f58220;
      width: 50px;
      margin: auto;
      color: #f58220;
      svg path {
        stroke: #f58220;
      }
      &:hover {
        color: #ffffff;
        background: ${darken(0.08, '#f58220')};
      }
    }
  }

  h3 {
    position: relative;
    font-size: 1.8rem;
    color: #4156a6;
  }

  h4 {
    position: relative;
    color: black;
    font-size: 2rem;
    font-weight: bold;
    color: #4156a6;
  }

  @media screen and (max-width: 850px) {
    h1 {
      position: relative;
      font-size: 2.5rem;
      color: #4156a6;
    }

    h3 {
      position: relative;
      font-size: 1.5rem;
      color: #4156a6;
    }

    h4 {
      position: relative;
      color: black;
      font-size: 1.5rem;
      font-weight: bold;
      color: #4156a6;
    }
  }
`;
export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0;
  background: transparent;
  font-weight: bold;
  color: #000000;
  border-radius: 20px;
  font-size: 16px;
  transition: background 0.2s;
  bottom: 0;

  @media screen and (max-width: 850px) {
  }
`;

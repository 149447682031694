import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { isSupported, Messaging } from 'firebase/messaging/sw';
import { getMessaging, getToken } from 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyB2n_MbT1CwOjTwxAoyTYj9iAHvJ-UBYz0',
  authDomain: 'drmais.firebaseapp.com',
  projectId: 'drmais',
  storageBucket: 'drmais.appspot.com',
  messagingSenderId: '902594411134',
  appId: '1:902594411134:web:4a5a9da93f50bdb532362f',
  measurementId: 'G-7VF9FN42KF',
};

const firebaseApp = initializeApp(config);
getAnalytics(firebaseApp);
let messaging;

const isSupport = async (): Promise<boolean> => {
  const result = await isSupported();
  return result;
};

const message = async (): Promise<Messaging> => {
  if (await isSupported()) {
    messaging = getMessaging(firebaseApp);
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        await navigator.serviceWorker
          .register('/firebase-messaging-sw.js', {
            scope: '/',
            updateViaCache: 'none',
          })
          .then(async (reg) => {
            // eslint-disable-next-line no-console
            // console.log('SW registered: ', reg);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log('ServiceWorker registration failed: ', err);
          });
      });
    }
  }
  return messaging;
};

const getTokenFB = async (): Promise<string | undefined> => {
  let token: string | undefined;
  if (Notification.permission !== 'denied') {
    await Notification.requestPermission();
  }
  if (
    Notification.permission === 'granted' &&
    'Notification' in window &&
    (await isSupported())
  ) {
    await getToken(messaging)
      .then((currentToken) => {
        token = currentToken;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('getToken error: ', err);
      });
  }

  return token;
};

export { message, getTokenFB, isSupport };

import styled from 'styled-components';

interface TextColorsProps {
  color?: string;
}

export const BoxLogin = styled.div`
  position: relative;
`;
export const BoxLoginBack = styled.a`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  i {
    font-size: 22px;
  }
`;

export const BoxLoginTitle = styled.div`
  text-align: center;
  margin: 10px 0;
`;
export const BoxLoginTitleH2 = styled.h2<TextColorsProps>`
  font-size: 34px;
  line-height: 1.29412em;
  font-weight: 700;
  color: ${(props) => props.color};
  @media screen and (max-width: 1199px) {
    font-size: 28px;
  }
`;
export const BoxLoginTitleH3 = styled.h3<TextColorsProps>`
  font-size: 18px;
  line-height: 1.30769em;
  margin-bottom: 15px;
  font-weight: 700;
  color: ${(props) => props.color};
  @media screen and (max-width: 1199px) {
    font-size: 20px;
  }
`;
export const BoxLoginTitleH4 = styled.h4<TextColorsProps>`
  font-size: 20px;
  line-height: 1.30769em;
  font-weight: 700;
  word-break: break-word;
  text-align: justify;
  color: ${(props) => props.color};
`;
export const BoxLoginIcon = styled.div`
  transition: all 0.2s ease-in-out;
  text-align: center;
  margin-top: 15px;

  i {
    position: relative;
    font-size: 85px;
    @media screen and (max-width: 1199px) {
      font-size: 55px;
    }
  }
`;
export const BoxLoginChangeEmail = styled.div``;

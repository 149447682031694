import styled from 'styled-components';

export const CardGeralBody = styled.div`
  width: 100%;
  flex-grow: 1;

  :after,
  :before {
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    color: #777;
    margin: 0;
    padding: 0;
  }
`;

import styled from 'styled-components';

export const SectionStrPg = styled.section`
  position: relative;
  height: calc(100vh - 200px);

  @media (min-width: 576px) {
    height: calc(100vh - 180px);
  }

  @media (min-width: 1200px) {
    height: calc(100vh - 260px);
  }
`;

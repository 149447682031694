import styled from 'styled-components';

export const ButtonsRedeSocial = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  justify-content: center;
  .facebook {
    button {
      :hover {
        border-radius: 50px !important;
        box-shadow: 0 10px 30px 1px rgb(0 0 0 / 8%);
        transition: all 0.2s ease-in-out;
      }
      i {
        margin: 0 !important;
      }
    }
  }
  .google {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    button {
      background-color: #fff !important;

      height: 47px;
      width: 80px;
      font-size: 26px !important;
      border: 1px solid #e5e5e5 !important;
      border-radius: 4px !important;
      box-shadow: none !important;
      justify-content: center;

      span {
        padding: 0 !important;
      }
      :hover {
        border-radius: 50px !important;
        box-shadow: 0 10px 30px 1px rgb(0 0 0 / 8%) !important;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .apple {
    margin-left: 10px;

    button {
      display: flex;

      justify-content: center;
      align-items: center;
      align-itens: center;
      margin: 0 auto;
      height: 47px;
      width: 80px;
      border: 1px solid #e5e5e5 !important;
      border-radius: 4px !important;
      box-shadow: none !important;

      :hover {
        border-radius: 50px !important;
        box-shadow: 0 10px 30px 1px rgb(0 0 0 / 8%) !important;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

import styled from 'styled-components';

export const ItemContainer = styled.div`
  border: solid 2px #e5e5e5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const ItemTitle = styled.h5`
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin: 0;
`;

export const ArchiveWarn = styled.div`
  text-align: 'start';
  color: #ed145b;
  margin: 0;
`;

export const NewFileContainer = styled.div`
  background-color: #e5e5e5;
  border-top: solid 2px #c4c4c4;
  padding-top: 10px;
  margin-top: 10px;
`;

import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import {
  executeUrl,
  handleConcatUserData,
  handleUseApiLog,
  valueParam,
} from '../utils/bibli';
import { formatDate } from '../utils/formatDates';

export interface Pessoa {
  ie_notifica_whatsapp?: 'S' | 'N';
  ie_notifica_noticias?: 'S' | 'N';
  ie_notifica_dicas?: 'S' | 'N';
  ie_notifica_informacoes?: 'S' | 'N';
  ie_grau_parentesco?: string;
  ie_google?: string;
  ie_facebook?: string;
  ie_apple?: string;
  ie_como_conheceu?: string;
  idtm_usuario?: number;
  idpessoa_fisica: number;
  first_name: string;
  nm_pessoa: string;
  nm_social: string;
  data_nascimento: string;
  dt_nascimento: string;
  ds_sexo: string;
  ie_sexo: string;
  nr_cpf: string;
  nm_mae: string;
  nm_pai: string;
  nr_identidade: string;
  ds_orgao_emissor_ci: string;
  nr_cert_nasc: string;
  data_emissao_ci: string;
  idpessoa_fisica_compl: number;
  cep: string;
  ds_logradouro: string;
  ds_complemento: string;
  ds_bairro: string;
  ds_municipio: string;
  sg_uf: string;
  ds_email: string;
  nr_ddi_celular: string;
  nr_ddd_celular: string;
  nr_celular: string;
  nr_endereco: string;
  ds_status: string;
  dt_insert: Date;
  dt_update: Date;
  status: string;
  url_img: string | null;
  ie_whatsapp: 'S' | 'N';
}

export interface User extends Pessoa {
  idtm_usuario: number;
  assinaturas_pln: AttendancePlan[];
  jwt: JwtAttributes;
  tm_usuario_grupo_familiar: ArrayMemberGroup;
  tm_usuario_cartao: ArrayCreditCard;
  nr_endereco: string;
  ds_texto_politica_priv: string | null;
  dt_aceite_politica_priv: string | null;
  idestabelecimento: number;
  terms: TermsProps;
  idfacebook: string;
  idgoogle: string;
  ds_produto: string | null;
  teste_complete: boolean;
}
export interface MemberType extends Pessoa {
  idtm_usuario_grupo_familiar: number;
  ie_grau_parentesco: string;
  ds_grau_parentesco: string;
}

export interface UserMember extends User {
  idtm_usuario_grupo_familiar: number;
  ie_grau_parentesco: string;
  ds_grau_parentesco: string;
}

export interface AttendanceDataTypes {
  tipo_atend?: 'EL' | 'PA' | 'DO'; // Eletivo | Pronto Atendimento | Domiciliar
  qtd_periodo_contrato?: number;
  qtd_max_dependente?: number;
  iniciar_consulta?: string;
  msg_vlr_assinatura?: string;
  msg_vlr_consulta?: string;
  opcao_parcela?: any[];
  assinaturas_pln?: any;
  isSchedule?: string;
  ie_recorrente?: string;
  dt_agenda?: string;
  ds_especialidade?: string;
  idconvenio_telemed_vlr?: string;
  idproduto_serv?: string;
  idxBtnAttendance?: number;
  idagenda_consulta_origem?: string;
  ds_epecialidade?: string;
  dt_agendamento?: any;
  agendarPaciente?: any;
  ie_anexos?: string;
  idperfil?: number;
  ie_clinica?: string;
  idsetor_atendimento?: number;
  data_nascimento?: string;
  anexos?: Array<any>;
  nr_conselho?: string;
  nm_pessoa_paciente?: string;
  nm_pessoa_medico?: string;
  atendimento?: Atendimento;
  idpessoa_paciente?: number;
  idpessoa_medico?: number;
  idagenda_consulta?: number;
  idestabelecimento_tipo_atendimento?: number;
  idpessoa_titular?: number;
  idatendimento_paciente?: number;
  nm_paciente?: string;
  tme?: any;
  ordem?: number;
  ds_button_acao?: string;
  situacao?: string;
  ie_retorno?: string;
  fromReturn?: boolean;
  idcontrato?: number;
  idconvenio?: string;
  idparametro?: number;
  modalidade?: number;
  idespecialidade?: number;
  idclassificacao?: number;
  ie_classificacao_agenda?: string;
  ie_telemedicina?: string;
  ie_tipo_agenda?: number;
  ie_tipo_atendimento?: number;
  idconta_receber_cartao_movto?: number;
  idficha_avaliacao_gerada?: number;
  idatendimento_paciente_anexo?: number;
  vl_total_servico?: number;
  vl_servico?: number;
  vl_atendimento?: number;
  vl_desconto?: number;
  nr_parcela?: number;
  vl_consulta?: number;
  dsKey?: string;
  idSocketSala?: string;
  ie_dependente?: string;
  ie_categoria_atend?: string;
  qtd_idade_min?: number;
  qtd_idade_max?: number;
  idroute?: string;
  avaliacao_atendimento?: EvaluationFormData;
  idestabelecimento?: number;
  nr_dias_restante_ret?: number;
  ds_produto?: string;
  singleType?: boolean;
  fromDashboard?: boolean;
  tipos_atendimento?: Array<number>;
  origem_cartao?: boolean;
  avisos?: any;
  url_img_medico?: string;
  patient?: Pessoa;
}
export interface LetAppTypes {
  notificationReqApi: boolean;
  termsReqApi: boolean;
  protocolReqApi: boolean;
  myNotifications: NotificationProps[] | undefined;
  myProtocols?: [] | undefined;
  dateValue: string;
  hash: string;
}

export interface NotificationProps {
  ds_titulo_notificacao: string;
  ds_subtitulo_notificacao: string;
  ds_notificacao: string;
  idpessoa_fisica_notificacao: number;
  data_notificacao: string;
  dt_leitura: string;
  ie_estagio_notificacao: string;
  ds_estagio_notificacao: string;
  dt_limite_notificacao: string;
}
export type ArrayMemberGroup = Array<MemberType>;

export type ArrayCreditCard = Array<CreditCardType>;
export interface CreditCardType {
  ie_forma_pg: string;
  ie_bandeira: string;
  nm_titular: string;
  idtm_usuario_cartao: number;
  ds_nrcartao: string;
  ds_validade: string;
  nr_cvv: string;
}
export interface AttendancePlan {
  idcontrato: any;
  idproduto_serv: number;
  ds_produto: string;
  dependentes: any[];
  ds_informacao: string;
  ie_prazo_contrato: string;
  ds_prazo_contrato: string;

  qtd_max_dependente: number;
  regra_pagto: PaymentRules[];
  produto_ser: ProductServ;
  contrato_regra_pagto: ContractRulesPayment;
}

interface ContractRulesPayment {
  conta_receber_cartao_mvtrec: AccountCardTrec;
  ds_forma: string;
  idcontrato_regra_pagto: string;
  ie_forma: string;
  vl_pagto: number;
}
interface AccountCardTrec {
  idconta_receber_cartao_mvtrec: number;
  cd_pg_recorrente: string;
  dt_fim_recorrencia: string;
  dt_inicio_recorrencia: string;
  ie_periodicidade: string;
  vl_programado: number;
}
interface ProductServ {
  ds_informacao: string;
  ds_produto: string;
  idproduto_serv: number;
  produto_serv_tipo_atd: ProductServAtdType[];
  itens: string[];
}
interface ProductServAtdType {
  ds_tipo_atendimento: string;
  idestabelecimento_tipo_atendimento: number;
  idproduto_serv_tipo_atd: number;
}
interface PaymentRules {
  idproduto_serv_regra_pagto: number;
  ie_forma: string;
  ds_forma: string;
  ds_periodicidade: string;
  vl_pagto: number;
  vl_economia: number;
}
interface IAttendanceDataTypes {
  attendance: AttendanceDataTypes;
}

export interface EvaluationFormData {
  idatendimento_paciente_avaliacao?: number;
  nr_estrela: number | null;
  ds_opiniao: string | null;
}

interface Horario {
  idestabelecimento_hora_func: number;
  hr_inicial: string;
  hr_final: string;
}
interface Atendimento {
  msg: Atendimento | undefined;
  horario: Horario[];
  ie_aberto: string;
}

export interface SocketAuth {
  socket?: any;
  dsKey?: string;
  idSocketSala?: string;
}

interface JwtAttributes {
  dt_exp: null;
  idapi_token: number;
  token: string;
}
export interface TermsProps {
  dt_aceite_politica_priv?: boolean | null | undefined;
  dt_aceite_termo_uso?: boolean | null | undefined;
  idtexto_termo_uso?: number;
  idtexto_politica_priv?: number;
  ds_titulo_politica_priv?: any;
  ds_titulo_termo_uso?: any;
  ds_texto_termo_uso?: any;
  ds_texto_politica_priv?: any;
}

interface AuthUserState {
  user: User;
  authUserToken: string;
}

interface SignInCredentials {
  login: string;
  password: string;
}

export interface CepValues {
  ds_municipio: string;
  cep: string;
  ds_bairro: string;
  ds_logradouro: string;
  sg_uf: string;
  ds_uf: string;
}

interface AuthUser {
  user: User;
  authUserToken: string;
}

interface Establishment {
  cep: string;
  cnpj: string;
  ds_estabelecimento: string;
  ds_razao_social: string;
  idestabelecimento: number;
  nm_fantasia: string;
  url_logo: string;
}
interface VariableValues {
  url_img_login?: string;
  url_img_consulta?: string;
  frase_consulta?: string;
  botao_consulta?: string;
  url_checklist?: string;
  url_loja_apple?: string;
  url_loja_google?: string;
  google_analytics: string;
  link_whatsapp: string;
  rg_hr_assinar?: string;
}
export interface ApplicationData {
  cd_build_aplicacao: string;
  cd_versao_atual: string;
  dominio: string;
  ds_aplicacao: string;
  ds_base_dados: string;
  ds_copyright: string;
  ds_cor_fonte: string;
  ds_cor_fundo: string;
  ds_icon_app: string;
  estabelecimento: Establishment;
  idaplicacao: number;
  ie_atualizacao_versao: string;
  ie_banco: string;
  ie_regra: string;
  ie_status_aplicacao: string;
  valores_parametros: any;
  valores_variaveis: VariableValues;
  ie_funcionamento: string;
}
export interface ParamsRequest {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  body?: any;
  pagination?: boolean;
  isPublic?: boolean;
  header?: any;
}

export interface AuthContextData {
  user: User;
  userFromLS: User;
  authUserToken: string;
  letApp: LetAppTypes;
  socketAuth: SocketAuth;
  setSocketAuth: React.Dispatch<React.SetStateAction<SocketAuth>>;
  attendance: AttendanceDataTypes;
  testeComplete: boolean;
  componentTest: boolean;
  handleOpenComponentTest: () => void;
  handleCloseComponentTest: () => void;
  inactivityModal: boolean;
  setInactivityModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenInactivityModal: () => void;
  handleCloseInactivityModal: () => void;
  whichRouteAmAt: string;
  applicationData: ApplicationData;
  getApplicationData(): Promise<any>;
  getPublicToken(method: string, url: string, body: any): Promise<any>;
  requestAPI({
    method,
    url,
    body,
    pagination,
    isPublic,
  }: ParamsRequest): Promise<any>;
  sendError(error: any): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<void>;
  setDataToken(): Promise<void>;
  setLogin(credentials: AuthUser): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  setDomainsData(): Promise<void>;
  getDomainsData(domain: string): any[];
  setTesteComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setComponentTest: React.Dispatch<React.SetStateAction<boolean>>;
  domainsResp: any;
  setAttendanceData(
    data: any,
    replace?: boolean,
    deleteParams?: string[]
  ): Promise<AttendanceDataTypes>;
  handleAddress(data: any): Promise<any>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const whichRouteAmAt = pathname;

  const isUserLogged = () =>
    JSON.parse(localStorage.getItem('@Dr+:UserID') || '{}');
  const userFromLS: User = isUserLogged();

  const [isPageFocused, setIsPageFocused] = useState<boolean>(true);
  const handleSetPageFocused = () => setIsPageFocused(true);
  const handleSetPageNotFocused = () => setIsPageFocused(false);

  const [domainsResp, setDomainsResp] = useState<any>({});
  const [data, setData] = useState<AuthUserState>(() => {
    const authUserToken = localStorage.getItem('@Dr+:AuthUserToken');
    const user = localStorage.getItem('@Dr+:UserID');

    if (authUserToken && user) {
      return {
        authUserToken,
        user: JSON.parse(user),
      };
    }
    return {} as AuthUserState;
  });

  const [attendance, setAttendance] = useState<IAttendanceDataTypes>(() => {
    const attendances = localStorage.getItem('@Dr+:Datas');

    if (attendances) {
      return {
        attendance: JSON.parse(attendances),
      };
    }
    return {} as IAttendanceDataTypes;
  });

  const [inactivityParam, setInactivityParam] = useState(true);
  const [inactivityModal, setInactivityModal] = useState(false);
  const handleOpenInactivityModal = () => setInactivityModal(true);
  const handleCloseInactivityModal = () => setInactivityModal(false);

  const [testeComplete, setTesteComplete] = useState(false);
  const [componentTest, setComponentTest] = React.useState(false);
  const handleOpenComponentTest = () => setComponentTest(true);
  const handleCloseComponentTest = () => setComponentTest(false);

  /* Caso esteja em uma rota diferente das abaixo e o teste de componente for falso, a inatividade pode acontecer */
  const exceptedRoutes = ['/attendance-now/', '/stream-page'];
  const isActiveRoute = exceptedRoutes.some(
    (route) => pathname === route && testeComplete
  );
  /* Caso esteja em uma rota diferente das abaixo e o teste de componente for falso, a inatividade pode acontecer */

  const [applicationData, setApplicationData] = useState<ApplicationData>(
    {} as ApplicationData
  );

  const [socketAuth, setSocketAuth] = React.useState<SocketAuth>({
    socket: undefined,
    dsKey: undefined,
    idSocketSala: undefined,
  });

  useEffect(() => {
    async function init() {
      await getApplicationData();
      await setDomainsData();
      await updateParams();

      navigator.serviceWorker.onmessage = (messageEvent) => {
        handleUseApiLog({
          requestAPI,
          url: window.location.href,
          action: 'PushNotification',
          message: 'onMessage',
          data: messageEvent.data,
          sendError,
        });
      };
    }
    init();
  }, []);

  function signOut() {
    localStorage.removeItem('@Dr+:Session');
    localStorage.removeItem('@Dr+:AuthUserToken');
    localStorage.removeItem('@Dr+:UserID');
    localStorage.removeItem('@Dr+:Datas');
    localStorage.removeItem('@ForumData');
    localStorage.setItem('logout', JSON.stringify(new Date()));

    setData({ authUserToken: '' } as AuthUserState);
  }

  const getPublicToken = useCallback(async (method, url, body) => {
    const dataAtual = new Date();
    const tokenLocalStorage = localStorage.getItem('TokenPublic');

    const dateToMilliseconds = (date) => {
      const milliseconds = moment(date).valueOf();
      return milliseconds;
    };
    let tokenPublic = tokenLocalStorage
      ? JSON.parse(tokenLocalStorage)
      : undefined;

    if (tokenPublic && tokenPublic.dt_exp >= dateToMilliseconds(dataAtual)) {
      return tokenPublic.token;
    }
    localStorage.removeItem('TokenPublic');

    tokenPublic = await executeUrl('POST', '/authenticate', {
      user: process.env.REACT_APP_USER_TOKEN,
      password: process.env.REACT_APP_PASS_TOKEN,
      idaplicacao: process.env.REACT_APP_IDAPLICACAO,
      method,
      url,
      body,
    });
    localStorage.setItem('TokenPublic', JSON.stringify(tokenPublic));

    return tokenPublic.token;
  }, []);

  const requestAPI = useCallback<AuthContextData['requestAPI']>(
    async ({ method, url, body, pagination, isPublic }: ParamsRequest) => {
      let token: string | null;

      try {
        token = localStorage.getItem('@Dr+:AuthUserToken');

        if (
          (isPublic && Object.keys(data).length && !data?.user) ||
          !token ||
          isPublic
        )
          token = await getPublicToken(method, url, body);

        const resp = await executeUrl(method, url, body, pagination, {
          'x-access-token': `${token}`,
        });

        return resp;
      } catch (err: any) {
        if (
          err.message
            .toUpperCase()
            .includes('Erro ao validar permissão do Token'.toUpperCase())
        ) {
          localStorage.removeItem('TokenPublic');
          signOut();
          history.push('/');
          return null;
        }

        throw err;
      }
    },
    [getPublicToken, history]
  );

  const sendError = useCallback(async (error: any): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('sendError!', error);
    if (
      error &&
      error.message &&
      error.name !== 'ErrorAPIRest' &&
      !(error instanceof Yup.ValidationError)
    ) {
      try {
        const msg: string = error.message;
        if (msg && !msg.includes('connect ECONNREFUSED')) {
          await requestAPI({
            method: 'POST',
            url: '/errorApplication',
            body: {
              error: msg.replace(/"/gim, ''),
              stack: error.stack,
              data: error.data,
            },
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Erro ao executar sendError!', e, 'Erro original: ', error);
      }
    }
  }, []);

  const getApplicationData = useCallback(async () => {
    try {
      const resp = await requestAPI({
        method: 'GET',
        url: `/dadosAplicacao/${process.env.REACT_APP_IDAPLICACAO}`,
      });

      if (resp) {
        const x: any = document.getElementById('favicon');
        if (x) x.href = `${resp.ds_icon_app || resp.estabelecimento.url_logo}`;
        setApplicationData(resp);
      }
    } catch (err: any) {
      sendError(err);
      history.push('/dashboard');
    }
  }, [history]);

  const updateUser = useCallback(
    (params: any) => {
      const localUser: User = JSON.parse(
        localStorage.getItem('@Dr+:UserID') || '{}'
      );

      handleConcatUserData(params);

      params = Object.assign(localUser, params);

      localStorage.setItem('@Dr+:UserID', JSON.stringify(params || ''));

      setData({
        authUserToken: data.authUserToken,
        user: params,
      });
    },
    [data.authUserToken]
  );

  const setLogin = useCallback(
    async (auth: AuthUser) => {
      const { authUserToken, user } = auth;

      handleConcatUserData(user);
      localStorage.setItem('@Dr+:AuthUserToken', authUserToken);
      localStorage.setItem('@Dr+:UserID', JSON.stringify(user || ''));

      setData({
        user,
        authUserToken,
      });

      history.push('/dashboard');
    },
    [history]
  );

  const isUpdate = useCallback(() => {
    let result = true;

    const x = localStorage.getItem('@Dr+:DtUpdate');

    if (x) {
      result = formatDate(new Date(), 'DD/MM/YYYY') !== x;
    }
    if (result) {
      localStorage.setItem(
        '@Dr+:DtUpdate',
        formatDate(new Date(), 'DD/MM/YYYY')
      );
    }

    return result;
  }, []);

  const updateParams = useCallback(async () => {
    if (data && data.user && data.user.idpessoa_fisica) {
      try {
        const response = await requestAPI({
          method: 'GET',
          url: `/tmUsuarioValidToken`,
        });
        const { user } = response;
        handleConcatUserData(user);

        localStorage.setItem('@Dr+:UserID', JSON.stringify(user || ''));
        setData({
          user,
          authUserToken: user.jwt.token,
        });
      } catch (err: any) {
        sendError(err);
        signOut();
        await getApplicationData();
        history.push('/');
      }
    }
  }, [data, history, requestAPI]);

  const signIn = useCallback(
    async ({ login, password }) => {
      const response = await requestAPI({
        method: 'POST',
        url: '/tmUsuarioAutenticar',
        body: {
          login,
          password,
        },
        isPublic: true,
      });

      const { user } = response;
      await setLogin({ user, authUserToken: user.jwt.token });
    },
    [requestAPI, setLogin]
  );

  const setDataToken = useCallback(async () => {
    const authUser = localStorage.getItem('@Dr+:Session');
    const userStr = localStorage.getItem('@Dr+:UserID');
    const user = JSON.parse(userStr || '{}');
    const authUserToken = authUser || '';
    localStorage.setItem('@Dr+:AuthUserToken', authUserToken);
    // localStorage.setItem('@Dr+:UserID', JSON.stringify(user));
    setData({ user, authUserToken });
  }, []);

  const setAttendanceData = useCallback(
    async (
      datas: AttendanceDataTypes,
      replace?: boolean,
      deleteParams?: string[]
    ) => {
      let dados = datas;

      if (!replace) {
        const dataStr = await localStorage.getItem('@Dr+:Datas');

        dados = JSON.parse(dataStr || '{}');
        dados = Object.assign(dados, datas);
      }

      if (deleteParams) {
        deleteParams.forEach((param, idx) => {
          delete dados[param];
        });
      }

      await localStorage.setItem('@Dr+:Datas', JSON.stringify(dados));

      setAttendance({ attendance: dados });
      return dados;
    },
    [data]
  );

  const setDomainsData = useCallback(async () => {
    let dados;
    const domains = localStorage.getItem('@Dr+:Domains');

    if (isUpdate() || !domains) {
      try {
        const resp = await requestAPI({
          method: 'GET',
          url: `dominioValorObj?iddominio=543,387,410,616,622,623,640,641`,
          isPublic: true,
        });
        dados = resp;
        setDomainsResp(dados);
        localStorage.setItem('@Dr+:Domains', JSON.stringify(dados));
      } catch (err: any) {
        sendError(err);
        history.push('/dashboard');
      }
    }
  }, [isUpdate, requestAPI]);

  const getDomainsData = (domain: string) => {
    let dados: any;
    let result = [];
    const domains = localStorage.getItem('@Dr+:Domains');
    if (domains) {
      dados = JSON.parse(domains || '{}');
      result = dados[domain] || [];
    }

    return result;
  };

  const handleAddress = useCallback(
    async (dados: any) => {
      const { uf, city, cep } = dados;
      let resp;
      let url = `/ufs`;
      if (uf) url = `/municipios/${uf}`;

      if (city) url = `bairros/${city}&itensPerPage=9999`;

      if (cep)
        url = `/logradouroPorCep?logradouro_cep.cep=${cep}&logradouro_cep.status=A&exact=S&itensPerPage=9999`;
      try {
        resp = await requestAPI({ method: 'GET', url });
      } catch (err: any) {
        sendError(err);
        history.push('/dashboard');
      }

      return resp.data ? resp.data : resp;
    },
    [requestAPI]
  );

  /* Realiza logout do usuário de outras abas do navegador */
  const handleStorageLogout = (e: StorageEvent) => {
    if (e.key === 'logout' && !isPageFocused) {
      localStorage.removeItem('logout');
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageLogout);
    window.addEventListener('focus', handleSetPageFocused);
    window.addEventListener('blur', handleSetPageNotFocused);

    return () => {
      window.removeEventListener('storage', handleStorageLogout);
      window.removeEventListener('focus', handleSetPageFocused);
      window.removeEventListener('blur', handleSetPageNotFocused);
    };
  }, [isPageFocused]);
  /* Realiza logout do usuário de outras abas do navegador */

  /* Caso o usuário não tenha interagido após o período do parâmetro, será redirecionado */
  const inactivityToleranceTime =
    Number(valueParam(applicationData, 283)) * 60000;
  const inactivityTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  const handlePageInteraction = () => {
    clearTimeout(inactivityTimeoutId.current);
    inactivityTimeoutId.current = setTimeout(() => {
      if (window.location.href.includes('/dashboard')) {
        window.location.reload();
      } else {
        history.push('/dashboard');
        handleOpenInactivityModal();
      }
    }, inactivityToleranceTime);
  };

  useEffect(() => {
    setInactivityParam(!isActiveRoute && !isPageFocused);
  }, [isActiveRoute, isPageFocused]);

  useEffect(() => {
    if (inactivityParam && !inactivityModal && inactivityToleranceTime > 0) {
      handlePageInteraction();

      window.addEventListener('focus', handlePageInteraction);
    }

    return () => {
      window.removeEventListener('focus', handlePageInteraction);
      clearTimeout(inactivityTimeoutId.current);
    };
  }, [inactivityParam, inactivityModal, inactivityToleranceTime]);
  /* Caso o usuário não tenha interagido após o período do parâmetro, será redirecionado  */

  return (
    <AuthContext.Provider
      value={{
        applicationData,
        user: data.user,
        userFromLS,
        authUserToken: data.authUserToken,
        attendance: attendance.attendance,
        testeComplete,
        componentTest,
        handleOpenComponentTest,
        handleCloseComponentTest,
        inactivityModal,
        setInactivityModal,
        handleOpenInactivityModal,
        handleCloseInactivityModal,
        whichRouteAmAt,
        letApp: {
          notificationReqApi: true,
          termsReqApi: false,
          protocolReqApi: true,
          myNotifications: undefined,
          myProtocols: undefined,
          dateValue: '',
          hash: '',
        },
        socketAuth,
        setSocketAuth,
        getApplicationData,
        getPublicToken,
        requestAPI,
        sendError,
        setDataToken,
        signIn,
        setLogin,
        signOut,
        updateUser,
        domainsResp,
        setDomainsData,
        getDomainsData,
        setAttendanceData,
        setTesteComplete,
        setComponentTest,
        handleAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };

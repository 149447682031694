import styled from 'styled-components';

export const BoxPaynow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const BPUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

interface BPLiProps {
  color?: string;
}

export const BPLi = styled.li<BPLiProps>`
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55556em;
  color: ${(props) => props.color};
`;

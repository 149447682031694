import React, { useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import DrControlScreen from '../../components/dr-control-screen';
import CardPayOptions from './card-pay-options';
import { useIsMounted } from '../../hooks/is-mounted';
import { AuthContextData, useAuth, User } from '../../hooks/auth';
import { useToast, ToastContextData } from '../../hooks/toast';
import DrModal, { DrModalProps } from '../../components/dr-modal';
import { valueParam } from '../../utils/bibli';

export interface OptionsInstallmentTypes {
  descricao?: string;
  qtd?: number;
  valor?: string;
  idproduto_serv_regra_pagto?: number;
  ie_forma?: string;
  ds_forma?: string;
  ds_periodicidade?: string;
  vl_pagto?: number;
  vl_economia?: number;
}

export interface RegraPagtoType {
  idproduto_serv_regra_pagto: number;
  ie_forma: string;
  ds_forma: string;
  ds_periodicidade: string;
  vl_pagto: number;
  vl_economia: number;
}

export interface AtendValType {
  idconvenio: number;
  idconvenio_telemed_vlr: number;
  nr_parcela: number;
  opcao_parcela: OptionsInstallmentTypes[];
  vl_atendimento: number;
  vl_desconto: number;
  vl_servico?: number;
  ie_retorno?: 'S' | 'N';
}

export interface SignatureType {
  [x: string]: any;
  ds_produto: string;
  ds_informacao: string;
  ie_prazo_contrato: string;
  ds_prazo_contrato: string;
  qtd_periodo_contrato: number;
  qtd_max_dependente: number;
  regra_pagto: OptionsInstallmentTypes[];
}
export interface StatusType {
  error: any;
  idcontrato: number;
  ds_titulo_contrato: string;
  dt_assinatura: string;
}
export interface ReturnAttendanceType {
  ds_especialidade: string;
  dt_atend_medico: string;
  idatend_original: number;
  idconvenio: number;
  idconvenio_telemed_vlr: number;
  idmedico: number;
  ie_retorno: string;
  nm_paciente: string;
  nr_parcela: number;
  vl_atendimento: number;
  vl_desconto: number;
}
export interface RespTmAtendValType {
  atendimento_vlr?: AtendValType;
  assinaturas_pln?: SignatureType;
  status_pln?: StatusType;
  atendimento_retorno?: ReturnAttendanceType;
}
interface getTmAtendimentoValoresParams {
  attendance: AuthContextData['attendance'];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  requestAPI: AuthContextData['requestAPI'];
  setAttendanceData: AuthContextData['setAttendanceData'];
  history: any;
  addToast: ToastContextData['addToast'];
  respTmAtendValRef: React.MutableRefObject<RespTmAtendValType | undefined>;
  isMountedRef: React.MutableRefObject<boolean>;
  user: User;
  location: any;
  sendError: (message: any) => Promise<void>;
  isUploadPage: string;
}

export interface IdRoute {
  id: string;
}

interface CbChooseScreenParams {
  attendance: AuthContextData['attendance'];
  respTmAtendValRef: React.MutableRefObject<any>;
  params: IdRoute;
  addToast: ToastContextData['addToast'];
  setModal: React.Dispatch<
    React.SetStateAction<
      Omit<DrModalProps, 'controllerModal'> & {
        showModal: boolean;
      }
    >
  >;
  modal: any;
}

async function getTmAtendimentoValores({
  attendance,
  setLoading,
  requestAPI,
  setAttendanceData,
  history,
  addToast,
  respTmAtendValRef,
  isMountedRef,
  user,
  location,
  sendError,
  isUploadPage,
}: getTmAtendimentoValoresParams) {
  try {
    setLoading(true);
    const idpessoa_titular = user ? user.idpessoa_fisica : 0;
    const {
      idpessoa_paciente,
      idconvenio,
      ie_telemedicina,
      ie_tipo_atendimento,
      ie_classificacao_agenda,
      ie_tipo_agenda,
      idespecialidade,
      idestabelecimento_tipo_atendimento,
      ie_retorno,
    } = attendance;

    if (!idconvenio || !idestabelecimento_tipo_atendimento) {
      throw new Error('Convênio ou tipo de atendimento não informado!');
    }

    let url = `/tmAtendimentoValores?idpessoa_fisica=${idpessoa_paciente || 0}`;
    url += `&idconvenio=${idconvenio}`;
    url += `&idestabelecimento_tipo_atendimento=${idestabelecimento_tipo_atendimento}`;
    url += `&idpessoa_titular=${idpessoa_titular}`;

    if (idespecialidade && ie_tipo_atendimento !== 3)
      url += `&idespecialidade=${idespecialidade}`;

    const resp = await requestAPI({ method: 'GET', url });
    respTmAtendValRef.current = resp;

    let route = '';
    const localStorageDatas = {
      idpessoa_paciente,
      idconvenio,
      ie_telemedicina,
      ie_tipo_atendimento,
      ie_classificacao_agenda,
      ie_tipo_agenda,
      idespecialidade,
      idestabelecimento_tipo_atendimento,
      idcontrato: resp.status_pln && resp.status_pln.idcontrato,
      ...resp.atendimento_retorno,
      ...resp,
      ie_retorno,
    };
    if (
      resp.atendimento_retorno &&
      resp.atendimento_retorno.ie_retorno === 'S' &&
      attendance.fromDashboard
    ) {
      route = isUploadPage === 'S' ? 'upload-page' : 'attendance-options';
    } else if (
      resp.atendimento_retorno &&
      resp.atendimento_retorno.ie_retorno === 'S' &&
      attendance.ie_retorno === 'S'
    ) {
      route = 'return-attendance';
    } else if (resp.status_pln && resp.status_pln.idcontrato) {
      route = 'upload-page';
    }

    await setAttendanceData(localStorageDatas);

    if (route !== '') {
      history.push(route);
    }
    if (isMountedRef.current) {
      setLoading(false);
    }
  } catch (err: any) {
    sendError(err);
    if (isMountedRef.current) {
      addToast({
        type: 'error',
        title: 'Por favor, Tente novamente.',
        description: err.message.replace('Validation error: ', ''),
      });

      history.push('/types-care');
    }
  }
}

function cbChooseScreen({
  attendance,
  respTmAtendValRef,
  params,
  addToast,
  setModal,
  modal,
}: CbChooseScreenParams) {
  if (!respTmAtendValRef.current) {
    addToast({
      type: 'error',
      title: 'Por favor, Tente novamente.',
      description: 'Resposta inválida do servidor',
    });

    return <Redirect to="/attendance-types" />;
  }

  if (
    respTmAtendValRef.current.atendimento_retorno &&
    respTmAtendValRef.current.atendimento_retorno.ie_retorno === 'S' &&
    attendance.ie_retorno === 'S'
  ) {
    return <Redirect to="/return-attendance" />;
  }
  if (
    respTmAtendValRef.current.status_pln &&
    respTmAtendValRef.current.status_pln.idcontrato
  ) {
    return <Redirect to="/upload-page" />;
  }

  if (respTmAtendValRef.current.status_pln) {
    if (respTmAtendValRef.current.status_pln.error) {
      return (
        <>
          <DrModal
            controllerModal={[modal.showModal, setModal]}
            title="Atenção"
            buttons={{
              cancel: {
                title: 'Voltar',
                onClick: () => {
                  modal.showModal = false;
                  setModal({ showModal: false });
                },
              },
            }}
            content={<h4>{respTmAtendValRef.current.status_pln.error.msg}</h4>}
          />
          <CardPayOptions installments={respTmAtendValRef.current} />
        </>
      );
    }
  }

  return <CardPayOptions installments={respTmAtendValRef.current} />;
}

const PayOptions: React.FC = () => {
  const [isLoading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState({ showModal: true });
  const location = useLocation<any>();
  const {
    setAttendanceData,
    attendance,
    requestAPI,
    user,
    sendError,
    applicationData,
  } = useAuth();
  const isUploadPage = valueParam(applicationData, 241);
  const { addToast } = useToast();
  const history = useHistory();
  const isMountedRef = useIsMounted();
  const { params } = useRouteMatch<IdRoute>();
  const respTmAtendValRef = React.useRef<RespTmAtendValType>();

  useEffect(() => {
    getTmAtendimentoValores({
      attendance,
      setLoading,
      requestAPI,
      setAttendanceData,
      history,
      addToast,
      respTmAtendValRef,
      isMountedRef,
      user,
      location,
      sendError,
      isUploadPage,
    });
  }, []);

  const chooseScreen = React.useCallback(
    () =>
      cbChooseScreen({
        attendance,
        respTmAtendValRef,
        params,
        addToast,
        setModal,
        modal,
      }),
    [params, addToast]
  );

  return (
    <>
      <Header />
      <DrControlScreen isLoading={isLoading} chooseScreen={chooseScreen} />
      <Footer />
    </>
  );
};

export default PayOptions;

import styled from 'styled-components';

export const CardGeralBtn = styled.div`
  width: 100%;
  margin-top: 10px;

  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  & h2 {
    color: rgb(96, 187, 70);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
`;

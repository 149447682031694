import styled from 'styled-components';

export const Container = styled.div`
  .site-header_logo {
    display: table;
    margin: auto;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

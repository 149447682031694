import React from 'react';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { useAuth } from '../../hooks/auth';
import EquipamentTest from '../../components/dr-equipament-test';
import CardAttNow from './card-attendance-now';

const AttendanceNow: React.FC = () => {
  const { testeComplete } = useAuth();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!testeComplete ? (
        <>
          <Header />
          <EquipamentTest />
          <Footer />
        </>
      ) : (
        <>
          <CardAttNow />
          <Footer />
        </>
      )}
    </>
  );
};

export default AttendanceNow;

import React, { useCallback, useState, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { util } from 'bibliotecas/bibli-front';
import { useHistory } from 'react-router-dom';
import { CardBlank } from '../../../styles/card-blank';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { PageTitle } from '../../../styles/page-title';
import DrCardBlog from '../../../components/dr-card-blog';
import DrAlert from '../../../components/dr-alert';
import SemImagem from '../../../assets/img/sem-imagem.png';
import DrCardLoading from '../../../components/dr-card-loading';
import { useRequestData } from '../../../hooks/requestData';
import DrButtonLoading from '../../../components/dr-button-loading';
import DrModal from '../../../components/dr-modal';
import { valueParam } from '../../../utils/bibli';

interface NewsType {
  dt_cadastro: string;
  data_cadastro: string;
  enunciado: string;
  imagem: string;
  classificacao: string;
  descricao: string;
  codigo: number;
  ie_curtida: 'S' | 'N' | null;
  ie_processando?: boolean;
  qtd_curtida?: number;
}

function onErrorImgNews(event: React.SyntheticEvent<HTMLImageElement>) {
  const eventChanged = event;
  eventChanged.currentTarget.src = SemImagem;
}
function showNews(news: NewsType[], handleLike) {
  const { applicationData } = useAuth();
  return news.map((post: NewsType, idx: number) => (
    <Col md={8} lg={6} xl={4} key={post.codigo}>
      <DrCardBlog
        urlLink={`/news-page/${post.codigo}`}
        head={
          <img
            onError={(e: any) => onErrorImgNews(e)}
            width={370}
            height={210}
            src={
              // eslint-disable-next-line no-nested-ternary
              post.imagem && post.imagem.trim()
                ? post.imagem
                : applicationData
                ? valueParam(applicationData, 107)
                : ''
            }
            alt="Imagem da noticía"
          />
        }
        date={post.data_cadastro}
        title={`${post.descricao} - ${post.enunciado}`}
        urlDesc="Leia Mais"
        buttonLike={{
          active: post.ie_curtida === 'S',
          handleClick: () => handleLike(post, 'S', idx),
        }}
        buttonDislike={{
          active: post.ie_curtida === 'N',
          handleClick: () => handleLike(post, 'N', idx),
        }}
      />
    </Col>
  ));
}

const NewsContainer: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingPagination, setLoadingPagination] = useState(true);
  const [newsEnded, setNewsEnded] = useState(false);
  const [news, setNews] = useState<NewsType[]>([]);
  const [modal, setModal] = useState(false);
  const countRef = useRef(0);
  const { requestAPI, user, sendError } = useAuth();
  const { addToast } = useToast();
  const processingRef = useRef<boolean[]>([]);
  const buttonRef = useRef(null);
  const history = useHistory();

  const handleLike = useCallback(
    async (post: NewsType, data: 'S' | 'N', i: number) => {
      if (!user) {
        setModal(true);
        return;
      }

      setNews((prev) => {
        const newsArrayReturn = JSON.parse(JSON.stringify(prev));
        newsArrayReturn[i].ie_curtida =
          prev[i].ie_curtida === data ? null : data;
        return newsArrayReturn;
      });

      if (!processingRef.current[i]) {
        processingRef.current[i] = true;
        await requestAPI({
          method: 'PUT',
          url: `/tmNoticiaCurtida/${user.idtm_usuario}/${post.codigo}?curtida=${data}`,
        });
        processingRef.current[i] = false;
      }
    },
    [news, requestAPI, user?.idtm_usuario]
  );

  useRequestData({
    countRef,
    url:
      user && user.idpessoa_fisica
        ? `/tmTimelineNoticias/${
            user.idpessoa_fisica
          }?${util.encodeURIComponent("*(tipo='Notícia')")}`
        : `/tmTimelineNoticias/0?${util.encodeURIComponent(
            "*(tipo='Notícia')"
          )}`,
    setState: setNews,
    requestAPI,
    itensPerPage: 15,
    idField: 'idatendimento_paciente',
    addToast,
    setLoading,
    insideData: true,
    setDataEnded: setNewsEnded,
    isLoading,
    titleError: 'Por favor, Tente novamente.',
    paginate: { setLoading: setLoadingPagination },
    elementRef: buttonRef,
    isPublic: !user?.idpessoa_fisica,
    sendError,
  });

  let elementNews;

  if (news) {
    elementNews = showNews(news, handleLike);
  } else {
    elementNews = (
      <Col className="m-0">
        <DrAlert
          warning
          title={{
            tag: 'h4',
            text: '',
          }}
        >
          <p>Nenhuma novidade no momento.</p>
        </DrAlert>
      </Col>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <DrCardLoading className="mt-4 pt-4 pb-4" />
      ) : (
        <CardBlank>
          <Container className="mt-3 pt-3">
            <DrModal
              controllerModal={[modal, setModal]}
              title="Atenção"
              buttons={{
                confirm: {
                  title: 'Login/Cadastro',
                  onClick: () => {
                    history.push('/sign-in');
                  },
                },
                cancel: {
                  title: 'Cancelar',
                  onClick: () => {
                    setModal(false);
                  },
                },
              }}
              content={<h4>Necessário realizar Login ou Cadastro!</h4>}
            />

            <PageTitle>Notícias, novidades e curiosidades</PageTitle>
            <Row className="justify-content-center">
              {(elementNews.length || newsEnded) && elementNews ? (
                elementNews
              ) : (
                <Col className="m-0">
                  <DrAlert
                    warning
                    title={{
                      tag: 'h4',
                      text: '',
                    }}
                  >
                    <p>Não foi encontrado nenhuma noticía!!</p>
                  </DrAlert>
                </Col>
              )}
            </Row>

            {!newsEnded && elementNews.length && (
              <Row className="justify-content-center">
                <Col xl={5}>
                  <div ref={buttonRef}>
                    <DrButtonLoading
                      type="button"
                      title="Carregando atendimentos..."
                      color="blue"
                      loading={isLoadingPagination}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </CardBlank>
      )}
    </>
  );
};

export default NewsContainer;

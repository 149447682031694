import React from 'react';
import { Container } from 'react-bootstrap';
import HelmetComponent from '../../components/dr-helmet-component';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import DrAttendanceData from './dr-attendance-data';

const AttendanceData: React.FC = () => (
  <>
    <HelmetComponent title="Histórico de Atendimentos" />
    <Header />
    <Container className="pt-3">
      <DrAttendanceData />
    </Container>
    <Footer />
  </>
);
export default AttendanceData;

import styled, { css } from 'styled-components';

interface PageSubtitleProps {
  textCenter?: boolean;
  bold?: boolean;
  fontSize?: string;
}

export const PageSubtitle = styled.h4<PageSubtitleProps>`
  font-size: ${(props) => props.fontSize || '18px'};
  color: ${(props) => props.color || '#777'};
  line-height: 1.44444em;
  margin: 0 0 5px;

  @media screen and (max-width: 850px) {
    max-width: 300px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 999px) {
    max-width: 400px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 780px) {
    font-size: 15px;
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: 500px) {
    font-size: 12px;
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${(props) =>
    props.textCenter &&
    css`
      text-align: center;
    `}
  ${(props) =>
    props.textCenter &&
    css`
      font-weight: bold !important;
    `}
`;
export const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: red;
`;

export const CardGeral = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e5e5e5;
  padding: 20px;
  // align-items: flex-start;

  :after,
  :before {
    box-sizing: border-box;
  }
  @media (hover: hover) {
    &:hover {
      background-color: #3f56a5 !important;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    &:hover {
      transition: none;
    }
  }
  @media (hover: hover) {
    &:hover ${PageSubtitle} {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    ${PageSubtitle} {
      transition: none;
    }
  }
  @media (hover: hover) {
    &:hover {
      background-color: #3f56a5;
    }
    &:hover ${PageSubtitle} {
      color: #fff !important;
    }
  }
`;

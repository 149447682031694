import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import DrInputSelect from '../dr-input-select';
import { DrModalProps } from '../dr-modal';
import DrModalContent from '../dr-modal-content';
import DrLoading from '../dr-card-loading';
import { useIsMounted } from '../../hooks/is-mounted';
import { useAuth } from '../../hooks/auth';

interface ObjDevice {
  default?: string;
  options: MediaDeviceInfo[];
}

export interface CurrentDeviceType {
  video: string;
  audioInput: string;
  audioOutput: string;
}

interface DrModalSelectDeviceProps {
  currentDeviceRef: React.MutableRefObject<CurrentDeviceType>;
  onSubmit: (formData: any) => Promise<void>;

  setModal: React.Dispatch<
    React.SetStateAction<
      Omit<DrModalProps, 'controllerModal'> & {
        showModal: boolean;
      }
    >
  >;
}

const DrModalSelectDevice: React.FC<DrModalSelectDeviceProps> = ({
  currentDeviceRef,
  onSubmit,
  setModal,
}: DrModalSelectDeviceProps) => {
  const [deviceVideo, setDeviceVideo] = useState<ObjDevice>({ options: [] });

  const [deviceAudioInput, setDeviceAudioInput] = useState<ObjDevice>({
    options: [],
  });

  const [deviceAudioOutput, setDeviceAudioOutput] = useState<ObjDevice>({
    options: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useIsMounted();
  const { sendError } = useAuth();
  const onSubmitForm = React.useCallback<SubmitHandler>(
    async (formData) => {
      try {
        setIsLoading(true);
        await onSubmit(formData);
        if (isMountedRef.current) {
          setModal({ showModal: false });
        }
      } catch (error: any) {
        sendError(error);
      }
    },
    [onSubmit, setModal, setIsLoading, isMountedRef]
  );

  const tagVideoAux = document.createElement<any>('video');

  useEffect(() => {
    const devicesVideo: ObjDevice = {
      options: [],
    };

    const devicesAudioInput: ObjDevice = {
      options: [],
    };

    const devicesAudioOutput: ObjDevice = {
      options: [],
    };

    async function getDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        devices.forEach((device) => {
          if (device) {
            let objDevice;
            let keyDeviceCurr = '';
            if (device.kind === 'videoinput') {
              objDevice = devicesVideo;
              keyDeviceCurr = 'video';
            } else if (device.kind === 'audioinput') {
              objDevice = devicesAudioInput;
              keyDeviceCurr = 'audioInput';
            } else if (device.kind === 'audiooutput') {
              objDevice = devicesAudioOutput;
              keyDeviceCurr = 'audioOutput';
            }

            objDevice.options.push(device);
            const currentDevice = currentDeviceRef.current[keyDeviceCurr];
            if (currentDevice === device.deviceId) {
              objDevice.default = currentDevice;
            }
          }
        });

        if (isMountedRef.current) {
          setDeviceVideo(devicesVideo);
          setDeviceAudioInput(devicesAudioInput);
          setDeviceAudioOutput(devicesAudioOutput);
          setIsLoading(false);
        }
      } catch (error: any) {
        sendError(error);
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    getDevices();
  }, []);

  const content = (
    <Row>
      <Col xs={12}>
        {deviceVideo.options.length > 0 ? (
          <DrInputSelect
            name="video"
            selectTitle="Dispositivo de vídeo"
            value={deviceVideo.default}
            options={deviceVideo.options.map((device: MediaDeviceInfo) => ({
              label: device.label,
              value: device.deviceId,
            }))}
          />
        ) : (
          <h5>Não foi encontrado dispositivo de vídeo.</h5>
        )}
      </Col>

      <Col xs={12}>
        {deviceAudioInput.options.length ? (
          <DrInputSelect
            name="audio_input"
            selectTitle="Dispositivo de entrada de audio"
            value={deviceAudioInput.default}
            options={deviceAudioInput.options.map(
              (device: MediaDeviceInfo) => ({
                label: device.label,
                value: device.deviceId,
              })
            )}
          />
        ) : (
          <h5>Não foi encontrado dispositivo de entrada de audio.</h5>
        )}
      </Col>

      {typeof tagVideoAux.setSinkId === 'function' && (
        <Col xs={12}>
          {deviceAudioOutput.options.length ? (
            <DrInputSelect
              name="audio_output"
              selectTitle="Dispositivo de saída de áudio"
              value={deviceAudioOutput.default}
              options={deviceAudioOutput.options.map(
                (device: MediaDeviceInfo) => ({
                  label: device.label,
                  value: device.deviceId,
                })
              )}
            />
          ) : (
            <h5>Não foi encontrado dispositivo de saída de audio.</h5>
          )}
        </Col>
      )}
    </Row>
  );

  return (
    <Form onSubmit={onSubmitForm}>
      {isLoading ? (
        <DrLoading />
      ) : (
        <DrModalContent
          title="Configuração"
          content={!isLoading && content}
          bodyHeight={isLoading ? '50vh' : undefined}
          buttons={
            isLoading
              ? undefined
              : {
                  confirm: { type: 'submit', title: 'Aplicar' },
                  cancel: {
                    onClick: () => {
                      setModal({ showModal: false });
                    },
                  },
                }
          }
        />
      )}
    </Form>
  );
};

export default DrModalSelectDevice;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import DrButtonsFooter from '../../../components/dr-buttons-footer';

import DrCardHeaderSchedule from '../../../components/dr-card-header-schedule';

import { useAuth } from '../../../hooks/auth';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle, PageSubtitle } from '../../../styles/page-title';
import { valueParam } from '../../../utils/bibli';

const DrConfirmSchedule: React.FC = () => {
  const history = useHistory();
  const { attendance, applicationData } = useAuth();

  function cancelationMsg() {
    const value = valueParam(applicationData, 223);
    if (value && value !== 1) {
      const [hour, minutes, seconds] = value.split(':');
      const dt_agenda = moment(attendance.dt_agendamento)
        .local()
        .format('DD/MM/YYYY');

      const hr_agenda = moment(attendance.dt_agendamento)
        .add(hour, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds')
        .format('HH:mm');

      return (
        <div>
          <h6>
            Não efetuando o pagamento até{' '}
            <h6
              style={{
                color: '#ed145b',
                display: 'inline',
                fontWeight: 'bold',
              }}
            >
              {`${dt_agenda} às ${hr_agenda}h`}
            </h6>
            , agendamento será cancelado automaticamente
          </h6>
        </div>
      );
    }
    return '';
  }
  return (
    <Container>
      <CardBlank className="p-3 mt-3 " definedSize="50vh">
        <PageTitle>Agendado!</PageTitle>
        <DrCardHeaderSchedule headerData={attendance} />
        {!attendance.idconta_receber_cartao_movto &&
          !attendance.ds_produto &&
          attendance.ie_retorno !== 'S' && (
            <>
              <Col>
                {(attendance.msg_vlr_assinatura ||
                  attendance.msg_vlr_consulta) && (
                  <h6
                    style={{
                      textAlign: 'start',
                      color: '#3f56a5',
                      marginTop: 10,
                    }}
                  >
                    Efetivação da consulta só acontecerá após pagamento:
                  </h6>
                )}
                {attendance.msg_vlr_assinatura &&
                  attendance.msg_vlr_assinatura !== '' && (
                    <h6
                      style={{
                        textAlign: 'start',
                        color: '#ed145b',
                        margin: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {attendance.msg_vlr_assinatura}
                    </h6>
                  )}
                {attendance.msg_vlr_consulta &&
                  attendance.msg_vlr_consulta !== '' && (
                    <h6
                      style={{
                        textAlign: 'start',
                        color: '#ed145b',
                        margin: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {attendance.msg_vlr_consulta}
                    </h6>
                  )}
                <br />
                <h6 style={{ textAlign: 'start', color: '#3f56a5' }}>
                  {cancelationMsg()}
                </h6>
              </Col>
              <Col className="d-flex mt-4">
                <DrButtonsFooter
                  buttonLeft={{
                    onClick: () =>
                      history.push({
                        pathname: '/pay-options',
                      }),
                    title: 'Pagar Agora',
                  }}
                  buttonRight={{
                    onClick: () => history.push({ pathname: '/dashboard' }),
                    title: 'Pagar Depois',
                  }}
                />
              </Col>
            </>
          )}
      </CardBlank>

      <DrButtonsFooter
        buttonLeft={false}
        buttonRight={{
          onClick: () => history.push({ pathname: `/dashboard` }),
          title: 'Voltar',
        }}
      />
    </Container>
  );
};

export default DrConfirmSchedule;

import styled from 'styled-components';

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row,
  justify-content: center;
  align-items: center;

`;

export const PictureText = styled.h3`
  align-items: center;

  color: #777;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 700;
`;

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthContextData, useAuth } from '../../hooks/auth';
import { useIsMounted } from '../../hooks/is-mounted';
import { ToastMessage, useToast } from '../../hooks/toast';
import DrButton from '../dr-button';

import {
  AtendValType,
  IdRoute,
  OptionsInstallmentTypes,
  SignatureType,
} from '../../pages/PayOptions';
import { handleJsonPayment } from '../../utils/bibli';
import DrModal from '../dr-modal';

interface InstallmentButtonProps {
  optionInstallment: OptionsInstallmentTypes;
  installmentsSignature?: SignatureType;
  installmentsAttendance?: AtendValType;
  idx: number;
  dashboard?: boolean;
}
interface cbHandleNextPageParams
  extends Pick<
    InstallmentButtonProps,
    'installmentsSignature' | 'optionInstallment' | 'installmentsAttendance'
  > {
  setAttendanceData: AuthContextData['setAttendanceData'];
  history: any;
  params: IdRoute;
  isMountedRef: React.MutableRefObject<boolean>;
  addToast: (message: Omit<ToastMessage, 'id'>) => void;
  idx: number;
  dashboard?: boolean;
  sendError: (message: any) => Promise<void>;
}

function cbHandleNextPage({
  installmentsSignature,
  installmentsAttendance,
  optionInstallment,
  setAttendanceData,
  history,
  isMountedRef,
  addToast,
  idx,
  dashboard,
  sendError,
}: cbHandleNextPageParams) {
  const { paymentValues, deleteParams } = handleJsonPayment({
    installmentsSignature,
    installmentsAttendance,
    optionInstallment,
    idx,
    dashboard,
    sendError,
  });

  setAttendanceData(
    { ...paymentValues, fromDashboard: dashboard },
    false,
    deleteParams
  )
    .then((response) => {
      if (dashboard) delete response.idpessoa_paciente;
      if (isMountedRef.current) {
        history.push({
          pathname: paymentValues.route,
        });
      }
    })
    .catch((err) => {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `${err.message}`,
      });
    });
}

const InstallmentButton: React.FC<InstallmentButtonProps> = ({
  optionInstallment,
  installmentsSignature,
  installmentsAttendance,
  idx,
  dashboard,
}) => {
  const history = useHistory();
  const { params } = useRouteMatch<IdRoute>();
  const { setAttendanceData, sendError, user } = useAuth();
  const isMountedRef = useIsMounted();
  const { addToast } = useToast();
  const [modal, setModal] = React.useState(false);
  const handleNextPage = React.useCallback(() => {
    cbHandleNextPage({
      installmentsSignature,
      installmentsAttendance,
      optionInstallment,
      setAttendanceData,
      history,
      params,
      isMountedRef,
      addToast,
      idx,
      dashboard,
      sendError,
    });
  }, [
    installmentsSignature,
    installmentsAttendance,
    optionInstallment,
    setAttendanceData,
    history,
    params,
    isMountedRef,
    addToast,
    idx,
    dashboard,
    sendError,
  ]);
  return (
    <Row className="justify-content-center mt-2">
      <Col>
        <DrModal
          controllerModal={[modal, setModal]}
          title="Atenção"
          buttons={{
            confirm: {
              title: 'Login/Cadastro',
              onClick: () => {
                history.push('/sign-in');
              },
            },
            cancel: {
              title: 'Cancelar',
              onClick: () => {
                setModal(false);
              },
            },
          }}
          content={<h4>Necessário realizar Login ou Cadastro!</h4>}
        />
        <DrButton
          type="button"
          title={optionInstallment.descricao}
          titleColor="#3f56a5"
          onClick={() => (!user ? setModal(true) : handleNextPage())}
        />
      </Col>
    </Row>
  );
};
export default InstallmentButton;

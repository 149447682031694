import React from 'react';
import { useLocation } from 'react-router-dom';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { useAuth } from '../../../hooks/auth';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';

const AttendanceError: React.FC = () => {
  const { setAttendanceData } = useAuth();
  const location = useLocation<any>();
  const [messageError, setMessageError] = React.useState<string>('');
  React.useEffect(() => {
    if (location && location.state && location.state.camera) {
      setMessageError(
        `Por conta de um erro de câmera: ${location.state.camera}`
      );
    } else if (location && location.state && location.state.microfone) {
      setMessageError(
        `Por conta de um erro de microfone: ${location.state.microfone}`
      );
    }
    clearData();
  }, [location]);
  async function clearData() {
    await setAttendanceData({ idSocketSala: undefined, dsKey: undefined });
  }
  return (
    <>
      <CardBlank className="container pb-3 pt-3">
        <PageTitle>
          Desculpe!
          <br />
          <br />
          Houve um encerramento precoce do atendimento.
          <br />
          <br />
          <p style={{ color: '#c00' }}>{messageError}</p>
          Volte para a fila para retornar ao atendimento.
        </PageTitle>
      </CardBlank>

      <DrButtonsFooter
        buttonLeft={{
          href: '/attendance-now/returnAttendanceError',
          title: 'Voltar para a fila',
        }}
        buttonRight={{ title: 'Ir para o ínicio' }}
      />
    </>
  );
};
export default AttendanceError;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import { useToast } from '../../hooks/toast';
import Input from '../../components/dr-input';
import { useAuth } from '../../hooks/auth';
import { scrollPositionTop, verifyErrorYup } from '../../utils/bibli';
import { PageTitle } from '../../styles/page-title';
import { CardBlank } from '../../styles/card-blank';
import { Fields } from '../../styles/fields';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import Button from '../../components/dr-button';
import DrButtonLoading from '../../components/dr-button-loading';

interface IProfileFormData {
  senha_atual: string;
  nova_senha: string;
  confirma_senha: string;
}

const SetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { user, requestAPI, sendError } = useAuth();

  const handleSubmit = useCallback(
    async (data: IProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha_atual: Yup.string().required('Campo Obrigatório').trim(),

          nova_senha: Yup.string()
            .required('Campo Obrigatório')
            .notOneOf(
              [Yup.ref('senha_atual')],
              'Senha atual e nova senha são iguais. Não permitido!'
            )
            .matches(
              /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
              'Senha deve conter no mínimo 6 caracteres contendo letras minúsculas, maiúsculas e números.'
            )
            .trim(),
          confirma_senha: Yup.string()
            .oneOf(
              [Yup.ref('nova_senha')],
              'Senha e confirmação precisam ser iguais.'
            )
            .trim(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { senha_atual, nova_senha, confirma_senha } = data;

        const formData = {
          ...(senha_atual
            ? {
                senha_atual,
                nova_senha,
                confirma_senha,
              }
            : {}),
        };
        setLoading(true);
        await requestAPI({
          method: 'PUT',
          url: `tmUsuarioAlterarSenha/${user.idtm_usuario}`,
          body: formData,
        });
        setLoading(false);

        history.push('/profile');

        addToast({
          type: 'success',
          title: 'Perfil atualizado',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });
      } catch (err: any) {
        sendError(err);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          verifyErrorYup(err, formRef);
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        }
      }
    },
    [requestAPI, user, history, addToast]
  );

  useEffect(() => {
    scrollPositionTop();
  }, []);

  return (
    <>
      <Header />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container className="pt-3">
          <CardBlank className="pt-3">
            <PageTitle>Alterar Senha</PageTitle>
            <Fields>
              <Row className="m-0">
                <Col xl={4}>
                  <Input
                    icon
                    name="senha_atual"
                    label="Senha Atual"
                    placeholder="*********"
                    customType="password"
                    validate
                  />
                </Col>
                <Col xl={4}>
                  <Input
                    icon
                    name="nova_senha"
                    label="Nova Senha"
                    placeholder="********"
                    customType="password"
                    validate
                  />
                </Col>
                <Col xl={4}>
                  <Input
                    icon
                    name="confirma_senha"
                    label="Confirme a senha"
                    placeholder="********"
                    customType="password"
                    validate
                  />
                </Col>
              </Row>
            </Fields>
          </CardBlank>
        </Container>
        <Container className="mt-3">
          <Row className="col-xl-12 justify-content-center row-btn m-0 p-0">
            <Col xl={4} className="p-0">
              <DrButtonLoading
                spaceBetween
                type="submit"
                color="blue"
                title="Alterar"
                loading={isLoading}
              />
            </Col>
            <Col xl={4} className="p-0">
              <Button
                spaceBetween
                type="button"
                color="dark-grey"
                href="/profile"
                title="Cancelar"
                disabled={isLoading}
              />
            </Col>
          </Row>
        </Container>
      </Form>
      <Footer />
    </>
  );
};

export default SetPassword;

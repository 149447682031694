import styled from 'styled-components';

export const ModalContentContainer = styled.div``;

export const ModalFooter = styled.div`
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding: 15px;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth, MemberType } from '../../hooks/auth';
import Header from '../../components/dr-header';
import UserData from '../../components/dr-user-data';
import Footer from '../../components/dr-footer';
import { useToast } from '../../hooks/toast';
import DrCardLoading from '../../components/dr-card-loading';
import { handleConcatUserData } from '../../utils/bibli';

interface IdUser {
  id: string;
}

const UpdateFamilyMember: React.FC = (Props) => {
  const { user, requestAPI, sendError } = useAuth();
  const { addToast } = useToast();
  const { params } = useRouteMatch<IdUser>();
  const history = useHistory();
  const [member, setMember] = React.useState({} as MemberType);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function getMemberData() {
      requestAPI({
        method: 'GET',
        url: `/tmUsuarioGrupoFamiliarUsu?idpessoa_fisica=${user.idpessoa_fisica}&idtm_usuario_grupo_familiar=${params.id}`,
      })
        .then((resp) => {
          const result = handleConcatUserData(resp);
          setMember(result);
        })
        .catch((err: any) => {
          sendError(err);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
          history.push('/dashboard');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getMemberData();
  }, [addToast, history, params.id, requestAPI, user.idpessoa_fisica]);

  return (
    <>
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <UserData
          type="family"
          userData={member}
          method="PUT"
          url={`/tmUsuarioGrupoFamiliar/${params.id}`}
        />
      )}
      <Footer />
    </>
  );
};

export default UpdateFamilyMember;

import styled from 'styled-components';

interface BoxMemberProps {
  noHover?: boolean;
}

export const BoxMember = styled.div<BoxMemberProps>`
  position: relative;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  transition: background 0.2s;
  background: #ffffff;
  display: flex;

  &:hover {
    @media (hover: hover) {
      cursor: ${({ noHover }) => (noHover ? 'default' : 'pointer')};
      color: ${({ noHover }) => (noHover ? '' : '#ED145B')};
    }
  }
  @media screen and (max-width: 770px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const BoxMemberPic = styled.div`
  display: flex;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-style: none;
    padding: 3px;
    align-self: center;
    margin: 15px auto 15px 15px;
  }
`;

export const BoxMemberName = styled.h3`
  font-size: 1.12rem;
  overflow-wrap: anywhere;
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
`;

import styled from 'styled-components';
import { shade, darken } from 'polished';
import Tooltip from '../../../components/dr-tooltip';

export const DrBoxSignInContainer = styled.div`
  .login-tooltip {
    display: flex;
    position: relative;
    align-items: center;
    .buttonTooltip {
      width: 35px;
      height: 30px;
    }
  }
`;
export const ToolTipButton = styled(Tooltip)`
  svg {
    height: 30px;
    width: 30px;
    margin-left: 5px;
    cursor: pointer;
  }
`;

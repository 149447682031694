import styled from 'styled-components';
import { string } from 'yup/lib/locale';

interface ColorLinkProps {
  color?: string;
  colorHover?: string;
}
export const BoxLoginBody = styled.div<ColorLinkProps>`
  color: #777;
  font-weight: 500;
  margin-bottom: 15px;

  a {
    color: ${(props) => props.color};
    font-weight: 700;
    display: block;
    transition: all 0.2s ease-in-out;
    :hover {
      color: ${(props) => props.colorHover};
    }
  }
`;
export const BoxLoginBodyOl = styled.ol`
  font-size: 20px;
  li:not(:first-child) {
    margin-top: 15px;
  }
`;
export const BoxLoginBodyLi = styled.li``;

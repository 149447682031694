import React from 'react';
import { ContainerQRCode } from './styled';

export interface QRCodeProps {
  url?: string;
}

const DrQRCode: React.FC<QRCodeProps> = ({ url }) => {
  const [qrCode, setQRCode] = React.useState<any>('');

  const generateQRCode = () => {
    setQRCode(url);
  };

  React.useEffect(() => {
    generateQRCode();
  }, []);

  return (
    <ContainerQRCode>
      {qrCode && <img src={qrCode} alt="QR Code" />}
    </ContainerQRCode>
  );
};

export default DrQRCode;

import React, { useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { BoxPicture } from '../../styles/box-picture';
import { BoxPictureImg } from '../../styles/box-picture_img';
import { BoxPictureTitle } from '../../styles/box-picture_title';
import DrCardConfig from '../../components/dr-card-config';

import {
  handleWhatsAppMessage,
  scrollPositionTop,
  handleUseApiLog,
  valueParam,
} from '../../utils/bibli';

import {
  CardConfig,
  CardConfigBody,
  CardConfigIcon,
  CardConfigTitle,
  CardConfigP,
  CardConfigI,
} from '../../components/dr-card-config/styled';
import DrModal from '../../components/dr-modal';
import DrIconFont from '../../components/dr-icon-font';
import DrImageProfile from '../../components/dr-image-profile';
import EquipamentTest from '../../components/dr-equipament-test';

const Profile: React.FC = () => {
  const {
    user,
    applicationData,
    requestAPI,
    sendError,
    componentTest,
    handleOpenComponentTest,
    handleCloseComponentTest,
  } = useAuth();
  const { addToast } = useToast();
  const [modal, setModal] = React.useState(false);
  const [modalDeleteAccount, setModalDeleteAccount] = React.useState(false);
  const [textWithPlatForm, setTextWithPlatForm] = React.useState('');
  const [avatar, setAvatar] = React.useState('');
  const history = useHistory();

  useEffect(() => {
    const linkWhatsapp = applicationData.valores_variaveis?.link_whatsapp;
    if (linkWhatsapp && linkWhatsapp !== '-1') {
      const message = handleWhatsAppMessage(
        applicationData.valores_variaveis?.link_whatsapp,
        user
          ? undefined
          : 'Oi, estou na {plataforma} e gostaria de mais informações.',
        !user,
        user
      );

      setTextWithPlatForm(message);
    }

    if (valueParam(applicationData, 126) !== undefined)
      setAvatar(valueParam(applicationData, 126));
  }, [applicationData, user]);

  useEffect(() => {
    if (!user) {
      history.push('/sign-in');
      return;
    }
    scrollPositionTop();
  });

  useEffect(() => {
    handleCloseComponentTest();
  }, []);

  async function removerUsuario() {
    try {
      await requestAPI({
        method: 'PUT',
        url: `/tmUsuarioRemover/${user.idtm_usuario}`,
      })
        .then((resp) => {
          setModalDeleteAccount(false);
          history.push('/sign-out');
          addToast({
            type: 'success',
            title: 'Conta excluída com sucesso!',
          });
        })
        .catch((error) => {
          sendError(error);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Erro ao excluir os dados da conta! ${error.message}`,
          });
        });
    } catch (error) {}
  }

  return (
    <>
      <Header />
      {componentTest ? (
        <EquipamentTest />
      ) : (
        <Container className="pt-3">
          <DrModal
            controllerModal={[modal, setModal]}
            title="Sair do sistema"
            buttons={{
              confirm: {
                title: 'Confirmar',
                onClick: () => {
                  history.push('/sign-out');
                },
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => setModal(false),
              },
            }}
            content={<h4>Deseja realmente sair do sistema?</h4>}
          />
          <DrModal
            controllerModal={[modalDeleteAccount, setModalDeleteAccount]}
            title="Atenção!"
            buttons={{
              confirm: {
                title: 'Confirmar',
                onClick: () => removerUsuario(),
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => setModalDeleteAccount(false),
              },
            }}
            content={
              <h4>
                Deseja realmente remover seu acesso e apagar seus dados? Esta
                ação não poderá ser desfeita!
              </h4>
            }
          />
          {user ? (
            <>
              <BoxPicture>
                <BoxPictureImg>
                  <DrImageProfile
                    source={user.url_img || ''}
                    alt="foto de perfil"
                    width={300}
                    height={300}
                    style={{ borderRadius: '50%' }}
                  />
                </BoxPictureImg>
                <BoxPictureTitle>
                  {!user.nm_social ||
                  (user.nm_social && user.nm_social.trim().length === 0) ||
                  !user.nm_social
                    ? user.nm_pessoa
                    : user.nm_social}
                </BoxPictureTitle>
              </BoxPicture>

              <Row>
                <DrCardConfig
                  icon="avatar"
                  path={`/update-user-registration-datas/${user.idtm_usuario}`}
                  title="Meus Dados"
                  description="Edite dados pessoais, endereço e dados de contato"
                />
                <DrCardConfig
                  icon="group"
                  path="/family-group"
                  title="Dependentes"
                  description="Atualizar ou cadastrar dependentes"
                />
                <DrCardConfig
                  icon="padlock"
                  path={`/set-password/${user.idtm_usuario}`}
                  title="Segurança"
                  description="Altere sua senha"
                />
                <DrCardConfig
                  icon="credit-card"
                  path="/payments-methods"
                  title="Cartões"
                  description="Adicione ou exclua seus cartões de crédito"
                />
                <DrCardConfig
                  icon="write"
                  path="/plans"
                  title="Meus Contratos"
                  description="Gerencia as suas assinaturas"
                />
                {valueParam(applicationData, 207) === 'S' ? (
                  <DrCardConfig
                    icon="searching"
                    path="/historic"
                    title="Histórico de Atendimentos"
                    description="Gerencia seus atendimentos"
                    state="profile"
                  />
                ) : (
                  <></>
                )}

                <Col
                  lg={4}
                  xl={3}
                  className="mb-3"
                  style={{ cursor: 'pointer' }}
                >
                  <CardConfig onClick={() => handleOpenComponentTest()}>
                    <CardConfigIcon>
                      <CardConfigI>
                        <DrIconFont name="checklist" />
                      </CardConfigI>
                    </CardConfigIcon>
                    <CardConfigBody>
                      <CardConfigTitle>Testar Componentes</CardConfigTitle>
                      <CardConfigP>
                        Verificar se todos os componentes estão funcionando
                        corretamente
                      </CardConfigP>
                    </CardConfigBody>
                  </CardConfig>
                </Col>

                <Col
                  lg={4}
                  xl={3}
                  className="mb-3"
                  style={{ cursor: 'pointer' }}
                >
                  <CardConfig
                    onClick={() => {
                      handleUseApiLog({
                        requestAPI,
                        url: window.location.href,
                        action: 'WhatsApp',
                        message: textWithPlatForm,
                      });
                      window.open(textWithPlatForm, '_blank');
                    }}
                  >
                    <CardConfigIcon>
                      <CardConfigI colorI="#60bb46">
                        <DrIconFont name="bubble-chat" />
                      </CardConfigI>
                    </CardConfigIcon>
                    <CardConfigBody>
                      <CardConfigTitle colorT="#60bb46">Ajuda</CardConfigTitle>
                      <CardConfigP colorP="#60bb46">
                        Solicitar ajuda administrativa
                      </CardConfigP>
                    </CardConfigBody>
                  </CardConfig>
                </Col>
                <Col
                  lg={4}
                  xl={3}
                  className="mb-3"
                  style={{ cursor: 'pointer' }}
                >
                  <CardConfig
                    onClick={() => setModalDeleteAccount(true)}
                    colorH="#ed145b"
                  >
                    <CardConfigIcon>
                      <CardConfigI colorI="#ed145b">
                        <DrIconFont name="delete-profile" />
                      </CardConfigI>
                    </CardConfigIcon>
                    <CardConfigBody>
                      <CardConfigTitle colorT="#ed145b">
                        Excluir minha conta
                      </CardConfigTitle>
                      <CardConfigP colorP="#ed145b">
                        Quero remover meu acesso e excluir meus dados.
                      </CardConfigP>
                    </CardConfigBody>
                  </CardConfig>
                </Col>
                <Col
                  lg={4}
                  xl={3}
                  className="mb-3"
                  style={{ cursor: 'pointer' }}
                >
                  <CardConfig onClick={() => setModal(true)}>
                    <CardConfigIcon>
                      <CardConfigI>
                        <DrIconFont name="logout" />
                      </CardConfigI>
                    </CardConfigIcon>
                    <CardConfigBody>
                      <CardConfigTitle>Sair do sistema</CardConfigTitle>
                      <CardConfigP>Quero sair do sistema</CardConfigP>
                    </CardConfigBody>
                  </CardConfig>
                </Col>
              </Row>
            </>
          ) : (
            <> </>
          )}
        </Container>
      )}
      <Footer />
    </>
  );
};

export default Profile;

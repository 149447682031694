import moment from 'moment';
import React from 'react';

export function onlyNumbersMask(value?: string): string | undefined {
  let result = value;
  if (result) {
    result = result.replace(/[^0-9]/g, '');
  }
  return result;
}

export function onlyNumbers(
  e: React.FormEvent<HTMLInputElement>
): React.FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 20;
  let { value } = e.currentTarget;

  value = value.replace(/[^0-9]/g, '');
  e.currentTarget.value = value;
  return e;
}

export function emailMask(value?: string) {
  let result = value;
  if (result) {
    result = result.trim();
  }
  return result;
}
export function emailInput(e: React.FormEvent<HTMLInputElement>) {
  let { value } = e.currentTarget;
  value = value.trim();
  e.currentTarget.value = value;
  return e;
}

export function nameInputMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/[^a-zà-úA-ZÀ-Ú ']/g, '');
  }
  return result;
}
export function nameInput(e: React.FormEvent<HTMLInputElement>) {
  let { value } = e.currentTarget;

  value = value.replace(/[^a-zà-úA-ZÀ-Ú ']/g, '');
  e.currentTarget.value = value;
  return e;
}

export function phoneNumberMask(value: string) {
  let result = value;

  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/^(\d{2})(\d)/g, '($1) $2');
    result = result.replace(/(\d)(\d{4})$/, '$1-$2');
    return result;
  }

  return result;
}

export function phoneNumber(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;
  e.currentTarget.value = phoneNumberMask(e.currentTarget.value) || '';
  return e;
}

export function cepMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/^(\d{5})(\d)/, '$1-$2');
  }
  return result;
}

export function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  e.currentTarget.value = cepMask(e.currentTarget.value) || '';

  return e;
}

export function currencyMask(value?: string) {
  let result = value;

  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/(\d)(\d{2})$/, '$1, $2');
    result = result.replace(/(?=(\d{3})+(\D))\B/g, '.');
  }

  return result;
}

export function currency(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.value = currencyMask(e.currentTarget.value) || '';
  return e;
}

export function dateMask(value?: string) {
  let result = value;
  if (value?.includes('-') && !value.endsWith('-')) {
    const data = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    return data;
  }

  if (result) {
    result = result.replace(/\D/g, ''); // Remove tudo o que não é dígito
    result = result.replace(/^(\d{2})$/g, '$1');
    result = result.replace(/^(\d{2})(\d{1,2})$/g, '$1/$2');
    result = result.replace(/^(\d{2})(\d{2})(\d{1,4})$/g, '$1/$2/$3');
  }
  return result;
}

export function date(e: any) {
  e.currentTarget.maxLength = 10;
  e.currentTarget.value = dateMask(e.currentTarget.value) || '';

  return e;
}

export function cpfMask(value?: string) {
  let result = value;
  if (result) {
    result.toString();
    result = result.replace(/\D/g, '');
    result = result.replace(/(\d)(\d{2})$/, '$1-$2');
    result = result.replace(/(?=(\d{3})+(\D))\B/g, '.');
  }

  return result;
}

export function cnpjMask(value?: string) {
  let result = value;
  if (result) {
    result.toString();
    result = result.replace(/\D/g, ''); // Remove tudo o que não é dígito
    result = result.replace(/^(\d{2})$/g, '$1');
    result = result.replace(/^(\d{2})(\d{3})$/g, '$1.$2');
    result = result.replace(/^(\d{2})(\d{3})(\d{3})$/g, '$1.$2.$3');
    result = result.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})$/g, '$1.$2.$3/$4');
    result = result.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g,
      '$1.$2.$3/$4-$5'
    );
  }

  return result;
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;

  e.currentTarget.value = cpfMask(e.currentTarget.value) || '';

  return e;
}

export function cardNumberMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/^(\d{2})(\d)/, '$1/$2');
    result = result.replace(/\D/g, '');
    result = result.replace(/^(\d{4})(\d)/g, '$1 $2');
    result = result.replace(/^(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3');
    result = result.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, '$1 $2 $3 $4');
  }
  return result;
}

export function cardNumber(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 19;
  e.currentTarget.value = cardNumberMask(e.currentTarget.value) || '';

  return e;
}

export function cardDateMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/^(\d{2})(\d)/, '$1/$2');
  }
  return result;
}

export function cardDate(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 7;
  e.currentTarget.value = cardDateMask(e.currentTarget.value) || '';
  return e;
}

export function cvcMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/[^0-9]/g, '');
  }

  return result;
}

export function cvc(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 4;
  e.currentTarget.value = cvcMask(e.currentTarget.value) || '';

  return e;
}

export function timeTextMask(value?: string) {
  let result = value;
  if (result) {
    result = result.replace(/\D/g, '');
    result = result.replace(/^([0-2][1-9])$/, '$1');
    result = result.replace(/^([0-2][0-9])(\d{1,})$/, '$1:$2');
  }
  return result;
}

export function timeText(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 5;
  e.currentTarget.value = timeTextMask(e.currentTarget.value) || '';

  return e;
}

import styled from 'styled-components';

export const HeaderContainer = styled.div`
  @media (max-width: 1199px) {
    .site-header {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 99999;
      padding-top: 5px;
      margin: 0;
      background-color: #fff;
      box-shadow: 0px 0 10px 1px #888;
    }
  }

  @media (min-width: 1200px) {
    .site-header {
      z-index: 100;
      background-color: #fff;
      padding: 20px 0;
      margin: 0 0 30px;
      box-shadow: 0 10px 30px 1px rgb(0 0 0 / 5%);
    }
  }

  h2 {
    color: #777;
    font-size: 16px;
    margin-top: 5px;
    font-weight: 700;

    @media (max-width: 360px) {
      font-size: 7px;
    }

    @media (min-width: 361px) and (max-width: 440px) {
      font-size: 10px;
    }

    @media (min-width: 441px) and (max-width: 540px) {
      font-size: 12px;
    }

    @media (min-width: 541px) and (max-width: 767px) {
      font-size: 14px;
    }

    @media (min-width: 321px) and (max-width: 540px) {
      font-size: 9px;
    }
  }

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 65px;

    @media screen and (max-width: 540px) {
      max-height: 55px;
    }
  }

  .head-logo {
    flex-grow: 0;
    @media (max-width: 1199px) {
      display: none;
      position: relative;
      z-index: 5;
    }
  }

  .head-nav {
    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .main-nav .main-menu li a i {
    font-size: 40px;
    display: block;
  }

  .main-nav {
    width: 100%;
  }
  .main-menu {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
  }
  .main-home {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }

  .main-home:hover .text-home {
    color: #fcaf17;
  }

  .main-add {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }

  .main-add:hover .text-add {
    color: #ed145b;
  }
  .main-forum:hover .text-forum {
    color: #3f56a5;
  }
  .main-forum i {
    font-size: 32px !important;
  }

  .main-searching {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }

  .icon-box-searching {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text-searching {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .main-searching:hover .text-searching {
    color: #3f56a5;
  }

  .main-notifications {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }

  .main-notifications:hover .text-notifications {
    color: #03bbd7;
  }

  .main-help {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }

  .icon-box-help {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text-help {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .main-help:hover .text-help {
    color: #60bb46;
  }

  .main-perfil {
    :after,
    :before {
      box-sizing: border-box;
    }
    svg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35px;
    }

    @media (min-width: 1200px) {
      display: none;
      position: relative;
      z-index: 5;
    }
  }

  .main-perfil:hover .text-perfil {
    color: #606062;
  }

  .head-acc {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1199px) {
      display: none;
      position: relative;
      z-index: 5;
    }
  }
  .box-perfil {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-acc_name {
    font-size: 16px;
    font-weight: 700;
    color: #777;
    margin-right: 5px;
    text-align: end;

    @media (max-width: 1400px) {
      font-size: 14px;
    }

    .small {
      display: block;
      font-size: 12px;
      margin-left: 20px;
      font-weight: 800;
      color: #000;
      text-transform: uppercase;
      :hover {
        color: #ed145b;
      }
    }
    .smallPlan {
      margin-top: 5px;
      display: block;
      font-size: 12px;
      margin-left: 20px;
      font-weight: 800;
      color: #3f56a5;
      text-transform: uppercase;
      :hover {
        color: #fcaf17;
      }
    }
  }
  .box-acc_pic {
    :after,
    :before {
      box-sizing: border-box;
    }

    img {
      border-radius: 50%;
      margin-left: 10px;
    }
  }

  .exit {
    margin-top: 5px;
    display: block;
    font-size: 14px;
    font-weight: 800;
    color: #ed145b;
    cursor: pointer;
  }
`;

import React from 'react';
import DrButton, { ButtonProps } from '../dr-button';
import DrLoading from '../dr-loading';

export interface DrButtonLoadingProps extends Omit<ButtonProps, 'title'> {
  title: string;
  loading: boolean;
  ratio?: number;
  drColor?: string;
  maisColor?: string;
}

const DrButtonLoading: React.FC<DrButtonLoadingProps> = ({
  loading,
  title,
  disabled,
  drColor,
  maisColor,
  ...rest
}: DrButtonLoadingProps) => (
  <DrButton
    title={
      <>
        <span style={{ opacity: loading ? 0 : 1 }}>{title}</span>
        {loading && (
          <DrLoading
            drColor={drColor}
            maisColor={maisColor}
            customLoading
            className="pt-0"
            ratio={0.5}
          />
        )}
      </>
    }
    disabled={loading || disabled}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

DrButtonLoading.defaultProps = {
  ratio: 0.5,
  drColor: '#ffffff',
  maisColor: '#ffffff',
};

export default DrButtonLoading;

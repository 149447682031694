import React from 'react';
import Videos from '../dr-videos';

interface VideoConvidadoProps {
  remoteStreams: Map<any, any>;
  urlImgRemoto: any;
  stateCameraOn: boolean;
  videoHtmlRef: React.RefObject<HTMLVideoElement>;
}

const VideoConvidado: React.FC<VideoConvidadoProps> = (
  props: VideoConvidadoProps
) => {
  const { remoteStreams, urlImgRemoto, stateCameraOn, videoHtmlRef } = props;
  return (
    <Videos
      urlImgRemoto={urlImgRemoto}
      remoteStreams={remoteStreams}
      hiddenVideo={!stateCameraOn}
      videoHtmlRef={videoHtmlRef}
    />
  );
};

export default VideoConvidado;

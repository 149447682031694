import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import HelmetComponent from '../../components/dr-helmet-component';
import { scrollPositionTop } from '../../utils/bibli';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import DrButton from '../../components/dr-button';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrIcon from '../../components/dr-icon-font';
import { BoxContainer, Box } from '../../styles/box';
import DrCardLoading from '../../components/dr-card-loading';
import DrGoogleAnalytics from '../../components/dr-google-analytics';

const UserAutenticate: React.FC = () => {
  const location = useLocation();
  const { requestAPI, sendError } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [msgResp, setMsgResp] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    scrollPositionTop();
    async function validate() {
      const query = new URLSearchParams(location.search);

      try {
        const cpf = query.get('cpf');
        if (!cpf) throw new Error('CPF não identificado!');

        await requestAPI({
          method: 'PUT',
          url: `/tmUsuarioVerificarToken/B/${cpf}`,
          body: {
            nr_token: query.get('token'),
          },
          isPublic: true,
        });
        setSuccess(true);
        setMsgResp('Conta ativada com sucesso!');
      } catch (err: any) {
        sendError(err);
        setSuccess(false);
        setMsgResp(err.message);
      } finally {
        setLoading(false);
      }
    }
    validate();
  }, []);

  return (
    <>
      {isLoading ? (
        <DrCardLoading className="mt-4 pt-4 pb-4" minHeight="35vh" />
      ) : (
        <>
          <HelmetComponent title="Seja Bem Vindo(a)!" />
          <DrGoogleAnalytics />
          <BoxContainer>
            <Container>
              <Row>
                <Col xl={5} lg={12} className="mb-4 p-4">
                  <Box>
                    <DrBox
                      className="mb-4 p-5"
                      icon={
                        <DrIcon
                          name="alert"
                          color={success ? '#03bbd7' : '#ED145B'}
                        />
                      }
                      title={msgResp}
                      titleColor={success ? '#3f56a5' : '#ED145B'}
                      body={
                        <DrButton
                          type="button"
                          className="box-login_btn"
                          color={success ? '#3f56a5' : '#ED145B'}
                          href="sign-in"
                          title={success ? 'Entrar' : 'Tentar Novamente'}
                        />
                      }
                    />
                  </Box>
                </Col>
                <Col xl={7}>
                  <Box>
                    <DrHeaderWellcome />

                    <Banner />
                  </Box>
                </Col>
              </Row>
            </Container>
            <Footer />
          </BoxContainer>
        </>
      )}
    </>
  );
};

export default UserAutenticate;

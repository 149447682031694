import styled from 'styled-components';

export const CardCardsExclude = styled.button`
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #ed145b;
  margin: 15px 0 0;
  cursor: pointer;
  background-color: transparent;

  border: none;

  text-decoration: none;

  @media (hover: hover) {
    &: {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    &: {
      transition: none;
    }
  }
  @media (hover: hover) {
    &::hover {
      color: #3f56a5;
    }
  }
`;

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { DrImageStyl } from './styled';
import { useAuth } from '../../hooks/auth';

import PerfilPhoto from '../../assets/backgrounds/foto-perfil.png';
import { valueParam } from '../../utils/bibli';

interface DrImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  source: string;
}

const DrImageProfile: React.FC<DrImageProps> = (Props: DrImageProps) => {
  const { source, width, height, ...rest } = Props;
  const { applicationData } = useAuth();

  const onImageError = React.useCallback((err) => {
    const eventChanged = err;
    eventChanged.target.src = PerfilPhoto;
  }, []);

  return (
    <>
      {!!height && !!width && (
        <DrImageStyl
          src={source || valueParam(applicationData, 126)}
          alt={rest.alt || 'Imagem Padrão'}
          size={{
            width,
            height,
          }}
          onError={onImageError}
          {...rest}
        />
      )}
    </>
  );
};

export default DrImageProfile;

import styled from 'styled-components';

export const CardGeral = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e5e5e5;
  padding: 20px;
  // align-items: flex-start;

  i.trash {
    @media screen and (min-width: 850px) {




  }

  :after,
  :before {
    box-sizing: border-box;
  }


`;

/* eslint-disable react/button-has-type */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from 'react-bootstrap';
import GlobalStyle from './styles/global-old';
import AppProvider from './hooks';
import Routes from './routes/index';
import { Content } from './pages/NotFound/styled';

import Logo from './assets/marca/logo.png';
import { executeUrl } from './utils/bibli';

const ErrorFallback: React.FC<any> = ({ error, resetErrorBoundary }) => (
  <Container>
    <Content>
      <div className="logo">
        <img src={Logo} width="170" height="170" alt="Logo Dr+" />
      </div>
      <div className="text">
        <h2>Ops!</h2>
        <h1>
          <strong> Ocorreu um erro inesperado</strong>
        </h1>
        <p>{error.toString()}</p>

        <div className="buttons">
          <button
            style={{ backgroundColor: '#3F56A5', border: 'transparent' }}
            type="button"
            onClick={() => {
              window.location.replace('https://app.drmais.com.br');
            }}
          >
            Tentar novamente
          </button>
        </div>
      </div>
    </Content>
  </Container>
);

const myErrorHandler = async (
  error: Error,
  info: { componentStack: string }
) => {
  try {
    let msg: any = error.message;
    const stackTrace = info;
    if (typeof msg === 'object')
      msg = msg ? msg.replace(/"/gim, '') : error.message;

    const body = {
      error: `${msg}`,
      stack: stackTrace.componentStack ? stackTrace.componentStack : 'web',
    };

    await executeUrl('POST', '/errorApplication', body);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Erro ao executar sendError!', e, 'Erro original: ', error);
  }
};

const App: React.FC = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </Router>
  </ErrorBoundary>
);

export default App;

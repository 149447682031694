import React from 'react';
import { Form } from '@unform/web';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Container } from 'react-bootstrap';
import DrRenderGrpPreEval, {
  dataPreEvaluationType,
} from '../../../components/dr-render-grp-pre-eval';
import { CardBlank } from '../../../styles/card-blank';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { useAuth, AuthContextData } from '../../../hooks/auth';
import { useIsMounted } from '../../../hooks/is-mounted';
import { ToastMessage, useToast } from '../../../hooks/toast';
import { valueParam } from '../../../utils/bibli';

interface CardPreEvaluationProps {
  dataPreEvaluation: dataPreEvaluationType;
}

interface cbOnSubmitParams {
  formData: any;
  dataPreEvaluation: dataPreEvaluationType;
  groupCount: number;
  setGroupCount: React.Dispatch<React.SetStateAction<number>>;
  dataPreEvalFormRef: React.MutableRefObject<any>;
  requestAPI: AuthContextData['requestAPI'];
  attendance: AuthContextData['attendance'];
  setAttendanceData: AuthContextData['setAttendanceData'];
  paramsId: IdRoute['id'];
  isMountedRef: React.MutableRefObject<boolean>;
  addToast: (message: Omit<ToastMessage, 'id'>) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  history: any;
  sendError: (message: any) => Promise<void>;
}
interface IdRoute {
  id: string;
}

function cbOnSubmit({
  formData,
  dataPreEvaluation,
  groupCount,
  setGroupCount,
  dataPreEvalFormRef,
  requestAPI,
  attendance,
  setAttendanceData,
  paramsId,
  isMountedRef,
  addToast,
  setIsLoading,
  history,
  sendError,
}: cbOnSubmitParams) {
  const attendaceChanged = attendance;
  Object.assign(dataPreEvalFormRef.current, formData);
  const dataPreEvalFormRefChgd = dataPreEvalFormRef;

  const groupsEval = dataPreEvaluation.grupos;
  if (groupCount < groupsEval.length - 1) {
    setGroupCount(groupCount + 1);
  } else {
    dataPreEvalFormRefChgd.current.ficha_avaliacao_gerada = {
      idficha_avaliacao: dataPreEvaluation.idficha_avaliacao,
      idpessoa_fisica: attendaceChanged.idpessoa_paciente,
    };

    setIsLoading(true);

    requestAPI({
      method: 'POST',
      url: '/fichaAvaliacaoGerada',
      body: dataPreEvalFormRefChgd.current,
    })
      .then(async (response) => {
        attendaceChanged.idficha_avaliacao_gerada =
          response.idficha_avaliacao_gerada;

        attendaceChanged.nm_paciente = response.nm_paciente;

        await setAttendanceData(attendaceChanged, true);

        history.push('/attendance-options');
      })
      .catch((error: any) => {
        sendError(error);
        if (isMountedRef.current) {
          addToast({
            type: 'error',
            title: 'Erro ao prosseguir na consulta',
            description: error.message.replace('Validation error: ', ''),
          });
          setIsLoading(false);
        }
      });
  }
}

const CardPreEvaluation: React.FC<CardPreEvaluationProps> = ({
  dataPreEvaluation,
}: CardPreEvaluationProps) => {
  const { params } = useRouteMatch<IdRoute>();
  const isMountedRef = useIsMounted();
  const { addToast } = useToast();
  const {
    requestAPI,
    attendance,
    setAttendanceData,
    sendError,
    applicationData,
  } = useAuth();
  const history = useHistory();
  const formRef = React.useRef<FormHandles>(null);
  const [groupCount, setGroupCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const dataPreEvalFormRef = React.useRef<any>({});

  const groupsEval = dataPreEvaluation.grupos;

  const onSubmit = React.useCallback(
    (formData) => {
      cbOnSubmit({
        formData,
        dataPreEvaluation,
        groupCount,
        setGroupCount,
        dataPreEvalFormRef,
        requestAPI,
        attendance,
        setAttendanceData,
        paramsId: params.id,
        isMountedRef,
        addToast,
        setIsLoading,
        history,
        sendError,
      });
    },
    [
      dataPreEvaluation,
      groupCount,
      setGroupCount,
      requestAPI,
      attendance,
      setAttendanceData,
      params.id,
      isMountedRef,
      addToast,
      setIsLoading,
      history,
    ]
  );

  return (
    <Container className="pt-3">
      <Form onSubmit={onSubmit} ref={formRef}>
        {dataPreEvaluation.grupos.length > 0 && (
          <CardBlank className="container pt-3 pb-3">
            <DrRenderGrpPreEval
              dataPreEvaluation={dataPreEvaluation}
              idxGroup={groupCount}
              formRef={formRef}
            />
          </CardBlank>
        )}

        <DrButtonsFooter
          buttonLeft={{
            type: 'submit',
            title: 'Continuar',
            loading:
              groupCount === groupsEval.length - 1 ? isLoading : undefined,
          }}
          buttonRight={
            groupCount > 0
              ? {
                  title: 'Voltar',
                  onClick: () => {
                    setGroupCount(groupCount - 1);
                  },
                }
              : {
                  onClick: () => {
                    history.push('/dashboard');
                  },
                }
          }
        />
      </Form>
    </Container>
  );
};

export default CardPreEvaluation;

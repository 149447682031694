import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import {
  CardEquip,
  CardEquipIcon,
  CardEquipTitle,
  CardEquipAlert,
  CardEquipAlertProps,
} from './styled';

export interface CardEquipProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  icon?: React.ReactNode;
  cssDivIcon?: FlattenSimpleInterpolation;
  cssIcon?: FlattenSimpleInterpolation;
  body?: React.ReactNode;
  title?: string | false;
  alert?: { status: CardEquipAlertProps['status']; text: string } | false;
  classNameTitle?: string;
  styleTitle?: React.CSSProperties;
}
const DrCardEquip: React.FC<CardEquipProps> = (props: CardEquipProps) => {
  const {
    icon,
    body,
    title,
    alert,
    classNameTitle,
    styleTitle,
    cssIcon,
    cssDivIcon,
    className,
    ...rest
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CardEquip {...rest} className={className}>
      {icon && (
        <CardEquipIcon externalCssDiv={cssDivIcon} externalCssIcon={cssIcon}>
          {icon}
        </CardEquipIcon>
      )}
      {(body || title) && (
        <div>
          {title && (
            <CardEquipTitle className={classNameTitle} style={styleTitle}>
              {title}
            </CardEquipTitle>
          )}
          {body}
        </div>
      )}
      {alert && (
        <CardEquipAlert status={alert.status}>{alert.text}</CardEquipAlert>
      )}
    </CardEquip>
  );
};

DrCardEquip.defaultProps = {
  icon: false,
  body: false,
  title: false,
  alert: false,
  classNameTitle: undefined,
};

export default DrCardEquip;

import styled from 'styled-components';

export const Fields = styled.div`
  width: 100%;

  .btn-search {
    button {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Field = styled.div`
  margin: 0 0 20px;
  border-radius: 3px;
  margin-top: 20px;
  min-height: 30px;
  width: 100%;
`;

export const FormControl = styled.div`
  width: 100%;
  height: 100%;
`;

export const FormSearchBtn = styled.button`
  background-color: #3f56a5;
`;

export const Select = styled.select`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  min-height: 53px;
  width: 100%;
  height: 100%;
`;

export const FieldSelect = styled.div`
  border: 2px solid #e5e5e5;
  border-radius: 3px;
  padding: 0 5px 0 10px;
  margin-top: 20px;
  min-height: 58px;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
`;

export const Label = styled.h4`
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  color: #777;
`;

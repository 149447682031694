import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: white; */
  flex-direction: column;
  @media screen and (max-width: 850px) {
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: auto;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
  height: 60vh;

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    img {
      height: 230px;
      width: 230px;
    }
  }

  .text {
    text-align: center;
    h1 {
      text-align: center;
      text-align: center;
      font-size: 2rem;
      color: #4156a6;
    }

    h2 {
      margin-top: 1rem;
      text-align: center;
      color: #4156a6;
    }

    h3 {
      margin: 10px;
      color: #4156a6;
    }
  }
  .button-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  button {
    background: #ed145b;
    font-weight: bold;
    height: 45px;
    width: 200px;
    color: #fff;
    border: 2px solid #ed145b;
    border-radius: 20px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: rgba(237, 20, 91, 0.8);
      color: #fff;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 95vw;
    height: 80vh;

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      img {
        height: 130px;
        width: 130px;
      }
    }

    .text {
      h1 {
        text-align: center;
        text-align: center;
        font-size: 2rem;
        color: #4156a6;
      }

      h2 {
        margin-top: 1rem;
        text-align: center;
        color: #4156a6;
        font-size: 1.8rem;
      }

      h3 {
        margin: 10px;
        color: #4156a6;
        font-size: 1.5rem;
      }
    }
  }
`;

import styled from 'styled-components';

export const CardGeral = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #e5e5e5;
  padding: 15px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
`;

export const CardGeralBody = styled.div`
  width: 100%;
  flex-grow: 1;

  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    color: #777;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 1199px) {
      font-size: 0.8rem;
    }
  }
`;

export const CardGeralBtn = styled.div`
  width: 100%;
  margin-top: 15px;

  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
`;

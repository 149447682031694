import styled, { css } from 'styled-components';

interface ButtonLikeProps {
  like: boolean;
}

export const ButtonLike = styled.button<ButtonLikeProps>`
  position: relative;
  z-index: 10;
  pointer-events: all;
  color: #3f56a5;
  height: 100%;
  height: 50px;
  width: 75px;
  background-color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: 1px solid #e5e5e5;

  ${(props) =>
    props.like &&
    css`
      background-color: #3f56a5;
    `}
  @media screen and (max-width: 300px) {
    width: 60px;
  }

  :hover {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #3f56a5;

    i {
      color: #fff;
    }
  }
`;

import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import moment from 'moment';
import { Container, Appointment } from './styled';
import { useAuth, AttendanceDataTypes } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Input from '../../components/dr-input';
import { verifyErrorYup } from '../../utils/bibli';
import HelmetComponent from '../../components/dr-helmet-component';
import Header from '../../components/dr-header';

interface AttendanceTypes {
  idpessoa_fisica: number;
  atendimento: string;
  modalidade: number;
  dt_preferencia_atd?: string;
  hr_preferencia_atd: string;
  vl_consulta: string;
  idagenda_consulta: number;
}

const AttendanceAppointment: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(false);
  const { requestAPI, attendance, sendError } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [localStorageDatas, setLocalStorageDatas] =
    useState<AttendanceDataTypes>(attendance);

  async function handleSubmit(data: AttendanceTypes) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        dt_preferencia_atd: Yup.string()
          .required('E obrigágorio agendar uma data e horário')
          .trim(),
        hr_preferencia_atd: Yup.string()
          .required('E obrigágorio agendar uma data e horário')
          .trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      const dt = moment(data.dt_preferencia_atd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
      const hr = moment(data.hr_preferencia_atd, 'hh:mm').format('HH:mm');

      const dateFormat = `${dt} ${hr}`;
      const x = moment(dateFormat, 'YYYY-MM-DD HH:mm');

      if (x < moment()) {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Data de agendamento menor que data atual',
        });
        return;
      }

      setLoading(true);
      const body = {
        idpessoa_fisica: localStorageDatas.idpessoa_paciente,
        atendimento: 'PA',
        modalidade: 2,
        ie_telemedicina: 'S',
        dt_preferencia_atd: dateFormat,
        vl_consulta: localStorageDatas.vl_total_servico,
        ie_retorno: localStorageDatas.ie_retorno,
        idconta_receber_cartao_movto:
          localStorageDatas.idconta_receber_cartao_movto,
        idficha_avaliacao_gerada: localStorageDatas.idficha_avaliacao_gerada,
        idpessoa_titular: localStorageDatas.idpessoa_titular,
        idconvenio: localStorageDatas.idconvenio,
        idestabelecimento_tipo_atendimento:
          localStorageDatas.idestabelecimento_tipo_atendimento,
      };

      await requestAPI({
        method: 'POST',
        url: '/checkinAtendimentoPaciente',
        body,
      });

      history.push(`/home`);
      addToast({
        type: 'success',
        title: 'Agendamento concluído',
        description: 'Agendamento realizado com sucesso!',
      });
    } catch (err: any) {
      sendError(err);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
        return;
      }
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  }

  // function handleSchedule(event: ChangeEvent<HTMLInputElement>) {
  //   const inputValue = event.target.value;

  //   setSchedule(strToDate(inputValue, 'YYYY-MM-DDTHH:mm'));
  // }

  return (
    <>
      <HelmetComponent title="Agendamento do Atendimento" />
      <Header />

      <Container>
        <Appointment>
          <h1>Agendar um Atendimento</h1>
          <h3>Informe a data e a hora desejada :</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="input-date-hours">
              <div>
                <Input
                  name="dt_preferencia_atd"
                  customType="dateText"
                  max="2099-12-31"
                  validate
                  label="Data"
                  inputMode="numeric"
                />
              </div>
              <div>
                <Input
                  name="hr_preferencia_atd"
                  customType="timeText"
                  placeholder="HH:mm"
                  validate
                  label="Hora"
                  inputMode="numeric"
                />
              </div>
            </div>

            <div className="buttons">
              <Link to={`/attendance-options/${localStorageDatas.idroute}`}>
                <button type="button" className="now">
                  Voltar
                </button>
              </Link>
              <button type="submit" className="submit">
                Agendar
              </button>
            </div>
          </Form>
        </Appointment>
      </Container>
    </>
  );
};
export default AttendanceAppointment;

import React from 'react';
import { Link } from 'react-router-dom';
import * as styled from './styled';
import { ButtonLike } from '../../styles/button-like';
import { ButtonDislike } from '../../styles/button-dislike';
import DrIconFont from '../dr-icon-font';

interface buttonsLikeDislikeI {
  active: boolean;
  handleClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

interface DrCardBlogProps {
  urlLink?: string | false;
  head?: React.ReactNode;
  date?: React.ReactNode;
  title?: React.ReactNode;
  urlDesc?: React.ReactNode;
  buttonLike?: buttonsLikeDislikeI | false;
  buttonDislike?: buttonsLikeDislikeI | false;
}

const DrCardBlog: React.FC<DrCardBlogProps> = (props: DrCardBlogProps) => {
  const { urlLink, head, date, title, urlDesc, buttonLike, buttonDislike } =
    props;

  const body = (
    <styled.DrCardBlogContainer>
      {head && (
        <styled.CardBlogHead className="head">{head}</styled.CardBlogHead>
      )}

      {(date || title) && (
        <styled.CardBlogBody>
          {date && (
            <styled.CardBlogDate>{`Publicado em ${date}`}</styled.CardBlogDate>
          )}
          {title && (
            <styled.CardBlogTitle className="title">
              {title}
            </styled.CardBlogTitle>
          )}
        </styled.CardBlogBody>
      )}

      {urlDesc && (
        <styled.CardBlogBtn className="btn">
          <styled.CardBlogUrl className="url">{urlDesc}</styled.CardBlogUrl>
        </styled.CardBlogBtn>
      )}
    </styled.DrCardBlogContainer>
  );
  return (
    <styled.BoxBlog id="BOXBLOG">
      <styled.CardBlog id="CardBlog ">
        {urlLink ? <Link to={urlLink}>{body}</Link> : body}
        {(buttonLike || buttonDislike) && (
          <styled.CardBlogLikes>
            {buttonLike && (
              <ButtonLike
                like={buttonLike.active}
                onClick={buttonLike.handleClick}
              >
                <div>
                  <DrIconFont
                    name="like"
                    color={buttonLike.active ? '#fff' : '#3f56a5'}
                  />
                </div>
              </ButtonLike>
            )}
            {buttonDislike && (
              <ButtonDislike
                like={buttonDislike.active}
                onClick={buttonDislike.handleClick}
              >
                <div>
                  <DrIconFont
                    name="dislike"
                    color={buttonDislike.active ? '#fff' : '#ed145b'}
                  />
                </div>
              </ButtonDislike>
            )}
          </styled.CardBlogLikes>
        )}
      </styled.CardBlog>
    </styled.BoxBlog>
  );
};

DrCardBlog.defaultProps = {
  urlLink: false,
  head: false,
  date: false,
  title: false,
  urlDesc: false,
  buttonDislike: false,
  buttonLike: false,
};

export default DrCardBlog;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DrAlert from '../../../components/dr-alert';
import {
  BoxFicha,
  BoxFichaBody,
  BoxFichaHeader,
} from '../../../components/dr-box-ficha/styled';
import DrButton from '../../../components/dr-button';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import {
  BoxMember,
  BoxMemberName,
  BoxMemberPic,
} from '../../../components/dr-card-member/styled';
import HelmetComponent from '../../../components/dr-helmet-component';
import DrIconFont from '../../../components/dr-icon-font';
import DrImageProfile from '../../../components/dr-image-profile';
import { useAuth } from '../../../hooks/auth';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import { useToast } from '../../../hooks/toast';
import { valueParam } from '../../../utils/bibli';
import { userRequirements } from '../../../utils/validations';
import { PreviewScheduleContainer, ContainerProfessionalCard } from './styled';
import DrShowSchedulesSpecialties from '../../../components/dr-show-schedules-specialties';

interface DrCardSelectProps {
  specialties: any;
  avatarPadrao: string;
  setAttendanceData: any;
}

function mapArraySpecialties({
  avatarPadrao,
  medico,
  history,
  setAttendanceData,
}) {
  return (
    <ContainerProfessionalCard>
      <BoxMember
        style={{ margin: 5 }}
        onClick={async () => {
          await setAttendanceData({
            nm_pessoa_medico: medico.nm_pessoa,
            idpessoa_medico: medico.idpessoa_fisica,
            idespecialidade: medico.idespecialidade,
          });
          history.push({ pathname: '/schedule-attendance' });
        }}
        key={`id#_${medico.nm_pessoa}`}
      >
        <BoxMemberPic>
          <DrImageProfile
            source={medico.url_img || avatarPadrao}
            alt="foto de perfil"
            width={100}
            height={100}
            style={{
              borderRadius: '50%',
              borderStyle: 'none',
              padding: '3px',
              alignSelf: 'center',
              margin: '15px auto 15px 15px',
            }}
          />
        </BoxMemberPic>
        <div
          style={{
            alignSelf: 'center',
          }}
        >
          <BoxMemberName>
            <strong>{medico.nm_pessoa}</strong>
          </BoxMemberName>
          <BoxMemberName>{`CRM: ${medico.nr_conselho}`}</BoxMemberName>
          <BoxMemberName>{medico.ds_especialidade}</BoxMemberName>
        </div>
        <PreviewScheduleContainer>
          <DrShowSchedulesSpecialties
            specialty={medico}
            styleGrid="column"
            color="black"
            screen="selectSpecialties"
          />
        </PreviewScheduleContainer>
      </BoxMember>
    </ContainerProfessionalCard>
  );
}

const DrCardSelectSpecialty: React.FC<DrCardSelectProps> = (
  Props: DrCardSelectProps
) => {
  const { specialties, setAttendanceData, avatarPadrao } = Props;
  const [arrButton, setArrButton] = React.useState(() =>
    specialties.map(() => false)
  );
  const [state, setState] = React.useState(true);
  const { attendance, user, applicationData } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  let arrayRequirements: any = [];
  const paramsUser = valueParam(applicationData, 209);
  if (paramsUser) {
    arrayRequirements = paramsUser.split(',');
    if (
      !arrayRequirements.some((field) => field === 'url_img') &&
      valueParam(applicationData, 210) === 'S'
    ) {
      arrayRequirements.push('url_img');
    }
  }

  React.useEffect(() => {
    if (user) {
      if (userRequirements(user, arrayRequirements)) {
        history.push({
          pathname: `/update-user-registration-datas/${user.idtm_usuario}`,
          state: 'new-attendance-incomplete-data',
        });

        addToast({
          type: 'error',
          title: 'Ops!',
          description: `É necessário completar o formulário de cadastro antes de continuar`,
        });
      }
    }
  }, []);

  React.useEffect(() => {
    setArrButton((prev) => {
      const prevBtn = [...prev];
      // eslint-disable-next-line no-param-reassign
      prevBtn[0] = !prevBtn[0];
      return [...prevBtn];
    });
    setState(false);
  }, []);

  function buttonText() {
    if (user) {
      return 'Voltar';
    }

    if (
      attendance?.tipos_atendimento &&
      attendance?.tipos_atendimento.length === 1
    ) {
      return 'Cancelar';
    }
    return 'Voltar';
  }

  function buttonURL() {
    if (
      (attendance.singleType && !user) ||
      attendance.fromReturn ||
      attendance.idagenda_consulta ||
      (attendance && attendance.origem_cartao && !user)
    ) {
      return '/dashboard';
    }
    if (!attendance.singleType && !user) {
      return '/types-care';
    }
    return '/select-to-attendance';
  }

  return (
    <>
      <HelmetComponent title="Agendamento para atendimento" />

      <Container className="pt-3">
        {specialties && specialties.length > 0 ? (
          <CardBlank className="p-3" definedSize="50vh">
            <PageTitle>Selecione o Profissional</PageTitle>

            {specialties.map((especialidade, i) => (
              <BoxFicha
                className="mt-4"
                active={arrButton[i] === true}
                key={especialidade.idespecialidade}
              >
                <BoxFichaHeader>
                  <DrButton
                    title={especialidade.ds_especialidade}
                    color="blue"
                    type="button"
                    data-
                    icon={
                      <DrIconFont
                        name={
                          arrButton[i] === true
                            ? 'circle-arrow-down'
                            : 'circle-arrow-right'
                        }
                        fontSize="28px"
                      />
                    }
                    iconRight
                    data-toggle={state ? 'collapse' : undefined}
                    data-target={`#id_${especialidade.idespecialidade}`}
                    onClick={() => {
                      setArrButton((prev) => {
                        const prevBtn = [...prev];
                        // eslint-disable-next-line no-param-reassign
                        prevBtn[i] = !prevBtn[i];
                        return [...prevBtn];
                      });
                    }}
                  />
                </BoxFichaHeader>

                <BoxFichaBody
                  id={`id_${especialidade.idespecialidade}`}
                  // eslint-disable-next-line prettier/prettier
                  className={
                    arrButton[i] === true ? 'collapse show' : 'collapse'
                  }
                >
                  <li
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Container>
                      {specialties[i].profissionais.map((medico) => (
                        <Row className="justify-content-center">
                          {mapArraySpecialties({
                            avatarPadrao,
                            medico: {
                              ...medico,
                              ds_especialidade: especialidade.ds_especialidade,
                              idespecialidade: especialidade.idespecialidade,
                            },
                            history,
                            setAttendanceData,
                          })}
                        </Row>
                      ))}
                    </Container>
                  </li>
                </BoxFichaBody>
              </BoxFicha>
            ))}
          </CardBlank>
        ) : (
          <CardBlank className="mt-3 pt-3 pb-3">
            <Row className="col-xl-12 justify-content-center pt-1 m-0">
              <Col>
                <DrAlert
                  warning
                  title={{
                    text: 'Não foram localizadas especialidades para essa agenda',
                    fontSize: '14px',
                  }}
                />
              </Col>
            </Row>
          </CardBlank>
        )}
      </Container>

      <DrButtonsFooter
        buttonLeft={false}
        buttonRight={{
          title: buttonText(),
          href: buttonURL(),
        }}
      />
    </>
  );
};
export default DrCardSelectSpecialty;

import React from 'react';
import { Container } from './styled';
import { useAuth } from '../../hooks/auth';

const Banner: React.FC = () => {
  const { applicationData } = useAuth();

  return (
    <Container>
      <div className="img-banner-login">
        {applicationData.valores_variaveis &&
          applicationData.valores_variaveis.url_img_login && (
            <img src={applicationData.valores_variaveis.url_img_login} alt="" />
          )}
      </div>
    </Container>
  );
};

export default Banner;

import React, { useRef, useState, useCallback } from 'react';
import cardValidator from 'card-validator';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';

import Input from '../../components/dr-input';
import { isCreditCardDate, isCreditCardNumber } from '../../utils/validations';
import { verifyErrorYup } from '../../utils/bibli';
import getValidationCards from '../../utils/getValidationCards';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { Fields } from '../../styles/fields';
import { BoxCards } from '../../styles/box-cards';
import { CardCards } from '../../styles/card-cards';
import { Field } from '../../styles/field';
import Button from '../../components/dr-button';
import DrButtonLoading from '../../components/dr-button-loading';
import { isValidDate } from '../../utils/formatDates';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

interface Cr {
  ie_forma_pg: string;
  ie_bandeira: string;
  nm_titular: string;
  ds_nrcartao: string;
  ds_validade: string;
}

const AddCard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { updateUser, requestAPI, sendError } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [inputNumber, setInputNumber] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [inputName, setInputName] = useState<string>('');
  const [inputCvc, setInputCvc] = useState<string>('');
  const [inputExpire, setInputExpire] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');

  const handleSubmit = useCallback(
    async (data: Cr) => {
      try {
        data.ie_forma_pg = 'C';
        data.ie_bandeira = getValidationCards(cardBrand);
        const schema = Yup.object().shape({
          ds_nrcartao: Yup.string()
            .test({
              message: 'Cartão de crédito inválido',
              test: (value) => cardValidator.number(value).isValid,
            })
            .required('Cartão de crédito é obrigatório')
            .trim(),
          nm_titular: Yup.string()
            .required('Nome do titular do cartão é obrigatório')
            .trim(),
          ds_validade: Yup.string()
            .required('Data de validade do cartão é obrigatória')
            .test({
              message: 'Data informada é inválida',
              test: (value) => {
                if (value) return cardValidator.expirationDate(value).isValid;
                return true;
              },
            })
            .trim(),
          ie_bandeira: Yup.string().required().trim(),
        });

        data.ds_validade = moment(data.ds_validade, 'MM/YY').format('MM/YYYY');

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await requestAPI({
          method: 'POST',
          url: `/tmUsuarioCartao`,
          body: data,
        });
        setLoading(false);

        history.push('/payments-methods');
      } catch (err: any) {
        sendError(err);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          verifyErrorYup(err, formRef);
          return;
        }
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    },
    [addToast, cardBrand, history, requestAPI]
  );

  function handleInputNumber(value: string) {
    setInputNumber(value);
  }
  function handleInputName(value: string) {
    setInputName(value);
  }

  function handleInputExpire(value: string) {
    setInputExpire(value);
  }

  const handleCallback = useCallback((issuer: any, isValid: boolean) => {
    setCardBrand(issuer.issuer);
  }, []);

  return (
    <>
      <Header />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container className="pt-3">
          <CardBlank className="pt-2 pb-2">
            <PageTitle>Novo Cartão de Crédito</PageTitle>
            <Fields>
              <Row className="justify-content-center">
                <BoxCards className="col-md-12 col-xl-4">
                  <CardCards>
                    <Cards
                      key={0}
                      cvc={inputCvc}
                      expiry={inputExpire}
                      name={inputName}
                      number={inputNumber}
                      locale={{ valid: 'validade' }}
                      acceptedCards={[
                        'visa',
                        'mastercard',
                        'discover',
                        'amex',
                        'jcb',
                        'dinersclub',
                        'elo',
                        'aura',
                      ]}
                      placeholders={{ name: 'Seu nome aqui' }}
                      callback={handleCallback}
                    />
                  </CardCards>
                </BoxCards>
              </Row>
              <Row className="pt-1 m-0">
                <Col md={12} xl={4}>
                  <Field>
                    <Input
                      name="ds_nrcartao"
                      label="Número do Cartão"
                      placeholder="•••• •••• •••• ••••"
                      customType="cardNumber"
                      getValue={handleInputNumber}
                      inputMode="numeric"
                      validate
                    />
                  </Field>
                </Col>
                <Col md={12} xl={4}>
                  <Field>
                    <Input
                      name="nm_titular"
                      label="Nome do Titular"
                      getValue={handleInputName}
                      placeholder="Digite o nome do titular"
                      customType="nameInput"
                      validate
                    />
                  </Field>
                </Col>
                <Col md={12} xl={4}>
                  <Field>
                    <Input
                      type="text"
                      name="ds_validade"
                      getValue={handleInputExpire}
                      label="Validade ( MM / AA )"
                      placeholder="MM / AA"
                      customType="cardDate"
                      inputMode="numeric"
                      validate
                    />
                  </Field>
                </Col>
              </Row>
            </Fields>
          </CardBlank>
        </Container>
        <Container className="mt-3">
          <Row className="col-xl-12 justify-content-center row-btn m-0">
            <Col xl={4}>
              <DrButtonLoading
                type="submit"
                color="blue"
                title="Salvar"
                loading={loading}
              />
            </Col>
            <Col xl={4}>
              <Button
                type="button"
                color="dark-grey"
                href="/payments-methods"
                title="Cancelar"
              />
            </Col>
          </Row>
        </Container>
      </Form>
      <Footer />
    </>
  );
};
export default AddCard;

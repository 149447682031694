import styled from 'styled-components';

interface MarginProps {
  margin?: string;
}

export const BoxPicture = styled.div<MarginProps>`
  text-align: center;
  margin: ${(props) => props.margin};
`;
BoxPicture.defaultProps = {
  margin: '0 0 15px',
};

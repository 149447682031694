import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerStreamPage = styled.div`
  height: 100vh;
`;

export const Header = styled.header`
  body {
    height: 100%;
  }
  @media (max-width: 800px) {
    padding: 0.2rem;
  }

  margin: 0;
  z-index: 100;
  position: relative;
  background-color: #fff;
  padding: 20px 0;
  box-shadow: 0 10px 30px 1px rgb(0 0 0 / 5%);
`;

export const RowFooter = styled(Row)`
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  width: 100%;
`;

export const DivDrBoxChat = styled.div`
  position: relative;
  max-width: 50px;

  @media (min-width: 1200px) {
    position: absolute;
    left: 0.5em;
    top: 1em;
  }
`;

export const ImgHeaderLogo = styled.img`
  width: 4rem;

  @media (min-width: 576px) {
    width: 70px;
  }
`;

export const ColImgLogoHeader = styled(Col)`
  @media (max-width: 575px) {
    padding: 0 0.4rem 0 0.2rem;
    align-self: center;
  }
`;

export const ColHeadAtend = styled(Col)`
  @media (max-width: 575px) {
    padding: 0 0.2rem;
  }
`;

export const SpanHeadAtend = styled.span`
  color: #343a40 !important;
  font-weight: bolder;

  @media (max-width: 575px) {
    font-size: 0.9rem;
  }
`;

export const ColIconHeader = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    padding: 0 0.1rem;
    align-self: center;
  }
`;

import React from 'react';
import { IconDefault, IconDefaultProps } from './styled';

export interface DrIconFontProps extends IconDefaultProps {
  name: string;
  className?: string;
  cursor?: any;
  onClick?: () => void;
}

const DrIconFont: React.FC<DrIconFontProps> = (props: DrIconFontProps) => {
  const { name, className, cursor, onClick, ...rest } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <IconDefault
      style={{ cursor: `${cursor}` }}
      onClick={onClick}
      className={`icon-${name}${className ? ` ${className}` : ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

DrIconFont.defaultProps = {
  className: undefined,
  cursor: 'default',
};

export default DrIconFont;

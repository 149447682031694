/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Button from '../dr-button';
import { CardGeralPl } from '../../styles/CardGeralPl';
import { CardGeralBody } from '../../styles/card-geral_body';
import { CardGeralBtn } from '../../styles/card-geral_btn';
import { PageSubtitle } from '../../styles/page-title';
import { Ul } from '../dr-plan/styled';
import { useAuth } from '../../hooks/auth';
import { upperCaseLetter, valueParam } from '../../utils/bibli';
import DrModal from '../dr-modal';
import { msToClock } from '../../utils/formatDates';
import { useIsTimeToConsult } from '../../hooks/useIsTimeToConsult';
import { Container, Text } from './styled';

interface SchedulesProps {
  schedules: any;
  itemId: string;
}

interface ConsultAlertProps {
  ConsultSchedules: {
    nm_pessoa_medico: string;
    nr_conselho: string;
    ds_especialidade: string;
    nm_pessoa_paciente: string;
  };
  schedulingDateFormatted: any;
  schedulingDayFormatted: any;
  schedulingTimeFormatted: any;
}
const ConsultAlert = ({
  ConsultSchedules,
  schedulingDateFormatted,
  schedulingDayFormatted,
  schedulingTimeFormatted,
}: ConsultAlertProps) => {
  let ee;
  return (
    <>
      <Container>
        <Text>
          Paciente :{' '}
          <b style={{ color: '#ed145b' }}>
            {ConsultSchedules.nm_pessoa_paciente}
          </b>
          <br />
          Profissional : <b>{ConsultSchedules.nm_pessoa_medico}</b> <br />
          CRM : <b>{ConsultSchedules.nr_conselho}</b>
          <br />
          Especialidade : <b>{ConsultSchedules.ds_especialidade}</b>
          <br />
          Para o dia :{' '}
          <b>
            {schedulingDateFormatted} {schedulingTimeFormatted}h{' '}
          </b>
        </Text>
      </Container>
    </>
  );
};

const MapArrayRender = ({ schedules, history, setAttendanceData }) => {
  const { applicationData } = useAuth();
  let typeAttendance = 'Consulta';
  if (schedules.ie_retorno && schedules.ie_retorno === 'S')
    typeAttendance = 'Retorno';

  const render = [
    {
      lbl: 'Médico(a): ',
      val: schedules.nm_pessoa_medico,
    },

    { lbl: 'CRM: ', val: schedules.nr_conselho },
    {
      lbl: 'Especialidade: ',
      val: schedules.ds_especialidade,
    },
    {
      lbl: 'Paciente: ',
      val: schedules.nm_pessoa_paciente,
    },
    {
      lbl: 'Tipo: ',
      val: typeAttendance,
    },
  ];

  const schedulingDateFormatted = moment(schedules.dt_agenda).format(
    'DD/MM/YYYY'
  );
  const schedulingDayFormatted = moment(schedules.dt_agenda)
    .local()
    .format('dddd');
  const schedulingTimeFormatted = moment(schedules.dt_agenda).format('HH:mm');
  const deviceTimeZone = moment(schedules.dt_agenda).format('Z');

  // Aplica o fuso horário de Brasília à data de agendamento e retorna HH:mm
  const schedulingTimeServerTz = new Date(schedules.dt_agenda)
    .toLocaleTimeString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    })
    .slice(0, 5);

  // Valida se a timezone e o horário de agendamento são iguais
  const checkTimeZones = () =>
    deviceTimeZone === '-03:00' &&
    schedulingTimeServerTz === schedulingTimeFormatted;

  return (
    <>
      <div
        style={{
          alignItems: 'center',
        }}
      >
        <PageSubtitle color="#ed145b" textCenter style={{ fontWeight: 700 }}>
          {`${schedulingDateFormatted} (${upperCaseLetter(
            schedulingDayFormatted,
            '-',
            '-'
          )})`}
        </PageSubtitle>

        <PageSubtitle color="#ed145b" textCenter style={{ fontWeight: 700 }}>
          {schedulingTimeFormatted}h
        </PageSubtitle>

        <PageSubtitle color="#ed145b" textCenter fontSize={14}>
          {checkTimeZones()
            ? '(Horário de Brasília)'
            : `(${schedulingTimeServerTz}h - Horário de Brasília)`}
        </PageSubtitle>

        {valueParam(applicationData, 212) &&
          valueParam(applicationData, 212) !== -1 && (
            <h6 style={{ textAlign: 'center', color: '#3f56a5' }}>
              Evite atraso! Não será possível realizar a consulta após{' '}
              {valueParam(applicationData, 212)}min do horário agendado.
            </h6>
          )}
      </div>
      {render.map((val, ind) => (
        <Row key={`#id_${ind.toString()}`} style={{ paddingLeft: '20px' }}>
          <Ul>
            <li>
              <strong>{val.lbl}</strong>

              {`${val.val}`}
            </li>
          </Ul>
        </Row>
      ))}
    </>
  );
};

const DrCardSchedule: React.FC<SchedulesProps> = (Props: SchedulesProps) => {
  const { schedules } = Props;
  const { setAttendanceData, applicationData } = useAuth();
  const history = useHistory();
  const schedulingTimeFormatted = moment(schedules.dt_agenda).format('HH:mm');
  const { isTimeToConsult } = useIsTimeToConsult();
  const schedulingDayFormatted = moment(schedules.dt_agenda)
    .local()
    .format('dddd');
  const schedulingDateFormatted = moment(schedules.dt_agenda).format(
    'DD/MM/YYYY'
  );
  const consultSchedules = {
    nm_pessoa_medico: schedules.nm_pessoa_medico,
    nr_conselho: schedules.nr_conselho,
    ds_especialidade: schedules.ds_especialidade,
    nm_pessoa_paciente: schedules.nm_pessoa_paciente,
  };

  const hourToShowButton = 43200000; // 43200000ms = 12h;
  const scheduleDate = moment(schedules.dt_agenda).valueOf();
  const currentServerDate = new Date(schedules?.dt_atual_servidor).valueOf();
  const [clock, setClock] = React.useState({
    serverDate: currentServerDate,
    duration: scheduleDate - currentServerDate,
  });
  const timeToShowConsultButton = scheduleDate - hourToShowButton;
  const [showButtonConsultNow, setShowButtonConsultNow] = React.useState(
    new Date().getTime() >= timeToShowConsultButton
  );
  const [iniciarConsult, setIniciarConsult] = React.useState(false);
  const [consultModal, setConsultModal] = React.useState(false);
  const [modalProps, setModalProps] = React.useState({
    title: '',
    msg: '',
    type: '',
  });
  const [buttonLabel, setButtonLabel] = React.useState('Carregando...');
  const timeLeftInterval = useRef<any>();
  const timeToShowButton = useRef<any>();
  const changeServerDateInterval = useRef<ReturnType<typeof setInterval>>();

  const {
    ds_produto: assinante,
    idconta_receber_cartao_movto: houvePagamento,
  } = schedules;

  const msg = assinante
    ? `Assinante`
    : houvePagamento
    ? `Pagamento efetuado!`
    : ``;

  const handleOpeniniciarConsultModal = () => setIniciarConsult(true);
  const handleCloseiniciarConsultModal = () => setIniciarConsult(false);

  const handleOpenConsultModal = () => setConsultModal(true);
  const handleCloseConsultModal = () => setConsultModal(false);

  const canShowButton = () =>
    !!(
      schedules.ds_produto ||
      schedules.idconta_receber_cartao_movto ||
      schedules.ie_retorno === 'S'
    );

  function cancelationMsg() {
    const value = valueParam(applicationData, 223);
    if (value && value !== 1 && !schedules.ds_produto) {
      const [hour, minutes, seconds] = value.split(':');
      const dt_agenda = moment(schedules.dt_agenda).local();
      const dt_agendaFormated = moment(schedules.dt_agendamento).format(
        'DD/MM/YYYY'
      );

      const sumHrParameter = moment(schedules.dt_agendamento)
        .add(hour, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds');

      const sumHrParameterFormated = moment(sumHrParameter).format('HH:mm');

      if (sumHrParameter.isAfter(dt_agenda)) {
        return (
          <h6>
            Não efetuando o pagamento, agendamento será cancelado
            automaticamente.
          </h6>
        );
      }
      return (
        <div>
          <h6>
            Não efetuando o pagamento até{' '}
            <h6
              style={{
                color: '#ed145b',
                display: 'inline',
                fontWeight: 'bold',
              }}
            >
              {`${dt_agendaFormated} às ${sumHrParameterFormated}h`}
            </h6>
            , agendamento será cancelado automaticamente
          </h6>
        </div>
      );
    }
    return '';
  }

  React.useEffect(() => {
    moment.locale('pt-br');
  }, []);

  const goToUploadPage = () => {
    setAttendanceData({ ...schedules }, true).then(() => {
      history.push('/upload-page');
    });
  };

  const handleRoute = async () => {
    let timeToConsult;
    if (!schedules.idatendimento_paciente) {
      if (schedules.idestabelecimento_tipo_atendimento !== 1) {
        timeToConsult = await isTimeToConsult(schedules?.idagenda_consulta);
      }

      if (!timeToConsult.isTime) {
        setModalProps({
          title: timeToConsult.title,
          msg: timeToConsult.msg,
          type: timeToConsult.type,
        });
        handleOpenConsultModal();
      } else {
        goToUploadPage();
      }
    }

    if (schedules.idatendimento_paciente) {
      await setAttendanceData(schedules);
      history.push('/attendance-now/');
    }
  };

  const handleRefreshCurrentServerDate = React.useCallback(() => {
    changeServerDateInterval.current = setInterval(() => {
      setClock((prevState) => ({
        ...prevState,
        serverDate: prevState.serverDate + 1000,
        duration: prevState.duration - 1000,
      }));
    }, 1000);
  }, []);

  React.useEffect(() => {
    handleRefreshCurrentServerDate();
    return () => clearInterval(changeServerDateInterval.current);
  }, [clock]);

  React.useEffect(() => {
    if (!showButtonConsultNow) {
      timeToShowButton.current = setInterval(() => {
        if (clock.serverDate >= timeToShowConsultButton) {
          setShowButtonConsultNow(true);
          clearInterval(timeToShowButton.current);
        }
      }, 1000);
    }

    return () => clearInterval(timeToShowButton.current);
  }, [showButtonConsultNow, clock.serverDate]);
  React.useEffect(() => {
    async function fetchData() {
      let timeToConsult;
      try {
        timeToConsult = await isTimeToConsult(schedules?.idagenda_consulta);
        if (
          !(
            !schedules.idatendimento_paciente &&
            !schedules.idconta_receber_cartao_movto &&
            !schedules.ds_produto &&
            schedules.ie_retorno !== 'S'
          ) &&
          clock.serverDate >= scheduleDate - 1000
        ) {
          setModalProps({
            title: 'Iniciar consulta',
            msg: ``,
            type: timeToConsult.type,
          });
          if (!iniciarConsult) {
            const timer = setTimeout(() => {
              handleOpeniniciarConsultModal();
            }, 1000);

            clearTimeout(timer);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    if (showButtonConsultNow) {
      timeLeftInterval.current = setInterval(() => {
        setButtonLabel((value) => {
          value = `Consulta iniciará em ${msToClock(clock.duration)}`;
          if (
            !(
              !schedules.idatendimento_paciente &&
              !schedules.idconta_receber_cartao_movto &&
              !schedules.ds_produto &&
              schedules.ie_retorno !== 'S'
            ) &&
            clock.serverDate >= scheduleDate - 1000
          ) {
            value = 'Iniciar Consulta';
            handleOpeniniciarConsultModal();
            clearInterval(changeServerDateInterval.current);
            clearInterval(timeLeftInterval.current);
          }
          return value;
        });
      }, 1000);
    }

    return () => clearInterval(timeLeftInterval.current);
  }, [showButtonConsultNow, clock]);

  return (
    <Col md={12} className="mb-3">
      <CardGeralPl style={{ flexWrap: 'wrap' }}>
        <CardGeralBody className="flex-grow-1 ">
          <MapArrayRender
            schedules={schedules}
            history={history}
            setAttendanceData={setAttendanceData}
          />
        </CardGeralBody>
        <CardGeralBtn>
          {msg ? <h2>{msg}</h2> : ''}

          {!schedules.idconta_receber_cartao_movto &&
            !schedules.ds_produto &&
            schedules.ie_retorno !== 'S' && (
              <h6 style={{ textAlign: 'center', color: '#3f56a5', margin: 0 }}>
                {cancelationMsg()}
              </h6>
            )}
          {canShowButton() && showButtonConsultNow && !!buttonLabel && (
            <Button
              title={buttonLabel}
              onClick={handleRoute}
              titleColor="#ffffff"
              color="#ed145b"
              type="button"
              style={{ marginBottom: 5 }}
              disabled={buttonLabel === 'Carregando...'}
            />
          )}
          {canShowButton() &&
            valueParam(applicationData, 241) &&
            valueParam(applicationData, 241) === 'S' && (
              <Button
                title="Anexar Arquivos"
                onClick={() => goToUploadPage()}
                color="#3f56a5"
                titleColor="#ffffff"
                type="button"
                style={{ marginBottom: 5 }}
              />
            )}
          {!schedules.idatendimento_paciente &&
            !schedules.idconta_receber_cartao_movto &&
            !schedules.ds_produto &&
            schedules.ie_retorno !== 'S' && (
              <Button
                title="Pagar Agora"
                onClick={() => {
                  setAttendanceData(
                    { ...schedules, fromDashboard: true },
                    true
                  ).then(() => {
                    history.push({
                      pathname: '/pay-options',
                    });
                  });
                }}
                titleColor="#3f56a5"
                type="button"
                style={{ marginBottom: 5 }}
              />
            )}
          {!schedules?.idatendimento_paciente && (
            <>
              <Button
                title="Reagendar"
                onClick={() => {
                  setAttendanceData({ ...schedules }, true).then(() => {
                    history.push({
                      pathname: `/select-specialty/eletivo`,
                    });
                  });
                }}
                titleColor="#3f56a5"
                type="button"
                style={{ marginBottom: 5 }}
              />
              <Button
                title="Cancelar"
                onClick={() => {
                  setAttendanceData({ ...schedules }, true).then(() => {
                    history.push({
                      pathname: `/cancel-schedule/eletivo`,
                    });
                  });
                }}
                titleColor="#3f56a5"
                type="button"
              />
            </>
          )}
        </CardGeralBtn>
      </CardGeralPl>

      <DrModal
        controllerModal={[iniciarConsult, setIniciarConsult]}
        onHide={() => handleCloseiniciarConsultModal()}
        title="Horario da sua consulta chegou!"
        buttons={{
          startConsultation: {
            title: 'Iniciar Consulta',
            onClick: handleRoute,
          },
          cancel: {
            title: 'Fechar',
            onClick: handleCloseiniciarConsultModal,
          },
        }}
        content={
          <>
            <ConsultAlert
              ConsultSchedules={consultSchedules}
              schedulingDateFormatted={schedulingDateFormatted}
              schedulingDayFormatted={schedulingDayFormatted}
              schedulingTimeFormatted={schedulingTimeFormatted}
            />
          </>
        }
      />

      <DrModal
        controllerModal={[consultModal, setConsultModal]}
        onHide={() => handleCloseConsultModal()}
        title={modalProps.title}
        buttons={{
          confirm:
            modalProps.type === 'late'
              ? {
                  title: 'Reagendar',
                  onClick: () => {
                    setAttendanceData({ ...schedules }, true).then(() => {
                      history.push({
                        pathname: `/select-specialty/eletivo`,
                      });
                    });
                  },
                }
              : undefined,
          cancel: {
            title: 'Fechar',
            onClick: handleCloseConsultModal,
          },
        }}
        content={<h4>{modalProps.msg}</h4>}
      />
    </Col>
  );
};

export default DrCardSchedule;

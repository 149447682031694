import styled from 'styled-components';

export const BoxPicture = styled.div`
  position: absolute;
  display: flex;
  width: 170px;
  height: 150px;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const BoxPictureUpload = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  &::before {
    content: attr(data-title);
    font-size: 16px;
    font-weight: 700;
    color: #3f56a5;
    white-space: nowrap;
    margin-left: 5px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  i {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 30px;
    background-color: #3f56a5;
    color: #fff;
  }
`;

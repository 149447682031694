import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RowAnswer = styled(Row)`

.responder{
  @media screen and (max-width: 850px) {
    display: none;
}

`;

export const BoxPictureForumTitle = styled.h1`
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-left: 5px;
  font-weight: bold;

  @media screen and (max-width: 850px) {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: 500px) {
    max-width: 150px;
  }
`;

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { HeaderContainer } from './styled';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as StartAttendanceIcon } from '../../assets/svg/add.svg';
import { ReactComponent as AttendanceIcon } from '../../assets/svg/searching.svg';
import { ReactComponent as Avatar } from '../../assets/svg/avatar.svg';
import { ReactComponent as MessageIcon } from '../../assets/svg/bubble-chat.svg';
import DrIconFont from '../dr-icon-font';
import logo from '../../assets/marca/logo.png';
import {
  getJustFirstName,
  handleUseApiLog,
  handleWhatsAppMessage,
  valueParam,
} from '../../utils/bibli';
import DrModal from '../dr-modal';
import DrModalWhatsapp from '../dr-modal-whatsapp';
import DrImageProfile from '../dr-image-profile';

const Header: React.FC = () => {
  const { user, applicationData, setAttendanceData, requestAPI } = useAuth();
  const history = useHistory();
  const [modal, setModal] = React.useState(false);
  const [textWithPlatForm, setTextWithPlatForm] = useState('');
  const [avatar, setAvatar] = useState('');
  const [modalWhatsapp, setModalWhatsapp] = useState(false);

  useEffect(() => {
    const linkWhatsapp = applicationData.valores_variaveis?.link_whatsapp;
    if (linkWhatsapp && linkWhatsapp !== '-1') {
      const message = handleWhatsAppMessage(
        applicationData.valores_variaveis?.link_whatsapp,
        user
          ? undefined
          : 'Oi, estou na {plataforma} e gostaria de mais informações.',
        !user,
        user
      );

      setTextWithPlatForm(message);
    }

    if (valueParam(applicationData, 126) !== undefined)
      setAvatar(valueParam(applicationData, 126));
  }, [applicationData, user]);

  return (
    <HeaderContainer id="Header">
      <header className="site-header">
        <Container>
          <DrModal
            controllerModal={[modal, setModal]}
            title="Sair do sistema"
            buttons={{
              confirm: {
                title: 'Confirmar',
                onClick: () => {
                  history.push('/sign-out');
                },
                loading: false,
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => setModal(false),
              },
            }}
            content={<h4>Deseja realmente sair do sistema?</h4>}
          />
          <Row>
            <Col lg={6} xl={2} className="head-logo">
              <Link to="/dashboard">
                <img
                  onError={(el) => {
                    el.currentTarget.src = logo;
                  }}
                  src={
                    applicationData ? applicationData.ds_icon_app : undefined
                  }
                  alt="logo"
                  width={70}
                  height={70}
                />
              </Link>
            </Col>
            <Col lg={12} xl={7} className="head-nav">
              <nav className="main-nav">
                <ul className="main-menu">
                  <li className="main-home">
                    <Link to="/dashboard">
                      <div className="icon-box">
                        <HomeIcon title="Início" fill="#fcaf17" />
                        <h2 className="text-home">Início</h2>
                      </div>
                    </Link>
                  </li>

                  <li className="main-add active">
                    <div
                      style={{ cursor: 'pointer' }}
                      role="presentation"
                      onClick={async () => {
                        await setAttendanceData({}, true);
                        history.push('/types-care');
                      }}
                    >
                      <div className="icon-box">
                        <StartAttendanceIcon
                          title="Nova Consulta"
                          fill="#ed145b"
                        />
                        <h2 className="text-add">Nova Consulta</h2>
                      </div>
                    </div>
                  </li>

                  {user && (
                    <li className="main-searching active">
                      <Link to="/historic">
                        <div className="icon-box-searching">
                          <AttendanceIcon
                            title="Histórico de Atendimentos"
                            className="icon"
                            fill="#3f56a5"
                          />
                          <h2 className="text-searching">Histórico</h2>
                        </div>
                      </Link>
                    </li>
                  )}

                  {valueParam(applicationData, 207) === 'S' ? (
                    <li className="main-forum active">
                      <Link to="/forum">
                        <div style={{ cursor: 'pointer' }} role="presentation">
                          <DrIconFont
                            name="group2"
                            color="#3f56a5"
                            fontSize="10px"
                            cursor="pointer"
                          />
                          <h2 className="text-forum">Dúvidas</h2>
                        </div>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}

                  {applicationData &&
                    applicationData.valores_variaveis &&
                    applicationData.valores_variaveis?.link_whatsapp &&
                    applicationData.valores_variaveis?.link_whatsapp !== '-1' &&
                    (!user ? (
                      <li className="main-help active">
                        <div
                          onClick={() => {
                            handleUseApiLog({
                              requestAPI,
                              url: window.location.href,
                              action: 'WhatsApp',
                              message: textWithPlatForm,
                            });
                            setModalWhatsapp(true);
                          }}
                          style={{ cursor: 'pointer' }}
                          role="presentation"
                        >
                          <div className="icon-box-help">
                            <MessageIcon
                              title="Ajuda"
                              className="icon"
                              fill="#60bb46"
                            />

                            <h2 className="text-help">Fale Conosco</h2>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <></>
                    ))}

                  <DrModalWhatsapp
                    showModal={modalWhatsapp}
                    closeModal={() => setModalWhatsapp(false)}
                  />

                  <li className="main-perfil">
                    {user ? (
                      <Link to="/profile">
                        <div className="icon-box">
                          <div className="box-acc_pic">
                            <DrImageProfile
                              source={user?.url_img || ''}
                              width={32}
                              height={32}
                              alt="Imagem do avatar"
                            />
                          </div>
                          <h2 className="text-perfil">Meu Perfil</h2>
                        </div>
                      </Link>
                    ) : (
                      <Link to="/sign-in">
                        <div className="icon-box">
                          <Avatar className="icon" fill="#606062" />
                          <h2 className="text-perfil">Entrar</h2>
                        </div>
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </Col>
            <Col lg={6} xl={3}>
              <div className="head-acc">
                <div className="box-perfil">
                  <h6 className="box-acc_name">
                    {user ? (
                      <>
                        Olá,&nbsp;
                        {getJustFirstName(user)}
                        <Link to="/profile">
                          <small className="small">Meu Perfil</small>
                        </Link>
                      </>
                    ) : (
                      <Link to="/sign-in">
                        <small className="small">Entrar</small>
                      </Link>
                    )}

                    {user && user?.ds_produto && (
                      <Link to="/plans">
                        <small className="smallPlan">
                          {`Plano: ${user.ds_produto}`}
                        </small>
                      </Link>
                    )}
                    {user && (
                      <div onClick={() => setModal(true)} className="exit">
                        Sair
                      </div>
                    )}
                  </h6>

                  {user ? (
                    <Link to="/profile">
                      <div className="box-acc_pic">
                        <DrImageProfile
                          source={user?.url_img || ''}
                          width={70}
                          height={70}
                          alt="Imagem do avatar"
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link to="/sign-in">
                      <div className="box-acc_pic">
                        <DrImageProfile
                          source=""
                          width={70}
                          height={70}
                          alt="Imagem do avatar"
                        />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </HeaderContainer>
  );
};

export default Header;

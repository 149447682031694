import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    color: #4156a6;
    font-weight: bold;
  }

  form {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin: 0 auto;
  }

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 15px;
    width: 100%;
    padding: 0 15px;
    margin: 0 0 10px;
  }

  @media screen and (max-width: 850px) {
    max-width: 100%;

    h1 {
      font-size: 2rem;
    }
  }
`;

export const Content = styled.button`
  border: 0;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 100%;

  h2 {
    font-size: 2rem;
  }

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    background: linear-gradient(to bottom right, #e6f7fb 0%, #fff 100%);
    height: 100px;
    width: 100px;
  }

  @media screen and (max-width: 1366px) {
    width: 90%;

    h2 {
      font-size: 1.2rem;
    }

    img {
      margin: 50% auto;
    }
  }
`;

export const Protocol = styled.div`
  border-radius: 15px;
  border-left: 3px solid #00bbd661;
  border-right: 3px solid #4156a682;
  border-top: 1px solid #00bbd661;
  border-bottom: 1px solid #4156a682;
  width: 100%;
  overflow-y: auto;
  max-height: 50%;
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #cccccc00;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background: #d1d2d452;
    border-radius: 10px;
    position: relative;
    left: 10px;
  }
  .dados-protocol {
    display: flex;

    .title-protocol {
      font-weight: bold;
    }
  }

  h2 {
    position: relative;
    font-size: 2rem;
    color: #4156a6;
    margin: 5px;
  }
  h2.descript-protocol {
    word-break: break-word;
    text-align: justify;
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 1.4rem;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin: 12px 0 0;
  height: 44px;
  width: 200px;
  font-weight: bold;
  color: #9f1e49;
  border: 2px solid #9f1e49;
  border-radius: 20px;
  font-size: 16px;
  transition: background 0.2s;
  float: left;

  &:hover {
    color: #ffffff;
    background: ${darken(0.08, '#9f1e49')};
  }

  @media screen and (max-width: 1400px) {
    width: 100px;
    height: 34px;
  }
`;

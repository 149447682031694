import styled, { css } from 'styled-components';

interface CardBlankProps {
  definedSize?: string;
}

export const CardBlank = styled.section<CardBlankProps>`
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 20px 1px rgb(0 0 0 / 5%);
  margin-top: 30px;
  ${(props) =>
    props.definedSize &&
    css`
      position: relative;
      min-height: ${props.definedSize};
    `}// @media screen and (max-width: 1023px) {
  //   margin-top: 1.5rem !important;
  // }
`;

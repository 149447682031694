import React from 'react';
import { DivAlert, DivAlertProps } from './styled';

interface DivAlertExclProps extends DivAlertProps {
  title?:
    | {
        text: string;
        tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
        fontSize?: string;
      }
    | false;
  className?: string;
  style?: any;
}

export function getTitleEl(
  title: DivAlertExclProps['title'],
  children?: any
): React.ReactNode {
  if (!title) return false;
  const style = {
    fontSize: title.fontSize || '18px',
    fontWeight: 700,
    marginTop: 0,
  };
  if (!children) style.marginTop = 10;
  switch (title.tag) {
    case 'h1':
      return <h1 style={style}>{title.text}</h1>;
    case 'h2':
      return <h2 style={style}>{title.text}</h2>;
    case 'h3':
      return <h3 style={style}>{title.text}</h3>;
    case 'p':
      return <p style={{ ...style, fontWeight: 500 }}>{title.text}</p>;
    default:
      return <h4 style={style}>{title.text}</h4>;
  }
}

const DrAlert: React.FC<React.PropsWithChildren<DivAlertExclProps>> = ({
  children,
  className,
  title,
  style,
  ...rest
}: React.PropsWithChildren<DivAlertExclProps>) => {
  const titleEl = getTitleEl(title, children);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DivAlert role="alert" className={className} {...rest} style={style}>
      {titleEl}
      {children}
    </DivAlert>
  );
};

DrAlert.defaultProps = {
  title: false,
  className: undefined,
};

export default DrAlert;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import Loading from '../../components/dr-card-loading';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

const SignOut: React.FC = () => {
  const history = useHistory();
  const { user, signOut, requestAPI, sendError } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function handleSignOut() {
      try {
        await requestAPI({
          method: 'PUT',
          url: `/tmUsuarioLogout?id=${user.jwt.idapi_token}`,
        });

        signOut();
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Atenção',
          description: err.message,
        });
      }
    }
    if (user && user.jwt) {
      handleSignOut();
    } else {
      history.push('/dashboard');
    }
  }, [addToast, history, requestAPI, signOut, user]);

  return (
    <>
      <Header />
      <Loading />
      <Footer />
    </>
  );
};

export default SignOut;

import React, { useEffect, useState, useRef } from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useAuth, EvaluationFormData } from '../../hooks/auth';
import { scrollPositionTop, verifyErrorYup } from '../../utils/bibli';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { Fields } from '../../styles/fields';
import DrInputTextArea from '../dr-input-text-area';
import { useToast } from '../../hooks/toast';

import { Ratings } from './styled';
import DrButtonLoading from '../dr-button-loading';

interface EvaluationComponentProps {
  idatendimento_paciente: number | undefined;
  evaluationDatas?: EvaluationFormData;
}

const EvaluationComponent: React.FC<EvaluationComponentProps> = ({
  idatendimento_paciente,
  evaluationDatas,
}) => {
  const { requestAPI, sendError } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [isLoading, setLoading] = useState(false);
  const [star, setStar] = useState(0);
  const [evaluation, setEvaluation] = useState<any>();

  useEffect(() => {
    scrollPositionTop();
    setEvaluation(evaluationDatas);
  }, [evaluationDatas]);

  async function handleSubmit(data: EvaluationFormData) {
    if (star === 0) {
      addToast({
        type: 'error',
        title: 'Por favor, selecione o número de estrelas',
        description:
          'Para uma melhor avaliação, é necessário o uso das estrelas. ',
      });
      return;
    }
    data.nr_estrela = star;

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        ds_opiniao: Yup.string().nullable().trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      const resp = await requestAPI({
        method: 'POST',
        url: `/atendimentoPacienteAvaliacao/${idatendimento_paciente}`,
        body: data,
      });
      setEvaluation(resp);
      addToast({
        type: 'success',
        title: 'Avaliação Concluída!',
        description: 'Sua avaliação foi enviada. Obrigado por avaliar!',
      });
    } catch (err: any) {
      sendError(err);
      if (!(err instanceof Yup.ValidationError)) {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      } else {
        verifyErrorYup(err, formRef);
      }
    } finally {
      setLoading(false);
    }
  }

  function handleStars() {
    return (
      <Rating
        className="stars"
        name="nr_estrela"
        disabled={!!evaluation?.nr_estrela}
        value={evaluation?.nr_estrela ? evaluation?.nr_estrela : star}
        onChange={(event, newValue) => {
          setStar(Number(newValue));
        }}
      />
    );
  }

  return (
    <CardBlank className="container pt-3 pb-3 mt-4">
      <PageTitle className="page-title text-center" color="#3f56a5">
        Avaliação do Atendimento
      </PageTitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Fields>
          <Row className="justify-content-center">
            <Col xl={8}>
              <Box
                component="fieldset"
                mb={3}
                borderColor="transparent"
                className="mt-2 mb-2"
              >
                <Ratings>
                  <Typography component="legend" />
                  {handleStars()}
                </Ratings>
              </Box>
            </Col>
            <Col xl={8}>
              {(!evaluation || (evaluation && evaluation.ds_opiniao)) && (
                <DrInputTextArea
                  label="Observações"
                  name="ds_opiniao"
                  placeholder="Diga o que achou"
                  value={evaluation?.ds_opiniao}
                  enabled={!(evaluation && evaluation.ds_opiniao)}
                />
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl={8}>
              {!evaluation && (
                <div>
                  <DrButtonLoading
                    type="submit"
                    color="blue"
                    title="Enviar Avaliação"
                    loading={isLoading}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Fields>
      </Form>
    </CardBlank>
  );
};
export default EvaluationComponent;

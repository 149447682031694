import styled from 'styled-components';

import Tooltip from '../dr-tooltip';

interface SelectProps {
  isError: boolean;
  hasIcon: boolean;
  loading: boolean;
  isDisabled: boolean;
}

export const FieldSelect = styled.div`
  position: relative;
  border: 2px solid #e5e5e5;
  border-radius: 3px;
  padding: 0 5px 0 10px;
  margin: 15px 0;
  min-height: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #000;
`;

export const SelectLabel = styled.label`
  position: absolute;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #777;
  top: -25px;
  left: 0;
  z-index: 2;
`;
export const ImgDrSpinner = styled.img`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  position: relative;
  animation: spinner 1500ms infinite linear;
`;
export const DataList = styled.input`
  border-radius: 3px;
  border: 2px solid #e5e5e5;
  width: 100%;
  font-size: 18px;
  color: #777 !important;
  opacity: 1;
  min-height: 54px;
  vertical-align: middle;
  padding: 0;
  :disabled {
    color: #777 !important;
  }
`;

export const Container = styled.div<SelectProps>`
  margin-bottom: 20px;
  h3 {
    color: #03bbd7;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.30769em;
    margin: 15px 0 0;
    text-align: center;
  }
  .field-select {
    position: relative;
    border: 2px solid #e5e5e5;
    border-radius: 3px;
    padding: ${(props) => props.hasIcon && '0 5px 0 10px'};
    margin: 20px 0;
    min-height: 58px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border: ${(props) => props.isError && 'solid 2px #c00'};
    background-color: ${(props) =>
      (props.loading || props.isDisabled) && '#e9ecef'};

    .form-control {
      -webkit-box-shadow: ${(props) => !props.isError && 'none !important'};
      -webkit-appearance: ${(props) => props.loading && 'none'};
      -moz-appearance: ${(props) => props.loading && 'none'};
      border: 0;
      border-radius: 0;
      padding: 5px 5px 5px 10px;
      font-weight: 500;
      margin-left: ${(props) => props.hasIcon && '10px'};
    }

    select {
      border-radius: 0;
      width: 100%;
      font-size: 18px;
      color: #777 !important;
      opacity: 1;
      height: 54px;
      min-height: 54px;
      resize: none;
    }
    @media screen and (max-width: 1199px) {
      margin: 20px 0 0 0;
    }
  }
`;

export const Label = styled.div`
h4 {
  width: 100%;
  position: absolute;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #777;
  top: -25px;
  left: 0;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 700;
  color: #777;
  top: -45px;
  left -15px;
  z-index: 2;
}
`;
interface iconColor {
  color: string;
  size: string;
}
export const FieldIcon = styled.i<iconColor>`
  font-size: ${(props) => props.size && `${props.size}px`};
  color: ${(props) => props.color && `${props.color}`};
`;

export const Error = styled(Tooltip)`
  z-index: 9999;
`;

import React from 'react';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import PatientsCard from './patients-card';

const SelectToAttendance: React.FC = () => (
  <>
    <Header />
    <PatientsCard />
    <Footer />
  </>
);

export default SelectToAttendance;

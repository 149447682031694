import React from 'react';
import DrHeader from '../../components/dr-header';
import DrFooter from '../../components/dr-footer';
import ScreenAttendanceEnd from './screen-attendance-end';

const AttendanceEnd: React.FC = () => (
  <>
    <DrHeader />
    <ScreenAttendanceEnd />
    <DrFooter />
  </>
);

export default AttendanceEnd;

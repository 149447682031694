import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import NotFound from '../pages/NotFound';
import ResendEmail from '../pages/ResendEmail';
import UserAutenticate from '../pages/UserAutenticate';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import RecoverPassword from '../pages/RecoverPassword';
import Historic from '../pages/Historic';
import Forum from '../pages/Forum';
import LatestTopics from '../pages/LatestTopics';
import DetailsTopics from '../pages/DetailsTopics';
import SpecialtiesTopics from '../pages/SpecialtiesTopics';
import MoreInteractionsTopics from '../pages/MoreInteractionsTopics';
import Profile from '../pages/Profile';
import UpdateUserRegistrationDatas from '../pages/UpdateUserRegistrationDatas';
import SetPassword from '../pages/SetPassword';
import ConfirmationCode from '../pages/ConfirmationCode';
import FamilyGroup from '../pages/FamilyGroup';
import UpdateFamilyMember from '../pages/UpdateFamilyMember';
import CreateFamilyMember from '../pages/CreateFamilyMember';
import Protocols from '../pages/Protocols';
import NewProtocol from '../pages/NewProtocol';
import ProtocolDatas from '../pages/ProtocolDatas';
import PaymentsMethods from '../pages/PaymentsMethods';
import Plans from '../pages/Plans';
import AddCard from '../pages/AddCard';
import SelectToAttendance from '../pages/SelectToAttendance';
import SelectDependents from '../pages/SelectDependents';
import PreEvaluation from '../pages/PreEvaluation';
import PayOptions from '../pages/PayOptions';
import AttendanceNow from '../pages/AttendanceNow';
import Payment from '../pages/Payment';
import PaymentMade from '../pages/PaymentMade';
import AttendanceData from '../pages/AttendanceData';
import AttendanceAppointment from '../pages/AttendanceAppointment';
import AttendanceOptions from '../pages/AttendanceOptions';
import AttendanceEnd from '../pages/AttendanceEnd';
import AttendanceError from '../pages/AttendanceError';
import NewsPage from '../pages/NewsPage';
import StreamPage from '../pages/StreamPage';
import RegistrationSuccess from '../pages/RegistrationSuccess';
import ReturnAttendance from '../pages/ReturnAttendance';
import Dashboard from '../pages/Dashboard';
import TypesCare from '../pages/TypesCare';
import SignOut from '../pages/SignOut';
import SelectSpecialty from '../pages/SelectSpecialty';
import ScheduleAttendance from '../pages/ScheduleAttendance';
import ConfirmSchedule from '../pages/ConfirmSchedule';
import CancelSchedule from '../pages/CancelSchedule';
import UploadPage from '../pages/UploadPage';
import PaymentPix from '../pages/PaymentPix';

const Routes: React.FC = () => (
  <Switch>
    {/* ROTAS PUBLICAS */}

    <Route path="/" exact component={Dashboard} />

    <Route path="/sign-in" component={SignIn} />

    <Route path="/dashboard" component={Dashboard} />

    <Route path="/resend-email" component={ResendEmail} />

    <Route path="/user-autenticate" component={UserAutenticate} />

    <Route path="/sign-up" component={SignUp} />

    <Route path="/registration-success" component={RegistrationSuccess} />

    <Route path="/forgot-password" component={ForgotPassword} />

    <Route path="/confirmation-code/:cpf/:email" component={ConfirmationCode} />

    <Route path="/confirmation-code/:cpf/:email" component={ConfirmationCode} />

    <Route path="/select-to-attendance/:id?" component={SelectToAttendance} />

    <Route path="/select-dependents" component={SelectDependents} />

    <Route path="/pay-options/:id?" component={PayOptions} />

    <Route path="/select-specialty/:id?" component={SelectSpecialty} />

    <Route path="/schedule-attendance/:id?" component={ScheduleAttendance} />

    <Route path="/news-page/:id" component={NewsPage} />

    <Route path="/latest-topics" component={LatestTopics} />

    <Route path="/details-topics/:id" component={DetailsTopics} />

    {/* FIM DAS ROTAS PUBLICAS */}

    {/* ROTAS PRIVADAS */}
    <Route path="/historic" component={Historic} isPrivate />

    <Route path="/forum" component={Forum} isPrivate />

    <Route path="/specialties-topics" component={SpecialtiesTopics} isPrivate />

    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/set-password/:id" component={SetPassword} isPrivate />

    <Route path="/family-group" component={FamilyGroup} isPrivate />

    <Route path="/protocols" component={Protocols} isPrivate />

    <Route path="/protocol-datas/:id" component={ProtocolDatas} isPrivate />

    <Route path="/payments-methods" component={PaymentsMethods} isPrivate />

    <Route path="/plans" component={Plans} isPrivate />

    <Route path="/add-card" component={AddCard} isPrivate />

    <Route path="/profile" component={Profile} isPrivate />

    <Route path="/set-password/:id" component={SetPassword} isPrivate />

    <Route path="/family-group" component={FamilyGroup} isPrivate />

    <Route path="/protocols" component={Protocols} isPrivate />

    <Route path="/protocol-datas/:id" component={ProtocolDatas} isPrivate />

    <Route path="/payments-methods" component={PaymentsMethods} isPrivate />

    <Route path="/plans" component={Plans} isPrivate />

    <Route path="/add-card" component={AddCard} isPrivate />

    <Route path="/cancel-schedule/:id?" component={CancelSchedule} isPrivate />

    <Route path="/pre-evaluation/:id?" component={PreEvaluation} isPrivate />

    <Route path="/return-attendance" component={ReturnAttendance} isPrivate />

    <Route path="/payment" component={Payment} isPrivate />

    <Route path="/payment-made" component={PaymentMade} isPrivate />

    <Route path="/payment-pix" component={PaymentPix} isPrivate />

    <Route path="/attendance-now/:id?" component={AttendanceNow} isPrivate />

    <Route path="/attendance-data/:id" component={AttendanceData} isPrivate />

    <Route path="/attendance-end" component={AttendanceEnd} isPrivate />

    <Route path="/attendance-error" component={AttendanceError} isPrivate />

    <Route path="/new-protocol/:id?" component={NewProtocol} isPrivate />

    <Route path="/stream-page" component={StreamPage} isPrivate />

    <Route path="/types-care" component={TypesCare} />

    <Route path="/sign-out" component={SignOut} isPrivate />

    <Route path="/upload-page" component={UploadPage} isPrivate />

    <Route
      path="/update-user-registration-datas/:id"
      component={UpdateUserRegistrationDatas}
      isPrivate
    />

    <Route
      path="/update-family-member/:id"
      component={UpdateFamilyMember}
      isPrivate
    />

    <Route
      path="/create-family-member/"
      component={CreateFamilyMember}
      isPrivate
    />

    <Route
      path="/update-user-registration-datas/:id"
      component={UpdateUserRegistrationDatas}
      isPrivate
    />

    <Route
      path="/update-family-member/:id"
      component={UpdateFamilyMember}
      isPrivate
    />

    <Route
      path="/create-family-member/"
      component={CreateFamilyMember}
      isPrivate
    />

    <Route
      path="/confirm-schedule/:id?"
      component={ConfirmSchedule}
      isPrivate
    />

    <Route
      path="/attendance-data-return/:id"
      component={AttendanceData}
      isPrivate
    />

    <Route
      path="/attendance-data-attendance/:id"
      component={AttendanceData}
      isPrivate
    />
    <Route
      path="/attendance-appointment"
      component={AttendanceAppointment}
      isPrivate
    />

    <Route
      path="/attendance-options/:id?"
      component={AttendanceOptions}
      isPrivate
    />

    <Route
      path="/recover-password/:cpf"
      component={RecoverPassword}
      isPrivate
    />

    <Route
      path="/more-interactions-topics"
      component={MoreInteractionsTopics}
      isPrivate
    />

    {/* FIM DAS ROTAS PRIVADAS */}

    {/* ROTA/COMPONENTE NÃO ENCONTRADO */}

    <Route component={NotFound} />

    {/* ROTA/COMPONENTE NÃO ENCONTRADO */}
  </Switch>
);

export default Routes;

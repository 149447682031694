import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { isFutureDate } from '../../utils/validations';
import { verifyErrorYup } from '../../utils/bibli';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { Fields } from '../../styles/fields';
import DrSelect from '../../components/dr-input-select';
import Input from '../../components/dr-input';
import Button from '../../components/dr-button';
import DrInputTextArea from '../../components/dr-input-text-area';
import Loading from '../../components/dr-card-loading';
import DrButtonLoading from '../../components/dr-button-loading';
import { dateToStr, formatStrToDate } from '../../utils/formatDates';

interface NewProtocolFormData {
  ds_protocolo: string;
  dt_inicio: string;
  dt_fim: string;
  dt_protocolo: string;
  ie_protocolo_tipo: string;
}

interface IdProtocol {
  id: string;
}

const NewProtocol: React.FC = () => {
  const { params } = useRouteMatch<IdProtocol>();
  const { requestAPI, getDomainsData, sendError } = useAuth();
  const [protocolsType, setProtocolsType] = useState<any[]>([]);

  const [isLoading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [dsProtocolo, setDsProtocolo] = useState<string>();
  const [dataInicio, setDataInicio] = useState<string>();
  const [dataFim, setDataFim] = useState<string>();
  const [situation, setSituation] = useState<string>();

  useEffect(() => {
    async function getProtocols() {
      setLoading(true);
      const resp = getDomainsData('410');
      const arrayFiltered = resp.map((array) => ({
        label: array.ds_dominio_valor,
        value: array.vl_dominio,
      }));
      arrayFiltered.unshift({ label: 'Selecione', value: '' });
      setProtocolsType(arrayFiltered);

      if (params.id) {
        const respProtocol = await requestAPI({
          method: 'GET',
          url: `/atendimentoProtocoloSolDoc/${params.id}`,
        });

        const {
          ds_protocolo,
          data_inicio,
          data_fim,
          ds_protocolo_tipo,
          ds_estagio,
        } = respProtocol;
        setDsProtocolo(ds_protocolo);
        setDataInicio(data_inicio);
        setDataFim(data_fim);
        setSituation(ds_estagio);
        setProtocolsType(ds_protocolo_tipo);
        setDisableButton(true);
      }
      setLoading(false);
    }

    getProtocols();
  }, []);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  async function handleSubmit(data: NewProtocolFormData) {
    data.dt_inicio = dateToStr(
      formatStrToDate(data.dt_inicio, 'DD/MM/YYYY'),
      'YYYY-MM-DD'
    );
    data.dt_fim = dateToStr(
      formatStrToDate(data.dt_fim, 'DD/MM/YYYY'),
      'YYYY-MM-DD'
    );

    try {
      const schema = Yup.object().shape({
        dt_inicio: Yup.string()
          .required('Data de início é obrigatória')
          .test({
            message: 'Data inválida',
            test: (value) => !isFutureDate(value),
          })
          .test({
            message: 'Data de início maior que a de fim!',
            test: (value) => {
              if (
                (!isFutureDate(value) && data.dt_inicio > data.dt_fim) ||
                data.dt_inicio > data.dt_fim
              ) {
                return false;
              }
              return true;
            },
          })
          .trim(),
        dt_fim: Yup.string()
          .required('Data do fim é obrigatória')
          .test({
            message: 'Data inválida',
            test: (value) => !isFutureDate(value),
          })
          .trim(),
        ie_protocolo_tipo: Yup.string()
          .required('Tipo de protocolo é obrigatório')
          .trim(),
        ds_protocolo: Yup.string().required('Campo obrigatório').trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (data.dt_inicio > data.dt_fim) {
        addToast({
          type: 'error',
          title: 'Erro de datas',
          description: 'Data de início maior que a de fim!',
        });
        return;
      }

      setButtonLoading(true);

      await requestAPI({
        method: 'POST',
        url: '/atendimentoProtocoloSolDoc',
        body: data,
      });

      addToast({
        type: 'success',
        title: 'Protocolo criado!',
        description: 'O seu protocolo foi solicitado!',
      });
      setButtonLoading(false);
      history.push('/protocols');
    } catch (err: any) {
      sendError(err);
      setButtonLoading(false);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  }

  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Container className="pt-3">
            <CardBlank className="pt-2 pb-2">
              {params.id ? (
                <PageTitle>{`Solicitação de ${protocolsType}`}</PageTitle>
              ) : (
                <PageTitle>Nova Solicitação</PageTitle>
              )}
              <Fields>
                <Row className="pt-1 m-0">
                  <Col md={12} xl={3}>
                    {typeof protocolsType === 'string' ? (
                      <Input
                        value={protocolsType}
                        name="ie_protocolo_tipo"
                        label="Tipo de solicitação"
                        disabled
                      />
                    ) : (
                      <DrSelect
                        name="ie_protocolo_tipo"
                        options={protocolsType}
                        selectTitle="Tipo de solicitação"
                        value={protocolsType}
                      />
                    )}
                  </Col>
                  <Col md={12} xl={3}>
                    <Input
                      icon
                      name="dt_inicio"
                      label="Data Início"
                      placeholder="00/00/0000"
                      customType="dateText"
                      validate
                      value={dataInicio}
                      getValue={(val) => setDataInicio(val)}
                      disabled={disableButton}
                    />
                  </Col>
                  <Col md={12} xl={3}>
                    <Input
                      icon
                      name="dt_fim"
                      label="Data Fim"
                      placeholder="00/00/0000"
                      customType="dateText"
                      validate
                      value={dataFim}
                      disabled={disableButton}
                      getValue={(val) => setDataFim(val)}
                    />
                  </Col>
                  {params.id && (
                    <Col md={12} xl={3}>
                      <Input
                        name="ds_estagio"
                        label="Situação"
                        disabled
                        value={situation}
                      />
                    </Col>
                  )}

                  <Col xl={12}>
                    <DrInputTextArea
                      name="ds_protocolo"
                      label="Observações"
                      placeholder="Digite aqui..."
                      value={dsProtocolo}
                      enabled={!disableButton}
                      maxLength={5000}
                    />
                  </Col>

                  {params.id && (
                    <Col xl={12}>
                      <DrInputTextArea
                        name="ds_resposta"
                        label="Resposta"
                        enabled={false}
                      />
                    </Col>
                  )}
                </Row>
              </Fields>
            </CardBlank>
          </Container>
          <Container className="mt-3">
            <Row className="justify-content-center row-btn">
              {!disableButton && (
                <Col xl={4}>
                  <DrButtonLoading
                    loading={buttonLoading}
                    type="submit"
                    color="blue"
                    title="Solicitar"
                  />
                </Col>
              )}
              <Col xl={4}>
                <Button
                  type="button"
                  color="dark-grey"
                  href="/protocols"
                  title={!disableButton ? 'Cancelar' : 'Voltar'}
                />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
      <Footer />
    </>
  );
};

export default NewProtocol;

import React from 'react';
import DrHeader from '../../components/dr-header';
import DrFooter from '../../components/dr-footer';
import ScreenAttendaceError from './screen-attendance-error';

const AttendanceError: React.FC = () => (
  <>
    <DrHeader />
    <ScreenAttendaceError />
    <DrFooter />
  </>
);

export default AttendanceError;

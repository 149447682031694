import React from 'react';
import SmartBanner from 'react-smartbanner';

// import '../../../node_modules/react-smartbanner/dist/main.css';

import './main.css';

const DrSmartBanner: React.FC = () => (
  <SmartBanner
    title="Dr+ on-line"
    daysHidden={0}
    daysReminder={0}
    button="Ver"
    url={{
      ios: 'https://testflight.apple.com/join/dnrWi7dq',
      android:
        'https://play.google.com/store/apps/details?id=com.apptelemedicina',
      windows:
        'https://play.google.com/store/apps/details?id=com.apptelemedicina',
      kindle:
        'https://play.google.com/store/apps/details?id=com.apptelemedicina',
    }}
    storeText={{
      ios: 'na App Store',
      android: 'na Google Play',
      windows: 'na Google Play',
      kindle: 'na Amazon Appstore',
    }}
    price={{
      ios: 'Baixar agora',
      android: 'Baixar agora',
      windows: 'Baixar agora',
      kindle: 'Baixar agora',
    }}
    position="top"
    hidden={false}
  />
);

export default DrSmartBanner;

import React from 'react';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';

import DrConfirmSchedule from './dr-confirm-schedule';

const ConfirmSchedule: React.FC = () => (
  <>
    <Header />
    <DrConfirmSchedule />
    <Footer />
  </>
);
export default ConfirmSchedule;

import styled from 'styled-components';

interface BPLiProps {
  color?: string;
}
export const BPLi = styled.li<BPLiProps>`
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55556em;
  color: ${(props) => props.color};
`;

export const Ul = styled.ul`
  list-style: none;
  color: #777;
  margin: 0;
  padding: 0;
`;

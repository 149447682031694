import React from 'react';
import { Col } from 'react-bootstrap';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import InstallmentButton from '../dr-installment-button';
import { CardGeralPl } from '../../styles/CardGeralPl';
import { BPLi, Ul } from './styled';
import { CardGeralBody } from '../../styles/card-geral_body';
import { PageTitle } from '../../styles/page-title';
import { formatValue } from '../../utils/formatNumbers';

interface PropsPlan {
  plan: any;
  setModal?: React.Dispatch<any>;
  loading: boolean;
  itemId: any;
  title?: string;
  className?: string;
  dashboard?: boolean;
  borderColor?: string;
}

const Plan: React.FC<PropsPlan> = (props: PropsPlan) => {
  const {
    plan,
    setModal,
    loading,
    itemId,
    title,
    className,
    dashboard,
    borderColor,
  } = props;

  const visibility = React.useContext(VisibilityContext);

  let obj: any = {};
  if (plan && plan.opcao_parcela && plan.opcao_parcela.length) {
    obj = JSON.parse(JSON.stringify(plan.opcao_parcela[0]));
  }
  obj.descricao = 'Mais opções';
  return (
    <Col md={12} lg={6} xl={4} className={`${className} m-0`}>
      <CardGeralPl
        style={{
          borderColor: borderColor || '',
          flexWrap: 'wrap',
        }}
      >
        <CardGeralBody className="flex-grow-1 ">
          <Ul>
            {plan.ds_especialidade ? (
              <BPLi
                style={{
                  fontSize: 24,
                  textAlign: 'center',
                  color: '#3f56a5',
                  marginBottom: 5,
                }}
              >
                {plan.ds_especialidade}
              </BPLi>
            ) : (
              <></>
            )}
            {!plan.ds_especialidade && plan.ds_categoria_atend ? (
              <BPLi
                style={{
                  fontSize: 24,
                  textAlign: 'center',
                  color: '#3f56a5',
                  marginBottom: 5,
                }}
              >
                {plan.ds_categoria_atend}
              </BPLi>
            ) : (
              <></>
            )}
            <BPLi
              style={{
                color: '#ed145b',
                borderBottom: '2px solid #7777771a',
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              <PageTitle style={{ margin: 0 }}>{title || ''}</PageTitle>
              {(plan.ds_produto && plan.ds_produto.trim()) ||
                (plan.vl_atendimento &&
                  `Consulta única: ${formatValue(plan.vl_atendimento)}`)}
            </BPLi>
            {plan.ds_informacoes && (
              <BPLi
                style={{
                  alignItems: 'flex-start',
                  marginBottom: 5,
                }}
              >
                {plan.ds_informacoes}
              </BPLi>
            )}
            {plan.itens &&
              plan.itens.length &&
              plan.itens.map((item, index) => (
                <BPLi
                  key={`#id_${index.toString()}`}
                  style={{
                    alignItems: 'flex-start',
                    marginBottom: 5,
                  }}
                >
                  {item}
                </BPLi>
              ))}
          </Ul>
        </CardGeralBody>
        {plan &&
          plan.regra_pagto &&
          plan.regra_pagto.length &&
          plan.regra_pagto.map((optionInstallment, index) => {
            optionInstallment.descricao = `${
              optionInstallment.ds_forma
            } ${formatValue(optionInstallment.vl_pagto)}`;
            return (
              <InstallmentButton
                dashboard={dashboard}
                installmentsSignature={plan}
                optionInstallment={optionInstallment}
                key={`#id${index.toString()}`}
                idx={index}
              />
            );
          })}

        {plan &&
          plan.opcao_parcela &&
          plan.opcao_parcela.length &&
          plan.opcao_parcela
            .slice(0, 2)
            .map((optionInstallment, index) => (
              <InstallmentButton
                dashboard={dashboard}
                installmentsAttendance={plan}
                optionInstallment={optionInstallment}
                key={`#id_${index.toString()}`}
                idx={index}
              />
            ))}
        {plan && plan.opcao_parcela && plan.opcao_parcela.length > 0 && (
          <InstallmentButton
            dashboard={dashboard}
            installmentsAttendance={plan}
            optionInstallment={obj}
            key={plan.opcao_parcela[0].qtd}
            idx={0}
          />
        )}
      </CardGeralPl>
    </Col>
  );
};

Plan.defaultProps = {
  setModal: undefined,
};
export default Plan;

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../components/dr-button';
import { useAuth } from '../../hooks/auth';
import HelmetComponent from '../../components/dr-helmet-component';
import Header from '../../components/dr-header';
import Attendances from '../../components/dr-attendances';
import Footer from '../../components/dr-footer';
import { valueParam } from '../../utils/bibli';

interface LocationProps {
  fromDashboard: boolean;
}

const Historic: React.FC = () => {
  const { applicationData } = useAuth();

  const location = useLocation<LocationProps>();
  const state: any = location.state || {};

  const handleRoute = () => {
    let route = '/dashboard';
    if (state === 'profile') {
      route = '/profile';
    }
    return route;
  };

  return (
    <>
      <HelmetComponent title="Histórico de Atendimentos" />
      <Header />

      {valueParam(applicationData, 207) === 'S' ||
      state.fromDashboard === true ? (
        <Container className="mb-3 mt-3">
          <Row className="justify-content-center row-btn">
            <Col xl={4}>
              <Button
                type="button"
                color="dark-grey"
                href={handleRoute()}
                title="Voltar"
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <></>
      )}

      <Container>
        <Attendances title="Atendimentos" itensPerPage="15" filter />
      </Container>
      <Footer />
    </>
  );
};

export default Historic;

import React from 'react';
import { useAuth } from '../../hooks/auth';
import { scrollPositionTop } from '../../utils/bibli';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import CardAttOptions from './card-attendance-options';

const AttendanceOptions: React.FC = () => {
  const { socketAuth, setSocketAuth } = useAuth();

  React.useEffect(() => {
    scrollPositionTop();
  }, []);

  React.useEffect(() => {
    if (socketAuth.socket) {
      socketAuth.socket.emit('exit-room', {
        boolDisconnectSocket: true,
        closeRoom: true,
        novoAnfitriao: false,
      });

      socketAuth.socket.close();
      setSocketAuth({ socket: undefined });
    }
  }, []);

  return (
    <>
      <Header />
      <CardAttOptions />
      <Footer />
    </>
  );
};

export default AttendanceOptions;

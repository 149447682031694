import React from 'react';
import { useAuth } from '../../hooks/auth';
import { BoxMember, BoxMemberPic, BoxMemberName } from './styled';
import { personAge } from '../../utils/formatDates';
import DrImageProfile from '../dr-image-profile';

interface DrCardMemberProps extends React.HTMLAttributes<HTMLDivElement> {
  member: any;
  urlImg?: string | null;
}

const DrCardMember: React.FC<DrCardMemberProps> = ({
  urlImg,
  member,
  ...rest
}: DrCardMemberProps) => {
  let gender = member.ie_sexo;

  if (gender === 'M') {
    gender = 'Masculino';
  } else if (gender === 'F') {
    gender = 'Feminino';
  } else {
    gender = 'Não informado';
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BoxMember {...rest}>
      <BoxMemberPic>
        <DrImageProfile
          source={`${urlImg || ''}`}
          width={100}
          height={100}
          alt="foto de perfil"
          style={{
            borderRadius: '50%',
            borderStyle: 'none',
            padding: '3px',
            alignSelf: 'center',
            margin: '15px auto 15px 15px',
          }}
        />
      </BoxMemberPic>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          width: '70%',
        }}
      >
        <BoxMemberName>
          <strong>{member.nm_social || member.nm_pessoa}</strong>
        </BoxMemberName>
        <BoxMemberName>
          {personAge(member.dt_nascimento) > 1
            ? `${personAge(member.dt_nascimento)} Anos`
            : `${personAge(member.dt_nascimento)} Ano`}
        </BoxMemberName>
        <BoxMemberName>{gender}</BoxMemberName>
      </div>
    </BoxMember>
  );
};

DrCardMember.defaultProps = {
  urlImg: undefined,
};

export default DrCardMember;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DrButtonLoading, { DrButtonLoadingProps } from '../dr-button-loading';
import DrButton, { ButtonProps } from '../dr-button';

interface buttonProps extends Partial<Omit<DrButtonLoadingProps, 'title'>> {
  // eslint-disable-next-line react/require-default-props
  title?: ButtonProps['title'];
}

interface DrButtonsFooterProps {
  containerClassNm?: string;
  classNameButton?: string;
  buttonLeft?: buttonProps | false;
  buttonRight?: buttonProps | false;
  sizeCol?: number;
}

const Button: React.FC<buttonProps> = ({
  type = 'button',
  loading,
  title,
  ...rest
}: buttonProps) => {
  let buttonRender = <></>;
  if (loading !== undefined) {
    if (typeof title !== 'string') {
      throw new Error(
        'Erro em DrButtonsFooterProps. propriedade title precisa ser uma string para DrButtonLoading'
      );
    }
    buttonRender = (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrButtonLoading loading={loading} type={type} title={title} {...rest} />
    );
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    buttonRender = <DrButton type={type} title={title} {...rest} />;
  }

  return buttonRender;
};

const ButtonLeft: React.FC<buttonProps> = ({
  color = 'blue',
  ...rest
}: buttonProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button color={color} {...rest} />
);
const ButtonRight: React.FC<buttonProps> = ({
  color = 'dark-grey',
  title = 'Cancelar',
  href,
  onClick,
  ...rest
}: buttonProps) => {
  if (href === undefined) {
    if (onClick) {
      // eslint-disable-next-line no-param-reassign
      href = undefined;
    } else {
      // eslint-disable-next-line no-param-reassign
      href = '/dashboard';
    }
  }

  return (
    <Button
      color={color}
      href={href}
      title={title}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

const DrButtonsFooter: React.FC<DrButtonsFooterProps> = ({
  containerClassNm,
  buttonLeft,
  buttonRight,
  classNameButton,
  sizeCol,
}: DrButtonsFooterProps) => (
  <Container className={containerClassNm}>
    <Row className={classNameButton}>
      {buttonLeft && (
        <Col xl={sizeCol}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ButtonLeft {...buttonLeft} />
        </Col>
      )}
      {buttonRight && (
        <Col xl={sizeCol}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ButtonRight {...buttonRight} />
        </Col>
      )}
    </Row>
  </Container>
);

DrButtonsFooter.defaultProps = {
  containerClassNm: 'mt-4',
  classNameButton: 'justify-content-center align-items-center row-btn',
  buttonLeft: false,
  buttonRight: {},
  sizeCol: 4,
};

export default DrButtonsFooter;

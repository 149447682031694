import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Row, Col, Container } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/dr-input';
import HelmetComponent from '../../../components/dr-helmet-component';
import { scrollPositionTop, verifyErrorYup } from '../../../utils/bibli';
import DrButtonLoading from '../../../components/dr-button-loading';

interface CodeProps {
  nr_token: string;
}

interface IdCPF {
  cpf: string;
  email: string;
}

const DrBoxConfirmationCode: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { setDataToken, letApp, requestAPI, sendError } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);

  const { params } = useRouteMatch<IdCPF>();

  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    scrollPositionTop();
    addToast({
      type: 'success',
      title: 'Solicitação realizada!',
      description: 'Foi enviado um código para o seu e-mail',
    });
  }, []);

  async function handleCod(e: any) {
    try {
      e.preventDefault();
      setLoading(true);
      await requestAPI({
        method: 'GET',
        url: `tmUsuarioToken/T/${params.cpf}/${params.email}`,
        isPublic: true,
      });

      setLoading(false);
      addToast({
        type: 'success',
        title: 'O código foi enviado novamente para o seu email!',
        description: 'Use o código recebido para continuar.',
      });
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
      setLoading(false);
    }
  }

  const handleSubmit = useCallback(
    async (data: CodeProps) => {
      data.nr_token = data.nr_token.trim();

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nr_token: Yup.string().required('O código é obrigatório!').trim(),
          cpf: Yup.string().required('CPF não identificado!').trim(),
        });

        const { cpf } = params;

        await schema.validate(
          { ...data, cpf },
          {
            abortEarly: false,
          }
        );

        setLoading2(true);

        const resp = await requestAPI({
          method: 'PUT',
          url: `tmUsuarioVerificarToken/T/${cpf}`,
          body: data,
          isPublic: true,
        });
        setLoading2(false);

        letApp.hash = resp.hash;

        await setDataToken();

        history.push({
          pathname: `/recover-password/${cpf}`,
          state: letApp.hash,
        });
      } catch (err: any) {
        sendError(err);
        setLoading2(false);
        if (err instanceof Yup.ValidationError) {
          verifyErrorYup(err, formRef);
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        }
      }
    },
    [addToast, history, letApp, params.cpf, requestAPI, setDataToken]
  );

  return (
    <>
      <HelmetComponent title="Confirmação do Código" />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <Row className="pb-0">
            <Col xl={12} className="p-3">
              <Input
                name="nr_token"
                label="Código"
                customType="token"
                icon
                placeholder="Digite o código"
                inputMode="numeric"
                onKeyDown={() => {}}
              />
            </Col>
          </Row>
          <Row className="pb-2">
            <Col xl={12}>
              <DrButtonLoading
                loading={isLoading2}
                disabled={isLoading}
                type="submit"
                title="Confirmar"
                color="blue"
              />

              <DrButtonLoading
                style={{ marginTop: '15px' }}
                loading={isLoading}
                disabled={isLoading2}
                type="button"
                title="Reenviar"
                color="dark-grey"
                onClick={handleCod}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default DrBoxConfirmationCode;

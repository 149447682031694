import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  title: string;
}

const HelmetComponent: React.FC<HelmetProps> = ({ title }) => {
  const location = useLocation();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title} | Dr+</title>
      <link rel="canonical" href={`${location.pathname}`} />
    </Helmet>
  );
};

export default HelmetComponent;

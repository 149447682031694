import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export interface CardEquipIconProps {
  externalCssDiv?: FlattenSimpleInterpolation;
  externalCssIcon?: FlattenSimpleInterpolation;
}

export interface CardEquipAlertProps {
  status: 'success' | 'error' | 'warning';
}

export const CardEquip = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CardEquipIcon = styled.div<CardEquipIconProps>`
  transition: all 0.2s ease-in-out;

  ${({ externalCssDiv }) => externalCssDiv && externalCssDiv}

  i {
    @media (max-width: 1199px) {
      font-size: 40px;
    }

    position: relative;
    font-size: 60px;
    color: #03bbd7;

    ${({ externalCssIcon }) => externalCssIcon && externalCssIcon}
  }
`;

export const CardEquipAlert = styled.span<CardEquipAlertProps>`
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 3px;
  max-width: 140px;
  width: 100%;
  text-align: center;

  ${(props) => {
    switch (props.status) {
      case 'success':
        return css`
          background-color: #d1e7dd;
          color: #0f5132;
        `;
      case 'error':
        return css`
          background-color: #f8d7da;
          color: #842029;
        `;
      case 'warning':
        return css`
          background-color: #ffcc80;
          color: #4d2e00;
        `;
      default:
        return '';
    }
  }}
`;

export const CardEquipTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #777;
  margin: 10px 0;
`;

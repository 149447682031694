import styled from 'styled-components';

import Tooltip from '../dr-tooltip';

interface InputProps {
  isError: boolean;
  disabled: boolean;
}
export const FormLabel = styled.label`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #777;
  left: 0;
  z-index: 2;
`;

export const Field = styled.div<InputProps>`
  position: relative;
  margin: 0 0 20px;
  border: ${(props) =>
    props.isError ? 'solid 2px #c00' : 'solid 2px #e5e5e5'};
  border-radius: 3px;
  padding: 0 5px 0 10px;
  min-height: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${(props) => (props.disabled ? '#e9ecef' : '#ffffff')};
  textarea {
    height: 120px;
    border-radius: 3px;
    border: solid 2px #e5e5e5;
    width: 100%;
    font-size: 18px;
    color: #777 !important;
    opacity: 1;
    min-height: 54px;
    vertical-align: middle;
    padding: 0;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    margin-top: 10px;
    font-weight: 500;
  }
`;
export const Error = styled(Tooltip)`
  z-index: 9999;
  font-size: 28px;
`;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import { Container, Row, Col } from 'react-bootstrap';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import DrAlert from '../../dr-alert';
import DrCardEquip, { CardEquipProps } from '../../dr-card-equip';
import DrIcon from '../../dr-icon-font';
import LogoApple from '../../../assets/svg/logo-store-apple.svg';
import StoreGoogle from '../../../assets/img/logo-store-google.png';
import {
  getDeviceInfos,
  detectIOS,
  detectBrowser,
  scrollPositionTop,
  handleGetTokenNotification,
} from '../../../utils/bibli';
import {
  useAuth,
  User,
  AuthContextData,
  ApplicationData,
} from '../../../hooks/auth';
import { useToast, ToastMessage } from '../../../hooks/toast';
import { useIsMounted } from '../../../hooks/is-mounted';
import DrButtonsFooter from '../../dr-buttons-footer';

type statusEquipType = 'error' | 'ok' | 'not-verified';
interface objStatusEquipInt {
  status: statusEquipType;
  msgError?: string;
  msgWarning?: string;
  isMobile?: boolean;
  isPermission?: boolean;
}

type equipType =
  | 'internet'
  | 'mic'
  | 'camera'
  | 'audio'
  | 'server'
  | 'notification';
interface statusEquipInt extends Partial<objStatusEquipInt> {
  equip: equipType;
}

type dispArrStaEquipType = statusEquipInt | statusEquipInt[];

const arrayEquipData = [
  {
    icon: <DrIcon name="wifi" />,
    title: 'Internet',
  },
  {
    icon: <DrIcon name="microphone" />,
    title: 'Microfone',
  },
  {
    icon: <DrIcon name="video" />,
    title: 'Câmera',
  },
  {
    icon: <DrIcon name="volume-alto" />,
    title: 'Áudio',
  },
  {
    icon: <DrIcon name="setting" />,
    title: 'Servidores',
  },
  {
    icon: <DrIcon name="notification" />,
    title: 'Notificações',
  },
];

function handleTestConnection(
  user: User,
  // eslint-disable-next-line no-unused-vars
  dispatch: (action: statusEquipInt) => void,
  sendError,
  applicationData: ApplicationData
) {
  try {
    const info = getDeviceInfos();
    const socket = io.connect(applicationData?.valores_parametros[80], {
      query: {
        dadosSession: JSON.stringify({
          v: user.jwt.idapi_token,
          w: user.idestabelecimento,
          x: user.idpessoa_fisica,
          z: user.idtm_usuario, // z: idtmusuario do usuário (Opcional)
          bool_mobile: info.bool_mobile,
          ie_navegador: info.ie_navegador,
        }),
      },
      reconnection: false,
    });

    socket.on('connection-success', () => {
      dispatch({ equip: 'server', status: 'ok' });
      socket.close();
    });

    socket.on('connect_error', (e) => {
      sendError(e);
      dispatch({
        equip: 'server',
        msgError: 'Servidor indisponível no momento',
      });
    });

    socket.on('Error', ({ msg }) => {
      sendError(new Error(msg));
      dispatch({
        equip: 'server',
        msgError: `Erro ao conectar com servidor!${msg ? ` ${msg}` : ''}`,
      });
    });
  } catch (err: any) {
    sendError(err);
    dispatch({
      equip: 'server',
      msgError: `Erro ao conectar com servidor! ${err.message}`,
    });
  }
}

function testAudio(
  audio: boolean,
  // eslint-disable-next-line no-unused-vars
  dispatchEquip: (action: statusEquipInt) => void,
  isIOS: boolean,
  sendError,
  applicationData: ApplicationData
) {
  try {
    if (!audio) {
      dispatchEquip({
        equip: 'audio',
        msgError: 'Não foi encontrado seu som!',
      });
    } else if (isIOS) {
      dispatchEquip({ equip: 'audio', status: 'ok' });
    } else {
      const soundTest = new Audio(applicationData?.valores_parametros[121]);
      soundTest.volume = 0.01;
      soundTest.play().then(() => {
        dispatchEquip({ equip: 'audio', status: 'ok' });
      });
    }
  } catch (error: any) {
    sendError(error);
    if (detectBrowser() === 'safari') {
      dispatchEquip({
        equip: 'audio',
        status: 'ok',
        msgWarning:
          'Sem permissão de reprodução automática para este navegador!',
      });
    } else {
      dispatchEquip({
        equip: 'audio',
        msgError: `Não foi possível testar seu dispositivo de áudio! ${error.message}`,
      });
    }
  }
}

async function testMic(
  mic: boolean,
  // eslint-disable-next-line no-unused-vars
  dispatchEquip: (action: statusEquipInt) => void,
  sendError
) {
  const equipString = 'mic';
  try {
    // pegando dispositivo de microfone
    if (!mic) {
      dispatchEquip({
        equip: equipString,
        msgError: 'Não foi encontrado seu microfone!',
      });
    } else {
      const x = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (!x) {
        dispatchEquip({
          equip: equipString,
          msgError: 'Não foi permitido acesso ao microfone!',
        });
      } else {
        let hasTrackAudio = false;
        x.getTracks().forEach((track) => {
          if (track.kind === 'audio') {
            hasTrackAudio = true;
          }
          track.stop();
          x.removeTrack(track);
        });

        if (!hasTrackAudio) {
          dispatchEquip({
            equip: equipString,
            msgError: 'Deu algum problema com seu microfone!',
          });
        } else {
          dispatchEquip({
            equip: equipString,
            status: 'ok',
          });
        }
      }
    }
  } catch (e: any) {
    sendError(e);
    if (e.message === 'Permission denied') {
      dispatchEquip({
        equip: equipString,
        msgError: 'Seu microfone está bloqueado.',
        isPermission: false,
      });
    } else if (e.message === 'Permission dismissed') {
      dispatchEquip({
        equip: equipString,
        msgError: 'Seu microfone está bloqueado.',
        isPermission: false,
      });
    } else if (e.message.includes('the platform in the current context')) {
      dispatchEquip({
        equip: equipString,
        msgError: 'Seu microfone está bloqueado.',
        isPermission: false,
      });
    } else if (e.message === 'Requested device not found') {
      dispatchEquip({
        equip: equipString,
        msgError: 'Não foi permitido dispositivo: Microfone!',
      });
    } else {
      dispatchEquip({
        equip: equipString,
        msgError: e.message,
      });
    }
  }
}

async function testVideo(
  video: boolean,
  // eslint-disable-next-line no-unused-vars
  dispStatEquipToast: (action: statusEquipInt) => void,
  sendError
) {
  const equipStr = 'camera';
  try {
    if (!video) {
      dispStatEquipToast({
        equip: equipStr,
        msgError: 'Não foi encontrada sua câmera!',
        isPermission: false,
      });
    } else {
      const y = await navigator.mediaDevices.getUserMedia({ video: true });
      let hasTrackVideo = false;
      y.getTracks().forEach((track) => {
        if (track.kind === 'video') {
          hasTrackVideo = true;
        }
        track.stop();
        y.removeTrack(track);
      });

      if (hasTrackVideo) {
        dispStatEquipToast({
          equip: equipStr,
          status: 'ok',
        });
      } else {
        dispStatEquipToast({
          equip: equipStr,
          msgError: 'Algum erro ocorreu em sua câmera!',
        });
      }
    }
  } catch (e: any) {
    sendError(e);
    if (e.message === 'Permission denied') {
      dispStatEquipToast({
        equip: equipStr,
        msgError: 'Sua câmera está bloqueada.',
        isPermission: false,
      });
    } else if (e.message === 'Permission dismissed') {
      dispStatEquipToast({
        equip: equipStr,
        msgError: 'Sua câmera está bloqueada.',
        isPermission: false,
      });
    } else if (e.message.includes('the platform in the current context')) {
      dispStatEquipToast({
        equip: equipStr,
        msgError: 'Sua câmera está bloqueada.',
        isPermission: false,
      });
    } else if (e.message === 'Requested device not found') {
      dispStatEquipToast({
        equip: equipStr,
        msgError: 'Não foi permitido dispositivo: Câmera!',
      });
    } else {
      dispStatEquipToast({
        equip: equipStr,
        msgError: e.message,
      });
    }
  }
}

async function getPeripherals(
  // eslint-disable-next-line no-unused-vars
  dispStatEquipToast: (action: statusEquipInt) => void,
  // eslint-disable-next-line no-unused-vars
  addToast: (message: Omit<ToastMessage, 'id'>) => void,
  setLoadingTest: React.Dispatch<React.SetStateAction<boolean>>,
  sendError,
  applicationData: ApplicationData
) {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let video = false;
    let mic = false;
    let audio = false;
    const isIOS = detectIOS();

    devices.forEach((device) => {
      if (device.kind === 'videoinput') {
        video = true;
      } else if (device.kind === 'audioinput') {
        mic = true;
      } else if (device.kind === 'audiooutput') {
        audio = true;
      }
    });

    if (
      !audio &&
      (isIOS || detectBrowser() === 'firefox' || detectBrowser() === 'safari')
    )
      audio = true;

    testAudio(audio, dispStatEquipToast, isIOS, sendError, applicationData);

    testMic(mic, dispStatEquipToast, sendError);

    testVideo(video, dispStatEquipToast, sendError);
  } catch (e: any) {
    sendError(e);
    addToast({
      type: 'error',
      title: 'Ops!',
      description: `Erro ao verificar os seus componentes! ${e.message}`,
    });
  } finally {
    // setLoadingTest(false);
  }
}

function clickEquipamentTest(
  setArrStatusEquips: React.Dispatch<dispArrStaEquipType>,
  // eslint-disable-next-line no-unused-vars
  setStatEquipModified: (action: statusEquipInt) => void,
  setLoadingTest: React.Dispatch<React.SetStateAction<boolean>>,
  user: User,
  // eslint-disable-next-line no-unused-vars
  addToast: (message: Omit<ToastMessage, 'id'>) => void,
  requestAPI: AuthContextData['requestAPI'],
  sendError,
  applicationData: ApplicationData
) {
  setArrStatusEquips([
    { equip: 'internet', status: 'not-verified' },
    { equip: 'camera', status: 'not-verified' },
    { equip: 'audio', status: 'not-verified' },
    { equip: 'mic', status: 'not-verified' },
    { equip: 'server', status: 'not-verified' },
    { equip: 'notification', status: 'not-verified' },
  ]);

  setLoadingTest(true);

  handleTestConnection(user, setStatEquipModified, sendError, applicationData);

  getPeripherals(
    setStatEquipModified,
    addToast,
    setLoadingTest,
    sendError,
    applicationData
  );

  handleGetTokenNotification(user, requestAPI, setStatEquipModified, sendError);

  if (navigator.onLine) {
    setStatEquipModified({ equip: 'internet', status: 'ok' });
  } else {
    setStatEquipModified({
      equip: 'internet',
      msgError: 'Sem conexão com a internet',
    });
  }
}

function dispatchArrStatusEquips(
  prev: objStatusEquipInt[],
  actionEquips: dispArrStaEquipType
) {
  let changed = false;

  let idx = 0;

  if (!('forEach' in actionEquips)) {
    actionEquips = [actionEquips];
  }

  actionEquips.forEach((objEquip) => {
    const { equip, status, msgError, msgWarning, isMobile, isPermission } =
      objEquip;
    switch (equip) {
      case 'internet':
        idx = 0;
        break;
      case 'mic':
        idx = 1;
        break;
      case 'camera':
        idx = 2;
        break;
      case 'audio':
        idx = 3;
        break;
      case 'server':
        idx = 4;
        break;
      case 'notification':
        idx = 5;
        break;
      default:
    }

    if (status && status !== prev[idx].status) {
      prev[idx] = { status };
      changed = true;
    }

    if (msgError && msgError !== prev[idx].msgError) {
      prev[idx] = { status: 'error', msgError, isMobile, isPermission };
      changed = true;
    }

    if (msgWarning && msgWarning !== prev[idx].msgWarning) {
      prev[idx] = { status: 'ok', msgWarning };
      changed = true;
    }
  });

  if (changed) return [...prev];
  return prev;
}

function dispatchAddToast(
  action: statusEquipInt,
  setArrStatusEquips: React.Dispatch<dispArrStaEquipType>
) {
  setArrStatusEquips(action);
}

const EquipamentTestCard: React.FC = () => {
  const {
    user,
    requestAPI,
    applicationData,
    sendError,
    // attendance,
    setTesteComplete,
    whichRouteAmAt,
    handleCloseComponentTest,
  } = useAuth();
  const { addToast } = useToast();
  const [loadingTest, setLoadingTest] = React.useState(false);
  const isMountedRef = useIsMounted();
  const history = useHistory();
  const [arrStatusEquips, setArrStatusEquips] = React.useReducer(
    dispatchArrStatusEquips,
    [
      { status: 'not-verified' },
      { status: 'not-verified' },
      { status: 'not-verified' },
      { status: 'not-verified' },
      { status: 'not-verified' },
      { status: 'not-verified' },
    ]
  );

  const inMyProfile = whichRouteAmAt.includes('/profile');

  const handleButtonTitle = () => {
    const buttonTitle = inMyProfile ? 'Concluir' : 'Continuar Atendimento';
    return buttonTitle;
  };

  React.useEffect(() => {
    scrollPositionTop();
  }, []);

  function getLinkOfBrowser(): string {
    switch (detectBrowser()) {
      case 'safari':
        return 'https://support.apple.com/pt-br/guide/safari/ibrw7f78f7fe/mac';
      case 'firefox':
        return 'https://support.mozilla.org/pt-BR/kb/como-gerenciar-permissoes-camera-microfone-firefox';
      case 'edge':
        return 'https://support.microsoft.com/pt-br/windows/windows-c%C3%A2mera-microfone-e-privacidade-a83257bc-e990-d54a-d212-b5e41beba857';
      case 'chrome':
        return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=pt-br';
      default:
        return '';
    }
  }

  const hasNotVerified = arrStatusEquips.some(
    (objEquip) => objEquip.status === 'not-verified'
  );

  React.useEffect(() => {
    if (loadingTest && !hasNotVerified) {
      setLoadingTest(false);
    }
  }, [hasNotVerified, loadingTest]);

  const arrStatusErrEquip = arrStatusEquips.filter(
    (objEquip) => objEquip.status === 'error' || objEquip.isMobile
  );

  const arrStatusEquipsFiltered = arrStatusEquips.filter(
    (objEquip) =>
      (objEquip.status === 'error' &&
        !objEquip.msgError?.includes('câmera') &&
        !objEquip.msgError?.includes('microfone')) ||
      objEquip.msgWarning ||
      objEquip.isMobile
  );

  const buttonLeft = arrStatusEquips.some(
    (objSttEquip) => objSttEquip.status !== 'ok'
  )
    ? {
        title: 'Testar componentes',
        loading: loadingTest,
        onClick: () => {
          clickEquipamentTest(
            setArrStatusEquips,
            (action) => {
              if (isMountedRef.current) {
                dispatchAddToast(action, setArrStatusEquips);
              }
            },
            setLoadingTest,
            user,
            addToast,
            requestAPI,
            sendError,
            applicationData
          );
        },
      }
    : {
        title: handleButtonTitle(),
        onClick: () => {
          user.teste_complete = true;
          if (setTesteComplete) setTesteComplete(user.teste_complete);
          if (inMyProfile) handleCloseComponentTest();
        },
      };

  return (
    <>
      <Container className="pt-3">
        <CardBlank className="p-3">
          <PageTitle>Pré-requisitos Técnicos</PageTitle>
          <DrAlert
            battery
            warning
            title={{
              text: 'Antes de iniciar uma consulta, verifique se o seu dispositivo tem bateria suficiente.',
              fontSize: '14px',
            }}
          />
          <PageTitle>
            Agora, vamos testar os componentes do seu dispositivo
          </PageTitle>
          <Row className="justify-content-between">
            {arrayEquipData.map((equipData, idxEqDta) => {
              let alert: CardEquipProps['alert'];
              switch (arrStatusEquips[idxEqDta].status) {
                case 'error':
                  alert = { status: 'error', text: 'Erro' };
                  break;
                case 'ok':
                  alert = { status: 'success', text: 'Verificado' };
                  if (arrStatusEquips[idxEqDta].msgWarning)
                    alert = { status: 'warning', text: 'Nao verificado' };

                  break;
                case 'not-verified':
                  alert = { status: 'warning', text: 'Não verificado' };
                  break;
                default:
              }

              return (
                <Col
                  xs={6}
                  lg={4}
                  xl={2}
                  className="mb-4"
                  key={equipData.title}
                >
                  <DrCardEquip {...equipData} alert={alert} />
                </Col>
              );
            })}
          </Row>
          {arrStatusErrEquip.length > 0 && !loadingTest && (
            <div className="mt-4">
              <DrAlert
                danger
                title={{
                  tag: 'h4',
                  text: 'Atenção',
                }}
              >
                {arrStatusEquipsFiltered.map((objEquip) => {
                  if (
                    objEquip.isMobile &&
                    applicationData &&
                    applicationData.valores_variaveis &&
                    applicationData.valores_variaveis.url_loja_apple &&
                    applicationData.valores_variaveis.url_loja_google
                  ) {
                    return (
                      <div>
                        <p key={objEquip.msgError}>{objEquip.msgError}</p>
                        <Link
                          to={{
                            pathname:
                              applicationData.valores_variaveis.url_loja_apple,
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={LogoApple}
                            alt="Apple Logo"
                            style={{
                              height: 50,
                              width: 150,
                            }}
                          />
                        </Link>
                        <Link
                          to={{
                            pathname:
                              applicationData.valores_variaveis.url_loja_google,
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={StoreGoogle} alt="Google Logo" />
                        </Link>
                      </div>
                    );
                  }

                  return <p key={objEquip.msgError}>{objEquip.msgError}</p>;
                })}
                {arrStatusErrEquip.map((objEquip) => {
                  if (
                    objEquip.isPermission === false &&
                    arrStatusErrEquip.some(
                      (str) =>
                        str.msgError?.includes('câmera') ||
                        str.msgError?.includes('microfone')
                    )
                  ) {
                    return (
                      <div>
                        <p style={{ marginTop: 10 }} key={objEquip.msgError}>
                          {objEquip.msgError}
                        </p>
                      </div>
                    );
                  }
                  return <></>;
                })}

                {arrStatusErrEquip &&
                  arrStatusErrEquip.length &&
                  arrStatusErrEquip.some(
                    (str) =>
                      str.msgError?.includes('câmera') ||
                      str.msgError?.includes('microfone')
                  ) && (
                    <>
                      <div>
                        <p>O Dr+ precisa ter acesso aos seus dispositivos.</p>
                      </div>
                      <div>
                        <a
                          href={getLinkOfBrowser()}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Clique aqui para saber como resolver.
                        </a>
                      </div>
                    </>
                  )}
              </DrAlert>
            </div>
          )}
          {arrStatusEquipsFiltered.length > 0 &&
            !loadingTest &&
            arrStatusEquipsFiltered.some((obj) => obj.msgWarning) && (
              <div className="mt-4">
                <DrAlert
                  warning
                  title={{
                    tag: 'h4',
                    text: 'Atenção',
                  }}
                >
                  {arrStatusEquipsFiltered.map((objEquip) => (
                    <p key={objEquip.msgWarning}>{objEquip.msgWarning}</p>
                  ))}
                </DrAlert>
              </div>
            )}
        </CardBlank>
      </Container>

      <DrButtonsFooter
        buttonLeft={buttonLeft}
        buttonRight={{
          onClick: inMyProfile
            ? () => handleCloseComponentTest()
            : () => history.push('/dashboard'),
        }}
      />
    </>
  );
};

export default EquipamentTestCard;

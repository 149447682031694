import styled from 'styled-components';
import { CardBlank } from '../../styles/card-blank';
import { ForumP } from '../../pages/Forum/styled';

interface CardConfigProps {
  colorT?: string;
  colorP?: string;
  colorH?: string;
  colorI?: string;
}

export const CardConfigBody = styled.div``;

export const CardConfigTitle = styled.h3<CardConfigProps>`
  font-size: 18px;
  font-weight: 700;
  color: ${({ colorT }) => colorT || '#3f56a5'};
  margin: 0 0 15px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const CardConfigIcon = styled.div`
  margin: 0 0 15px;

  @media (max-width: 500px) {
    margin: 0 0 0;
  }
`;

export const CardConfigP = styled.p<CardConfigProps>`
  color: ${({ colorP }) => colorP || '#777777'};
  margin-bottom: 0;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const CardConfigI = styled.i<CardConfigProps>`
  font-size: 40px;
  color: ${({ colorI }) => colorI || '#03bbd7'};

  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

export const CardConfig = styled(CardBlank)<CardConfigProps>`
  padding: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  flex-wrap: wrap;

  @media (hover: hover) {
    &:hover {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    &:hover {
      transition: none;
    }
  }

  @media (hover: hover) {
    &:hover ${CardConfigTitle} {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) {
    &:hover ${CardConfigP} {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) {
    &:hover ${CardConfigI} {
      transition: all 0.2s ease-in-out;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    ${CardConfigTitle} {
      transition: none;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    ${CardConfigP} {
      transition: none;
    }
  }
  @media (hover: hover) and (prefers-reduced-motion: reduce) {
    ${CardConfigI} {
      transition: none;
    }
  }
  @media (hover: hover) {
    &:hover{
      background-color:  ${({ colorH }) => colorH || '#3f56a5'};
    }

    &:hover ${CardConfigP} {
      color: #fff;
    }
    &:hover ${CardConfigTitle} {
      color: #fff;
    }
    &:hover ${CardConfigI} {
      color: #fff;
    }
  }
  @media (hover: hover) {
    &:hover ${ForumP} {
      transition: all 0.2s ease-in-out;
      color:#fff;
    }
`;

import styled, { css } from 'styled-components';

export interface DivAlertProps {
  warning?: boolean;
  danger?: boolean;
  battery?: boolean;
  textAlignCenter?: boolean;
}

export const DivAlert = styled.div<DivAlertProps>`
  padding: 15px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  position: relative;

  ${(props) =>
    props.textAlignCenter &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.warning &&
    css`
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    `}

  ${(props) =>
    props.danger &&
    css`
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
    `}

  ${(props) =>
    props.battery &&
    css`
      padding-left: 50px;
      &::before {
        position: absolute;
        content: '';
        font-family: icomoon !important;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 30px;
        top: 20px;
        left: 15px;
      }
    `}
`;

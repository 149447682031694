import { useCallback } from 'react';
import { useAuth } from './auth';

interface TimeToConsultProps {
  isTime: boolean;
  title: string;
  msg: string;
  type: 'early' | 'late';
}

interface HookProps {
  isTimeToConsult: (
    idagenda_consulta: string | number
  ) => Promise<TimeToConsultProps>;
}

export const useIsTimeToConsult = (): HookProps => {
  const { requestAPI } = useAuth();

  const isTimeToConsult = useCallback(
    async (idagenda_consulta: string | number) => {
      try {
        if (!idagenda_consulta) {
          throw new Error(
            'Necessário informar id do agendamento como parâmetro!'
          );
        }

        const url = `/agendaConsultaValidaHorario?idagenda_consulta=${idagenda_consulta}`;

        const result: TimeToConsultProps = await requestAPI({
          method: 'GET',
          url,
        });

        return result;
      } catch (e: any) {
        if (e.data) return e.data;

        return {
          title: 'Ops!',
          msg: e.message,
          type: 'generic',
        };
      }
    },
    []
  );

  return { isTimeToConsult };
};

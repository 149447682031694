import styled from 'styled-components';
import VideoComp from '../dr-video-comp';

export const Container = styled.div`
  position: relative;
  .videoHeader {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100px;
    background: black;
    position: fixed;
    top: 0px;

    .logoHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      img {
        top: 3vh;
        left: 1vh;
        height: 80px;
        width: 80px;
        border-radius: 100%;
        z-index: 100;
        @media screen and (max-width: 850px) {
          height: 70px;
          width: 70px;
        }
      }
    }
    .nameIcons {
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      .nameHeader {
        display: flex;
        align-items: center;
        width: 95%;
        margin-left: 6px;
        label {
          margin-bottom: 0;
        }
      }
      .remote-status {
        svg {
          color: white;
          margin: 3px;
          width: 2em;
          height: 2em;
          font-size: 14px;
          @media screen and (max-width: 1143px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  ul {
    display: flex;
    position: absolute;
    width: auto;
    height: 100%;
    right: 0;
    flex-direction: column-reverse;
  }

  li {
    margin-top: 3px;
    width: 100px;
    margin-right: 10px;
  }

  .name {
    color: white;
    font-size: 2rem;
    z-index: 85;
  }

  .avatar-principal {
    border-radius: 5px;
    objectfit: 'cover';
    width: 96%;
    height: 50vh;
    max-width: 350px;
    min-width: 300px max-width:450px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    objectfit: cover;
  }

  @media screen and (max-width: 1143px) {
    .name {
      font-size: 1.8rem;
    }
  }
`;

export const Video = styled(VideoComp)`
  &::-webkit-media-controls,
  &::-webkit-media-controls-start-playback-button,
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-panel,
  &::-webkit-media-controls-container,
  &::-webkit-media-controls-overlay-play-button,
  &::-webkit-media-controls-enclosure {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
`;

import React, { useState, useEffect } from 'react';
import { Video } from './styled';
import { useAuth } from '../../hooks/auth';
import { valueParam } from '../../utils/bibli';

interface VideosProps {
  remoteStreams: Map<any, any>;
  urlImgRemoto: any;
  hiddenVideo: boolean;
  videoHtmlRef: React.RefObject<HTMLVideoElement>;
}

const Videos: React.FC<VideosProps> = ({
  remoteStreams,
  urlImgRemoto,
  hiddenVideo,
  videoHtmlRef,
}: VideosProps) => {
  const [selectedVideo, setSelectedVideo] = useState<any>();
  const { applicationData } = useAuth();

  useEffect(() => {
    const streamCurrent = remoteStreams.values().next().value;
    if (streamCurrent) {
      setSelectedVideo({
        ...streamCurrent,
        stream: new MediaStream(streamCurrent.stream),
      });
    }
  }, [remoteStreams]);

  const onError = React.useCallback<React.ReactEventHandler<HTMLImageElement>>(
    (event) => {
      // eslint-disable-next-line prefer-destructuring
      event.currentTarget.src = valueParam(applicationData, 126);
    },
    [applicationData]
  );

  return (
    <>
      <Video
        id={selectedVideo ? selectedVideo.id : 0}
        videoType="previewVideo"
        videoStream={selectedVideo && selectedVideo.stream}
        avatar={urlImgRemoto}
        videoStyles={{
          height: '100%',
          margin: 'auto',
          borderRadius: '10px',
          maxWidth: '100%',
        }}
        hidden={hiddenVideo}
        ref={videoHtmlRef}
        controlsConferencia={false}
      />
      {hiddenVideo && valueParam && (
        <img
          className="avatar-principal"
          src={urlImgRemoto || valueParam(applicationData, 126)}
          alt="Avatar do médico"
          style={{
            height: '100%',
            maxWidth: '100%',
            borderRadius: '10px',
            margin: 'auto',
          }}
          onError={onError}
        />
      )}
    </>
  );
};

export default Videos;

import React, { createContext, useContext, useCallback } from 'react';

interface UserContextData {
  userId(): void;
}

const UserIdContext = createContext<UserContextData>({} as UserContextData);

const UserIdProvider: React.FC = ({ children }) => {
  const userId = useCallback(() => {
    const user = localStorage.getItem('@Dr+:UserID');

    return user;
  }, []);

  return (
    <UserIdContext.Provider value={{ userId }}>
      {children}
    </UserIdContext.Provider>
  );
};

function useUser(): UserContextData {
  const context = useContext(UserIdContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { UserIdProvider, useUser };

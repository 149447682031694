import styled from 'styled-components';

interface LabelCheckboxProps {
  checked: boolean;
  isError: boolean;
  disabled: boolean;
  withPadding?: boolean;
}
interface marginFieldProps {
  noMargin?: boolean;
}
export const Field = styled.div<marginFieldProps>`
  position: relative;
  margin: ${(props) => (props.noMargin ? '0' : '0 0 20px')};
`;
export const FormCheckbox = styled.input`
  position: absolute;
  margin-left: 0;
  height: 30px;
  width: 30px;
  left: 0;
  top: 0;
  z-index: 5;
  opacity: 0;
`;

export const LabelCheckbox = styled.label<LabelCheckboxProps>`
  top: auto;
  left: auto;
  padding-left: 40px;
  position: relative;
  padding-top: 3px;
  font-weight: ${(props) => (props.disabled ? '300' : '700')};

  ::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: ${(props) => (props.disabled ? '#c1c1c1' : '#fff')};
    border: ${(props) =>
      props.isError ? 'solid 2px #c00' : 'solid 2px #e5e5e5'};
    border-radius: 3px;
    left: ${(props) => (props.withPadding ? '5px' : '0px')};
    top: 0;
  }
  ::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #3f56a5;
    border-radius: 3px;
    left: ${(props) => (props.withPadding ? '10px' : '5px')};
    top: 5px;
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
`;

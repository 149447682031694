import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const ButtonDelete = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  height: 21px;
  width: 21px;
  z-index: 999;
`;

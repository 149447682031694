import styled from 'styled-components';

interface SizeProps {
  width?: string;
  height?: string;
}
export const BoxPictureImg = styled.div<SizeProps>`
  position: relative;
  margin: 0 0 15px;
  display: inline-block;

  img {
    height: ${(props) => props.width};
    width: ${(props) => props.height};
    box-sizing: content-box;
    border-radius: 50%;
  }
`;
BoxPictureImg.defaultProps = {
  width: '150px',
  height: '150px',
};

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  height: 100%;

  @media screen and (max-width: 850px) {
    max-width: 100%;
  }
`;

export const Appointment = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 100%;
  border: 1px solid #d3e2e5;
  background: linear-gradient(to bottom right, #e6f7fb 0%, #fff 100%);
  border-left: 3px solid #00bbd652;
  border-right: 3px solid #4156a673;
  border-top: 1px solid #00bbd647;
  border-bottom: 1px solid #4156a661;

  h1 {
    position: relative;
    top: 0;
    color: #4156a6;
    font-family: 'Concert One', cursive;
    text-align: center;
  }
  h3 {
    color: #4156a6;
    font-weight: bold;
  }
  form {
    position: relative;
    width: 50%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    margin: 0 auto;
    .input-date-hours {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      justify-content: center;
      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }

    .submit {
      margin: 20px 0 0;
      background: transparent;
      height: 44px;
      width: 100px;
      font-weight: bold;
      color: #62bb46;
      border: 2px solid #62bb46;
      border-radius: 20px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.08, '#62bb46')};
        color: #fff;
      }
      @media screen and (max-width: 850px) {
        height: 34px;
        width: 100px;
        font-size: 14px;
      }
    }

    .now {
      margin: 20px 0 0;
      background: transparent;
      height: 44px;
      width: 100px;
      font-weight: bold;
      color: #9f1e49;
      border: 2px solid #9f1e49;
      border-radius: 20px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.08, '#9f1e49')};
        color: #fff;
      }
      @media screen and (max-width: 850px) {
        height: 34px;
        width: 100px;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    width: 95%;

    form {
      width: 95%;
      max-width: 300px;

      input {
        border-radius: 4px;
        height: 20px;
        width: 100px;

        padding: 0 15px;
      }
    }
  }
`;

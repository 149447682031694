import styled, { css } from 'styled-components';

interface ContainerProps {
  isVisible: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  span {
    width: 200px;
    background: #ed1164;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;

    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    color: #ffff;

    &::before {
      content: '';
      border-style: solid;
      border-color: #ed1164 transparent;
      border-width: 6px 6px 0 6px;
      bottom: 20px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      span {
        opacity: 1;
        visibility: visible;
        z-index: 99;
      }
    `}

  @media screen and (max-width: 850px) {
    span {
      width: 300px;
      background: #ed1164;
      padding: 8px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      opacity: 0;
      transition: opacity 0.4s;
      visibility: hidden;

      position: absolute;
      bottom: calc(100% + 12px);
      left: 50%;
      transform: translateX(-85%);
      color: #ffff;

      &::before {
        content: '';
        border-style: solid;
        border-color: #ed1164 transparent;
        border-width: 6px 6px 0 6px;
        bottom: 20px;
        top: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  ${(props) =>
    props.isVisible &&
    css`
      span {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { PageTitle } from '../../styles/page-title';
import { BoxPag, CodeBox } from './styled';
import { CardBlank } from '../../styles/card-blank';
import Button from '../../components/dr-button';
import DrIconFont from '../../components/dr-icon-font';
import { scrollPositionTop } from '../../utils/bibli';

interface IdRoute {
  id: string;
}

const ReceiptOfPayment: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<IdRoute>();
  const { attendance } = useAuth();
  const [values, setValues] = useState<any>(location.state && location.state);

  useEffect(() => {
    scrollPositionTop();
    window.history.forward();
  });

  function formatValue(value: any) {
    if (value) {
      const valueNumber = parseFloat(value);
      return valueNumber.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return 'R$ 0,00';
  }

  function handleOpenPdf() {
    return window.open(values.url_recibo);
  }

  return (
    <>
      <Header />
      <Container>
        <CardBlank className="pt-2 pb-2 mt-3">
          {values && (
            <>
              <PageTitle>Pagamento efetuado com sucesso</PageTitle>
              <Row className="justify-content-center m-0">
                <Col md={12} xl={6}>
                  {/* <BoxPagTitle>
                    <strong>{values.ds_situacao}</strong>
                  </BoxPagTitle> */}
                  <CodeBox>
                    <Row className=" m-0">
                      <Col className="col-6" xl={8}>
                        <p>
                          <strong>{values.data_recebimento}</strong>
                        </p>
                      </Col>

                      <Col className="col-6" xl={4}>
                        <p style={{ float: 'right' }}>
                          <strong>{`Código: ${values.idconta_receber_cartao_movto}`}</strong>
                        </p>
                      </Col>
                    </Row>
                  </CodeBox>

                  <BoxPag>
                    <Row className=" m-0">
                      <Col className="col-6" xl={8}>
                        <p>
                          <strong>Pin</strong>
                        </p>
                        {values.nr_autorizacao ? (
                          <p>
                            <strong>Autorização</strong>
                          </p>
                        ) : (
                          <></>
                        )}

                        <p>
                          <strong>Forma de pagamento</strong>
                        </p>

                        {values.nr_final_cartao ? (
                          <p>
                            <strong>Cartão de Crédito final</strong>
                          </p>
                        ) : (
                          <></>
                        )}
                      </Col>

                      <Col className="col-6" xl={4}>
                        <p className="text-right">
                          <strong>{values.idcaixa_recebimento}</strong>
                        </p>

                        <p className="text-right">
                          {values.paymentPix ? (
                            <strong>PIX</strong>
                          ) : (
                            <strong>Cartão de Credito</strong>
                          )}
                        </p>

                        <p className="text-right">
                          <strong>
                            {values.nr_autorizacao && values.nr_autorizacao}
                          </strong>
                        </p>
                        <p className="text-right">
                          <strong>{values.nr_final_cartao}</strong>
                        </p>
                      </Col>
                    </Row>
                  </BoxPag>
                  <BoxPag>
                    <Row className=" mb-2 m-0">
                      <Col className="col-6" xl={8}>
                        <p className="text-dark">
                          <strong>Valor Total</strong>
                        </p>
                      </Col>
                      <Col className="col-6" xl={4}>
                        <p className="text-dark text-right ">
                          <strong>{formatValue(values.vl_transacao)}</strong>
                        </p>
                      </Col>
                    </Row>
                  </BoxPag>
                </Col>
              </Row>
              <Row className="justify-content-center m-0">
                <Col className="col-12" xl={12}>
                  <p
                    style={{
                      color: '#3f56a5',
                      textAlign: 'center',
                      marginBottom: 0,
                    }}
                  >
                    Já foi enviado para seu e-mail! Baixar para ver agora.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center m-0">
                <Col md={12} xl={4}>
                  <Button
                    type="button"
                    title="Baixar Comprovante"
                    onClick={(ev) => handleOpenPdf()}
                    icon={<DrIconFont name="pdf-file" fontSize="28px" />}
                    titleColor="#3f56a5"
                    iconRight
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBlank>
      </Container>
      <Container className="mt-3">
        <Row className="col-xl-12 justify-content-center row-btn m-0">
          <Col md={12} xl={4}>
            <Button
              type="button"
              color="blue"
              href={
                attendance.fromDashboard ||
                (attendance.idagenda_consulta &&
                  (!attendance.iniciar_consulta ||
                    attendance.iniciar_consulta !== 'S')) ||
                attendance.agendarPaciente
                  ? `/dashboard`
                  : `/upload-page`
              }
              title={attendance.fromDashboard ? 'Concluir' : 'Prosseguir'}
              titleColor="#fff"
            />
          </Col>
          <Col md={12} xl={4}>
            <Button
              type="button"
              title="Voltar"
              color="dark-grey"
              onClick={() => history.push('/dashboard')}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ReceiptOfPayment;

import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/dr-header';
import HelmetComponent from '../../components/dr-helmet-component';
import Footer from '../../components/dr-footer';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';

import { CardGeral, PageSubtitle } from './styled';
import { BoxPictureImg } from '../../styles/box-picture_img';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import DrIcon from '../../components/dr-icon-font';
import DrImageProfile from '../../components/dr-image-profile';
import { scrollPositionTop } from '../../utils/bibli';
import DrCardLoading from '../../components/dr-card-loading';

const LatestTopics: React.FC = () => {
  const { user, sendError, requestAPI } = useAuth();
  const { addToast } = useToast();
  const [itemsTopics, setitemsTopics] = React.useState<any>([]);

  React.useEffect(() => { }, []);
  const history = useHistory();

  const handleLatestTopics = React.useCallback(async () => {
    try {
      const resp = await requestAPI({
        method: 'GET',
        url: '/forumUltimos',
      });
      if (resp) {
        setitemsTopics(resp);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: `Ocorreu um erro, tente novamente`,
        });
      }
    } catch (err) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Ocorreu um erro, tente novamente`,
      });
    }
  }, []);

  React.useEffect(() => {
    scrollPositionTop();
    handleLatestTopics();
  }, []);

  return (
    <>
      <HelmetComponent title="Fórum" />
      <Header />
      {!itemsTopics.registros ? (
        <DrCardLoading />
      ) : (
        <Container>
          <CardBlank className="mt-3 pt-3 pb-3">
            <Row>
              <Col>
                <DrIcon
                  className="ml-2"
                  name="arrow-left"
                  color="#0056b3"
                  onClick={() => history.goBack()}
                  cursor="pointer"
                  fontSize="22px"
                />
                <PageTitle>{itemsTopics?.titulo}</PageTitle>
              </Col>
            </Row>
            {itemsTopics &&
              itemsTopics.registros &&
              itemsTopics.registros.length &&
              itemsTopics.registros.map((items: any, index: number) => {
                const date = moment(items.dt_ultima).fromNow(true);

                return (
                  <Container className="mt-2" key={`#id_${index.toString()}`}>
                    <Link to={`/details-topics/${items.idforum}`}>
                      <CardGeral
                        className="pt-4 pl-4 pr-4 pb-0"
                        style={{
                          backgroundColor:
                            user?.idpessoa_fisica === items.idpessoa_fisica
                              ? '#fff3cd36'
                              : 'white',
                        }}
                      >
                        <Row>
                          <BoxPictureImg className="m-0" width="75px" height="75px">
                            <DrImageProfile
                              source={items.url_img}
                              width={75}
                              height={75}
                              style={{ borderRadius: '50%' }}
                              alt="foto de perfil"
                            />
                          </BoxPictureImg>

                          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                            <PageSubtitle
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                                maxWidth: '50rem',
                                fontSize: 18,
                                marginBottom: '5px',
                                color: '#e83e8c'
                              }}
                              className="pt-6"
                            >
                              {items.nm_pessoa}
                            </PageSubtitle>

                            <PageSubtitle
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '50rem',
                                fontWeight: 'bold',
                                fontSize: 20,
                              }}
                              className="pt-6"
                            >
                              {items.ds_titulo}
                            </PageSubtitle>
                          </div>
                        </Row>


                        <Row
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="pt-2  d-flex flex-row"
                        >
                          <Col md={3} sm={3} xs={3} className="pl-0">
                            {items.ds_especialidade ? (
                              <PageSubtitle
                                fontSize="16px"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '30rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                {items.ds_especialidade}
                              </PageSubtitle>
                            ) : (
                              <>&nbsp;</>
                            )}
                            <PageSubtitle color="#77777791" fontSize="16px">
                              {items.ds_tipo_duvida}
                            </PageSubtitle>
                          </Col>

                          <Col md={3} sm={3} xs={3}>
                            &nbsp;
                            <PageSubtitle color="#77777791" fontSize="16px">
                              {items.ds_categoria}
                            </PageSubtitle>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <PageSubtitle
                              color="#77777791"
                              fontSize="16px"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '150px',
                              }}
                            >
                              Respostas:
                            </PageSubtitle>
                            <PageSubtitle color="#77777791" fontSize="16px">
                              {items.qtde_resposta}
                            </PageSubtitle>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                            <PageSubtitle
                              color="#77777791"
                              fontSize="16px"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '200px',
                              }}
                            >
                              Atividade:
                            </PageSubtitle>

                            <PageSubtitle color="#77777791" fontSize="16px">
                              {date}
                            </PageSubtitle>
                          </Col>
                        </Row>
                      </CardGeral>
                    </Link>
                  </Container>
                );
              })}
          </CardBlank>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default LatestTopics;

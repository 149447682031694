import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import DrButton from '../dr-button';
import DrIconFont from '../dr-icon-font';
import {
  BoxFicha,
  BoxFichaTitle,
  BoxFichaHeader,
  BoxFichaBody,
  Li,
} from './styled';
import DrButtonLoading from '../dr-button-loading';
import DrModal from '../dr-modal';
import {
  BCAnchor,
  BCBubble,
  BCCardChat,
  BCCardMessages,
  BCDrIconFile,
  BCFile,
  BCFooter,
  BCPara,
  BCRowFooterMsg,
} from '../dr-box-chat/styled';

interface BoxFichaProps {
  arrayDocs: any[];
  title?: string;
  email: string;
  idAttendance: string;
  type: 'F' | 'E' | 'R' | 'A' | 'C' | 'P' | 'AP' | 'AM' | 'CHAT';
}

const DrBoxFicha: React.FC<BoxFichaProps> = ({
  arrayDocs,
  email,
  title,
  idAttendance,
  type,
}: BoxFichaProps) => {
  const { addToast } = useToast();
  const { requestAPI, sendError } = useAuth();
  const [idRel, setIdRel] = React.useState(0);
  const [modal, setModal] = useState({ showModal: false, obs: '' });
  const [modalChat, setModalChat] = useState({ showModalChat: false });
  const [boxFichaActive, setboxFichaActive] = useState(false);
  const [arrLoadingBtn, setArrLoadingBtn] = React.useState(() =>
    arrayDocs?.map(() => false)
  );
  function testUrl(data: any) {
    return (
      data.url_relatorio ||
      data.url_recibo ||
      data.url_contrato ||
      data.url_arquivo
    );
  }
  function handleDownload(url, ie_excluido) {
    if (ie_excluido && ie_excluido === 'S') {
      addToast({
        type: 'error',
        title: 'Ops!',
        description:
          'O arquivo não está mais disponível. Entre em contato para obter mais informações.',
      });
      return null;
    }
    return window.open(url);
  }

  async function handleSendEmail(i: string | number, url: string) {
    try {
      setArrLoadingBtn((prev) => {
        const prevBtn = [...prev];
        // eslint-disable-next-line no-param-reassign
        prevBtn[i] = true;
        return [...prevBtn];
      });
      const body = { dsEmail: email, idcontrato: idAttendance };
      await requestAPI({
        method: 'PUT',
        url,
        body,
      });
      setIdRel(0);

      addToast({
        type: 'success',
        title: 'E-mail enviado com sucesso!',
        description: `Documentos enviados para ${email}`,
      });
    } catch (error: any) {
      sendError(error);
      setIdRel(0);

      addToast({
        type: 'error',
        title: 'Ops!',
        description: `${error.message}`,
      });
    } finally {
      setArrLoadingBtn((prev) => {
        const prevBtn = [...prev];
        // eslint-disable-next-line no-param-reassign
        prevBtn[i] = false;
        return [...prevBtn];
      });
    }
  }
  async function handleUrlEmail(i, atendimento) {
    let url;
    if (atendimento.ie_excluido && atendimento.ie_excluido === 'S') {
      addToast({
        type: 'error',
        title: 'Ops!',
        description:
          'O arquivo não está mais disponível. Entre em contato para obter mais informações.',
      });
      return;
    }
    switch (type) {
      case 'R':
        url = `/atendimentoMedReceitaEmail?idAtendimento=${idAttendance}&idReceita=${atendimento.idatendimento_med_receita}`;
        break;
      case 'F':
        url = `/fichaAvaliacaoGeradaEmail?idAtendimento=${idAttendance}&idAvaliacao=${atendimento.idficha_avaliacao_gerada}`;
        break;
      case 'E':
        url = `/atendimentoPedidoExameExterEmail?idAtendimento=${idAttendance}&idPedido=${atendimento.idatendimento_pedido_exame}`;
        break;
      case 'A':
        url = `/atestadoPacienteEmail?idAtendimento=${idAttendance}&idAtestado=${atendimento.idatestado_paciente}`;
        break;
      case 'C':
        url = `/atendimentoReciboDoPg?idcaixa_recebimento=${atendimento.idcaixa_recebimento}`;
        break;
      case 'P':
        url = '/contratoClienteEnviarPorEmail';
        break;
      default:
        url = null;
    }

    await handleSendEmail(i, url);
  }

  function handleButtons(data, sendEmail) {
    return (
      <>
        <Col
          md={12}
          xl={data.title === 'CONTRATO' ? 3 : 6}
          className="d-flex  align-items-center"
        >
          <BoxFichaTitle>{data.title}</BoxFichaTitle>
        </Col>
        <Col
          xl={data.title === 'CONTRATO' ? 9 : 6}
          className="d-flex  justify-content-end"
        >
          {sendEmail ? (
            <DrButtonLoading
              title="Enviar por e-mail"
              loading={arrLoadingBtn[data.i]}
              titleColor="#3f56a5"
              drColor="#ed1164"
              maisColor="#fcaf17"
              color={data.btnColor}
              disabled={!testUrl(data.atd)}
              type="button"
              onClick={() => handleUrlEmail(data.i, data.atd)}
              icon={<DrIconFont name="email" fontSize="28px" />}
              iconRight
            />
          ) : (
            <></>
          )}
          <DrButton
            className="ml-2 baixar"
            title="Baixar"
            titleColor="#3f56a5"
            color={data.btnColor}
            disabled={!testUrl(data.atd)}
            type="button"
            onClick={() =>
              handleDownload(testUrl(data.atd), data.atd.ie_excluido)
            }
            icon={<DrIconFont name="pdf-file" fontSize="28px" />}
            iconRight
          />
        </Col>
      </>
    );
  }

  const isMyMessage = (data) => {
    if (data.idpessoa_participante === data.idpessoa_fisica) {
      return true;
    }
    return false;
  };

  function renderSentTime(data) {
    return (
      <Col xs="auto">
        <BCFooter other={!isMyMessage(data)}>
          {data && data.message && data.message.timestampSend
            ? moment(data.message.timestampSend).format('DD/MM/YYYY HH:mm')
            : moment(data.dt_insert, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm'
              )}
        </BCFooter>
      </Col>
    );
  }

  function copyMessage(text) {
    navigator.clipboard.writeText(text);
    addToast({
      type: 'success',
      title: 'Mensagem copiada com sucesso!',
    });
  }

  function verifyMessage(message) {
    const arrayMessage: any = [];
    if (message && message.length) {
      let link;
      let start = message.indexOf('http');
      let end = -1;
      let renderLink = false;
      while (start !== -1) {
        renderLink = true;
        if (start > 0) {
          arrayMessage.push({
            type: 'text',
            value: message.substring(end > 0 ? end : 0, start),
          });
        }
        end = start !== -1 ? message.indexOf(' ', start) : -1;
        if (end < 0) {
          end = message.length;
        }
        if (end > 0) {
          link = message.substring(start, end !== -1 ? end : message.length);
          arrayMessage.push({
            type: 'link',
            value: link,
          });

          start = message.indexOf('http', end);
          if (start < 0) {
            const textEnd = message.substring(end, message.length);
            if (textEnd.length) {
              arrayMessage.push({
                type: 'text',
                value: message.substring(end, message.length),
              });
            }
          }
        } else {
          start = -1;
        }
      }
      if (!renderLink) {
        arrayMessage.push({
          type: 'text',
          value: message,
        });
      }
    }
    return arrayMessage;
  }

  function renderBodyMessage(data) {
    let ext;
    if (data.ds_arquivo && data.ds_arquivo.length)
      [, ext] = data.ds_arquivo.split('.');

    const message =
      data.message && data.message.data ? data.message.data : data.ds_mensagem;

    const arrayMessage = verifyMessage(message);

    return (data.type && data.type === 'text') ||
      (data.ds_mensagem && data.ds_mensagem !== null) ? (
      <>
        <>
          {arrayMessage.map((item) =>
            item.type === 'text' ? (
              <BCPara other={!isMyMessage(data)}>{item.value}</BCPara>
            ) : (
              <BCAnchor
                target="_blank"
                href={item.value}
                other={!isMyMessage(data)}
              >
                {item.value}
              </BCAnchor>
            )
          )}
        </>
        <BCRowFooterMsg style={{ justifyContent: 'flex-end' }}>
          {renderSentTime(data)}
          <DrIconFont
            name="copy"
            fontSize="24px"
            cursor="pointer"
            title="Copiar mensagem"
            color={!isMyMessage(data) ? '#777' : '#fff'}
            onClick={() => {
              copyMessage(message);
            }}
          />
        </BCRowFooterMsg>
      </>
    ) : (
      <BCFile
        other={!isMyMessage(data)}
        onClick={() => {
          handleDownload(data.ds_arquivo, data.ie_excluido);
        }}
      >
        <div
          className={`d-flex align-items-center ${
            data.boolImage ? 'flex-column' : 'flex-row'
          }`}
        >
          {data.boolImage ? (
            <img
              src={data.message.data}
              className="message"
              style={{
                width: 200,
                cursor: 'pointer',
              }}
              alt={data.message.data}
            />
          ) : (
            <BCDrIconFile />
          )}
          .{ext}
        </div>
        <BCRowFooterMsg style={{ justifyContent: 'space-between' }}>
          <Col xs="auto">
            <BCFooter other={!isMyMessage(data)}>Baixar</BCFooter>
          </Col>
          {renderSentTime(data)}
        </BCRowFooterMsg>
      </BCFile>
    );
  }
  const renderMessages = () => (
    <>
      {arrayDocs.map((data, i) => (
        <BCBubble
          other={!isMyMessage(data)}
          className={i > 0 ? 'mt-3' : undefined}
        >
          {renderBodyMessage(data)}
        </BCBubble>
      ))}
    </>
  );

  function chatButton() {
    return (
      <>
        <DrModal
          controllerModal={[modalChat.showModalChat, setModalChat]}
          title="Chat"
          buttons={{
            cancel: {
              onClick: () =>
                setModalChat((prev) => ({ ...prev, showModalChat: false })),
              title: 'OK',
            },
          }}
          content={
            <div style={{ height: '30rem' }}>
              <BCCardChat>
                <BCCardMessages>{renderMessages()}</BCCardMessages>
              </BCCardChat>
            </div>
          }
        />
        <Col md={12} xl={6} className="d-flex  align-items-center">
          <BoxFichaTitle>Chat</BoxFichaTitle>
        </Col>
        <Col xl={6} className="d-flex  justify-content-end">
          <DrButton
            className="ml-2"
            title="Ver Chat"
            titleColor="#3f56a5"
            type="button"
            onClick={() =>
              setModalChat((prev) => ({ ...prev, showModalChat: true }))
            }
            iconRight
          />
        </Col>
      </>
    );
  }

  function titleReg(reg?: any) {
    if (title) return title;

    let result = '';
    switch (type) {
      case 'P':
        if (reg.plural) result = `CONTRATOS`;
        else result = `CONTRATO`;
        break;
      case 'F':
        if (reg.plural) result = `FICHAS CLÍNICAS`;
        else result = `FICHA CLÍNICA`;
        break;
      case 'E':
        if (reg.plural) result = `EXAMES`;
        else {
          result = `EXAME DE ${
            reg && reg.ds_tipo_procedimento
              ? reg.ds_tipo_procedimento.toUpperCase()
              : ''
          }`;
        }
        break;
      case 'R':
        if (reg.plural) result = `RECEITAS`;
        else {
          result = `RECEITA ${
            reg && reg.ds_tipo_receita ? reg.ds_tipo_receita.toUpperCase() : ''
          }`;
        }
        break;
      case 'A':
        if (reg.plural) result = `ATESTADOS / RELATÓRIOS`;
        else
          result = `${
            reg && reg.ds_titulo
              ? reg.ds_titulo.toUpperCase()
              : 'ATESTADO / RELATÓRIO'
          }`;
        break;
      case 'C':
        if (reg.plural) result = `RECIBOS DE PAGAMENTO`;
        else result = `RECIBO DE PAGAMENTO`;
        break;
      case 'AP':
        if (reg.idatendimento_paciente_anexo && reg.nm_arquivo)
          result = reg.nm_arquivo;
        else result = `ANEXOS ENVIADOS PELO PACIENTE`;
        break;
      case 'AM':
        if (reg.idatendimento_paciente_anexo && reg.nm_arquivo)
          result = reg.nm_arquivo;
        else result = `ANEXOS ENVIADOS PELO MÉDICO`;
        break;
      case 'CHAT':
        result = 'ARQUIVOS ENVIADOS PELO CHAT';
        break;
      default:
        if (reg.plural) result = `DOCUMENTOS`;
        else result = `DOCUMENTO`;
    }

    return result;
  }

  function handleRenderHtml(atendimento) {
    let boolTestUrl = false;

    if (atendimento && atendimento.length) {
      atendimento.every((atd) => {
        if (testUrl(atd)) {
          boolTestUrl = true;
          return false;
        }
        return true;
      });
    }

    if (
      atendimento &&
      atendimento.length > 1 &&
      !!boolTestUrl &&
      type !== 'AP' &&
      type !== 'AM' &&
      type !== 'CHAT'
    ) {
      let count = 0;
      return (
        <BoxFicha className="mt-4" active={boxFichaActive}>
          <BoxFichaHeader>
            <Row>
              <Col md={12} xl={6} className="d-flex  align-items-center">
                <BoxFichaTitle>{titleReg({ plural: true })}</BoxFichaTitle>
              </Col>
              <Col xl={6} className="d-flex  justify-content-end">
                <DrButton
                  title="Ver todos"
                  color="blue"
                  type="button"
                  icon={
                    <DrIconFont
                      name={
                        boxFichaActive
                          ? 'circle-arrow-down'
                          : 'circle-arrow-right'
                      }
                      fontSize="28px"
                    />
                  }
                  iconRight
                  data-toggle="collapse"
                  data-target={`#${type}`}
                  onClick={() => {
                    setboxFichaActive((prev) => !prev);
                  }}
                />
              </Col>
            </Row>
          </BoxFichaHeader>
          {atendimento.map((atd, index) => {
            if (testUrl(atd)) {
              count += 1;
              const data = {
                title: titleReg(atd),
                atd,
                btnColor: 'white',
                i: index,
              };
              return (
                <BoxFichaBody id={type} className="collapse">
                  <Li className="row">
                    {handleButtons(data, !atd.idatendimento_paciente_anexo)}
                  </Li>
                </BoxFichaBody>
              );
            }

            return <></>;
          })}
        </BoxFicha>
      );
    }

    if (
      atendimento.length === 1 &&
      testUrl(atendimento[0]) &&
      type !== 'AP' &&
      type !== 'AM' &&
      type !== 'CHAT'
    ) {
      const data = {
        title: titleReg(atendimento[0]),
        atd: atendimento[0],
        i: 0,
      };
      return (
        <BoxFicha
          className={data.title === 'CONTRATO' ? 'mt-3 mb-3' : 'mt-4'}
          style={{ padding: data.title === 'CONTRATO' ? '5px' : '20px' }}
        >
          <BoxFichaHeader>
            <Row>
              {handleButtons(data, !atendimento.idatendimento_paciente_anexo)}
            </Row>
          </BoxFichaHeader>
        </BoxFicha>
      );
    }
    if (atendimento && atendimento.length && (type === 'AP' || type === 'AM')) {
      return (
        <BoxFicha className="mt-4" active={boxFichaActive}>
          <BoxFichaHeader>
            <Row>
              <Col md={12} xl={6} className="d-flex  align-items-center">
                <BoxFichaTitle>{titleReg({ plural: true })}</BoxFichaTitle>
              </Col>
              <Col xl={6} className="d-flex  justify-content-end">
                <DrButton
                  title="Ver todos"
                  color="blue"
                  type="button"
                  icon={
                    <DrIconFont
                      name={
                        boxFichaActive
                          ? 'circle-arrow-down'
                          : 'circle-arrow-right'
                      }
                      fontSize="28px"
                    />
                  }
                  iconRight
                  data-toggle="collapse"
                  data-target={`#${type}`}
                  onClick={() => {
                    setboxFichaActive((prev) => !prev);
                  }}
                />
              </Col>
            </Row>
          </BoxFichaHeader>
          {atendimento.map((atd, index) => {
            if (testUrl(atd)) {
              const data = {
                title: titleReg(atd),
                atd,
                btnColor: 'white',
                i: index,
              };

              return (
                <BoxFichaBody id={type} className="collapse">
                  <Li className="row">
                    {handleButtons(data, !atd.idatendimento_paciente_anexo)}
                  </Li>
                </BoxFichaBody>
              );
            }
            if (
              atd.ie_tipo_anexo_atendimento === '6' &&
              atd.ds_observacao !== ''
            ) {
              const { ds_observacao } = atd;
              return (
                <>
                  <DrModal
                    controllerModal={[modal.showModal, setModal]}
                    title="Observação"
                    buttons={{
                      cancel: {
                        onClick: () =>
                          setModal(() => ({ showModal: false, obs: '' })),
                        title: 'OK',
                      },
                    }}
                    content={<h4>{modal.obs}</h4>}
                  />

                  <BoxFichaBody id={type} className="collapse">
                    <Li className="row">
                      <Col
                        md={12}
                        xl={6}
                        className="d-flex  align-items-center"
                      >
                        <BoxFichaTitle>Observação informada</BoxFichaTitle>
                      </Col>
                      <Col xl={6} className="d-flex  justify-content-end">
                        <DrButton
                          className="ml-2"
                          title="Abrir"
                          titleColor="#3f56a5"
                          type="button"
                          onClick={() =>
                            setModal(() => ({
                              showModal: true,
                              obs: ds_observacao,
                            }))
                          }
                          iconRight
                        />
                      </Col>
                    </Li>
                  </BoxFichaBody>
                </>
              );
            }
            return <></>;
          })}
        </BoxFicha>
      );
    }
    if (atendimento.length && type === 'CHAT') {
      return (
        <BoxFicha className="mt-4" style={{ padding: '20px' }}>
          <Row>{chatButton()}</Row>
        </BoxFicha>
      );
    }
    return <></>;
  }

  return <>{handleRenderHtml(arrayDocs)}</>;
};

export default DrBoxFicha;

import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';

import { useToast } from '../../hooks/toast';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { CardGeral } from '../../styles/card-geral';
import { CardGeralBody } from '../../styles/card-geral_body';
import { Strong, Ul } from './styled';
import Button from '../../components/dr-button';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import { CardGeralBtn } from '../../styles/card-geral_btn';
import Loading from '../../components/dr-card-loading';
import DrAlert from '../../components/dr-alert';
import { useRequestData } from '../../hooks/requestData';
import DrButtonLoading from '../../components/dr-button-loading';

function showProtocols(protocols) {
  return protocols.map((protocolo, i) => (
    <Col md={12} xl={4} className="mb-4" key={protocolo.cd_protocolo}>
      <CardGeral>
        <CardGeralBody>
          <Ul>
            <li>
              <Strong>Código: </Strong>
              {protocolo.cd_protocolo}
            </li>
            <li>
              <Strong>Dt. solicitação: </Strong>
              {protocolo.data_protocolo}
            </li>
            <li>
              <Strong>Status: </Strong>
              {protocolo.ds_estagio}
            </li>
          </Ul>
        </CardGeralBody>
        <CardGeralBtn>
          <Button
            type="button"
            title="Acessar"
            href={`/new-protocol/${protocolo.idatendimento_protocolo_sol_doc}`}
            titleColor="#3f56a5"
          />
        </CardGeralBtn>
      </CardGeral>
    </Col>
  ));
}

const RequestProtocol: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingPagination, setLoadingPagination] = useState(true);
  const { letApp, requestAPI, sendError } = useAuth();
  const { addToast } = useToast();
  const countRef = useRef(0);
  const [protocols, setProtocols] = useState<any>([]);
  const [protocolsEnd, setProtocolsEnd] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useRequestData({
    countRef,
    url: `/atendimentoProtocoloSolDoc`,
    setState: setProtocols,
    requestAPI,
    itensPerPage: 15,
    idField: 'idatendimento_protocolo_sol_doc',
    addToast,
    setLoading,
    isLoading,
    setDataEnded: setProtocolsEnd,
    cbAfterGetData: (arrayData) => {
      letApp.myProtocols = arrayData;
      letApp.protocolReqApi = false;
    },
    titleError: 'Por favor, Tente novamente.',
    paginate: { setLoading: setLoadingPagination },
    elementRef: componentRef,
    sendError,
  });

  let elementProtocols;
  if (protocols?.length) {
    elementProtocols = showProtocols(protocols);
  } else {
    elementProtocols = (
      <Col className="m-0">
        <DrAlert
          warning
          title={{
            tag: 'h4',
            text: '',
          }}
        >
          <p>Você não possui soliticações cadastradas!</p>
        </DrAlert>
      </Col>
    );
  }
  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : (
        <Container className="mt-3">
          <Row className="justify-content-center row-btn">
            <Col xl={4}>
              <Button
                type="button"
                color="blue"
                href="/new-protocol"
                title="Solicitar"
              />
            </Col>
            <Col xl={4}>
              <Button
                type="button"
                color="dark-grey"
                href="/profile"
                title="Cancelar"
              />
            </Col>
          </Row>
          <CardBlank className="pt-3 mt-3">
            <Container>
              <PageTitle>Minhas solicitações</PageTitle>
              <Row className="pt-1">{elementProtocols}</Row>
              {!protocolsEnd && elementProtocols.length && (
                <Row className="justify-content-center pb-2">
                  <Col xl={5}>
                    <div ref={componentRef}>
                      <DrButtonLoading
                        type="button"
                        title="Carregando atendimentos..."
                        color="blue"
                        loading={isLoadingPagination}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </CardBlank>
        </Container>
      )}

      <Footer />
    </>
  );
};
export default RequestProtocol;

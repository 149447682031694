import styled, { css } from 'styled-components';
import Tooltip from '../dr-tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}
export const Label = styled.div`
  h4 {
    width: 100%;
    position: absolute;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    color: #777;
    top: -25px;
    left: 0;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 {
    position: absolute;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    color: #777;
    top: -25px;
    left -10px;
    z-index: 2;
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  border: 2px solid #e5e5e5;
  border-radius: 3px;
  padding-right: 10px;
  margin: 20px 0px;
  min-height: 30px;
  width: 100%;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${(props) => props.isDisabled && '#e9ecef'};

  // input {
  //   border-radius: 3px;
  //   border: 2px solid #e5e5e5;
  //   width: 100%;
  //   font-size: 18px;
  //   color: #777 !important;
  //   opacity: 1;
  //   min-height: 54px;
  //   vertical-align: middle;
  //   padding: 0;
  // }

  .form-control {
    border: 0;
    border-radius: 0;
    padding: 5px;
    font-weight: 500;
    margin-left: ${(props) => (props.isDisabled ? '0px' : '5px')};
    :focus {
      color: #777 !important;
    }
  }
  .toltip {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 30px;
    height: 30px;
    z-index: 9999;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c00;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      // color: #00bbd6;
      // border-color: #00bbd6;

      // svg {
      //   color: #00bbd6;
      // }
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #00bbd6;
    `}
`;

export const Input = styled.input.attrs((props) => ({}))`
  border-radius: 3px;
  border: 2px solid #e5e5e5;
  width: 100%;
  font-size: 18px;
  color: #777 !important;
  opacity: 1;
  min-height: 54px;
  vertical-align: middle;
  padding: 0;
  ::placeholder {
    color: #000 !important;
  }
  :disabled {
    color: #777 !important;
  }
`;
interface ErrorProps {
  isDisabled: boolean;
}
export const Error = styled(Tooltip)<ErrorProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 9999;

  svg {
    margin-left: 5px;
    width: 30px;
    height: 30px;
  }
`;

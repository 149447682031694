import React from 'react';
import { useHistory } from 'react-router-dom';
import * as styled from './styled';
import Button from '../dr-button';

interface CardAttendanceProps {
  urlLink?: string | false;
  body?: React.ReactNode;
  returnButton?: React.ReactNode;
  state?: any;
}

const CardAttendances: React.FC<CardAttendanceProps> = (
  props: CardAttendanceProps
) => {
  const { urlLink, body, state, returnButton } = props;
  const history = useHistory();

  const object = (
    <>
      {body && (
        <>
          <styled.CardGeralBody
            className="flex-grow-1"
            onClick={() => {
              history.push({
                pathname: `${urlLink}`,
                state: `${state}`,
              });
            }}
          >
            {body}
          </styled.CardGeralBody>
          <styled.CardGeralBtn>{returnButton}</styled.CardGeralBtn>
        </>
      )}
    </>
  );

  return (
    <styled.CardGeral>
      {object}
      <styled.CardGeralBtn>
        <Button
          type="button"
          title="Acessar"
          href={`${urlLink}`}
          titleColor="#3f56a5"
        />
      </styled.CardGeralBtn>
    </styled.CardGeral>
  );
};

CardAttendances.defaultProps = {
  urlLink: undefined,
  body: undefined,
  state: undefined,
};

export default CardAttendances;

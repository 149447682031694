import React from 'react';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import UploadCard from './card-upload';

const UploadPage: React.FC = () => (
  <>
    <Header />
    <UploadCard />
    <Footer />
  </>
);

export default UploadPage;

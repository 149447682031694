import React, { useState } from 'react';
import { Col, Container, Image, Modal } from 'react-bootstrap';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { handleWhatsAppMessage, verifyErrorYup } from '../../utils/bibli';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import DrInput from '../dr-input';
import DrInputTextArea from '../dr-input-text-area';
import onlyNumbers from '../../utils/formatNumbers';
import DrButtonLoading from '../dr-button-loading';
import whatsappLogo from '../../assets/svg/whatsapp-logo.svg';

import { isValidPhone } from '../../utils/validations';

import { ButtonClose } from './styles';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
}

interface DataProps {
  telephone: string;
  message: string;
}

const DrModalWhatsapp = ({ showModal, closeModal }: ModalProps) => {
  const { requestAPI, applicationData, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = React.useRef<FormHandles>(null);

  async function handleWhatsappChatSubmit(data: DataProps) {
    try {
      const { telephone, message } = data;

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        telephone: Yup.string()
          .trim()
          .test({
            message: 'Número informado é inválido',
            test: (value) => isValidPhone(value),
          })
          .required('O número do DDD + celular é obrigatório'),
        // .min(8, 'Número inválido')
        // .max(9, 'Número inválido')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const messageFormated = `Oi, estou na {plataforma} e gostaria de mais informações. ${message
        .replace(/(\r\n)+/gim, '%20')
        .replace(/(\s)+/gim, '%20')}`;

      const linkWhatsapp = handleWhatsAppMessage(
        applicationData.valores_variaveis?.link_whatsapp,
        messageFormated,
        true
      );

      const extra = `Mensagem: ${message} URL: ${window.location.href}`;
      const postData = {
        telefone: onlyNumbers(telephone),
        extra: extra.substring(0, 1000),
        origem: 'Dr+',
        tipo_chat: process.env.REACT_APP_IDAPLICACAO,
      };

      setLoading(true);
      await requestAPI({
        method: 'POST',
        url: `/contatoChatImp`,
        body: postData,
      });
      setLoading(false);

      closeModal();

      window.open(linkWhatsapp);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        verifyErrorYup(err, formRef);
        err.inner.forEach((error: any) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    }
  }

  return (
    <Modal size="lg" centered show={showModal} onHide={closeModal}>
      <CardBlank className="pt-3">
        <Modal.Header>
          <Container>
            <Col className="p-0">
              <PageTitle style={{ color: '#79D484' }}>
                <Image
                  src={whatsappLogo}
                  style={{ height: 45 }}
                  alt="Logo Whatsapp"
                />
              </PageTitle>
            </Col>
          </Container>
          <ButtonClose onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          {/* eslint-disable-next-line react/jsx-no-bind  */}
          <Form ref={formRef} onSubmit={handleWhatsappChatSubmit}>
            <Col className="p-1">
              <DrInput
                icon
                name="telephone"
                label="N.° Celular ou telefone fixo"
                customType="phoneNumber"
                inputMode="numeric"
                validate
              />
            </Col>
            <Col className="p-1">
              <DrInputTextArea
                name="message"
                label="Mensagem"
                maxLength={950}
                placeholder="Digite aqui..."
              />
            </Col>
            <Col className="p-1">
              <DrButtonLoading
                loading={loading}
                type="submit"
                title="Iniciar Conversa"
                style={{ background: '#33BFA5' }}
              />
            </Col>
          </Form>
        </Modal.Body>
      </CardBlank>
    </Modal>
  );
};

export default DrModalWhatsapp;

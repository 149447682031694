import React from 'react';
import { Modal } from 'react-bootstrap';

import Loading from '../dr-loading';

interface ModalLoadingProps {
  showModal: boolean;
  modalContent?: React.ReactNode;
  text?: string;
}

const DrModalLoading: React.FC<ModalLoadingProps> = ({
  showModal,
}: ModalLoadingProps) => (
  <Modal show={showModal} fullscreen>
    <Loading ratio={1.5} />
  </Modal>
);

export default DrModalLoading;

import React, { useEffect } from 'react';

import { Col, Row, Container } from 'react-bootstrap';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import EvaluationComponent from '../../../components/EvaluationComponent';
import { useAuth } from '../../../hooks/auth';
import { CardBlank } from '../../../styles/card-blank';
import { PageSubtitle, PageTitle } from '../../../styles/page-title';
import { scrollPositionTop } from '../../../utils/bibli';
import { SpanLabel, ContainerAttendance } from './styled';

interface AttendanceDatas {
  idatendimento_paciente: number;
  idatend_original: number;
  atendimento: string;
  data_atend_medico: string;
  ds_especialidade: string;
  idclassificacao: number;
  idespecialidade: number;
  idestabelecimento: number;
  idmedico_atendimento: number;
  idparametro: number;
  idpessoa_fisica: number;
  ie_classificacao_agenda: string;
  ie_telemedicina: string;
  ie_tipo_agenda: number;
  ie_tipo_atendimento: number;
  modalidade: number;
  nm_pessoa: string;
  nr_conselho: number;
  nr_dias_restante_ret: number;
  nr_prazo_retorno: number;
  sg_conselho: string;
  uf_conselho: string;
  ie_finalizado: string;
  nm_paciente: string;
  avaliacao_atendimento: {
    ds_opiniao: string;
    idatendimento_paciente_avaliacao: number;
    nr_estrela: number;
  };
}
const ScreenAttendanceEnd: React.FC = () => {
  const { user, attendance } = useAuth();
  const location = useLocation<AttendanceDatas>();
  const history = useHistory();
  useEffect(() => {
    scrollPositionTop();
    const goToDashboard = () => {
      history.listen(() => {
        if (history.action === 'POP') {
          history.push('/dashboard');
        }
      });
    };

    goToDashboard();
  }, []);
  const { avaliacao_atendimento } = location.state;

  return (
    <>
      {location && location.state ? (
        <Container className="pt-3">
          <ContainerAttendance>
            <Row className="justify-content-center">
              <Col xl={12}>
                <CardBlank>
                  <PageTitle className="pt-3">
                    {location.state.idatend_original
                      ? 'Retorno concluído!'
                      : 'Consulta concluída!'}
                  </PageTitle>
                  <Row className="justify-content-center m-auto">
                    <Col xl={location.state.idatend_original ? 6 : 4}>
                      <PageSubtitle>
                        <SpanLabel>Paciente: </SpanLabel>
                        {location.state.nm_paciente}
                      </PageSubtitle>
                      <PageSubtitle>
                        <SpanLabel>Atendimento: </SpanLabel>{' '}
                        {location.state.idatendimento_paciente}
                      </PageSubtitle>
                      <PageSubtitle>
                        <SpanLabel>Data e hora: </SpanLabel>{' '}
                        {location.state.data_atend_medico}
                      </PageSubtitle>
                    </Col>
                    <Col
                      xl={location.state.idatend_original ? 6 : 4}
                      className="pb-3"
                    >
                      <PageSubtitle>
                        <SpanLabel>Dr(a): </SpanLabel>
                        {location.state.nm_pessoa}
                      </PageSubtitle>

                      <PageSubtitle>
                        <SpanLabel>CRM: </SpanLabel>
                        {location.state.nr_conselho}
                      </PageSubtitle>
                      <PageSubtitle>
                        <SpanLabel>Especialidade: </SpanLabel>{' '}
                        {location.state.ds_especialidade}
                      </PageSubtitle>
                    </Col>
                    {location.state.nr_dias_restante_ret >= 0 && (
                      <Col xl={location.state.idatend_original ? 6 : 4}>
                        <PageSubtitle>
                          <SpanLabel>Prazo de retorno: </SpanLabel>
                          {location.state.nr_dias_restante_ret} dias
                        </PageSubtitle>
                      </Col>
                    )}
                  </Row>
                </CardBlank>
              </Col>
            </Row>
            <Row className="justify-content-center ">
              <Col xl={6} className="mt-4">
                <CardBlank
                  className="p-3 d-flex flex-column justify-content-center"
                  style={{ height: '100%' }}
                >
                  <PageSubtitle textCenter>
                    {location.state.ie_finalizado === 'N'
                      ? 'Logo o médico irá enviar os documentos emitidos no e-mail:'
                      : 'Os documentos emitidos durante a consulta foram enviados para o e-mail:'}
                  </PageSubtitle>
                  <PageSubtitle textCenter color="#ed144b">
                    {user.ds_email}
                  </PageSubtitle>

                  <PageSubtitle textCenter>
                    {location.state.ie_finalizado === 'N'
                      ? 'Também estarão disponíveis no histórico de atendimentos.'
                      : 'Também estão disponíveis no histórico de atendimentos.'}
                  </PageSubtitle>

                  {location.state?.nr_dias_restante_ret >= 0 && (
                    <PageSubtitle textCenter>
                      {`O retorno desta consulta é de ${location.state?.nr_dias_restante_ret} dias!`}
                    </PageSubtitle>
                  )}
                </CardBlank>
              </Col>
              <Col xl={6}>
                <EvaluationComponent
                  idatendimento_paciente={
                    location.state?.idatendimento_paciente
                  }
                  evaluationDatas={
                    avaliacao_atendimento &&
                    Object.keys(avaliacao_atendimento).length
                      ? avaliacao_atendimento
                      : undefined
                  }
                />
              </Col>
            </Row>
          </ContainerAttendance>
        </Container>
      ) : (
        <CardBlank className="container pt-3 pb-3">
          <PageTitle>
            Desculpe! Não foram encontrados dados do atendimento
          </PageTitle>
        </CardBlank>
      )}
      <Row className=" ml-0 mr-0">
        <DrButtonsFooter
          buttonLeft={
            location.state.ie_finalizado !== 'N'
              ? {
                  title: 'Dados deste atendimento',

                  onClick: () => {
                    history.push({
                      pathname: `/attendance-data/${location.state.idatendimento_paciente}`,
                      state: { id: 'fromAttendanceEnd' },
                    });
                  },
                }
              : undefined
          }
          buttonRight={{ href: '/dashboard', title: 'Finalizar' }}
        />
      </Row>
    </>
  );
};

export default ScreenAttendanceEnd;

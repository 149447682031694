import React, { useRef, useEffect, InputHTMLAttributes } from 'react';

import { useField } from '@unform/core';
import { Col } from 'react-bootstrap';

import DrIconFont from '../dr-icon-font';
import DrButton from '../dr-button';

interface DrInputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleSubmit: any;
}

const DrInputFile: React.FC<DrInputFileProps> = (Props: DrInputFileProps) => {
  const { name, handleSubmit } = Props;
  const inputFile = useRef<any>(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputFile.current,
      path: 'files',
    });
  }, [fieldName, registerField]);

  return (
    <Col md={12} lg={3}>
      <DrButton
        className="input-anexo"
        type="button"
        onClick={() => {
          inputFile.current?.click();
        }}
        title="Novo Arquivo"
        titleColor="#3f56a5"
        icon={
          <DrIconFont
            name="file"
            color="#3f56a5"
            fontSize="24px"
            cursor="pointer"
          />
        }
      />
      <input
        className="form-control"
        ref={inputFile}
        name={name}
        type="file"
        hidden
        onChange={(e) => handleSubmit(e, name)}
      />
    </Col>
  );
};
export default DrInputFile;

import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import moment from 'moment';
import Header from '../../components/dr-header';
import HelmetComponent from '../../components/dr-helmet-component';
import Footer from '../../components/dr-footer';

import Input from '../../components/dr-input';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle, PageSubtitle } from '../../styles/page-title';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import DrCardLoading from '../../components/dr-card-loading';
import { InfoRow } from './styled';

import DrIconFont from '../../components/dr-icon-font';
import DrButtonsFooter from '../../components/dr-buttons-footer';
import getValidationErrors from '../../utils/getValidationErrors';
import DrImageProfile from '../../components/dr-image-profile';
import { valueParam } from '../../utils/bibli';
import DrCardForum from '../../components/dr-card-forum';
import { BoxPictureForumTitle } from '../../components/dr-card-forum/styled';

import DrInputTextArea from '../../components/dr-input-text-area';
import DrModal from '../../components/dr-modal';

interface InteracoesType {
  ds_administracao?: string;
  ds_especialidade?: string;
  ds_mensagem?: string;
  idforum?: number;
  idforum_curtida?: number;
  idforum_resposta?: number;
  idpessoa_fisica?: number;
  idprofissional?: number;
  idusuario_resposta?: number;
  ie_resolvido?: string;
  ie_topico_privado?: string;
  nm_pessoa?: string;
  nr_conselho?: number;
  qtde_curtida?: number;
  dt_insert?: string;
  sg_conselho?: string;
  uf_conselho?: string;
  url_img?: string;
}

interface DetailsTopicsType {
  ds_titulo?: string;
  ds_especialidade?: string;
  dt_ultima?: string;
  ds_categoria?: string;
  ds_tipo_duvida?: string;
  qtde_curtida?: number;
  qtde_resposta?: number;
  qtde_user?: number;
  idforum?: number;
  idpessoa_fisica?: number;
  interacoes?: Array<InteracoesType>;
  msg_topico_fechado_modal?: string;
  msg_topico_fechado?: string;
  msg_topico_fechado_resolvido?: string;
  ds_btn_responder?: string;
  ds_btn_resolvido?: string;
  dt_fechamento?: string;
}

interface IdRoute {
  id: string;
}

const HandleRenderImage = (Props) => {
  const { person, image } = Props;
  const {
    idusuario_resposta,
    nm_pessoa,
    ds_administracao,
    idprofissional,
    sg_conselho,
    nr_conselho,
    uf_conselho,
    ds_especialidade_profissional,
  } = person;

  return (
    <Row className="m-0 mb-3">
      <DrImageProfile
        source={image}
        alt="foto de perfil"
        width={80}
        height={80}
        style={{ borderRadius: '50%' }}
      />

      <div style={{ marginLeft: 5 }}>
        {idusuario_resposta && (
          <>
            <Row className="ml-1">
              <DrIconFont
                name="bookmarks"
                fontSize="24px"
                color="#00bbd6"
                className=" mt-3"
              />
              <BoxPictureForumTitle className="mt-3 mb-0">
                {nm_pessoa}
              </BoxPictureForumTitle>
            </Row>
            <PageSubtitle className="mb-0 mt-0">
              {ds_administracao}
            </PageSubtitle>
          </>
        )}

        {idprofissional && (
          <>
            <Row className="ml-1">
              <DrIconFont name="health" fontSize="24px" color="#00bbd6" />
              <BoxPictureForumTitle className="mb-0 mt-0 pb-0">
                {nm_pessoa}
              </BoxPictureForumTitle>
            </Row>

            <PageSubtitle className="mb-0 mt-0 pb-0">
              {sg_conselho}: {nr_conselho}({uf_conselho})
            </PageSubtitle>
            <PageSubtitle className="mb-0 mt-0 pb-0">
              {ds_especialidade_profissional}
            </PageSubtitle>
          </>
        )}

        {!idprofissional && !idusuario_resposta && (
          <BoxPictureForumTitle
            style={{
              marginTop: '1.5rem',
            }}
          >
            {nm_pessoa}
          </BoxPictureForumTitle>
        )}
      </div>
    </Row>
  );
};

const DetailsTopics: React.FC = () => {
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { requestAPI, sendError, user, applicationData } = useAuth();
  const { addToast } = useToast();
  const formRef = React.useRef<FormHandles>(null);
  const [detailsTopics, setDetailsTopics] = useState<DetailsTopicsType>(
    {} as DetailsTopicsType
  );
  const [resolved, setResolved] = React.useState(false);
  const [totalLikes, setTotalLikes] = React.useState(0);

  const [modalLogin, setModalLogin] = useState({} as any);
  const [modalClosed, setModalClosed] = useState({} as any);
  const [modalAnswer, setModalAnswer] = useState({} as any);
  const [modalRemove, setModalRemove] = useState({} as any);
  const [modalResolved, setModalResolved] = useState({} as any);
  const [topicClosed, setTopicClosed] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const { params } = useRouteMatch<IdRoute>();
  const idRoute = params.id;

  const getData = React.useCallback(() => {
    requestAPI({
      method: 'GET',
      url: `/forumDetalhe/${idRoute}`,
    })
      .then((resp) => {
        const idResolved = resp.interacoes.find(
          (int: any) => int.ie_resolvido === 'S'
        );
        if (resp.dt_fechamento) setTopicClosed(true);
        if (idResolved) {
          setResolved(idResolved.idforum);
        }
        setTotalLikes(resp.qtde_curtida);
        resp.dt_ultima = moment(resp.dt_ultima).fromNow(true);
        setDetailsTopics(resp);
      })
      .catch((err: any) => {
        sendError(err);
        addToast({
          title: 'Ops',
          type: 'error',
          description: `Ocorreu um erro ao obter dados do forum: ${err.message}`,
        });
        history.push('/dashboard');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location?.state]);

  React.useEffect(() => {
    getData();
  }, []);

  const handleShowModalLogin = React.useCallback(() => {
    setModalLogin({ showModal: true });
  }, []);

  const handleClickConfirmResolved = React.useCallback(
    (chkEv, idforum, ieResolvido, index) => {
      handleSubmitCheckbox({ idforum, ie_resolvido: ieResolvido, index });
    },
    [resolved]
  );
  const handleResolved = React.useCallback(
    async ({ idforum, ie_resolvido, index, chkEv }) => {
      if (!user) {
        handleShowModalLogin();
        return;
      }
      if (topicClosed && valueParam(applicationData, 225) === 'S') {
        await handleSubmitCheckbox({ idforum, ie_resolvido, index, chkEv });
      } else {
        setModalResolved({
          showModal: true,
          idforum,
          ie_resolvido,
          index,
          chkEv,
        });
      }
    },
    [resolved, user?.idpessoa_fisica, topicClosed]
  );

  const handleSubmitCheckbox = React.useCallback(
    async (data) => {
      try {
        setLoading(true);
        await requestAPI({
          method: 'PUT',
          url: `/forumInteracao/${data.idforum}`,
          body: { ie_resolvido: data.ie_resolvido },
        });

        if (resolved === data.idforum) setResolved(false);
        else setResolved(data.idforum);
        setLoading(false);
        setTopicClosed(true);
        setModalResolved({ showModal: false });
      } catch (err: any) {
        formRef.current?.setFieldValue(
          `ie_resolvido${data.index}`,
          data.idforum === resolved ? 'S' : 'N'
        );
        setLoading(false);
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    },
    [resolved]
  );

  const handleRemove = React.useCallback(({ idforum, index }) => {
    if (!user) {
      handleShowModalLogin();
      return;
    }

    setModalRemove({
      showModal: true,
      idforum,
      index,
    });
  }, []);

  const setInactive = React.useCallback(async ({ id, index }) => {
    try {
      setLoading(true);
      await requestAPI({
        method: 'PUT',
        url: `/forumInactive/${id}`,
      });

      setDetailsTopics((topicos) => {
        const result = topicos?.interacoes?.filter(
          ({ idforum }) => idforum !== id
        );
        topicos.interacoes = result;
        return { ...topicos };
      });
      setLoading(false);

      setModalRemove({
        showModal: false,
      });
      addToast({
        title: 'Sucesso',
        type: 'success',
        description: `${index > 0 ? 'A sua resposta' : 'O seu tópico'
          } foi removid${index > 0 ? 'a' : 'o'}!`,
      });

      if (index === 0) history.push('/forum');
    } catch (err: any) {
      setLoading(false);
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }, []);

  const handleSubmit = React.useCallback(async (data: any) => {
    try {
      if (!user) {
        handleShowModalLogin();
        return;
      }
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        ds_mensagem: Yup.string()
          .required('Mensagem é obrigatório. Por favor, descreva sua mensagem!')
          .trim(),
      });

      await schema.validate(data, { abortEarly: false });
      setLoading(true);

      const response = await requestAPI({
        method: 'POST',
        url: '/forum',
        body: {
          ds_mensagem: data.ds_mensagem,
          idforum_topico: data.idforum,
          idforum_resposta: data.idforum_resposta || undefined,
        },
      });

      setDetailsTopics((prev: any) => {
        let prevState = prev.interacoes;
        prevState = [...prev.interacoes, response];

        return { ...prev, interacoes: prevState };
      });

      setLoading(false);

      setModalAnswer({
        showModal: false,
      });

      addToast({
        title: 'Sucesso',
        type: 'success',
        description: `${modalAnswer.index > 0 ? 'A sua resposta' : 'O seu tópico'
          } foi criad${modalAnswer.index > 0 ? 'a' : 'o'}!`,
      });
    } catch (err: any) {
      setLoading(false);
      sendError(err);
      if (!(err instanceof Yup.ValidationError)) {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      } else {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }
  }, []);
  const handleAnswer = React.useCallback(
    ({ idforum, idforum_resposta, index }) => {
      if (!user) {
        handleShowModalLogin();
        return;
      }

      setModalAnswer({
        showModal: true,
        idforum,
        idforum_resposta,
        index,
      });
    },
    [user?.idpessoa_fisica]
  );
  const handleCloseTopic = React.useCallback(
    ({
      execFunc,
      idforum,
      idforum_resposta,
      index,
      ie_resolvido,
      chkEv,
      isDelete,
    }: any) => {
      if (!user) {
        setModalLogin({ showModal: true });
        return;
      }
      if (
        topicClosed &&
        valueParam(applicationData, 225) === 'S' &&
        !ie_resolvido &&
        !isDelete
      ) {
        setModalClosed({ showModal: true });
      } else {
        execFunc({
          idforum,
          idforum_resposta,
          index,
          ie_resolvido,
          chkEv,
        });
      }
    },
    [detailsTopics, topicClosed]
  );

  return (
    <>
      <HelmetComponent title="Fórum" />
      <Header />
      {isLoading ? (
        <DrCardLoading />
      ) : (
        <Container>
          <DrModal
            controllerModal={[modalClosed.showModal, setModalClosed]}
            content={
              <h4 style={{ textAlign: 'center' }}>
                {detailsTopics?.msg_topico_fechado_modal}
              </h4>
            }
            title="Atenção"
            buttons={{
              cancel: {
                title: 'Voltar',
                onClick: () => setModalClosed({ showModal: false }),
              },
            }}
          />
          <DrModal
            controllerModal={[modalLogin.showModal, setModalLogin]}
            title="Atenção"
            buttons={{
              confirm: {
                title: 'Login/Cadastro',
                onClick: () => {
                  history.push('/sign-in');
                },
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => {
                  setModalLogin({ showModal: false });
                },
              },
            }}
            content={<h4>Necessário realizar Login ou Cadastro!</h4>}
          />
          <DrModal
            controllerModal={[modalAnswer.showModal, setModalAnswer]}
            content={
              <Container className="p-3">
                <HandleRenderImage
                  person={{ nm_pessoa: user?.first_name }}
                  image={user?.url_img}
                />
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Row className="m-0">
                    <Col className="p-0">
                      <DrInputTextArea
                        label="Comente aqui"
                        name="ds_mensagem"
                        placeholder="Digite aqui..."
                      />
                    </Col>
                    <div style={{ display: 'none' }}>
                      <Input name="idforum" value={modalAnswer?.idforum} />
                      <Input
                        name="idforum_resposta"
                        value={modalAnswer?.idforum_resposta}
                      />
                    </div>
                  </Row>

                  <Row className=" ml-0 mr-0">
                    <DrButtonsFooter
                      containerClassNm="mt-1 mb-1"
                      buttonLeft={{
                        type: 'submit',
                        title: detailsTopics?.ds_btn_responder || 'Responder',
                        loading,
                      }}
                      buttonRight={{
                        title: 'Cancelar',
                        onClick: () =>
                          setModalAnswer({
                            showModal: false,
                          }),
                      }}
                    />
                  </Row>
                </Form>
              </Container>
            }
          />
          <DrModal
            controllerModal={[modalRemove.showModal, setModalRemove]}
            title="Atenção"
            buttons={{
              confirm: {
                title: 'Confirmar',
                onClick: () =>
                  setInactive({
                    id: modalRemove.idforum,
                    index: modalRemove.index,
                  }),
                loading,
              },
              cancel: {
                title: 'Voltar',
                onClick: () => setModalRemove({ showModal: false }),
              },
            }}
            content={
              <h4 style={{ textAlign: 'center' }}>
                {`Deseja realmente remover ${modalRemove.index > 0 ? 'essa resposta?' : 'esse tópico?'
                  }`}
              </h4>
            }
          />
          <DrModal
            onHide={() => {
              setModalResolved({ showModal: false });
              formRef.current?.setFieldValue(
                `ie_resolvido${modalResolved.index}`,
                modalResolved.idforum === resolved ? 'S' : 'N'
              );
            }}
            controllerModal={[modalResolved.showModal, setModalResolved]}
            title="Confirmação"
            buttons={{
              confirm: {
                title: 'Confirmar',
                onClick: () => {
                  handleClickConfirmResolved(
                    modalResolved.chkEv,
                    modalResolved.idforum,
                    modalResolved.ie_resolvido,
                    modalResolved.index
                  );
                },
                loading,
              },
              cancel: {
                title: 'Voltar',
                onClick: () => {
                  setModalResolved({ showModal: false });
                  formRef.current?.setFieldValue(
                    `ie_resolvido${modalResolved.index}`,
                    modalResolved.idforum === resolved ? 'S' : 'N'
                  );
                },
              },
            }}
            content={
              <h4 style={{ textAlign: 'center' }}>
                Ao marcar esta postagem como resolvido, a dúvida será fechada e
                não serão mais possíveis interações.
              </h4>
            }
          />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CardBlank className="mt-3 pt-3 pb-3">
              <Row>
                <Col>
                  <DrIconFont
                    className="ml-2"
                    name="arrow-left"
                    color="#0056b3"
                    onClick={() => history.goBack()}
                    cursor="pointer"
                    fontSize="22px"
                  />
                </Col>
              </Row>
              <Container>
                <PageTitle
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    maxWidth: '100%',
                  }}
                >
                  {detailsTopics?.ds_titulo}
                </PageTitle>
                {topicClosed && (
                  <PageSubtitle
                    textCenter
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      maxWidth: '100%',
                      color: '#ef3170',
                    }}
                  >
                    {detailsTopics?.msg_topico_fechado}
                  </PageSubtitle>
                )}
                <Row className="m-0 justify-content-center">
                  <Col>
                    <PageSubtitle
                      style={{ fontWeight: 'bold', color: 'black' }}
                    >
                      {detailsTopics?.ds_especialidade}
                    </PageSubtitle>
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <PageSubtitle
                      style={{ fontWeight: 'bold', color: 'black' }}
                    >
                      {detailsTopics?.ds_tipo_duvida}
                    </PageSubtitle>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <PageSubtitle
                      style={{ fontWeight: 'bold', color: 'black' }}
                    >
                      {detailsTopics?.ds_categoria}
                    </PageSubtitle>
                  </Col>
                </Row>
                <InfoRow className="mb-2">
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                      <PageSubtitle>
                        Atividade: {detailsTopics?.dt_ultima}
                      </PageSubtitle>
                      <PageSubtitle>
                        {detailsTopics?.qtde_user} usuários(as)
                      </PageSubtitle>
                    </Col>

                    <Col style={{ textAlign: 'right' }}>
                      <PageSubtitle>
                        {detailsTopics?.interacoes
                          ? detailsTopics.interacoes.length - 1
                          : 0}{' '}
                        respostas
                      </PageSubtitle>
                      <PageSubtitle>{totalLikes} curtidas</PageSubtitle>
                    </Col>
                  </Row>
                </InfoRow>

                {detailsTopics?.interacoes?.map((item: any, index: number) => (
                  <DrCardForum
                    key={`#id_${item.idforum.toString()}`}
                    resposta={item}
                    topico={detailsTopics}
                    resolved={resolved}
                    index={index}
                    HandleRenderImage={HandleRenderImage}
                    handleAnswer={handleAnswer}
                    handleResolved={handleResolved}
                    handleRemove={handleRemove}
                    setTotalLikes={setTotalLikes}
                    handleShowModalLogin={handleShowModalLogin}
                    handleCloseTopic={handleCloseTopic}
                    formRef={formRef}
                    handleSubmit={handleSubmit}
                  />
                ))}
              </Container>
            </CardBlank>
          </Form>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default DetailsTopics;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { FiAlertCircle, FiTrash2 } from 'react-icons/fi';
import DrCardLoading from '../../../components/dr-card-loading';
import DrInputTextArea from '../../../components/dr-input-text-area';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { ApplicationData, AuthContextData, useAuth } from '../../../hooks/auth';
import DrInputFile from '../../../components/dr-input-file';
import {
  ItemContainer,
  ItemTitle,
  ArchiveWarn,
  NewFileContainer,
} from './styles';
import { ToastMessage, useToast } from '../../../hooks/toast';
import { ToolTipButton } from '../../SignIn/dr-box-sign-in/styled';
import { valueParam } from '../../../utils/bibli';
import DrButton from '../../../components/dr-button';
import DrModal from '../../../components/dr-modal';
import { useIsTimeToConsult } from '../../../hooks/useIsTimeToConsult';

interface AttachmentCardProps {
  requestAPI: AuthContextData['requestAPI'];
  attendance: AuthContextData['attendance'];
  sendError: (message: any) => Promise<void>;
  getDomainsData(domain: string): any[];
  addToast: (message: Omit<ToastMessage, 'id'>) => void;
  applicationData: ApplicationData;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  attachmentData: any;
  setAttachmentData: React.Dispatch<React.SetStateAction<any>>;
  attachmentType: any;
}

const AttachmentCard: React.FC<AttachmentCardProps> = ({
  attendance,
  requestAPI,
  sendError,
  addToast,
  applicationData,
  setLoading,
  attachmentData,
  setAttachmentData,
  attachmentType,
}: AttachmentCardProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [openModal, setOpenModal] = useState<any>({
    showModal: false,
    item: null,
  });
  const [open, setOpen] = useState<any>(() => attachmentType.map(() => false));

  const closeDropdown = useCallback((e: any) => {
    if (
      dropdownRef.current !== null &&
      !dropdownRef.current?.contains(e.target) &&
      e.target.nodeName !== 'path'
    ) {
      setOpen((prev) => !prev);
      document.removeEventListener('click', closeDropdown, false);
    }
  }, []);

  const openDropdown = useCallback(() => {
    setOpen((prev) => !prev);
    document.addEventListener('click', closeDropdown, false);
  }, []);

  const deleteAttachment = async (deleteItem) => {
    try {
      setLoading(true);
      const filtered = attachmentData.filter(
        (remove) => remove.url_arquivo !== deleteItem.url_arquivo
      );

      setAttachmentData(filtered);

      await requestAPI({
        method: 'PUT',
        url: `/atendimentoPacienteAnexoInactive/${deleteItem.idatendimento_paciente_anexo}`,
      });
      addToast({
        type: 'success',
        title: '',
        description: 'O arquivo foi deletado com sucesso',
      });
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: '',
        description: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const verifyTypeAttachment = (fileName: string) => {
    const valuesParams = valueParam(applicationData, 236);

    const acceptedFormats = valuesParams.split(',');
    let extension: string = '';

    if (fileName && fileName.length) {
      const idx = fileName.lastIndexOf('.');
      if (idx !== -1) {
        extension = fileName.substring(idx);
      }
      extension = extension.toLowerCase();
    }
    if (!acceptedFormats.find((result: string) => extension === result)) {
      throw new Error('Não foi possível importar! Arquivo não suportado!');
    }
  };

  const verifySizeAttachment = (fileSize: number) => {
    const maxFileMegaBytes = parseFloat(valueParam(applicationData, 237));
    const maxFileBytes = maxFileMegaBytes * 1048576;
    if (fileSize > maxFileBytes) {
      throw new Error(
        `Tamanho do arquivo ultrapassou o limite permitido de ${maxFileMegaBytes} MB`
      );
    }
  };

  const handleSubmit = async (e, tipoAnexo) => {
    setLoading(true);
    const [file] = e.target.files;
    const { size } = file;
    const body: any = new FormData();
    try {
      verifyTypeAttachment(file.name);
      verifySizeAttachment(size);

      body.append('file', file);
      body.append('nm_arquivo', file.name.toLocaleLowerCase());
      body.append('idatendimento_paciente', null);
      body.append('ie_forma_envio_anexo_atend', 1);
      body.append('ie_tipo_anexo_atendimento', tipoAnexo);
      body.append(
        'idagenda_consulta',
        attendance.idagenda_consulta ? attendance.idagenda_consulta : null
      );
      body.append('idpessoa_fisica', attendance.idpessoa_paciente);
      body.append('ie_visualizacao_restrita', 'N');

      const resp = await requestAPI({
        method: 'POST',
        url: `/atendimentoPacienteAnexo`,
        body,
      });

      setAttachmentData((prev) => [...prev, resp]);

      addToast({
        type: 'success',
        title: '',
        description: 'O arquivo foi anexado com sucesso',
      });
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Ops',
        description: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <DrModal
        controllerModal={[openModal.showModal, setOpenModal]}
        title="Você realmente deseja remover este arquivo?"
        buttons={{
          confirm: {
            title: 'Sim',
            onClick: () => deleteAttachment(openModal.item),
          },
          cancel: {
            title: 'Não',
            onClick: () => setOpenModal({ showModal: false }),
          },
        }}
      />
      {attachmentType &&
        attachmentType.map((item, index) => {
          const arrayFiltered = attachmentData.filter(
            (selected) =>
              selected?.ds_tipo_anexo_atendimento === item.ds_observacao
          );
          return (
            <ItemContainer
              style={{
                backgroundColor: `${
                  arrayFiltered && arrayFiltered.length ? '#e5e5e5' : '#ffffff'
                }`,
              }}
              key={`#id_${index.toString()}`}
            >
              <Row className="align-items-baseline justify-content-sm-between row-btn m-auto">
                <Row ref={dropdownRef} className="align-items-baseline ml-1">
                  <ItemTitle>{item.label}</ItemTitle>
                  <ToolTipButton
                    visible={open[index]}
                    title={item.ds_observacao}
                    click={() => openDropdown()}
                  >
                    <FiAlertCircle
                      color="rgb(119, 119, 119)"
                      style={{ width: 18 }}
                      onClick={() => openDropdown()}
                    />
                  </ToolTipButton>
                </Row>
                <DrInputFile handleSubmit={handleSubmit} name={item.value} />
              </Row>
              {arrayFiltered && arrayFiltered.length ? (
                arrayFiltered.map((obj, idx) => (
                  <NewFileContainer key={`#id_${idx.toString()}`}>
                    <Row className="align-items-baseline justify-content-sm-between row-btn m-auto">
                      <ItemTitle style={{ marginLeft: 7, fontWeight: '300' }}>
                        {arrayFiltered[idx].nm_arquivo}
                      </ItemTitle>
                      <Col md={3}>
                        <DrButton
                          className="input-anexo"
                          type="button"
                          onClick={() =>
                            setOpenModal({
                              showModal: true,
                              item: arrayFiltered[idx],
                            })
                          }
                          title="Remover"
                          titleColor="#ed145b"
                          icon={
                            <FiTrash2
                              color="#ed145b"
                              style={{ width: '24px', marginRight: 1 }}
                            />
                          }
                          fontWeight="500"
                        />
                      </Col>
                    </Row>
                  </NewFileContainer>
                ))
              ) : (
                <></>
              )}
            </ItemContainer>
          );
        })}
    </div>
  );
};

const UploadCard: React.FC = () => {
  const { requestAPI, sendError, getDomainsData, applicationData, attendance } =
    useAuth();

  const { isTimeToConsult } = useIsTimeToConsult();

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const params = location?.state;

  const formRef = useRef<FormHandles>(null);

  const [attachmentType, setAttachmentType] = useState<any>([]);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [attachmentData, setAttachmentData] = useState<any>([]);
  const [observacao, setObservacao] = useState<any>();
  const [canConsult, setCanConsult] = useState<boolean>();

  const msgFormatsAccepteds =
    applicationData && applicationData.valores_parametros
      ? valueParam(applicationData, 236).split(',').join(', ')
      : '';

  const checkConsultTime = async () => {
    if (attendance.idestabelecimento_tipo_atendimento !== 1) {
      const timeToConsult = await isTimeToConsult(
        attendance.idagenda_consulta!
      );
      setCanConsult(timeToConsult.isTime);
    }
  };

  const handleRoute = () => {
    let url = '/pre-evaluation';
    if (params && params !== undefined && params === '/confirm-schedule') {
      url = '/dashboard';
    }
    if (attendance.idagenda_consulta && !canConsult) {
      url = '/dashboard';
    }
    history.push(url);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await requestAPI({
        method: 'GET',
        url: `/atendimentoPacienteAnexo?idpessoa_fisica=${attendance.idpessoa_paciente}&status=A`,
      });

      setAttachmentData(data);
      setObservacao(() => data.find((item) => item.ds_observacao !== null));
    } catch (error: any) {
      addToast({
        type: 'error',
        title: '',
        description: `${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const getDomains = async () => {
    const domainType = getDomainsData('640').map((dominio) => ({
      label: dominio.ds_dominio_valor,
      value: dominio.vl_dominio,
      ds_observacao: dominio.ds_observacao,
    }));

    const domainTypeFiltered = domainType.filter(
      (item) => item.label !== 'Chat' && item.label !== 'Observação'
    );
    setAttachmentType(domainTypeFiltered);
  };

  useEffect(() => {
    getDomains();
    getData();
    checkConsultTime();
  }, []);

  const handleSubmit = async (data) => {
    try {
      setBtnLoading(true);

      const body = {
        idatendimento_paciente: null,
        ds_observacao: data.ds_observacao || null,
        ie_forma_envio_anexo_atend: 1,
        ie_tipo_anexo_atendimento: 6,
        idagenda_consulta: attendance.idagenda_consulta
          ? attendance.idagenda_consulta
          : null,
        idpessoa_fisica: attendance.idpessoa_paciente,
        ie_visualizacao_restrita: 'N',
      };

      await requestAPI({
        method: 'POST',
        url: '/atendimentoPacienteAnexo',
        body,
      });

      handleRoute();
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: '',
        description: `${error.message}`,
      });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      {!loading && attachmentType && attachmentType.length ? (
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CardBlank className="mt-4 p-4">
              <PageTitle>{valueParam(applicationData, 229)}</PageTitle>
              <AttachmentCard
                attendance={attendance}
                addToast={addToast}
                requestAPI={requestAPI}
                sendError={sendError}
                getDomainsData={getDomainsData}
                applicationData={applicationData}
                loading={loading}
                setLoading={setLoading}
                attachmentData={attachmentData}
                setAttachmentData={setAttachmentData}
                attachmentType={attachmentType}
              />
              <ArchiveWarn>
                <h6>* Fomatos permitidos: {msgFormatsAccepteds}.</h6>
                <h6>
                  * Tamanho máximo por arquivo:{' '}
                  {valueParam(applicationData, 237)}
                  MB.
                </h6>
              </ArchiveWarn>
            </CardBlank>

            <CardBlank className="mt-4 p-4">
              <PageTitle>{valueParam(applicationData, 238)}</PageTitle>

              <DrInputTextArea
                name="ds_observacao"
                label=""
                defaultValue={observacao?.ds_observacao}
                value={observacao?.ds_observacao}
                onChange={(ev) => {
                  setObservacao((prev) => ({
                    ...prev,
                    ds_observacao: ev.target.value,
                  }));
                }}
                maxLength={950}
                placeholder="Digite aqui sua observação..."
              />
            </CardBlank>

            <Row className="mt-5">
              <DrButtonsFooter
                containerClassNm="mt-1 mb-1"
                buttonLeft={{
                  title: !canConsult ? 'Salvar' : 'Continuar',
                  type: 'submit',
                  loading: btnLoading,
                }}
                buttonRight={{
                  title: 'Cancelar',
                  onClick: () => history.push('/dashboard'),
                }}
              />
            </Row>
          </Form>
        </Container>
      ) : (
        <DrCardLoading />
      )}
    </div>
  );
};

export default UploadCard;

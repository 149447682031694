import styled from 'styled-components';

export const Container = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1023px) {
    .img-banner-login {
      display: none;
    }
  }
`;

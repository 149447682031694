import styled from 'styled-components';

export const CardGeralPl = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 375px;
  border-radius: 5px;
  border: 2px solid #e5e5e5;
  padding: 20px;

  :after,
  :before {
    box-sizing: border-box;
  }
  @media (max-width: 441px) {
    width: 330px;
  }
  @media (max-width: 375px) {
    width: 295px;
  }
  @media (max-width: 360px) {
    width: 270px;
  }
  @media (max-width: 320px) {
    width: 235px;
  }
  @media (max-width: 280px) {
    width: 205px;
  }
`;

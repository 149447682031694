import React from 'react';

interface DragDropProps {
  sendFiles: (files: FileList) => void;
  className?: string;
}

const DragDrop: React.FC<DragDropProps> = ({
  className,
  sendFiles,
  children,
}: React.PropsWithChildren<DragDropProps>) => {
  const [bgColor, setBgColor] = React.useState('transparent');
  const auxDragRef = React.useRef(0);

  const changeBgColor = (state) => {
    setBgColor((state && '#9bf0fd') || 'transparent');
  };

  return (
    <div
      style={{
        backgroundColor: bgColor,
      }}
      className={className}
      onDragEnter={(e: any) => {
        auxDragRef.current += 1;
        e.preventDefault();
        e.stopPropagation();
        changeBgColor(true);
        e.dataTransfer.dropEffect = 'copy';
      }}
      onDragLeave={(e: any) => {
        auxDragRef.current -= 1;
        e.preventDefault();
        e.stopPropagation();
        if (auxDragRef.current === 0) {
          changeBgColor(false);
        }
      }}
      onDragOver={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrop={(e: any) => {
        auxDragRef.current = 0;
        e.preventDefault();
        e.stopPropagation();
        changeBgColor(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          sendFiles(e.dataTransfer.files);
        }
      }}
    >
      {children}
    </div>
  );
};

export default DragDrop;

import styled from 'styled-components';

interface ContainerProps {
  spaceBetween?: boolean;
  textDecoration?: boolean;
  fontWeight?: string;
  customPadding?: string;
  customWidth?: string;
}

export const Container = styled.div<ContainerProps>`
  @media (min-width: 1200px) {
    padding: ${(props) => props.spaceBetween && '0 15px 0 15px'};
  }

  button {
    width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
    position: relative;
    font-size: 1.3rem;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
    padding: ${(props) =>
      props.customPadding ? props.customPadding : '10px 15px'};
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-decoration: ${(props) =>
      props.textDecoration && 'line-through !important'};
    @media (max-width: 575px) {
      font-size: 0.9rem;
    }
  }
`;
interface IcoProps {
  iconRight: boolean;
}
export const LabelIcon = styled.div<IcoProps>`
  display: flex;
  flex-direction: ${(props) => props.iconRight && 'row-reverse'};
  justify-content: center;
  align-items: center;

  .icone {
    display: flex;
    align-items: center;
  }
`;

export const DetailCircles = styled.div`
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 30px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    left: 0;
  }
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 30px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    right: 0;
  }
`;

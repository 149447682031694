import styled, { css } from 'styled-components';

interface ButtonDislikeProps {
  like: boolean;
}

export const ButtonDislike = styled.button<ButtonDislikeProps>`
  border-left: 1px solid #e5e5e5;
  z-index: 10;
  pointer-events: all;
  color: #ed145b;
  height: 100%;
  height: 50px;
  width: 75px;
  background-color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: 1px solid #e5e5e5;

  ${(props) =>
    props.like &&
    css`
      background-color: #ed145b;
    `}

  @media screen and (max-width: 300px) {
    width: 60px;
  }

  :hover {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #ed145b;

    i {
      color: #fff;
    }
  }
`;

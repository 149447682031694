import styled from 'styled-components';

export const Container = styled.div`
  video {
    object-fit: 'contain';
    height: 100vh;
    width: 100%;
    margin: 5px;
    border-radius: 5px;
  }

  img {
    object-fit: 'contain';
    height: 100vh;
    width: 100%;
    margin: 5px;
    z-index: 40;
    border-radius: 5px;
  }

  // video[poster] {
  //   object-fit: cover;
  // }
`;

export const Video = styled.video`
  &::-webkit-media-controls-play-button {
    display: none;
  }

  &::-webkit-media-controls-timeline {
    display: none;
  }

  &::-webkit-media-controls-volume-slider {
    display: none;
  }

  &::-webkit-media-controls-mute-button {
    display: none;
  }

  &::-webkit-media-controls-volume-slider-container {
    display: none;
  }
`;

import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import DrModal from '../components/dr-modal';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  path?: string;
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  path,
  ...rest
}: RouteProps) => {
  const { authUserToken, user, socketAuth, setSocketAuth } = useAuth();
  const location = useLocation();
  const [modal, setModal] = React.useState(false);
  const history = useHistory();

  const pathsToCheckPrivate = ['/forum', '/recover-password'];

  const matchFoundNameRoute = pathsToCheckPrivate.some((pathLocation) =>
    location.pathname.startsWith(pathLocation)
  );

  const auth =
    matchFoundNameRoute ||
    (authUserToken !== undefined && user && user.idpessoa_fisica !== undefined);

  React.useEffect(() => {
    if (
      socketAuth.socket &&
      !path?.startsWith('/attendance-now') &&
      !path?.startsWith('/stream-page')
    ) {
      socketAuth.socket.close();
      setSocketAuth({ socket: undefined });
    }
  });

  return (
    <ReactDOMRoute
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      path={path}
      render={(props) => {
        // Quando a rota for privada validar se o usuario esta logado
        if (isPrivate && !auth) {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <>
            <Component />
            <DrModal
              controllerModal={[modal, setModal]}
              title="Atenção"
              buttons={{
                confirm: {
                  title: 'Login/Cadastro',
                  onClick: () => {
                    history.push({
                      pathname:
                        isPrivate &&
                        (!user || user.idpessoa_fisica === undefined)
                          ? '/sign-in'
                          : '/dashboard',
                      state: { from: props.location },
                    });
                  },
                },
                cancel: {
                  title: 'Cancelar',
                  onClick: () => {
                    history.goBack();
                    setModal(false);
                  },
                },
              }}
              content={<h4>Necessário realizar Login ou Cadastro!</h4>}
            />
          </>
        );
      }}
    />
  );
};

Route.defaultProps = {
  path: undefined,
  isPrivate: undefined,
};

export default Route;

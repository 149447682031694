import styled, { css } from 'styled-components';

interface ContainerProps {
  hideContainer: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 150px;
  height: 150px;
  display: flex;
  align-items: baseline;

  ${(props) =>
    props.hideContainer &&
    css`
      display: none;
    `}
`;

export const PopUpContainer = styled.div<PopUpContainerProps>`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  ${(props) =>
    props.isClosed &&
    css`
      display: none;
    `}

  @media screen and (max-width: 850px) {
    width: 100%;
    margin: auto;
  }
`;

export const PopUpContent = styled.div`
  width: 100%;
  max-width: 530px;
  background: transparent;
  margin: auto;
  .svg {
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      right: 3px;
      top: 3px;
      height: 22px;
      width: 22px;
      color: white;
      border: 2px solid rgb(255 255 255 / 65%);
      border-radius: 50%;
      z-index: 99999;
      cursor: pointer;
      &:hover {
        color: #9f1e49;
        background: rgb(255 255 255 / 65%);
      }
    }
  }

  .edit-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  img {
    background-size: cover;
    margin: 0 auto;
    border-width: 3px;
    border-style: solid;
    border-color: #4156a6;
    box-shadow: 2px 2px 10px black;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  h2 {
    font-size: 2rem;
    color: #4156a6;
    margin: 5px;
    font-family: 'Concert One', cursive;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 25px auto;
    width: 50%;
  }
`;

export const Img = styled.div`
  border: none;
  background: none;


  }
`;

export const I = styled.i`
  height: 50px;
  width: 60px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #3f56a5;
`;

export const PutPhoto = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarEditor {
    width: 100% !important;
    height: 100% !important;
  }
`;

interface ZoomInputProps {
  hideInput: boolean;
}

export const ZoomInput = styled.div<ZoomInputProps>`
  margin-top: 5px;
  span {
    color: red;
    color: #4156a6;
    font-weight: bold;
    text-align: left;
    // margin-left: 5px;
    margin-top: 5px;
  }

  .scale {
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
    width: 100%;
    background-color: red;
  }
  .buttonsActions {
    display: flex;
    justify-content: center;
  }
  ${(props) =>
    props.hideInput &&
    css`
      display: none;
    `}
`;

export const ButtonEditAvatar = styled.button`
  position: absolute;
  width: 60px;
  height: 60px;
  background: white;
  border-color: #4156a6;
  border-radius: 50%;
  border-widht: 3px;
  right: 0;
  bottom: 0;
  font-size: 22px;
  color: #4156a6;
  margin: 5px;
  &:hover {
    background: #4156a6;
    color: white;
  }
`;

export const ButtonChangeAvatar = styled.button`
  width: 70px;
  height: 70px;
  border: 1px solid #fcaf17;
  box-shadow: 1px 1px 3px #fcaf17;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 5px;

  .galeria {
    width: 2.3em;
    height: 2.3em;
  }

  p {
    color: #777;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  svg {
    width: 2em;
    height: 2em;
    color: #fcaf17;
  }
  &:hover {
    svg {
      width: 2.2em;
      height: 2.2em;
    }
    p {
      color: #fcaf17;
    }
  }
`;

interface ButtonRemoveAvatarProps {
  avatarRemoved: boolean;
}

export const ButtonRemoveAvatar = styled.button<ButtonRemoveAvatarProps>`
  width: 70px;
  height: 70px;
  border: 1px solid #ed1164;
  box-shadow: 1px 1px 3px #ed1164;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 5px;

  .excluir {
    width: 2.3em;
    height: 2.3em;
  }

  p {
    color: #777;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  svg {
    width: 2em;
    height: 2em;
    color: #ed1164;
  }
  &:hover {
    svg {
      width: 2.2em;
      height: 2.2em;
    }
    p {
      color: #ed1164;
    }
  }

  ${(props) =>
    props.avatarRemoved &&
    css`
      display: none;
    `}
`;

interface ButtonSaveAvatarProps {
  avatarEdited: boolean;
}

export const ButtonSaveAvatar = styled.button<ButtonSaveAvatarProps>`
  width: 70px;
  height: 70px;
  border: 1px solid #3f56a5;
  box-shadow: 1px 1px 3px #3f56a5;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 5px;

  .salvar {
    width: 2.3em;
    height: 2.3em;
  }

  p {
    color: #777;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  svg {
    width: 2em;
    height: 2em;
    color: #3f56a5;
  }
  &:hover {
    svg {
      width: 2.2em;
      height: 2.2em;
    }
    p {
      color: #3f56a5;
    }
  }

  ${(props) =>
    props.avatarEdited &&
    css`
      display: none;
    `}
`;

interface PopUpContainerProps {
  isClosed: boolean;
}

export const ButtonClosePopUp = styled.button`
  width: 60px;
  height: 50px;
  background: white;
  border: none;
  font-size: 20px;
  color: #ed1164;
  margin: 5px;
  &:hover {
    background: #ed1164;
  }
  svg {
    width: 1.5em;
    height: 1.5em;
    color: #ed1164;
    &:hover {
      color: white;
    }
  }
`;

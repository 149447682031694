import styled from 'styled-components';

interface BPLiProps {
  color?: string;
}
export const BPLi = styled.li<BPLiProps>`
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55556em;
  color: ${(props) => props.color};
`;
export const Strong = styled.strong`
  font-size: 18px;
  font-weight: bold;
  color: #777;
`;
export const P = styled.li`
  font-size: 16px;
  font-weight: 400;
  color: #777;
`;

import React from 'react';
import DrCardLoading, { DrCardLoadingProps } from '../dr-card-loading';

interface DrControlScreenProps {
  isLoading?: boolean;
  propsLoading?: DrCardLoadingProps;
  chooseScreen: () => JSX.Element;
}

const DrControlScreen: React.FC<DrControlScreenProps> = ({
  isLoading,
  propsLoading,
  chooseScreen,
}: DrControlScreenProps) => {
  let screen = <></>;
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (isLoading) screen = <DrCardLoading {...propsLoading} />;
  else {
    screen = chooseScreen();
  }

  return screen;
};

DrControlScreen.defaultProps = {
  isLoading: undefined,
  propsLoading: {},
};

export default DrControlScreen;

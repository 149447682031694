import React from 'react';
import { useHistory } from 'react-router-dom';
import DrCardLoading from '../../components/dr-card-loading';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import DrCardDependents from './dr-card-dependents';

const SelectDependents: React.FC = () => {
  const {
    requestAPI,
    attendance,
    user,
    setAttendanceData,
    applicationData,
    sendError,
  } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [members, setMembers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const attendanceType = attendance.tipos_atendimento?.toString();

    async function getMembersData() {
      let url = `/tmUsuarioGrupoFamiliarUsu?idpessoa_fisica=${
        user.idpessoa_fisica
      }${
        attendance.idestabelecimento_tipo_atendimento
          ? `&idestabelecimento_tipo_atendimento=${
              attendanceType || attendance.idestabelecimento_tipo_atendimento
            }`
          : ''
      }`;
      if (applicationData?.valores_variaveis?.rg_hr_assinar)
        url += `&rg_horario=${applicationData.valores_variaveis.rg_hr_assinar}`;

      requestAPI({
        method: 'GET',
        url,
      })
        .then((resp) => {
          if (resp && resp.length) setMembers(resp);
        })
        .catch((error: any) => {
          sendError(error);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: error.message,
          });

          history.push({
            pathname: '/dashboard',
            state: { showModal: true, msg: error.message },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getMembersData();
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <DrCardDependents
          dependentes={[...members]}
          qtdDependentes={attendance.qtd_max_dependente || 0}
          history={history}
          attendance={attendance}
          setAttendanceData={setAttendanceData}
          sendError={sendError}
        />
      )}
      <Footer />
    </>
  );
};

export default SelectDependents;

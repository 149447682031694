import styled, { css } from 'styled-components';

interface ColorProps {
  color?: string;
}

export const PageTitle = styled.h2<ColorProps>`
  font-size: 21px;
  color: ${(props) => props.color || '#3f56a5'};
  margin: 0 0 15px;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 850px) {
    font-size: 18px;
  }
`;

interface PageSubtitleProps {
  textCenter?: boolean;
  fontSize?: number;
}

export const PageSubtitle = styled.h4<PageSubtitleProps>`
  font-size: ${({ fontSize }) => fontSize || 18}px;
  color: ${(props) => props.color || '#777'};
  line-height: 1.44444em;
  margin: 0 0 5px;

  @media screen and (max-width: 850px) {
    font-size: 15px;
  }
  ${(props) =>
    props.textCenter &&
    css`
      text-align: center;
    `}
`;

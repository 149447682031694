import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrBoxSignIn from './dr-box-sign-in';
import DrIcon from '../../components/dr-icon-font';
import { BoxContainer, Box } from '../../styles/box';
import DrSmartBanner from '../../components/dr-smart-banner';
import DrHelpChat from '../../components/dr-help-chat';
import Header from '../../components/dr-header';

const SignIn: React.FC = () => (
  <>
    <Header />
    <DrSmartBanner />
    <BoxContainer>
      <Container className="container-geral signin">
        <Row>
          <Col xl={5} className=" p-4">
            <Box>
              <DrBox
                icon={<DrIcon name="avatar" color="#ed145b" />}
                title="Faça seu login"
                titleColor="#fcaf17"
              >
                <DrBoxSignIn />
              </DrBox>

              <DrHelpChat />
            </Box>
          </Col>
          <Col xl={7}>
            <Box>
              <DrHeaderWellcome />
              <Banner />
            </Box>
          </Col>
        </Row>
        <Footer />
      </Container>
    </BoxContainer>
  </>
);

export default SignIn;

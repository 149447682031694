import styled, { css } from 'styled-components';

export const ForumP = styled.p`
  color: #777777;
  margin-bottom: 0;

  @media (max-width: 500px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    max-width: 30rem !important;
  }
  @media (max-width: 850px) {
    max-width: 200px !important;
  }
`;

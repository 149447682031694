import React from 'react';
import UserData from '../../components/dr-user-data';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';

const CreateFamilyMember: React.FC = () => (
  <>
    <Header />
    <UserData type="family" method="POST" url="/tmUsuarioGrupoFamiliar" />
    <Footer />
  </>
);

export default CreateFamilyMember;

import React from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Modal } from 'react-bootstrap';
import DrModalContent, { DrModalContentProps } from '../dr-modal-content';

export interface DrModalProps extends DrModalContentProps {
  controllerModal: [boolean, React.Dispatch<React.SetStateAction<any>>];
  onHide?: () => void;
  form?: { onSubmit: SubmitHandler; ref?: React.RefObject<FormHandles> };
  modalContent?: React.ReactNode;
  backdrop?: string;
  keyboard?: boolean;
}

const DrModal: React.FC<DrModalProps> = ({
  controllerModal,
  form,
  onHide,
  modalContent,
  keyboard,
  backdrop,
  ...rest
}: DrModalProps) => {
  const [showModal, setShowModal] = controllerModal;

  // eslint-disable-next-line react/jsx-props-no-spreading
  const modalContentRender = modalContent || <DrModalContent {...rest} />;

  const onHideModal = React.useCallback(() => {
    if (onHide) {
      onHide();
    }

    setShowModal({ showModal: false });
  }, [onHide, setShowModal]);

  return (
    <Modal
      centered
      show={showModal}
      onHide={onHideModal}
      backdropClassName={backdrop}
      keyboard={keyboard}
      size="lg"
    >
      {form ? (
        <Form ref={form.ref} onSubmit={form.onSubmit}>
          {modalContentRender}
        </Form>
      ) : (
        modalContentRender
      )}
    </Modal>
  );
};

DrModal.defaultProps = {
  form: undefined,
  onHide: undefined,
  modalContent: false,
};

export default DrModal;

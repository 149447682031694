import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrBoxSignUp from './dr-box-sign-up';
import DrIcon from '../../components/dr-icon-font';
import { BoxContainer, Box } from '../../styles/box';
import DrHelpChat from '../../components/dr-help-chat';
import Header from '../../components/dr-header';

const SignUp: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  return (
    <>
      <Header />
      <BoxContainer height="auto">
        <Container className="container-geral signup">
          <Row>
            <Col xl={5} className="mb-4 p-4 ">
              <Box>
                <DrBox
                  arrowOnClick={() => {
                    if (currentStep === 0) {
                      history.goBack();
                    } else {
                      setCurrentStep(0);
                    }
                  }}
                  arrowColor="#3f56a5"
                  icon={<DrIcon name="write" color="#03bbd7" />}
                  title="Conta do Responsável"
                  subTitleColor="#3f56a5"
                  titleColor="#3f56a5"
                >
                  <DrBoxSignUp
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                </DrBox>

                <DrHelpChat />
              </Box>
            </Col>
            <Col xl={7}>
              <Box>
                <DrHeaderWellcome />
                <Banner />
              </Box>
            </Col>
          </Row>
          <Footer />
        </Container>
      </BoxContainer>
    </>
  );
};

export default SignUp;

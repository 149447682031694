import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { CardBlank } from '../../../styles/card-blank';

interface PropLegenda {
  color?: string;
}
export const DrBoxSignInContainer = styled.div`
  .login-tooltip {
    display: flex;
    position: relative;
    align-items: center;
    .buttonTooltip {
      width: 35px;
      height: 30px;
    }
  }
`;

export const Arrow = styled.span`
  align-self: center;
  background-color: #777;
  height: 30px;
  margin: 5px;
  color: red;
  @media (max-width: 540px) {
    margin: 5px 0px 5px 0px;
    width: 15px;
  }
`;

export const ContainerDiv = styled.div`
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
export const Circle = styled.span<PropLegenda>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
export const Legend = styled.h3<PropLegenda>`
  font-size: 1rem;
  overflow-wrap: break-word;
  font-weight: 600;
  margin: 0;
  color: ${({ color }) => color};
`;

export const TextContent = styled.div`
  width: 93%;
  margin: 0;
  margin-top: 1rem;
`;

export const PadraoText = styled.p`
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #7c7c7c;
`;
export const TextDestaque = styled.p`
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #ed145b;
`;

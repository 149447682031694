import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useAuth, AuthContextData } from '../../../hooks/auth';
import { useToast, ToastContextData } from '../../../hooks/toast';
import { useIsMounted } from '../../../hooks/is-mounted';
import { handleNoSleep, detectMobile } from '../../../utils/bibli';
import { CardBlank } from '../../../styles/card-blank';
import DrButtonLoading from '../../../components/dr-button-loading';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import EquipamentTest from '../../../components/dr-equipament-test';

interface cbOnClickAttNowParams {
  attendance: AuthContextData['attendance'];
  requestAPI: AuthContextData['requestAPI'];
  setAttendanceData: AuthContextData['setAttendanceData'];
  addToast: ToastContextData['addToast'];
  history: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isMountedRef: React.MutableRefObject<boolean>;
  sendError: (message: any) => Promise<void>;
  user: AuthContextData['user'];
}

async function cbOnClickAttNow({
  attendance,
  requestAPI,
  setAttendanceData,
  addToast,
  history,
  setIsLoading,
  isMountedRef,
  sendError,
  user,
}: cbOnClickAttNowParams) {
  setIsLoading(true);
  if (detectMobile()) {
    document.addEventListener('click', handleNoSleep, false);
  }
  const {
    idficha_avaliacao_gerada,
    vl_total_servico,
    idconta_receber_cartao_movto,
    idpessoa_paciente,
    ie_retorno,
    idcontrato,
    idagenda_consulta,
    idestabelecimento_tipo_atendimento,
  } = attendance;

  let localStorageDatas: any = {};

  try {
    const resp: any = await requestAPI({
      method: 'POST',
      url: '/checkinAtendimentoPaciente',
      body: {
        idpessoa_fisica: idpessoa_paciente,
        vl_consulta: vl_total_servico,
        idconta_receber_cartao_movto,
        ie_retorno: ie_retorno || 'N',
        idficha_avaliacao_gerada,
        idagenda_consulta,
        idpessoa_titular: user.idpessoa_fisica,
        idcontrato,
        idestabelecimento_tipo_atendimento,
      },
    });

    if (resp.ordem_fila) {
      localStorageDatas = {
        ...localStorageDatas,
        tme: resp.ordem_fila?.tme?.split('.', 1),
        ordem: resp.ordem_fila?.atendimentos[0]?.ordem,
        idatendimento_paciente:
          resp.ordem_fila?.atendimentos[0]?.idatendimento_paciente,
        idestabelecimento_tipo_atendimento:
          resp.idestabelecimento_tipo_atendimento,
        idpessoa_paciente,
      };
    } else {
      localStorageDatas = {
        ...localStorageDatas,
        tme: resp.data.tme ? resp.data?.tme?.split('.', 1) : null,
        idatendimento_paciente: resp.data.atendimento
          ? resp.data?.atendimentos[0]?.idatendimento_paciente
          : null,

        ordem: resp.data.atendimento ? resp.data?.atendimentos[0]?.ordem : null,
        idestabelecimento_tipo_atendimento:
          resp.data?.idestabelecimento_tipo_atendimento,
        idpessoa_paciente,
      };
    }

    await setAttendanceData(localStorageDatas, true);
    if (isMountedRef.current) {
      history.push('/attendance-now/');
    }
  } catch (error: any) {
    sendError(error);
    if (isMountedRef.current) {
      addToast({
        type: 'error',
        title: 'Error ao iniciar atendimento',
        description: error.message,
      });
      setIsLoading(false);
    }
  }
}

const AttendanceOptions: React.FC = () => {
  const history = useHistory();

  const {
    attendance,
    requestAPI,
    setAttendanceData,
    sendError,
    user,
    testeComplete,
  } = useAuth();
  const { addToast } = useToast();
  const isMountedRef = useIsMounted();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickAttNow = React.useCallback(() => {
    cbOnClickAttNow({
      sendError,
      attendance,
      requestAPI,
      setAttendanceData,
      addToast,
      history,
      setIsLoading,
      isMountedRef,
      user,
    });
  }, [
    attendance,
    requestAPI,
    setAttendanceData,
    addToast,
    history,
    setIsLoading,
    isMountedRef,
    user.idpessoa_fisica,
  ]);

  return (
    <div>
      {!testeComplete ? (
        <EquipamentTest />
      ) : (
        <Container className="pt-3">
          <CardBlank className="p-3">
            <Row className="justify-content-center">
              <Col md={12} xl={4}>
                <DrButtonLoading
                  loading={isLoading}
                  title="Ser atendido agora"
                  type="button"
                  onClick={onClickAttNow}
                  color="blue"
                />
              </Col>
            </Row>
          </CardBlank>
          <DrButtonsFooter
            buttonRight={{
              title: 'Cancelar',
              onClick: () => history.push('/dashboard'),
            }}
          />
        </Container>
      )}
    </div>
  );
};

export default AttendanceOptions;

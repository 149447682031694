import { FormHandles } from '@unform/core';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PageSubtitle, PageTitle } from '../../styles/page-title';
import DrCheckbox from '../dr-input-checkbox';

interface ItensGroup {
  idficha_avaliacao_item: number;
  nr_item: number;
  ds_item: string;
  idficha_avaliacao_grupo: number;
  ie_resultado: string;
  ds_resultado: string;
  value?: 'S' | 'N';
}

export interface dataPreEvalGrupo {
  idficha_avaliacao_grupo: number;
  ds_grupo: string;
  nr_seq_apresentacao: number;
  itens: ItensGroup[];
}

export interface dataPreEvaluationType {
  idficha_avaliacao: number;
  ds_tipo_avaliacao: string;
  grupos: dataPreEvalGrupo[];
}

interface DrRenderGrpPreEvalProps {
  dataPreEvaluation: dataPreEvaluationType;
  idxGroup: number;
  formRef: React.RefObject<FormHandles> | null;
}

interface SetArrayChckGrpRenderParams {
  arrayChckGrpRenderRef: { current: React.ReactNode[] };
  arrayChckItemRenderRef: { current: React.ReactNode[] };
}

interface SetItemCheckboxParams extends SetArrayChckGrpRenderParams {
  idxArrGroupCol: number;
  arrayGroupCol: arrayGroupColType;
  arrayItemsRender: arrayItemsRenderType;
  item: ItensGroup;
  checkbox: any;
  setCheckbox: any;
  idxLastGroupOne: any;
  idxLastGroupTwo: any;
  group: any;
  formRef: any;
}

type arrayGroupColType = [number, number, number][];
type arrayItemsRenderType = React.ReactNode[];

interface getIdxArrGrpColParams {
  ieResultado: string;
  foundFirstChkRef: { current: boolean };
  idx: number;
  arrayGroupCol?: arrayGroupColType;
}

interface ItemTitleProps {
  item: ItensGroup;
}

type ItemLabelProps = Pick<ItemTitleProps, 'item'>;

function getIdxArrGrpCol({
  ieResultado,
  foundFirstChkRef,
  idx,
  arrayGroupCol,
}: getIdxArrGrpColParams) {
  const foundFirstChkRefChgd = foundFirstChkRef;
  let changed = false;
  let newIdx = idx;
  if (ieResultado === 'B') {
    if (!foundFirstChkRef.current) {
      foundFirstChkRefChgd.current = true;
    }
  } else if (foundFirstChkRef.current) {
    if (arrayGroupCol) {
      arrayGroupCol.push([0, 0, 0]);
    }
    foundFirstChkRefChgd.current = false;
    changed = true;
    newIdx += 1;
  }

  return { newIdx, changed };
}

function mountArrayGroupCol(group: dataPreEvalGrupo) {
  const foundFirstChkRef = { current: false };
  let idxArrGroupCol = 0;
  const arrayGroupCol: arrayGroupColType = [[0, 0, 0]];
  let idxColGroupChk = 0;

  group.itens.forEach((item) => {
    const resp = getIdxArrGrpCol({
      ieResultado: item.ie_resultado,
      idx: idxArrGroupCol,
      foundFirstChkRef,
      arrayGroupCol,
    });

    idxArrGroupCol = resp.newIdx;

    if (resp.changed) {
      idxColGroupChk = 0;
    }

    if (item.ie_resultado === 'B') {
      arrayGroupCol[idxArrGroupCol][idxColGroupChk] += 1;
      idxColGroupChk = (idxColGroupChk + 1) % 3;
    }
  });

  return arrayGroupCol;
}

const ItemTitle: React.FC<ItemTitleProps> = ({ item }: ItemTitleProps) => (
  <PageTitle>{item.ds_item}</PageTitle>
);

const ItemLabel: React.FC<ItemLabelProps> = ({ item }: ItemLabelProps) => (
  <PageSubtitle>{item.ds_item}</PageSubtitle>
);

function setArrayChckGrpRender({
  arrayChckGrpRenderRef,
  arrayChckItemRenderRef,
}: SetArrayChckGrpRenderParams) {
  arrayChckGrpRenderRef.current.push(
    <Col md={12} xl={4} key={arrayChckGrpRenderRef.current.length}>
      {[arrayChckItemRenderRef.current]}
    </Col>
  );
  // eslint-disable-next-line no-param-reassign
  arrayChckItemRenderRef.current = [];
}
const disabled = false;
function handleOnChange({ ev, group, formRef, checkbox, setCheckbox }) {
  const { name, value, checked } = ev.target;
  if (group.ds_grupo === 'Pré-avaliação Pediatrica') {
    const idx = group.itens.find(
      (item, indexItem) =>
        item.ds_item ===
        'Está assintomática, é uma consulta para orientações de rotina'
    );
    const idx2 = group.itens.find(
      (item, indexItem2) =>
        item.ds_item === 'Não possui nenhuma das condições citadas'
    );
    const x = document.querySelectorAll('.checkbox');
    let idxAssintomatico = 0;
    x.forEach((item: any, index: number) => {
      if (item.name === name) idxAssintomatico = index;
    });

    if (name === `fichaitem_${idx.idficha_avaliacao_item}` && value === 'on') {
      x.forEach((check: any, index) => {
        if (check.name !== name && index >= 0 && index < 10)
          formRef.current?.setFieldValue(check.name, 'N');
      });
    } else if (idxAssintomatico < 10) {
      formRef.current?.setFieldValue(
        `fichaitem_${idx.idficha_avaliacao_item}`,
        'N'
      );
    }

    if (name === `fichaitem_${idx2.idficha_avaliacao_item}` && value === 'on') {
      x.forEach((check: any, index) => {
        if (check.name !== name && index >= 10 && index < 20)
          formRef.current?.setFieldValue(check.name, 'N');
      });
    } else if (idxAssintomatico >= 10 && idxAssintomatico < 20) {
      formRef.current?.setFieldValue(
        `fichaitem_${idx2.idficha_avaliacao_item}`,
        'N'
      );
    }
  }
}

function setItemCheckbox({
  arrayChckGrpRenderRef,
  arrayChckItemRenderRef,
  idxArrGroupCol,
  arrayGroupCol,
  arrayItemsRender,
  item,
  checkbox,
  setCheckbox,
  idxLastGroupOne,
  idxLastGroupTwo,
  group,
  formRef,
}: SetItemCheckboxParams) {
  const groupCol = arrayGroupCol[idxArrGroupCol];
  let idxCol = arrayChckGrpRenderRef.current.length;
  let maxItemsCol = groupCol[idxCol];
  // Identifica se o grupo da coluna atual já finalizou
  if (arrayChckItemRenderRef.current.length === maxItemsCol) {
    setArrayChckGrpRender({
      arrayChckGrpRenderRef,
      arrayChckItemRenderRef,
    });

    idxCol += 1;
    maxItemsCol = groupCol[idxCol];
  }

  arrayChckItemRenderRef.current.push(
    <DrCheckbox
      name={`fichaitem_${item.idficha_avaliacao_item}`}
      key={item.idficha_avaliacao_item}
      className="checkbox"
      initialValue={item.value}
      label={item.ds_item}
      onChange={(ev) =>
        handleOnChange({
          ev,
          group,
          formRef,
          checkbox,
          setCheckbox,
        })
      }
      disabled={disabled}
    />
  );

  // Identifica se foi montada a última coluna de um grupo de checkboxes
  if (
    arrayChckItemRenderRef.current.length === maxItemsCol &&
    (idxCol === 2 || groupCol[idxCol + 1] === 0)
  ) {
    setArrayChckGrpRender({
      arrayChckGrpRenderRef,
      arrayChckItemRenderRef,
    });

    arrayItemsRender.push(
      <Row key={`group-chk-${idxArrGroupCol}`}>
        {[arrayChckGrpRenderRef.current]}
      </Row>
    );

    // eslint-disable-next-line no-param-reassign
    arrayChckGrpRenderRef.current = [];
  }
}

function mountArrayItemsRender(
  group: dataPreEvalGrupo,
  arrayGroupCol: arrayGroupColType,
  checkbox,
  setCheckbox,
  formRef
) {
  const foundFirstChkRef = { current: false };
  let idxArrGroupCol = 0;
  const arrayChckGrpRenderRef: { current: React.ReactNode[] } = { current: [] };
  const arrayChckItemRenderRef: { current: React.ReactNode[] } = {
    current: [],
  };
  const arrayItemsRender: arrayItemsRenderType = [];

  group.itens.forEach((item, index) => {
    const respGetIdxArrGrp = getIdxArrGrpCol({
      ieResultado: item.ie_resultado,
      idx: idxArrGroupCol,
      foundFirstChkRef,
      arrayGroupCol,
    });

    idxArrGroupCol = respGetIdxArrGrp.newIdx;

    const idxLastGroupOne =
      item.ds_item ===
      'Está assintomática, é uma consulta para orientações de rotina'
        ? index
        : -1;

    const idxLastGroupTwo =
      item.ds_item === 'Não possui nenhuma das condições citadas' ? index : -1;

    if (item.ie_resultado === 'T') {
      arrayItemsRender.push(
        <ItemTitle item={item} key={item.idficha_avaliacao_item} />
      );
    } else if (item.ie_resultado === 'A') {
      arrayItemsRender.push(
        <ItemLabel item={item} key={item.idficha_avaliacao_item} />
      );
    } else if (item.ie_resultado === 'B') {
      setItemCheckbox({
        arrayChckGrpRenderRef,
        arrayChckItemRenderRef,
        idxArrGroupCol,
        arrayGroupCol,
        arrayItemsRender,
        item,
        checkbox,
        setCheckbox,
        idxLastGroupOne,
        idxLastGroupTwo,
        group,
        formRef,
      });
    }
  });

  return arrayItemsRender;
}

function createArrItemsRender(group, checkbox, setCheckbox, formRef) {
  const arrayGroupCol = mountArrayGroupCol(group);
  return mountArrayItemsRender(
    group,
    arrayGroupCol,
    checkbox,
    setCheckbox,
    formRef
  );
}

const DrRenderGrpPreEval: React.FC<DrRenderGrpPreEvalProps> = ({
  dataPreEvaluation,
  idxGroup,
  formRef,
}: DrRenderGrpPreEvalProps) => {
  const [checkbox, setCheckbox] = React.useState<any>('N');
  const group = dataPreEvaluation.grupos[idxGroup];
  const arrItemsRender = createArrItemsRender(
    group,
    checkbox,
    setCheckbox,
    formRef
  );

  return (
    <>
      <PageTitle>{group.ds_grupo}</PageTitle>
      {arrItemsRender}
    </>
  );
};

export default DrRenderGrpPreEval;

import styled, { css } from 'styled-components';

interface DrImageStylProps {
  size: any;
}

export const DrImageStyl = styled.img<DrImageStylProps>`
  ${(props) => {
    const { size } = props;
    return css`
      ${size.width ? `width: ${size.width}px;` : ''}
      ${size.height ? `height: ${size.height}px;` : ''}
    `;
  }}
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styled';
import logo from '../../assets/marca/logo.png';
import { useAuth } from '../../hooks/auth';

const DrHeaderWellcome: React.FC = () => {
  const { applicationData } = useAuth();
  return (
    <Container id="drHeaderWellcome">
      <header>
        <Link to="/" rel="nofollow">
          <div className="site-header_logo">
            <img
              onError={(el) => {
                el.currentTarget.src = logo;
              }}
              src={applicationData ? applicationData.ds_icon_app : undefined}
              width="180"
              height="180"
              alt=""
            />
          </div>
        </Link>
      </header>
    </Container>
  );
};

export default DrHeaderWellcome;

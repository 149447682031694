import styled from 'styled-components';

export const ContainerAttendance = styled.div`
  @media screen and (min-width: 992px) {
    .col-xl-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
  }
`;
export const SpanLabel = styled.span`
  color: #000;
`;

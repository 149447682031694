import styled from 'styled-components';

export const SiteFooter = styled.footer`
  margin-top: 15px;
  padding: 15px 0 15px;
  position: relative;
  z-index: 99;

  @media (max-width: 1200px) {
    margin-bottom: 100px;
  }

  :before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1199px;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
  }
`;
export const FootCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
  margin-top: 0px;
  p {
    margin-bottom: 0;
    color: #777;
    font-size: 12px;
  }
`;
export const FootLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    margin-bottom: 0;
    color: #777;
    font-size: 12px;
  }
`;
export const FootBannerApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;

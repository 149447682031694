import { createGlobalStyle } from 'styled-components';
import urlIcomoonEot from '../assets/fonts/icomoon.eot';
import urlIcomoonTtf from '../assets/fonts/icomoon.ttf';
import urlIcomoonWoff from '../assets/fonts/icomoon.woff';
import urlIcomoonSvg from '../assets/fonts/icomoon.svg';

export default createGlobalStyle`
// *{
//   outline: 0;
//   box-sizing: border-box;
//   text-decoration: none !important;
//   outline: none !important;
// }

// @font-face {
//   font-family: icomoon;
//   src: local('icomoon'), url(${urlIcomoonEot}?bogp26);
//   src: local('icomoon'), url(${urlIcomoonEot}?bogp26#iefix) format("embedded-opentype"), url(${urlIcomoonTtf}?bogp26) format("truetype"), url(${urlIcomoonWoff}?bogp26) format("woff"), url(${urlIcomoonSvg}?bogp26#icomoon) format("svg");
//   font-style: normal;
//   font-display: block;
// }
//  body {
//   font-family: Montserrat,sans-serif;
//   background-color: #f5f7f9;
//   color: #777;
//   position: relative;
//  }

// //  Cor de fundo do autocomplete
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus, .form-control{
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
//     -webkit-text-fill-color: #777 !important;
//     -webkit-text-fill-size: 18px !important;
// }
// @media (max-width: 1199px){
//   .row-btn > div + div {
//       margin-top: 20px;
//   }
// }
// @media (min-width: 1400px){
//   .container {
//       max-width: 1320px;
//   }
// }
//
//  Cor de fundo do autocomplete
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus{
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
//     -webkit-text-fill-color: #777 !important;
//     -webkit-text-fill-size: 18px !important;
// }
// .form-control{
//   box-shadow: none !important;
// }
// input::-webkit-input-placeholder{
//   -webkit-text-fill-color: #a4a4a4 !important;

// }
// @media (max-width: 1199px){
//   .row-btn > div + div {
//       margin-top: 20px;
//   }
// }
// @media (min-width: 1400px){
//   .container {
//       max-width: 1320px;
//   }
// }

// input, select, textarea, button {
//   appearance: none;
//   border: none;
//   box-sizing: border-box;
//   box-shadow: none;
//   color: #000;
//   display: block;
//   font-family: inherit;
//   font-size: 16px;
//   font-weight: 300;
//   line-height: 14px;
//   margin: 0;
//   outline: none;
//   padding: 0;
//   position: relative;
//   resize: none;
// }

// input[type="text"], input[type="number"], input[type="password"], input[type="email"], input[type="tel"], input[type="file"], input[type="search"], select, textarea {
//   border-radius: 3px;
//   border: solid 2px #e5e5e5;
//   width: 100%;
//   font-size: 18px;
//   color: #777 !important;
//   opacity: 1;
//   min-height: 54px;
//   vertical-align: middle;
//   padding: 0;
// }
`;

/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Form } from '@unform/web';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useToast, ToastContextData } from '../../../hooks/toast';
import { AnchorStyled } from '../../../styles/link-styled';
import { PageTitle } from '../../../styles/page-title';
import { CardBlank } from '../../../styles/card-blank';
import { Fields } from '../../../styles/fields';
import DrCheckbox from '../../dr-input-checkbox';
import { valueParam, verifyErrorYup } from '../../../utils/bibli';
import { useAuth, User, AuthContextData } from '../../../hooks/auth';
import { ScreenProps, screenType } from '../index';
import { useIsMounted } from '../../../hooks/is-mounted';
import DrButtonsFooter from '../../dr-buttons-footer';

async function cbOnSubmit(
  data,
  formRef: React.RefObject<FormHandles>,
  addToast: ToastContextData['addToast'],
  user: User,
  requestAPI: AuthContextData['requestAPI'],
  setLoadingIsMounted: (loading: boolean) => void,
  setScreenIsMount: (screen: screenType) => void,
  sendError: (message: any) => Promise<void>
) {
  try {
    formRef.current?.setErrors({});
    const schema = Yup.object().shape({
      termos_uso_dados: Yup.string().matches(/S/g, 'Campo Obrigatório').trim(),
      termos_privacidade: Yup.string()
        .matches(/S/g, 'Campo Obrigatório')
        .trim(),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    setLoadingIsMounted(true);

    await requestAPI({
      method: 'PUT',
      url: `/tmUsuarioTermosAtendimento/${user.idtm_usuario}`,
    });

    setScreenIsMount('equipament-test');
  } catch (error: any) {
    sendError(error);
    setLoadingIsMounted(false);
    if (error instanceof Yup.ValidationError) {
      verifyErrorYup(error, formRef);
    } else {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: error.message,
      });
    }
  }
}

interface ConsultationTermsProps {
  setScreen: ScreenProps['setScreen'];
}

const ConsultationTerms: React.FC<ConsultationTermsProps> = ({
  setScreen,
}: ConsultationTermsProps) => {
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef<FormHandles>(null);
  const { user, requestAPI, applicationData, sendError } = useAuth();
  const { addToast } = useToast();
  const isMountedRef = useIsMounted();

  const onSubmit = React.useCallback<SubmitHandler<any>>(
    (data) => {
      cbOnSubmit(
        data,
        formRef,
        addToast,
        user,
        requestAPI,

        (loadingParam) => {
          if (isMountedRef.current) {
            setLoading(loadingParam);
          }
        },
        (screenParam) => {
          if (isMountedRef.current) {
            setScreen(screenParam);
          }
        },
        sendError
      );
    },
    [addToast, user, requestAPI, setScreen, isMountedRef]
  );

  return (
    <Form ref={formRef} onSubmit={onSubmit}>
      <CardBlank className="pt-3 pb-3 container">
        <PageTitle>Nova consulta</PageTitle>
        <Fields>
          <Row className="justify-content-center">
            <Col xl={5}>
              <DrCheckbox
                name="termos_privacidade"
                label={
                  <>
                    Aceito os{' '}
                    <AnchorStyled
                      href={valueParam(applicationData, 173)}
                      target="_blank"
                    >
                      termos de privacidade
                    </AnchorStyled>
                    .
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl={5}>
              <DrCheckbox
                name="termos_uso_dados"
                label={
                  <>
                    Aceito os{' '}
                    <AnchorStyled
                      href={valueParam(applicationData, 174)}
                      target="_blank"
                    >
                      termos de uso dos seus dados
                    </AnchorStyled>
                    .
                  </>
                }
              />
            </Col>
          </Row>
        </Fields>
      </CardBlank>
      <DrButtonsFooter
        buttonLeft={{ type: 'submit', title: 'Aceito', loading }}
      />
    </Form>
  );
};

export default ConsultationTerms;

import io from 'socket.io-client';
import React from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import moment from 'moment';
import NoSleep from 'nosleep.js';
import platform from 'platform';
import {
  AttendanceDataTypes,
  User,
  AuthContextData,
  ApplicationData,
} from '../hooks/auth';
import api from '../services/api';
import { ToastContextData } from '../hooks/toast';
import getValidationErrors from './getValidationErrors';
import { userRequirements } from './validations';
import { getTokenFB, isSupport } from '../firebase';
import { getUserDeviceTimeZone } from './util';
// import { CardPayOptionsProps } from '../pages/PayOptions/card-pay-options';

interface MyColumn {
  myColumn: string;
}

interface ArrayCount {
  forEach(arg0: (item: any) => void);
  array?: Array<MyColumn>;
}

export interface handlePaginationParams {
  countRef: React.MutableRefObject<number>;
  url: string;
  setState: React.Dispatch<any>;
  requestAPI: AuthContextData['requestAPI'];
  itensPerPage: number;
  idField: string;
  addToast: ToastContextData['addToast'];
  sendError?: (error: any) => Promise<void>;
  setLoading: React.Dispatch<boolean>;
  setDataEnded?: React.Dispatch<boolean>;
  cbAfterGetData?: (arrayData: []) => void;
  insideData?: boolean;
  isPublic?: boolean;
  titleError?: string;
  sort?:
    | {
        attribute: string;
        typeSort: 'desc' | 'asc';
      }
    | { (firstEl, secondEl): number };
}

class ErrorAPIRest extends Error {
  data: any;

  constructor(message, data) {
    super(message);
    this.name = 'ErrorAPIRest';
    this.data = data;

    // global.Error.captureStackTrace(this, this.constructor); Causando erro quando é iOS.
  }
}

export async function executeUrl(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: any,
  pagination?: boolean,
  header?: any
): Promise<any> {
  try {
    let resp;
    const headers = header || {};
    headers.timezone = getUserDeviceTimeZone();

    if (method === 'GET') {
      resp = await api(headers).get(url);
    } else if (method === 'PUT') {
      resp = await api(headers).put(url, body);
    } else if (method === 'POST') {
      resp = await api(headers).post(url, body);
    } else if (method === 'DELETE') {
      resp = await api(headers).delete(url);
    } else {
      throw new Error(`Metodo informado (${method}) não implementado!`);
    }

    if (pagination) return resp;
    return resp ? resp.data : resp;
  } catch (err: any) {
    let msg;
    let data;
    if (err.response && err.response.data && err.response.data.error) {
      msg = err.response.data.error;
      data = err.response.data.data;
    } else msg = err.message;
    if (typeof msg === 'object') {
      msg = msg.msg;
    }

    msg = msg.replace('Validation error: ', '');
    msg = msg.replace('Network Error', 'Verifique sua conexão!');

    throw new ErrorAPIRest(msg, data);
  }
}

export function convertUrlToFile(
  url: string | null | undefined,
  fileName: string
): File | undefined {
  if (url) {
    const blobBin = atob(url.split(',')[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i += 1) {
      array.push(blobBin.charCodeAt(i) as never);
    }

    const filee = new File([new Uint8Array(array)], fileName, {
      type: 'image/png',
    });

    return filee;
  }
  return undefined;
}

export function detectIOS(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
function isIpad() {
  const ua = platform.parse(navigator.userAgent);
  const isIPad =
    (ua.tablet && ua.os.family === 'iOS') || ua.os.family === 'OS X';
  return isIPad;
}

export function detectMobile(): boolean {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    isIpad()
  ) {
    return true; // está utilizando celular
  }

  return false; // não é celular
}

declare global {
  interface Window {
    opera: any;
    safari: any;
    chrome: any;
    HTMLElement: any;
  }

  interface Document {
    documentMode: any;
  }
}

export function detectBrowser(): string | undefined {
  let safari: any;

  if (!!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
    return 'opera';
  }

  // Firefox 1.0+
  if ('InstallTrigger' in window) {
    return 'firefox';
  }

  // Safari 3.0+ "[object HTMLElementConstructor]"
  if (
    /constructor/i.test(window.HTMLElement) ||
    (function f(p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window.safari ||
        (typeof safari !== 'undefined' && window.safari.pushNotification)
    )
  ) {
    return 'safari';
  }

  // Internet Explorer 6-11
  if (document.documentMode) {
    return 'internet-explorer';
  }

  // Edge 20+
  if (
    !document.documentMode &&
    window.navigator.userAgent.indexOf('Edge') > -1
  ) {
    return 'edge';
  }

  const { userAgent } = navigator;

  if (userAgent.includes('Edg/')) {
    return 'edge';
  }

  // Chrome 1 - 79
  if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime)
  ) {
    return 'chrome';
  }

  if (userAgent.includes(' Opera/')) {
    return 'opera';
  }

  if (userAgent.includes(' Firefox/') && !userAgent.includes(' Seamonkey/')) {
    return 'firefox';
  }

  if (
    userAgent.includes(' Safari/') &&
    !userAgent.includes(' Chrome/') &&
    !userAgent.includes(' Chromium/')
  ) {
    return 'safari';
  }

  if (userAgent.includes(' Chrome/') && !userAgent.includes(' Chromium/')) {
    return 'chrome';
  }

  return undefined;
}

export function replaceAll(
  string: string,
  search: string,
  replace: string
): string {
  return string.split(search).join(replace);
}

export function handleArrayCountAttributeNotNull(
  pArray: Array<any>,
  pName: string
): number {
  let count = 0;
  const strArray = JSON.stringify(pArray);
  const newString = replaceAll(strArray, pName, 'myColuna');

  const array = JSON.parse(newString) as ArrayCount;

  Object.keys(array).forEach((key) => {
    const value = array[key];

    if (value.myColuna !== null) {
      count += 1;
    }
  });

  return count;
}

export function somenteNumeros(str: string | undefined) {
  let result = str;

  // if (result) result = result.replace(/[^0-9]+/g, '');
  if (result) result = result.replace(/\D+/g, '');
  return result;
}

export function getDeviceInfos(): any {
  const info: {
    // ie_sistema_operacional?: string | null;
    ie_navegador?: string;
    bool_mobile?: boolean;
  } = {
    // ie_sistema_operacional: detectIOS() ? 'ios' : null,
    ie_navegador: detectBrowser(),
    bool_mobile: detectMobile(),
  };

  return info;
}

export function valueParam(applicationData, idParam) {
  if (applicationData && applicationData.valores_parametros) {
    return applicationData.valores_parametros[idParam];
  }
  return null;
}

export function handleSocketConnection(
  idatendimento_paciente: Number | undefined,
  user: User,
  reconnection = true,
  history?,
  addToast?,
  sendError?: (error: any) => Promise<void>,
  applicationData?: ApplicationData
) {
  let socket;
  try {
    if (applicationData?.valores_parametros) {
      const info = getDeviceInfos();
      socket = io.connect(valueParam(applicationData, 80), {
        query: {
          dadosSession: JSON.stringify({
            u: idatendimento_paciente, // u: idatendimento_paciente (Opcional),
            v: user.jwt.idapi_token, // v: idapi_token do usuário (Obrigatório),
            w: user.idestabelecimento, // w: idestabelecimento do usuário (Obrigatório)
            x: user.idpessoa_fisica, // x: idpessoa_fisica do usuário (Obrigatório),
            // y: idusuario do usuário (Opcional),
            z: user.idtm_usuario, // z: idtmusuario do usuário (Opcional)
            // ie_sistema_operacional: info.ie_sistema_operacional,
            bool_mobile: info.bool_mobile,
            ie_navegador: info.ie_navegador,
          }),
        },
        reconnection,
      });

      socket.on('connect_error', (err: any) => {
        // eslint-disable-next-line no-console
        console.log('connect_error, ', err);
      });

      socket.on('connection-success', () => {
        // eslint-disable-next-line no-console
        console.log('connection successs');
      });

      socket.on('Error', (error: any) => {
        // eslint-disable-next-line no-console
        console.log('socket Error', error);
        if (history) {
          addToast({
            type: 'danger',
            title: 'Ops!',
            description: `Ocorreu um erro ao fazer a conexão ${error.msg}`,
          });
          history.push('/attendance-now');
        }
      });
    }
  } catch (err: any) {
    if (sendError) sendError(err);
  }

  return socket;
}

export function observerForPagination(
  pagination: () => Promise<boolean | null>,
  elemObserve: HTMLElement | null
): IntersectionObserver {
  if (elemObserve === null) {
    throw new Error(
      'Error em observerForPagination.elemObserver não pode ser null'
    );
  }

  const handleObserver = async (entities, observerParam) => {
    const target = entities[0];
    if (target.isIntersecting) {
      const hasData = await pagination();
      observerParam.unobserve(elemObserve);

      if (hasData === true) {
        observerParam.observe(elemObserve);
      }
    }
  };
  const observer = new IntersectionObserver(handleObserver, {
    root: null,
    rootMargin: '20px',
    threshold: 1.0,
  });
  observer.observe(elemObserve);

  return observer;
}

export function handleConcatUserData(data: any) {
  if (data) {
    if (data.nr_celular && data.nr_celular.length <= 9) {
      data.nr_celular = data.nr_ddd_celular + data.nr_celular;
    }

    if (data.url_img && !data.url_img.includes('?')) {
      data.url_img += `?${Date.now()}`;
    }
    handleDateAjust(data);

    if (data.tm_usuario_grupo_familiar?.length) {
      data.tm_usuario_grupo_familiar.forEach((membro) => {
        if (membro.url_img && !membro.url_img.includes('?')) {
          membro.url_img += `?${Date.now()}`;
        }

        if (membro.nr_celular && membro.nr_celular.length <= 9) {
          membro.nr_celular = membro.nr_ddd_celular + membro.nr_celular;
        }
      });
    }
  }

  return data;
}

export function handleDateAjust(data: any) {
  if (data) {
    Object.keys(data).forEach((val) => {
      if (val.includes('dt_') && data[val] && data[val].length === 10) {
        if (data[val].includes('/')) {
          // eslint-disable-next-line no-return-assign
          return (data[val] = moment(data[val], 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          ));
        }
        if (data[val].includes('-') && !data[val].endsWith('-')) {
          // eslint-disable-next-line no-return-assign
          return (data[val] = moment(data[val], 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          ));
        }
      }

      return data;
    });
  }
}

export function formatDateISO(pDate: any): string {
  // if (pDate) {
  //   const day = pDate.getDate();
  //   const month = pDate.getMonth() + 1;
  //   const year = pDate.getFullYear();
  //   return `${year}-${month}-${day}`;
  // }
  if (pDate && pDate.includes('-') && !pDate.endsWith('-')) {
    return pDate;
  }
  if (pDate && pDate.includes('/')) {
    return moment(pDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return pDate;
}

export function setDataNull(data: any): any {
  if (data) {
    return Object.keys(data).forEach((key) => {
      if (!data[key] || data[key].length === 0) data[key] = null;
    });
  }
  return null;
}

export function scrollPositionTop() {
  window.scrollTo(0, 0);
}

/** Trata da paginação de dados.
 * @returns True, caso tenha novos dados. False, caso não tenha. Null caso dê erro antes ou durante a requisição de dados.
 */
export async function handlePagination({
  countRef,
  url,
  setState,
  requestAPI,
  itensPerPage,
  idField,
  addToast,
  sendError,
  setLoading,
  setDataEnded,
  cbAfterGetData,
  insideData,
  isPublic,
  titleError,
  sort,
}: handlePaginationParams): Promise<boolean | null> {
  let ret: boolean | null = null;
  try {
    setLoading(true);
    const countRefChange = countRef;
    countRefChange.current += 1;

    let urlChange = url;
    if (urlChange.includes('?')) {
      urlChange += '&';
    } else {
      urlChange += '?';
    }
    urlChange += `itensPerPage=${itensPerPage}`;
    urlChange += `&page=${countRefChange.current}`;
    const resp = await requestAPI({
      method: 'GET',
      url: urlChange,
      pagination: insideData,
      isPublic,
    });

    let respApi = resp;
    if (insideData) {
      respApi = resp.data;
    }

    if (respApi.currentPage === respApi.totalPages) {
      ret = false;

      if (setDataEnded) {
        setDataEnded(true);
      }
    } else {
      ret = true;
    }

    const arrayData = respApi.data;

    setState((prev) => {
      if (prev && prev.length) {
        const idxOldRecord = arrayData.findIndex(
          (data) => data[idField] === prev[prev.length - 1][idField]
        );

        if (idxOldRecord !== -1) {
          arrayData.splice(0, idxOldRecord + 1);
        }
      }

      const arrayReturn = [...prev, ...arrayData];

      if (sort) {
        if (typeof sort === 'object') {
          arrayReturn.sort((firstEl, secondEl) => {
            if (
              (sort.typeSort === 'desc' &&
                secondEl[sort.attribute] > firstEl[sort.attribute]) ||
              (sort.typeSort === 'asc' &&
                secondEl[sort.attribute] > firstEl[sort.attribute])
            ) {
              return 1;
            }

            return 0;
          });
        } else if (typeof sort === 'function') {
          arrayReturn.sort(sort);
        }
      }

      return arrayReturn;
    });

    if (cbAfterGetData) {
      cbAfterGetData(arrayData);
    }
  } catch (err: any) {
    if (sendError) sendError(err);
    addToast({
      type: 'error',
      title: titleError || 'Ops',
      description: err.message.replace('Validation error: ', ''),
    });
  } finally {
    setLoading(false);
  }

  return ret;
}

export function verifyErrorYup(
  error: Yup.ValidationError,
  formRef: React.RefObject<FormHandles>,
  addToast?: ToastContextData['addToast']
): void {
  if (error instanceof Yup.ValidationError) {
    const errors = getValidationErrors(error);
    const errorKey = Object.keys(errors)[0];

    if (addToast && errors) {
      if (errorKey === 'dados_verdadeiros' || errorKey === 'termos_aceite') {
        const firstErrorInObject = Object.values(errors)[0];

        addToast({
          type: 'error',
          title: 'Ops!',
          description: firstErrorInObject,
        });
      }
    }

    formRef.current?.setErrors(errors);
    const inputError = Object.keys(errors)[0];
    const elemInput = formRef.current?.getFieldRef(inputError);

    if (elemInput.current) {
      elemInput.current.focus();
    } else if (elemInput) {
      elemInput.focus();
    }
  }
}

export function firstName(user: User): string {
  if (user?.nm_social) {
    return user?.nm_social.trim().split(' ')[0];
  }
  return user?.nm_pessoa.trim().split(' ')[0];
}

export interface AttendanceOpenedParams {
  respAttendanceRef: React.MutableRefObject<any>;
  setAttendanceData: React.Dispatch<any>;
  setModal: React.Dispatch<any>;
  setLoading: React.Dispatch<boolean>;
  requestAPI: AuthContextData['requestAPI'];
  addToast: ToastContextData['addToast'];
  sendError?: (error: any) => Promise<void>;
  isMountedRef: React.MutableRefObject<boolean>;
  history: any;
  patient: any;
  user: User;
  userRequirements: (data: any, validation: any) => boolean;
  attendance: AttendanceDataTypes;
  applicationData: any;
  fromReturn: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function attendanceOpened({
  user,
  patient,
  respAttendanceRef,
  setAttendanceData,
  history,
  addToast,
  sendError,
  requestAPI,
  setModal,
  isMountedRef,
  setLoading,
  fromReturn,
  attendance,
  applicationData,
}: AttendanceOpenedParams) {
  try {
    let pathname = `/update-family-member/${patient.idtm_usuario_grupo_familiar}`;
    let arrayRequirements: any = [];
    const paramsUser = valueParam(applicationData, 209);
    const paramsFamily = valueParam(applicationData, 214);
    const isUploadPage = valueParam(applicationData, 241);
    if (!user) {
      history.push('/types-care');
      return;
    }
    if (user.idpessoa_fisica === patient.idpessoa_fisica && paramsUser) {
      pathname = `/update-user-registration-datas/${user.idtm_usuario}`;
      arrayRequirements = paramsUser.split(',');
      if (
        !arrayRequirements.some((field) => field === 'url_img') &&
        valueParam(applicationData, 210) === 'S'
      ) {
        arrayRequirements.push('url_img');
      }
    } else if (paramsFamily) {
      arrayRequirements = paramsFamily.split(',');
      if (
        !arrayRequirements.some((field) => field === 'url_img') &&
        valueParam(applicationData, 211) === 'S'
      ) {
        arrayRequirements.push('url_img');
      }
    }

    if (userRequirements(patient, arrayRequirements)) {
      history.push({
        // eslint-disable-next-line dot-notation
        pathname,
        state: 'new-attendance-incomplete-data',
      });
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `É necessário completar o formulário de cadastro antes de continuar`,
      });
      return;
    }
    setLoading(true);

    // eslint-disable-next-line no-param-reassign
    respAttendanceRef.current = await requestAPI({
      method: 'GET',
      url: `/atendimentoAberto/${user.idpessoa_fisica}/${patient.idpessoa_fisica}?idestabelecimento_tipo_atendimento=${attendance.idestabelecimento_tipo_atendimento}`,
    });
    let title = '';

    const localStorageDatas: AttendanceDataTypes = {};

    localStorageDatas.idpessoa_paciente = patient.idpessoa_fisica;
    localStorageDatas.nm_pessoa_paciente = patient.nm_pessoa;
    localStorageDatas.idpessoa_titular = user ? user.idpessoa_fisica : 0;
    localStorageDatas.tipos_atendimento = attendance.tipos_atendimento;
    localStorageDatas.idespecialidade = attendance.idespecialidade;

    if (isMountedRef.current) {
      if (respAttendanceRef.current && respAttendanceRef.current.length) {
        // usuário possui um pagamento em aberto, segue para pré-avaliação ou se é retorno paga check-in

        localStorageDatas.idconta_receber_cartao_movto =
          respAttendanceRef.current[0].idconta_receber_cartao_movto;

        localStorageDatas.ie_telemedicina = 'S';
        localStorageDatas.modalidade = 2;
        localStorageDatas.vl_total_servico =
          respAttendanceRef.current[0].vl_transacao;
        localStorageDatas.ie_retorno =
          respAttendanceRef.current[0].ie_retorno || 'N';

        title = 'Sua consulta ja está paga, deseja ser atendido agora?';
      } else if (!(Object.keys(respAttendanceRef.current).length === 0)) {
        // usuário possui um atendimento em aberto, segue para video-chamada

        localStorageDatas.nm_pessoa_paciente =
          respAttendanceRef.current.nm_paciente;

        localStorageDatas.idatendimento_paciente =
          respAttendanceRef.current.idatendimento_paciente;

        localStorageDatas.idpessoa_paciente =
          respAttendanceRef.current.idpessoa_fisica;

        localStorageDatas.ordem =
          respAttendanceRef.current.ordem_fila.atendimentos[0]?.ordem;

        localStorageDatas.tme =
          respAttendanceRef.current.ordem_fila?.tme?.split('.', 1);
        localStorageDatas.avaliacao_atendimento =
          respAttendanceRef.current.avaliacao_atendimento;

        localStorageDatas.idSocketSala =
          respAttendanceRef.current.idsocket_sala;
        localStorageDatas.dsKey = respAttendanceRef.current.ds_key;

        localStorageDatas.ie_retorno = respAttendanceRef.current.ie_retorno;
        localStorageDatas.avaliacao_atendimento =
          respAttendanceRef.current.avaliacao_atendimento;

        title =
          'Você possui um atendimento em andamento, deseja ser atendido agora?';
      }
      await setAttendanceData(localStorageDatas);
      setLoading(false);

      if (title !== '' && !fromReturn && localStorageDatas.ie_retorno !== 'S') {
        setModal({
          showModal: true,
          title,
        });
        return;
      }

      handleNextScreenAttendanceOpened({
        respAttendanceRef,
        history,
        ie_tipo_atendimento: attendance.ie_tipo_atendimento,
        isMountedRef,
        fromReturn,
        isUploadPage,
      });
    }
  } catch (e: any) {
    if (sendError) sendError(e);
    if (isMountedRef.current) {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro ao verificar atendimento! ${e.message}`,
      });
      setLoading(false);
      history.push(`/dashboard`);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleNextScreenAttendanceOpened({
  respAttendanceRef,
  history,
  ie_tipo_atendimento,
  isMountedRef,
  fromReturn,
  isUploadPage,
}: any) {
  if (isMountedRef.current) {
    if (
      respAttendanceRef.current.length &&
      respAttendanceRef.current[0].ie_retorno === 'S' &&
      fromReturn
    ) {
      history.push({
        pathname:
          isUploadPage === 'S'
            ? `/upload-page`
            : `/attendance-options/pronto-atendimento`,
      });
    } else if (
      respAttendanceRef.current.length &&
      respAttendanceRef.current[0].ie_retorno === 'S'
    ) {
      history.push({
        pathname:
          isUploadPage === 'S'
            ? `/upload-page`
            : `/return-attendance/pronto-atendimento`,
      });
    } else if (
      respAttendanceRef.current.length &&
      respAttendanceRef.current[0].ie_retorno === 'N'
    ) {
      history.push({ pathname: `/upload-page` });
    } else if (
      respAttendanceRef.current.idatendimento_paciente &&
      respAttendanceRef.current.idSocketSala &&
      respAttendanceRef.current.dsKey
    ) {
      history.push({ pathname: `/stream-page` });
    } else if (
      respAttendanceRef.current.idatendimento_paciente &&
      !respAttendanceRef.current.idSocketSala &&
      !respAttendanceRef.current.dsKey
    ) {
      history.push({ pathname: `/attendance-now/returnAttendance` });
    } else if (parseInt(ie_tipo_atendimento, 10) === 3) {
      history.push({
        pathname: `/pay-options`,
        state: { fromReturn },
      });
    } else if (parseInt(ie_tipo_atendimento, 10) !== 3) {
      history.push({
        pathname: `/select-specialty/eletivo`,
      });
    }
  }
}

export function getJustFirstName(user: User): string {
  if (user?.nm_social) {
    return user?.nm_social.trim().split(' ')[0].substr(0, 17);
  }
  if (user?.nm_pessoa) {
    return user?.nm_pessoa.trim().split(' ')[0].substr(0, 17);
  }
  return '';
}

export function validateNameComplete(name: string) {
  const regexValidandoInputDeNome =
    /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{1,})+$/.test(
      name
    );
  if (regexValidandoInputDeNome) {
    let validar = true;
    let contador = 0;
    const valoresDoInputSemEspaco = name.trim().split(' ');
    for (let i = 0; i < valoresDoInputSemEspaco.length; i += 1) {
      const valorAtual = valoresDoInputSemEspaco[i];
      if (valorAtual.length === 1) contador += 1;
      else contador = 0;

      if (valorAtual.length === 1 && i + 1 === valoresDoInputSemEspaco.length)
        contador += 2;

      if (contador > 1) {
        validar = false;
        break;
      }
    }
    return validar;
  }

  return regexValidandoInputDeNome;
}

export function handleWhatsAppMessage(
  link_whatsapp: string,
  text?: string,
  replaceText?: boolean,
  user?: User
) {
  let linkWhatsapp = link_whatsapp;

  if (replaceText) {
    linkWhatsapp = linkWhatsapp.substring(0, linkWhatsapp.indexOf('?') + 1);

    linkWhatsapp += `text=${text || ''}`;
  } else {
    linkWhatsapp += text ? ` ${text}` : '';
  }

  if (user) {
    linkWhatsapp = linkWhatsapp.replace('{nm_pessoa}', getJustFirstName(user));
  }
  linkWhatsapp = linkWhatsapp.replace('{plataforma}', 'Web');

  return linkWhatsapp;
}

export function handleJsonPayment({
  installmentsSignature,
  installmentsAttendance,
  optionInstallment,
  idx,
  dashboard,
}: any) {
  const paymentValues: any = {};
  const deleteParams: any = [];

  paymentValues.ie_recorrente = installmentsSignature ? 'S' : 'N';
  paymentValues.descricao = optionInstallment.descricao;
  paymentValues.route = '/payment';

  if (installmentsSignature) {
    paymentValues.assinaturas_pln = {
      idproduto_serv: installmentsSignature.idproduto_serv,
      idproduto_serv_regra_pagto: optionInstallment.idproduto_serv_regra_pagto,
    };
    paymentValues.idestabelecimento_tipo_atendimento =
      installmentsSignature.idestabelecimento_tipo_atendimento;

    if (
      installmentsSignature.tipo_atendimento &&
      installmentsSignature.tipo_atendimento.length
    ) {
      const [idestabelecimento_tipo_atendimento] =
        installmentsSignature.tipo_atendimento;
      paymentValues.idestabelecimento_tipo_atendimento =
        idestabelecimento_tipo_atendimento;

      const [...tipos_atendimento] = installmentsSignature.tipo_atendimento;
      paymentValues.tipos_atendimento = tipos_atendimento;
    }
    if (installmentsSignature.ie_dependente)
      paymentValues.ie_dependente = installmentsSignature.ie_dependente;

    paymentValues.nr_parcela = null;

    paymentValues.vl_servico = optionInstallment.vl_produto;
    paymentValues.vl_total_servico = optionInstallment.vl_pagto;
    paymentValues.vl_desconto = optionInstallment.vl_desconto;
    paymentValues.qtd_max_dependente = installmentsSignature.qtd_max_dependente;
    paymentValues.idxBtnAttendance = idx;
    paymentValues.qtd_periodo_contrato =
      installmentsSignature.qtd_periodo_contrato;
    // eslint-disable-next-line no-template-curly-in-string
    if (installmentsSignature.qtd_max_dependente > 0)
      paymentValues.route = '/select-dependents';

    deleteParams.push('opcao_parcela');
  } else if (installmentsAttendance) {
    paymentValues.idconvenio_telemed_vlr =
      installmentsAttendance.idconvenio_telemed_vlr;
    if (installmentsAttendance.idespecialidade) {
      paymentValues.idespecialidade = installmentsAttendance.idespecialidade;
    }

    if (installmentsAttendance.idestabelecimento_tipo_atendimento)
      paymentValues.idestabelecimento_tipo_atendimento =
        installmentsAttendance.idestabelecimento_tipo_atendimento;
    if (installmentsAttendance.ie_tipo_atendimento)
      paymentValues.ie_tipo_atendimento =
        installmentsAttendance.ie_tipo_atendimento;
    if (installmentsAttendance.idconvenio)
      paymentValues.idconvenio = installmentsAttendance.idconvenio;

    paymentValues.vl_servico = installmentsAttendance.vl_atendimento;

    paymentValues.vl_total_servico =
      (installmentsAttendance.vl_atendimento || 0) -
      (installmentsAttendance.vl_desconto || 0);
    paymentValues.vl_desconto = installmentsAttendance.vl_desconto || 0;
    if (installmentsAttendance.nr_parcela)
      paymentValues.opcao_parcela = installmentsAttendance.opcao_parcela;

    if (installmentsAttendance.ie_dependente)
      paymentValues.ie_dependente = installmentsAttendance.ie_dependente;
    paymentValues.qtd_periodo_contrato = null;

    paymentValues.nr_parcela = optionInstallment.qtd;

    paymentValues.idxBtnAttendance = idx;
    // eslint-disable-next-line no-template-curly-in-string
    if (!dashboard && installmentsAttendance.qtd_idade_max)
      paymentValues.route = '/types-care';
    if (dashboard) paymentValues.singleType = true;

    deleteParams.push('assinaturas_pln');
  }

  return { paymentValues, deleteParams };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function upperCaseLetter(str: string, split: string, join: string) {
  const splitStr = str.toLowerCase().split(split);
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(join);
}

export function useQuery(attr: string) {
  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  return query.get(attr);
}

export async function sleep(ms: number) {
  return new Promise((resolve) => {
    const result = setTimeout(resolve, ms);
    if (!ms) clearTimeout(result);
  });
}

export async function handleNoSleep() {
  const noSleep = new NoSleep();
  noSleep.enable();
}

export function handleUseApiLog({
  requestAPI,
  url,
  action,
  message,
  data,
  sendError,
}: any) {
  try {
    if (requestAPI) {
      requestAPI({
        method: 'POST',
        url: '/useApiLog',
        body: {
          action,
          url,
          message,
          data,
        },
      });
    }
  } catch (err) {
    if (sendError) sendError(err);
  }
}

export async function handleGetTokenNotification(
  user,
  requestAPI,
  setStatEquipModified?: any,
  sendError?: (error: any) => Promise<void>
) {
  const infos = getDeviceInfos();
  if (infos.bool_mobile) {
    if (setStatEquipModified)
      setStatEquipModified({
        equip: 'notification',
        msgError:
          'Para uso no celular, deverá baixar o App Dr+ nas lojas da Apple ou Google',
        isMobile: detectMobile(),
      });
    return;
  }
  // Se navegador da suporte notificacao entra nesse if
  if (await isSupport()) {
    const result = await getTokenFB();
    if (result) {
      const infoDevice: any = {};
      infoDevice.token_device = result;
      infoDevice.idpessoa_fisica = user ? user.idpessoa_fisica : null;
      infoDevice.device = detectBrowser();

      requestAPI({
        method: 'POST',
        url: '/tokenDevice',
        body: infoDevice,
      })
        .then(() => {
          if (setStatEquipModified)
            setStatEquipModified({ equip: 'notification', status: 'ok' });
        })
        .then(() => {
          if (setStatEquipModified)
            setStatEquipModified({ equip: 'notification', status: 'ok' });
        })
        .catch((err: any) => {
          if (sendError) sendError(err);
          if (setStatEquipModified)
            setStatEquipModified({
              equip: 'notification',
              msgError: `Não foi possível persistir os dados de Token do dispositivo ${err.message}`,
            });
        });
    } else {
      let msg = `Deve habilitar o uso das notificações para que seja alertado quando o médico chamar para atendimento.${'\n'} `;
      msg +=
        'Acessar as configurações de notificação do seu navegador para ativação';

      if (setStatEquipModified)
        setStatEquipModified({
          equip: 'notification',
          msgError: msg,
        });
    }
  } else {
    // Entra aqui caso navegador nao de suporte para notificacao ex: Safari
    const msgWarning =
      'Navegador não da suporte para notificações, voce não podera ser notificado caso seja necessário!';
    if (setStatEquipModified) {
      setStatEquipModified({
        equip: 'notification',
        status: 'ok',
        msgWarning,
      });
    }
  }
}

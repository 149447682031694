import styled, { css } from 'styled-components';

export const ContainerLoad = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  top: 0;
  svg {
    fill: red;
  }
  h2.msgLoading {
    font-size: 2.5rem;
    color: #4156a6;
    font-family: 'Concert One', cursive;
    letter-spacing: 0.015em;
    text-align: center;
    max-width: 100%;
    font-weight: normal;
  }
  @media screen and (max-width: 850px) {
    max-width: 100%;
  }
`;

export const list = [
  {
    prop: 'balls',
    name: 'Balls',
  },
  {
    prop: 'bars',
    name: 'Bars',
  },
  {
    prop: 'bubbles',
    name: 'Bubbles',
  },
  {
    prop: 'cubes',
    name: 'Cubes',
  },
  {
    prop: 'cylon',
    name: 'Cylon',
  },
  {
    prop: 'spin',
    name: 'Spin',
  },
  {
    prop: 'spinningBubbles',
    name: 'SpinningBubbles',
  },
  {
    prop: 'spokes',
    name: 'Spokes',
  },
];

interface DivLoadingProps {
  notCentralize: boolean;
  ratio?: number;
}

export const DivLoading = styled.div<DivLoadingProps>`
  display: flex;

  ${(props) =>
    !props.notCentralize &&
    css`
      position: absolute;
      top: calc(
        50% + ${props.ratio === undefined ? '15px' : `${15 * props.ratio}px`}
      );
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

interface ImgDrSpinnerProps {
  ratio?: number;
}

export const ImgDrSpinner = styled.img<ImgDrSpinnerProps>`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  position: relative;
  animation: spinner 1500ms infinite linear;
  top: ${(props) =>
    props.ratio !== undefined ? `${-30 * props.ratio}px` : '-30px'};
`;

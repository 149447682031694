export default function onlyNumbers(str: string): string {
  let result = str;

  if (result) result = result.replace(/\D+/g, '');
  return result;
}

export function formatValue(value: any): string {
  if (value) {
    const valueNumber = parseFloat(value);
    return valueNumber.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  return 'R$ 0,00';
}

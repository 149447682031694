import styled from 'styled-components';
import VideoComp from '../dr-video-comp';

export const CardVideo = styled.div`
  position: absolute;
  height: 15vh;
  right: 0.5em;
  bottom: 3.5em;
  border-radius: 10px;
  z-index: 30;
  box-shadow: 0 10px 20px 1px rgb(0 0 0 / 5%);
  background-color: #fff;
  border: 1px solid #a6a6a6;

  @media (min-width: 1200px) {
    height: 20vh;
  }
`;

export const Video = styled(VideoComp)`
  height: 100%;
  border-radius: 10px;
`;

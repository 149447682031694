import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { Container, LabelIcon, DetailCircles } from './styled';

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title' | 'style'> {
  type: 'submit' | 'reset' | 'button';
  color?: string | 'pink' | 'blue' | 'dark-grey' | 'dark-grey' | 'white';
  title?: React.ReactNode;
  titleColor?: string;
  disabled?: boolean;
  href?: string;
  className?: string;
  icon?: any;
  style?: React.CSSProperties;
  iconRight?: boolean;
  marginLeftIcon?: string;
  marginLeftTitle?: string;
  customPadding?: string;
  customWidth?: string;
  spaceBetween?: boolean;
  detailCircles?: boolean;
  textDecoration?: boolean;
  fontWeight?: string;
  // customColor?: 'pink' | 'blue' | 'dark-grey' | 'light-grey' | string;
}

interface handleButtonParams extends Omit<ButtonProps, 'className'> {
  ref: React.ForwardedRef<HTMLButtonElement>;
}

function handleColor(color): string {
  switch (color) {
    case 'pink':
      return '#ed145b';
    case 'blue':
      return '#3f56a5';
    case 'white':
      return '#ffffff';
    case 'dark-grey':
      return '#777';
    default:
      return color || '#f1f1f1';
  }
}
function handleButton({
  color,
  titleColor,
  title,
  ref,
  disabled,
  type,
  icon,
  style,
  iconRight = false,
  marginLeftIcon,
  marginLeftTitle,
  detailCircles,
  ...rest
}: handleButtonParams): JSX.Element {
  return (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      style={{
        backgroundColor: handleColor(color),
        color: titleColor,
        ...style,
      }}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {detailCircles && <DetailCircles />}

      <LabelIcon iconRight={iconRight}>
        <div
          className="icone"
          style={{ marginLeft: iconRight === true ? marginLeftIcon : '' }}
        >
          {icon}
        </div>
        <span
          className="titulo"
          style={{ marginLeft: iconRight === true ? marginLeftTitle : '' }}
        >
          {title}
        </span>
      </LabelIcon>
    </button>
  );
}

const Button = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      href,
      className,
      color,
      title,
      disabled,
      type,
      icon,
      style,
      titleColor,
      spaceBetween,
      textDecoration,
      fontWeight,
      customPadding,
      customWidth,
      ...rest
    } = props;

    const buttonElement = handleButton({
      color,
      title,
      disabled,
      ref,
      type,
      icon,
      style,
      titleColor,
      ...rest,
    });

    return (
      <Container
        className={className}
        spaceBetween={spaceBetween}
        textDecoration={textDecoration}
        fontWeight={fontWeight}
        customPadding={customPadding}
        customWidth={customWidth}
      >
        {href && !disabled ? (
          <Link
            to={{
              pathname: href,
              state: {
                fromDashboard: true,
              },
            }}
          >
            {buttonElement}
          </Link>
        ) : (
          <>{buttonElement}</>
        )}
      </Container>
    );
  }
);

Button.defaultProps = {
  href: undefined,
  className: undefined,
  disabled: false,
  color: undefined,
  title: undefined,
  titleColor: 'white',
  icon: undefined,
  marginLeftIcon: '10px',
  marginLeftTitle: '10px',
  detailCircles: true,
};
export default Button;

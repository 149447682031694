import styled from 'styled-components';

interface HeightProps {
  height?: string;
}
export const BoxContainer = styled.div<HeightProps>`
  height: ${(props) => props.height};
  .container-geral {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
  @media (max-height: 668px) {
    .wellcome,
    .forgot-password,
    .confirmation-code,
    .recover-password {
      margin-top: 1rem;
      justify-content: flex-start;
    }
  }
  @media (max-height: 748px) {
    .signin {
      margin-top: 1rem;
      justify-content: flex-start;
    }
  }
  @media (max-height: 848px) {
    .signup {
      margin-top: 1rem;
      justify-content: flex-start;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
BoxContainer.defaultProps = {
  height: 'auto',
};

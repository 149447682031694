import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrIcon from '../../components/dr-icon-font';
import DrBoxConfirmationCode from './dr-box-confirmation-code';
import { BoxContainer, Box } from '../../styles/box';

const ConfirmationCode: React.FC = () => {
  const history = useHistory();

  return (
    <BoxContainer>
      <Container className="pt-3">
        <Row>
          <Col xl={5} className="mb-4 p-4">
            <Box>
              <DrBox
                arrowColor="#ed145b"
                arrowOnClick={() => {
                  history.goBack();
                }}
                iconColor="#ed145b"
                icon={<DrIcon name="avatar" color="#ed145b" />}
                title="Confirmar Código"
                titleColor="#fcaf17"
                subTitle="Foi enviado um código para o seu E-mail e/ou WhatsApp"
              >
                <DrBoxConfirmationCode />
              </DrBox>
            </Box>
          </Col>
          <Col xl={7}>
            <Box>
              <DrHeaderWellcome />
              <Banner />
            </Box>
          </Col>
        </Row>
        <Footer />
      </Container>
    </BoxContainer>
  );
};

export default ConfirmationCode;

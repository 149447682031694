import styled, { css } from 'styled-components';

export const Article = styled.article``;

export const Headerr = styled.header``;

export const PageTitle = styled.h1`
  font-size: 22px;
  color: #3f56a5;
  margin: 0 0 15px;
  font-weight: 700;
`;

export const PageSubTitle = styled.h2`
  font-size: 18px;
  color: #777;
  line-height: 1.44444em;
  margin: 0 0 15px;
  font-weight: 700;
`;

export const PageDate = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #777;
  margin: 0 0 20px;
`;

export const SingleDetails = styled.div`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #777;
`;

export const AlignLeft = styled.figure`
  margin: 0 0 1rem;
  margin-right: 20px;
  margin-bottom: 20px;
  float: left;
`;

export const Img = styled.img`
  vertical-align: middle;
  max-width: 100%;
  height: auto;

  @media (min-width: 769px) {
    height: 305px;
    width: 540px;
  }
`;

export const P = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const H3 = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44444em;
  color: #3f56a5;
`;

export const Ul = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 25px;
`;

export const Li = styled.li`
  position: relative;
  padding-left: 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  :before {
    content: '';
    font-family: icomoon !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 3;
    font-size: 8px;
    color: #3f56a5;
    top: 7px;
    float: left;
  }
`;

export const CardBlogLikes = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  height: 52px;
  margin-top: 30px;
`;

interface ButtonProps {
  background?: string;
  iconColor?: string;
}

export const ButtonLike = styled.button<ButtonProps>`
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100px;
  background-color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: 1px solid #e5e5e5;

  :hover {
    background-color: #3f56a5;

    i {
      color: #fff;
    }
  }

  background-color: ${(props) => props.background};
`;

export const ButtonDislike = styled.button<ButtonProps>`
  text-align: center;
  justify-content: center;
  border-left: 1px solid #e5e5e5;
  height: 100%;
  width: 100px;
  background-color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: 1px solid #e5e5e5;

  :hover {
    background-color: #ed145b;

    i {
      color: #fff;
    }
  }

  background-color: ${(props) => props.background};
`;

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding: 1rem;
`;
export const SubTitle = styled.h4`
  text-align: center;
`;

export const Text = styled.p`
  font-size: 1.5rem;
`;

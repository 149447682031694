import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth, User } from '../../hooks/auth';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';
import UserData from '../../components/dr-user-data';
import { useToast } from '../../hooks/toast';
import DrCardLoading from '../../components/dr-card-loading';
import { handleConcatUserData } from '../../utils/bibli';

interface IdUser {
  id: string;
}

const UpdateUserRegistrationDatas: React.FC = (Props) => {
  const { requestAPI, sendError, user } = useAuth();
  const { params } = useRouteMatch<IdUser>();
  const [userProfile, setUserProfile] = React.useState({} as User);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const { addToast } = useToast();

  async function getUserData() {
    try {
      const resp = await requestAPI({
        method: 'GET',
        url: `/tmUsuarioDetalhe?idtm_usuario=${params.id}`,
      });
      const result = handleConcatUserData(resp);
      setUserProfile(result);
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (user) {
      getUserData();
    } else {
      history.push('/dashboard');
    }
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <UserData
          type="user"
          userData={userProfile}
          method="PUT"
          url={`/tmUsuario/${params.id}`}
        />
      )}
      <Footer />
    </>
  );
};
export default UpdateUserRegistrationDatas;

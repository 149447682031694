import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { scrollPositionTop } from '../../../utils/bibli';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import { RespTmAtendValType } from '../index';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import Plan from '../../../components/dr-plan';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

export interface CardPayOptionsProps {
  installments: RespTmAtendValType;
}

const CardPayOptions: React.FC<CardPayOptionsProps> = ({
  installments,
}: any) => {
  const { addToast } = useToast();
  const { attendance, requestAPI, user, setAttendanceData } = useAuth();
  const history = useHistory();
  const handleRoute = async () => {
    let route;
    if (attendance.fromDashboard) {
      route = '/dashboard';
    } else if (
      attendance &&
      attendance.ie_dependente &&
      attendance.ie_dependente !== 'S'
    ) {
      route = '/types-care';
    } else if (attendance.isSchedule === 'S' || attendance.idagenda_consulta) {
      try {
        await requestAPI({
          method: 'PUT',
          url: `/cancelarAgenda`,
          body: {
            idpessoa_titular: user.idpessoa_fisica,
            idagenda_consulta: attendance.idagenda_consulta,
            valid_payment: 1,
            ds_observacao: 'Cliente desistiu de pagar.',
          },
        });
      } catch (err: any) {
        if (err.message.includes('Agendamento já consta pago')) {
          history.push('/dashboard');
          addToast({
            type: 'success',
            title: 'Pagamento Aprovado',
            description: `Agendamento já consta pago!`,
          });
          return;
        }
      }

      route = '/schedule-attendance';
    } else {
      route = '/select-to-attendance';
    }

    await setAttendanceData({ ...attendance, idagenda_consulta: undefined });
    history.push(route);
  };
  useEffect(() => {
    scrollPositionTop();
    if (!installments.assinaturas_pln.length && !installments.atendimento_vlr) {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Sem configuração de valor para este tipo de atendimento!',
      });
      handleRoute();
      return;
    }
    if (
      installments &&
      installments.atendimento_vlr &&
      installments.atendimento_vlr.opcao_parcela.length
    ) {
      const obj = JSON.parse(
        JSON.stringify(installments.atendimento_vlr.opcao_parcela[0])
      );
      obj.descricao = 'Mais opções';
    }
  }, []);

  return (
    <>
      <CardBlank className="container pt-3 pb-3">
        <PageTitle>Opções de pagamento</PageTitle>
        <Row>
          {installments.atendimento_vlr && (
            <Plan
              className="mb-4"
              itemId={10}
              key="#id_10"
              plan={installments.atendimento_vlr}
              loading={false}
              dashboard={attendance.fromDashboard}
            />
          )}

          {installments.assinaturas_pln &&
            installments.assinaturas_pln.length > 0 &&
            installments.assinaturas_pln.map((plano, idx) => (
              <Plan
                className="mb-4"
                itemId={idx}
                key={`#id_${idx.toString()}`}
                plan={plano}
                loading={false}
                borderColor="#ed145b"
                dashboard={attendance.fromDashboard}
              />
            ))}
        </Row>
      </CardBlank>

      <DrButtonsFooter
        buttonRight={{
          title: '  Voltar',
          onClick: () => handleRoute(),
        }}
      />
    </>
  );
};

export default CardPayOptions;

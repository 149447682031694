import styled, { css } from 'styled-components';

export const InfoRow = styled.div`
  padding: 0px 5px;
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
`;

export const Links = styled.div`
  margin-top: 5px;
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #777;
  cursor: pointer;
  &:hover {
    color: #ed145b;
  }
`;

import styled from 'styled-components';
import { darken } from 'polished';

export const Ul = styled.ul`
  list-style: none;
  color: #777;
  margin: 0;
  padding: 0;
`;

export const Strong = styled.strong`
  font-weight: bolder;
`;

import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Input from '../dr-input';
import HelmetComponent from '../dr-helmet-component';
import { verifyErrorYup } from '../../utils/bibli';
import { Fields } from '../../styles/fields';
import {
  BoxLogin,
  BoxLoginTitle,
  BoxLoginTitleH3,
  BoxLoginTitleH4,
  BoxLoginChangeEmail,
} from '../../styles/box-login';
import {
  BoxLoginBody,
  BoxLoginBodyOl,
  BoxLoginBodyLi,
} from '../../styles/box-login_body';
import DrBox from '../dr-box';
import DrIcon from '../dr-icon-font';
import DrButtonLoading from '../dr-button-loading';
import DrGoogleAnalytics from '../dr-google-analytics';

interface LoginTypes {
  cpf?: string;
  email?: string;
  msg: string;
  notSendEmail?: boolean;
}

const DrConfirmationRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { requestAPI, sendError } = useAuth();
  const location = useLocation<LoginTypes>();
  const history = useHistory();
  const [locationDatas, setLocationDatas] = useState(location.state);
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);

  let string = '';
  async function resendEmail() {
    try {
      setLoading(true);
      string = `tmUsuarioToken/B/${locationDatas.cpf}/${locationDatas.email}`;
      await requestAPI({
        method: 'GET',
        url: string,
        isPublic: true,
      });

      addToast({
        type: 'success',
        title: 'E-mail enviado com sucesso!',
        description: 'Confirme o seu acesso no e-mail que enviamos para você',
      });
      if (locationDatas?.notSendEmail) history.push('/sign-in');
      setLoading(false);
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
      setLoading(false);
    }
  }
  const handleChangeEmail = useCallback(async (dataForm: any) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        ds_email: Yup.string()
          .required('Campo obrigatório')
          .trim()
          .email('Digite um e-mail válido'),
        confirmar_email: Yup.string()
          .required('Campo obrigatório')
          .trim()
          .when('ds_email', {
            is: (val) => !!val.length,
            then: Yup.string()
              .email('Digite um e-mail válido')
              .required('Confirmação de E-mail obrigatório'),
            otherwise: Yup.string(),
          })
          .oneOf(
            [Yup.ref('ds_email')],
            'E-mail e confirmação precisam ser iguais.'
          ),
      });
      await schema.validate(dataForm, {
        abortEarly: false,
      });
      setLoading2(true);
      await requestAPI({
        method: 'PUT',
        url: `tmUsuarioAlterarEmail/${locationDatas.cpf}`,
        body: dataForm,
        isPublic: true,
      });
      string = `tmUsuarioToken/B/${locationDatas.cpf}/${dataForm.ds_email}`;
      await requestAPI({
        method: 'GET',
        url: string,
        isPublic: true,
      });

      addToast({
        type: 'success',
        title: 'E-mail enviado com sucesso!',
        description: 'Confirme o seu acesso no e-mail que enviamos para você',
      });
      history.push('/sign-in');
    } catch (err: any) {
      sendError(err);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
        return;
      }
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    } finally {
      setLoading2(false);
    }
  }, []);

  React.useEffect(() => {
    if (!locationDatas?.notSendEmail) resendEmail();
  }, [locationDatas]);

  return (
    <>
      <HelmetComponent title="Reenviar E-mail" />
      <DrGoogleAnalytics />
      <BoxLogin>
        <DrBox
          arrowColor="#3f56a5"
          arrowOnClick={() => {
            history.push('/sign-in');
          }}
          className="mb-4 p-4"
          icon={<DrIcon name="alert" color="#03bbd7" />}
          title="Cadastro realizado com sucesso!"
          titleColor="#3f56a5"
          subTitle="Agora vamos ativar sua conta"
          subTitleColor="#03bbd7"
          body={
            <BoxLoginBody>
              <BoxLoginBodyOl>
                <BoxLoginBodyLi>
                  Acesse seu e-mail{' '}
                  <strong style={{ wordBreak: 'break-all', color: '#ed145b' }}>
                    {locationDatas?.email}
                  </strong>{' '}
                  e verifique a caixa de entrada, SPAM ou lixo eletrônico.
                </BoxLoginBodyLi>
                <BoxLoginBodyLi>
                  Clique no link para ativar sua conta!
                </BoxLoginBodyLi>
              </BoxLoginBodyOl>
            </BoxLoginBody>
          }
          footer={
            <BoxLoginChangeEmail>
              <BoxLoginTitle>
                <BoxLoginTitleH3 color="#03bbd7">
                  Não recebeu o link de ativação?
                  <DrButtonLoading
                    loading={isLoading}
                    type="button"
                    color="blue"
                    title="Reenviar"
                    className="mt-2"
                    onClick={resendEmail}
                  />
                </BoxLoginTitleH3>
                <BoxLoginBody>
                  <BoxLoginBodyOl
                    style={{
                      listStyleType: 'none',
                      padding: 0,
                    }}
                  >
                    <BoxLoginBodyLi style={{ textAlign: 'left' }}>
                      Caso tenha algo de errado com o e-mail{' '}
                      <strong
                        style={{ wordBreak: 'break-all', color: '#ed145b' }}
                      >
                        {locationDatas?.email}
                      </strong>
                      , preencha o formulário abaixo para altera-lo.
                    </BoxLoginBodyLi>
                  </BoxLoginBodyOl>
                </BoxLoginBody>
              </BoxLoginTitle>
              <Form ref={formRef} onSubmit={handleChangeEmail}>
                <Fields>
                  <Row>
                    <Col xl={12}>
                      <Input
                        label="E-mail"
                        name="ds_email"
                        customType="email"
                        validate
                      />
                    </Col>
                    <Col xl={12}>
                      <Input
                        name="confirmar_email"
                        label="Confirmar E-Mail"
                        customType="email"
                        cantPaste
                        validate
                      />
                    </Col>
                  </Row>
                  <DrButtonLoading
                    loading={isLoading2}
                    type="submit"
                    color="dark-grey"
                    title="Alterar e reenviar"
                  />
                </Fields>
              </Form>
            </BoxLoginChangeEmail>
          }
        />
      </BoxLogin>
    </>
  );
};

export default DrConfirmationRegistration;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import HelmetComponent from '../dr-helmet-component';
import { CardBlank } from '../../styles/card-blank';
import * as styled from './styled';
import DrIcon from '../dr-icon-font';
import Button from '../dr-button';
import DrCardLoading from '../dr-card-loading';
import SemImagem from '../../assets/img/sem-imagem.png';
import DrModal from '../dr-modal';
import { valueParam } from '../../utils/bibli';

interface PostType {
  ds_titulo: string;
  ds_enunciado: any;
  ds_path_img: string;
  ds_noticia: any;
  data_publicacao?: string;
  ie_curtida: string | null;
}

interface NewsDetailsProps {
  code?: string;
}

function onErrorImgNews(
  event: React.SyntheticEvent<HTMLImageElement>,
  urlPadrao: string
) {
  const eventChanged = event;
  eventChanged.currentTarget.src = SemImagem;
}

const NewsDetails: React.FC<NewsDetailsProps> = ({ code }) => {
  const { requestAPI, applicationData, user, sendError } = useAuth();
  const { addToast } = useToast();
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [post, setPost] = useState<PostType>();
  const processingRef = useRef<boolean>();

  useEffect(() => {
    async function getData() {
      try {
        const resp = await requestAPI({
          method: 'GET',
          url: `tmNoticiaPublic/${code}`,
        });
        setPost(resp);
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
        history.push('/dashboard');
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  const handleLike = useCallback(
    async (data: string) => {
      if (!user) {
        setModal(true);
        return;
      }

      const beforeIeCurtida = post ? post.ie_curtida : null;
      setPost((prev) => {
        if (!prev) return prev;

        const ieCurtida = prev.ie_curtida === data ? null : data;
        return { ...prev, ie_curtida: ieCurtida };
      });

      if (!processingRef.current) {
        processingRef.current = true;
        await requestAPI({
          method: 'PUT',
          url: `/tmNoticiaCurtida/${user.idtm_usuario}/${code}?curtida=${data}`,
        }).catch((error) => {
          sendError(error);
          setPost((prev) => {
            if (!prev) return prev;

            return { ...prev, ie_curtida: beforeIeCurtida };
          });
        });
        processingRef.current = false;
      }
    },
    [post?.ie_curtida, requestAPI, setPost]
  );

  function createHTML(value: string) {
    return { __html: value };
  }

  return (
    <>
      <HelmetComponent title="Notícias" />
      <DrModal
        controllerModal={[modal, setModal]}
        title="Atenção"
        buttons={{
          confirm: {
            title: 'Login/Cadastro',
            onClick: () => {
              history.push('/sign-in');
            },
          },
          cancel: {
            title: 'Cancelar',
            onClick: () => {
              setModal(false);
            },
          },
        }}
        content={<h4>Necessário realizar Login ou Cadastro!</h4>}
      />
      <styled.Article>
        {isLoading ? (
          <DrCardLoading />
        ) : (
          <>
            <CardBlank className="pt-4 pb-4 mt-4 container">
              <div className="d-flex flex-column">
                <styled.Headerr>
                  <styled.PageTitle>{post?.ds_titulo}</styled.PageTitle>
                  <styled.PageSubTitle>
                    {post?.ds_enunciado}
                  </styled.PageSubTitle>
                  <styled.PageDate>
                    {`Publicado em ${post?.data_publicacao}`}
                  </styled.PageDate>
                </styled.Headerr>

                <styled.SingleDetails>
                  <styled.AlignLeft>
                    <styled.Img
                      onError={(e: any) =>
                        onErrorImgNews(e, valueParam(applicationData, 107))
                      }
                      src={
                        post?.ds_path_img && post.ds_path_img.trim()
                          ? post.ds_path_img
                          : valueParam(applicationData, 107)
                      }
                      alt="Imagem da noticía"
                    />
                  </styled.AlignLeft>
                  <styled.P
                    dangerouslySetInnerHTML={createHTML(post?.ds_noticia)}
                  />
                </styled.SingleDetails>
              </div>
              <styled.CardBlogLikes>
                <styled.ButtonLike
                  background={post?.ie_curtida === 'S' ? '#3f56a5' : '#fff'}
                  onClick={() => handleLike('S')}
                >
                  <DrIcon
                    name="like"
                    color={post?.ie_curtida === 'S' ? '#fff' : '#3f56a5'}
                  />
                </styled.ButtonLike>

                <styled.ButtonDislike
                  background={post?.ie_curtida === 'N' ? '#ed145b' : '#fff'}
                  onClick={() => handleLike('N')}
                >
                  <DrIcon
                    name="dislike"
                    color={post?.ie_curtida === 'N' ? '#fff' : '#ed145b;'}
                  />
                </styled.ButtonDislike>
              </styled.CardBlogLikes>
            </CardBlank>

            <Row className="justify-content-center mt-4">
              <Col xl={4}>
                <Button
                  type="button"
                  title="Voltar"
                  color="dark-grey"
                  href="/dashboard"
                />
              </Col>
            </Row>
          </>
        )}
      </styled.Article>
    </>
  );
};

NewsDetails.defaultProps = {
  code: undefined,
};

export default NewsDetails;

import React, { useCallback, useState, ChangeEvent, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import moment from 'moment';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { CardBlank } from '../../styles/card-blank';
import Input from '../dr-input';
import Button from '../dr-button';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { PageTitle } from '../../styles/page-title';
import CardAttendances from '../dr-card-attendances';
import * as styled from './styled';
import DrIconFont from '../dr-icon-font';
import { useRequestData } from '../../hooks/requestData';
import DrButtonLoading from '../dr-button-loading';
import DrAlert from '../dr-alert';
import DrSelect from '../dr-input-select';
import { verifyErrorYup } from '../../utils/bibli';

interface HistoricType {
  idatendimento_paciente: number;
  idpessoa_fisica: number;
  idmedico_atendimento: number;
  idatend_original: number;
  nm_pessoa: string;
  nm_paciente: string;
  sg_conselho: string;
  nr_conselho: string;
  uf_conselho: string;
  idespecialidade: number;
  ds_classificacao: string;
  ds_especialidade: string;
  data_atend_medico: string;
  idficha_avaliacao_gerada: number;
  dt_avaliacao: string;
  status: string;
  avaliacao_atendimento: string;
  nr_dias_restante_ret?: any;
  idclassificacao: number;
  idparametro: number;
  ie_classificacao_agenda: string;
  ie_telemedicina: string;
  ie_tipo_agenda: string;
  ie_tipo_atendimento: number;
  modalidade: any;
  ie_retorno: any;
  idconvenio: number;
  idpessoa_titular: number;
  idestabelecimento_tipo_atendimento: number;
  idestabelecimento: number;
}

function showAttendances(
  historics: HistoricType[],
  setAttendanceData,
  history
) {
  return historics.map((historic: HistoricType) => (
    <Col
      md={12}
      lg={6}
      xl={4}
      className="mb-3"
      key={historic.idatendimento_paciente}
    >
      <CardAttendances
        urlLink={`/attendance-data/${historic.idatendimento_paciente}`}
        state={`${historic}`}
        body={
          <ul>
            <li>
              <strong>Data: </strong>
              {historic.data_atend_medico}
            </li>
            <li>
              <strong>Paciente: </strong>
              {historic.nm_paciente}
            </li>
            <li>
              <strong>Tipo de Atendimento: </strong>

              {historic.ds_classificacao}
            </li>
            <li>
              <strong>Dr(a): </strong>
              {historic.nm_pessoa}
            </li>
            <li>
              <strong>Especialidade: </strong>
              {historic.ds_especialidade}
            </li>

            <li>
              <strong>CRM: </strong>
              {historic.nr_conselho}
            </li>
          </ul>
        }
        returnButton={
          historic?.nr_dias_restante_ret >= 0 && (
            <Button
              type="button"
              color="#ed145b"
              title={
                historic.ie_tipo_atendimento === 3
                  ? 'Realizar Retorno'
                  : 'Marcar Retorno'
              }
              style={{ marginTop: 15 }}
              onClick={async () => {
                await setAttendanceData(
                  {
                    ...historic,
                    fromReturn: true,
                    ie_retorno: historic.ie_retorno ? historic.ie_retorno : 'S',
                    idpessoa_paciente: historic.idpessoa_fisica,
                    idpessoa_titular: historic.idpessoa_titular,
                  },
                  true
                );
                if (historic.ie_tipo_atendimento === 3) {
                  history.push({
                    pathname: '/return-attendance',
                  });
                } else {
                  history.push({ pathname: '/select-specialty' });
                }
              }}
            />
          )
        }
      />
    </Col>
  ));
}

interface AttendancesProps {
  title?: string;
  itensPerPage?: string;
  filter?: boolean;
}

const Attendances: React.FC<AttendancesProps> = (props: AttendancesProps) => {
  const { title, itensPerPage, filter } = props;
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingPagination, setLoadingPagination] = useState(true);
  const [historics, setHistorics] = useState<HistoricType[]>([]);
  const { requestAPI, setAttendanceData, user, sendError } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [text, setText] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('nm_pessoa');
  const countRef = useRef(0);
  const [attendanceEnded, setAttendanceEnded] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [restartPagination, setRestartPagination] = useState(false);
  const [url, setUrl] = useState(
    `/historicoAtendimentoPacienteUser?idtm_usuario=${
      user ? user.idtm_usuario : '0'
    }`
  );

  const [isClearValue, setClearValue] = React.useState(false);

  const arraySelect = [
    { label: 'Dr(a)', value: 'nm_pessoa' },
    { label: 'Data', value: 'dt_atend_medico' },
    { label: 'CRM', value: 'nr_conselho' },
    { label: 'Especialidade', value: 'ds_especialidade' },
    { label: 'Paciente', value: 'nm_paciente' },
  ];

  const numberOfItensPerPage = Number(itensPerPage);

  useRequestData({
    countRef,
    url,
    setState: setHistorics,
    requestAPI,
    itensPerPage: numberOfItensPerPage,
    idField: 'idatendimento_paciente',
    addToast,
    setLoading,
    insideData: true,
    setDataEnded: setAttendanceEnded,
    isLoading,
    titleError: 'Por favor, Tente novamente.',
    paginate: filter ? { setLoading: setLoadingPagination } : undefined,
    elementRef: buttonRef,
    restartPagination,
    setRestartPagination,
    sendError,
  });

  function handleSelectSearch(event: ChangeEvent<HTMLSelectElement>) {
    setClearValue(true);
    setText('');
    setSelectedSearch(event.target.value);
  }

  const handleSubmit = useCallback(async () => {
    let isError = false;
    let valueSearch = text;

    if (selectedSearch === 'dt_atend_medico' && text) {
      valueSearch = valueSearch.padStart(10, '0');
      valueSearch = moment(valueSearch, 'DD/MM/YYYY').format('YYYY-MM-DD');

      try {
        const schema = Yup.object().shape({
          search: Yup.string().matches(
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g,
            'Data inválida'
          ),
        });

        await schema.validate(
          { search: valueSearch },
          {
            abortEarly: false,
          }
        );
      } catch (err: any) {
        sendError(err);
        isError = true;
        if (err instanceof Yup.ValidationError) {
          verifyErrorYup(err, formRef);
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        }
      }
    }

    if (!isError) {
      setUrl(
        `/historicoAtendimentoPacienteUser?idtm_usuario=${user.idtm_usuario}&${selectedSearch}=${valueSearch}`
      );
      setRestartPagination(true);
    }
  }, [addToast, selectedSearch, text]);

  const handleText = React.useCallback((str: string) => {
    setText(str);
    setClearValue(false);
  }, []);

  const handleEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const renderSwitch = useCallback(() => {
    let objInputProps = {};

    switch (selectedSearch) {
      case 'dt_atend_medico':
        objInputProps = {
          placeholder: 'Digite a data',
          customType: 'dateText',
          inputMode: 'numeric',
        };
        break;
      case 'nr_conselho':
        objInputProps = {
          placeholder: 'Digite o Nº',
          customType: 'onlyNumbers',
          inputMode: 'numeric',
        };
        break;
      case 'nm_pessoa':
        objInputProps = {
          placeholder: 'Digite o nome do Dr(a)',
          customType: 'name',
          inputMode: 'text',
        };
        break;
      case 'nm_paciente':
        objInputProps = {
          placeholder: 'Digite o nome do Paciente',
          customType: 'name',
          inputMode: 'text',
        };
        break;
      case 'ds_especialidade':
        objInputProps = {
          placeholder: 'Digite a especialidade',
          inputMode: 'text',
          customType: 'especialidade',
        };
        break;

      default:
    }

    return (
      <Input
        name="search"
        getValue={handleText}
        icon
        onKeyUp={handleEnter}
        value={text}
        validate
        clear={isClearValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...objInputProps}
      />
    );
  }, [handleEnter, selectedSearch, text, isClearValue]);

  let elementAttendance;

  if (historics?.length) {
    elementAttendance = showAttendances(historics, setAttendanceData, history);
  } else {
    elementAttendance = (
      <Col className="m-0">
        <DrAlert
          warning
          title={{
            tag: 'h4',
            text: '',
          }}
        >
          {filter ? (
            <p>Não foi encontrado nenhum atendimento para esta pesquisa!</p>
          ) : (
            <p>Ainda não foi realizado nenhum atendimento.</p>
          )}
        </DrAlert>
      </Col>
    );
  }

  return (
    <>
      {/* {isLoading ? (
        <DrCardLoading className="mt-4 pt-4 pb-4" minHeight="35vh" />
      ) : ( */}
      <CardBlank className="mt-3 pt-3 pb-3">
        <Container>
          <PageTitle>{title}</PageTitle>
          {filter ? (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <styled.Fields id="FIELDS">
                  <Row className="justify-content-center">
                    <Col md={12} xl={3} className="mb-auto">
                      <DrSelect
                        name="select"
                        selectTitle="Refine sua busca"
                        value={selectedSearch}
                        onChange={handleSelectSearch}
                        options={arraySelect}
                      />
                    </Col>

                    <Col md={12} xl={5} className="mt-auto mb-auto">
                      <styled.Field id="FIELD">{renderSwitch()}</styled.Field>
                    </Col>

                    <Col md={12} xl={1} className="mt-auto mb-auto">
                      <DrButtonLoading
                        title=""
                        type="submit"
                        color="#3f56a5"
                        className="btn-search"
                        loading={isLoading}
                        icon={
                          isLoading ? (
                            ''
                          ) : (
                            <DrIconFont
                              color="#fff"
                              name="zoom"
                              fontSize="28px"
                            />
                          )
                        }
                      />
                    </Col>
                  </Row>
                </styled.Fields>
              </Form>

              <Row className="pt-4">
                {(elementAttendance.length || attendanceEnded) &&
                  elementAttendance}
              </Row>

              {!attendanceEnded && (
                <Row className="justify-content-center">
                  <Col md={12} lg={6} xl={4}>
                    <div ref={buttonRef}>
                      <DrButtonLoading
                        type="button"
                        title="Carregando atendimentos..."
                        color="blue"
                        loading={isLoadingPagination}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <Row>
                {(elementAttendance.length || attendanceEnded) &&
                  elementAttendance}
              </Row>

              {elementAttendance.length && (
                <Row className="justify-content-center">
                  <Col md={12} lg={6} xl={4}>
                    <Button
                      type="button"
                      title="Ver todo o histórico"
                      color="blue"
                      href="/historic"
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </CardBlank>
      {/* )} */}
    </>
  );
};

Attendances.defaultProps = {
  title: undefined,
  itensPerPage: undefined,
  filter: false,
};

export default Attendances;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col, Container } from 'react-bootstrap';
import DrHeaderWellcome from '../../components/dr-header-wellcome';
import DrBox from '../../components/dr-box';
import Banner from '../../components/dr-banner';
import Footer from '../../components/dr-footer';
import DrIcon from '../../components/dr-icon-font';
import DrBoxForgotPassword from './dr-box-forgot-password';
import { BoxContainer, Box } from '../../styles/box';
import Header from '../../components/dr-header';

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Header />
      <BoxContainer>
        <Container className="container-geral forgot-password">
          <Row>
            <Col xl={5} className="mb-4 p-4">
              <Box>
                <DrBox
                  arrowColor="#ed145b"
                  arrowOnClick={() => {
                    history.goBack();
                  }}
                  iconColor="#ed145b"
                  icon={<DrIcon name="avatar" color="#ed145b" />}
                  title="Recuperar Senha"
                  titleColor="#fcaf17"
                >
                  <DrBoxForgotPassword />
                </DrBox>
              </Box>
            </Col>
            <Col xl={7}>
              <Box>
                <DrHeaderWellcome />
                <Banner />
              </Box>
            </Col>
          </Row>
          <Footer />
        </Container>
      </BoxContainer>
    </>
  );
};

export default ForgotPassword;

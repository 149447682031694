import React from 'react';
import { Link } from 'react-router-dom';
import HelmetComponent from '../../components/dr-helmet-component';
import { Container, Content } from './styled';

import Logo from '../../assets/marca/logo.png';
import Header from '../../components/dr-header';
import Footer from '../../components/dr-footer';

const NotFound: React.FC = () => (
  <>
    <HelmetComponent title="Pagina não encontrada" />
    <Header />
    <Container>
      <Content>
        <div className="logo">
          {/* <img src={Logo} alt="Logo Dr+" /> */}
          <img
            onError={(el) => {
              el.currentTarget.src = Logo;
            }}
            src={Logo}
            width="170"
            height="170"
            alt="Logo Dr+"
          />
        </div>
        <div className="text">
          <h1>
            <strong>Página não encontrada!</strong>
          </h1>
          <h2>Error 404</h2>
          {/* <h3>Estamos te redirecionando...</h3> */}
        </div>

        <Link to="/">
          <div className="button-login">
            <button type="button">Voltar</button>
          </div>
        </Link>
      </Content>
    </Container>
    <Footer />
  </>
);
export default NotFound;

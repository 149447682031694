import styled, { css } from 'styled-components';

interface StyledPersonalizationProps {
  fontSize?: number;
  color?: string;
  textCenter?: boolean;
}

interface TextInformationPixProps extends StyledPersonalizationProps {}

interface ButtonCopyCodePaymentProps extends StyledPersonalizationProps {}

export const ContainerInformationPix = styled.div`
  padding: 10px;
`;

export const ContainerPaymentWithCode = styled.div`
  border-top: 1px solid rgb(192, 192, 192);
  border-bottom: 1px solid rgb(192, 192, 192);
  margin-top: 50px;
  padding: 10px;
`;

export const TextInformationPix = styled.p<TextInformationPixProps>`
  line-height: 1.44444em;
  margin: 0 0.55rem 0.75rem;

  font-size: ${({ fontSize }) => fontSize || 16}px;
  color: ${({ color }) => color || '#888888'};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `}

  @media screen and (max-width: 850px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.7rem;
  }
`;

export const PaymentValuePix = styled.p`
  font-size: 0.95rem;
  color: rgb(179, 179, 179);

  @media screen and (max-width: 850px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.7rem;
  }
`;

export const TimeLimitForPayment = styled.p`
  color: rgb(179, 179, 179);

  @media screen and (max-width: 850px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.7rem;
  }
`;

export const TimeDateLimitForPlayer = styled.p`
  color: rgb(179, 179, 179);
  font-size: 0.8rem;

  @media screen and (max-width: 850px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.6rem;
  }
`;

export const ContainerTimePayment = styled.div``;

export const InputCodePayment = styled.input`
  width: 85%;
  margin: 0.35rem;
  background-color: #fff;
  border: none;
  border-rigth: 10px solid rgb(192, 192, 192);
`;

export const ButtonCopyCodePayment = styled.button<ButtonCopyCodePaymentProps>`
  cursor: pointer;
  width: 15%;
  color: rgb(25, 50, 180);
  background-color: #fff;
  border: none;
  font-size: 1rem;
  border-left: 1px solid rgb(192, 192, 192);

  @media screen and (max-width: 850px) {
    font-size: 0.75rem;
  }

  &:hover {
    background-color: rgb(190, 190, 190);
  }
`;

export const ContainerCodeOption = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: center;
  margin: 0 auto;
  margin-top: 15px;
  height: 3rem;
  margin-bottom: 20px;
  width: 85%;
  border-radius: 6px;
  border: 1px solid rgb(192, 192, 192);

  @media screen and (max-width: 850px) {
    width: 96%;
  }
`;

export const NameButtonCopy = styled.span`
  opacity: 1;
  font-size: 1rem;

  @media screen and (max-width: 850px) {
    font-size: 0.7rem;
  }
`;

import React from 'react';
import Footer from '../../components/dr-footer';
import CardRetAtt from './card-return-attendance';
import Header from '../../components/dr-header';

const ReturnAttendance: React.FC = () => (
  <>
    <Header />
    <CardRetAtt />
    <Footer />
  </>
);

export default ReturnAttendance;

import styled from 'styled-components';
import { darken } from 'polished';

export const BoxPagTitle = styled.h3`
  font-size: 18px;
  color: #212529 !important;
  text-align: center !important;
`;

export const BoxPag = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding-top: 10px;
`;

export const RowPag = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding-top: 10px;
`;

export const CodeBox = styled.div`
  width: 100%;
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding-top: 10px;

  @media screen and (max-width: 340px) {
    strong {
      font-size: 12px;
    }
  }
`;

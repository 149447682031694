import React, { useEffect } from 'react';
import ReactGa from 'react-ga';
import { useAuth } from '../../hooks/auth';

interface DrGoogleAnalyticsProps {
  currentRoute?: string;
}

const DrGoogleAnalytics: React.FC<DrGoogleAnalyticsProps> = ({
  currentRoute,
}) => {
  const { applicationData } = useAuth();

  useEffect(() => {
    if (
      applicationData &&
      applicationData.valores_variaveis &&
      applicationData.valores_variaveis.google_analytics
    ) {
      ReactGa.initialize(applicationData.valores_variaveis.google_analytics);

      ReactGa.pageview(currentRoute || window.location.pathname);
    }
  }, [currentRoute, applicationData, window.location.pathname]);

  return <></>;
};

export default DrGoogleAnalytics;

import React, { useEffect } from 'react';

import { Col, Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../hooks/auth';

import DrButtonsFooter from '../../../components/dr-buttons-footer';
import DrCardHeaderSchedule from '../../../components/dr-card-header-schedule';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import { useToast } from '../../../hooks/toast';
import DrInputTextArea from '../../../components/dr-input-text-area';
import HelmetComponent from '../../../components/dr-helmet-component';
import DrModalLoading from '../../../components/dr-modal-loading';
import { scrollPositionTop, verifyErrorYup } from '../../../utils/bibli';

interface DrCancelProps {
  cancelData: any;
}
const DrCancelSchedule: React.FC<DrCancelProps> = (Props: DrCancelProps) => {
  const { cancelData } = Props;
  const { addToast } = useToast();
  const formRef = React.useRef<FormHandles>(null);

  const { requestAPI, user, setAttendanceData, attendance, sendError } =
    useAuth();

  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    scrollPositionTop();
  }, []);
  async function handleCancelAttendance(data: any) {
    try {
      const schema = Yup.object().shape({
        ds_observacao: Yup.string()
          .required('Campo obrigatório!')
          .nullable(true)
          .trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      await requestAPI({
        method: 'PUT',
        url: `/cancelarAgenda`,
        body: {
          idpessoa_titular: user.idpessoa_fisica,
          idagenda_consulta: cancelData.idagenda_consulta,
          ds_observacao: data.ds_observacao,
        },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: `Agendamento cancelado com sucesso.`,
      });

      setLoading(false);
      history.push({ pathname: '/dashboard' });
    } catch (err: any) {
      sendError(err);
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
        history.push('/dashboard');
      }
    }
  }

  return (
    <>
      <HelmetComponent title="Cancelar Agendamento" />
      <Container className="pt-3">
        <DrModalLoading showModal={loading} />

        <Form ref={formRef} onSubmit={handleCancelAttendance}>
          <CardBlank className="p-3" definedSize="50vh">
            <PageTitle>Cancelar Agendamento</PageTitle>
            <DrCardHeaderSchedule headerData={attendance} />

            <Col xl={12} className="pt-4 pb-3">
              <DrInputTextArea
                name="ds_observacao"
                label="Qual o motivo do cancelamento?"
                placeholder="Digite aqui..."
                maxLength={5000}
              />
            </Col>
          </CardBlank>

          <DrButtonsFooter
            buttonRight={{
              type: 'button',
              onClick: async () => {
                await setAttendanceData({}, true);
                history.push('/dashboard');
              },
              title: 'Voltar',
            }}
            buttonLeft={{
              type: 'submit',

              title: 'Confirmar',
            }}
          />
        </Form>
      </Container>
    </>
  );
};

export default DrCancelSchedule;

import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../components/dr-header';
import HelmetComponent from '../../components/dr-helmet-component';
import Footer from '../../components/dr-footer';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';

const SpecialtiesTopics: React.FC = () => (
  <>
    <HelmetComponent title="Fórum" />
    <Header />
    <Container>
      <CardBlank className="mt-3 pt-3 pb-3">
        <PageTitle>Especialidades</PageTitle>
      </CardBlank>
    </Container>
    <Footer />
  </>
);

export default SpecialtiesTopics;

import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import DrCardLoading from '../../components/dr-card-loading';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { valueParam } from '../../utils/bibli';

import { useAuth } from '../../hooks/auth';
import DrCardSelectSpecialty from './dr-card-select-specialty';

const SelectSpecialty: React.FC = () => {
  const {
    requestAPI,
    attendance,
    setAttendanceData,
    applicationData,
    sendError,
    user,
  } = useAuth();
  let idade;
  if (attendance.patient?.data_nascimento) {
    idade = moment().diff(
      moment(attendance.patient?.data_nascimento, 'DD/MM/YYYY'),
      'years'
    );
  }

  const history = useHistory();

  const [specialties, setSpecialties] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    try {
      if (attendance?.idestabelecimento_tipo_atendimento) {
        let url = `/agendaConsultaVagas?idestabelecimento_tipo_atendimento=${attendance.idestabelecimento_tipo_atendimento}&detailHours=S`;
        if (user) {
          url += `&idpessoa_fisica=${attendance.idpessoa_paciente}`;
        }
        let electiveReturn;

        if (attendance.tipo_atend === 'EL' && attendance.fromReturn) {
          electiveReturn = true;
        }

        if (
          attendance.idespecialidade &&
          (attendance.idagenda_consulta ||
            attendance.singleType ||
            (attendance.nr_dias_restante_ret &&
              attendance.nr_dias_restante_ret >= 0))
        ) {
          url += `&agenda_consulta.idespecialidade=${attendance.idespecialidade}`;
          if (electiveReturn) url += `&retornoEletivo=${electiveReturn}`;
        } else if (
          attendance &&
          attendance.idespecialidade &&
          attendance.origem_cartao
        ) {
          url += `&agenda_consulta.idespecialidade=${attendance.idespecialidade}`;
          if (electiveReturn) url += `&retornoEletivo=${electiveReturn}`;
        }
        const resp = await requestAPI({ method: 'GET', url });
        setSpecialties(resp);
        setLoading(false);
      }
    } catch (e) {
      sendError(e);
      history.push({ pathname: `/dashboard` });
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <DrCardSelectSpecialty
          specialties={specialties}
          setAttendanceData={setAttendanceData}
          avatarPadrao={valueParam(applicationData, 126) || ''}
        />
      )}
      <Footer />
    </>
  );
};
export default SelectSpecialty;

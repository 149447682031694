import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  max-width: 40%;

  .rccs__issuer {
    height: 25% !important;
  }

  form {
    width: 70%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    margin: 10px auto;

    .select-cvv {
      display: grid;
      grid-template-columns: 60% 40%;
      grid-gap: 4px;
      width: 270px;

      @media screen and (max-width: 1000px) {
        display: block;
        grid-template-columns: none;
        grid-gap: 0;
      }
    }

    .new-card {
      background: white;
      width: 262px;
      height: 44px;
      font-weight: bold;
      color: #00bbd6;
      border: 2px solid #00bbd6;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      bottom: 0;
      &:hover {
        color: #ffffff;
        background: ${darken(0.08, '#00bbd6')};
      }
    }
    .div-new-card {
      display: flex;
      justify-content: center;
    }
    .valid-cvc {
      display: flex;
      .element:first-child {
        margin-right: 5px;
      }
      @media screen and (max-width: 1000px) {
        display: block;
        .element:first-child {
          margin-right: 0px;
          margin-bottom: 8px;
        }
      }
    }
  }

  select {
    float: left;
    margin-bottom: 11px;
    width: 100%;
    height: 40px;
    border: 2px solid #232129;
    color: 'black';
    border-radius: 3px;
  }

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 20px;
    width: 100%;
  }

  .flex-container {
    display: block;
  }

  h2 {
    display: flex;
    justify-content: left;
    padding-bottom: 2%;
    color: #4156a6;
    font-family: 'Concert One', cursive;
  }
  h3 {
    color: #4156a6;
    font-weight: bold;
    text-align: left;
  }

  .checkbox-whatsapp {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 350px) {
    form {
      width: 95%;
      max-width: 290px;

      // .valid-cvc {
      //   grid-template-columns: 300px 20px;
      // }
    }
  }

  @media screen and (max-width: 850px) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    h3 {
      font-size: 1.2rem;
    }
  }
`;
export const Content = styled.div`
  height: 100%;
  margin: 25px auto;
  border: 0;
  border-radius: 4px;
  background: transparent;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    display: flex;
    justify-content: center;
    color: #4156a6;
    font-family: 'Concert One', cursive;
    letter-spacing: 0.015em;
    z-index: 10;
    width: 100%;
    height: 65px;
    align-items: center;
    font-weight: bold;
    position: fixed;
    top: 100px;
    background: #f3fbfe;
    @media screen and (max-width: 850px) {
      top: 50px;
    }
  }
  .contentCardCredit {
    flex-direction: column;
    display: flex;
  }
  .cardCredit {
    padding-bottom: 65px;
    padding-top: 65px;
    width: 280px;
  }

  form {
    width: 95%;
    max-width: 360px;
    .buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 65px;
      background: #f3fbfe;
      z-index: 10px;
      position: fixed;
      bottom: 0;
      align-items: center;
      left: 0;
      flex-flow: row-reverse;
    }
  }

  @media screen and (max-width: 850px) {
    padding-top: 55px;
    margin: 0px auto;
  }
`;

interface SelectCreditCardProps {
  hideSelectCreditCard: boolean;
}

export const SelectCreditCard = styled.div<SelectCreditCardProps>`
  display: block;

  ${(props) =>
    props.hideSelectCreditCard &&
    css`
      display: none;
    `}
`;

export const NewCreditCard = styled.div`
  h2 {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    font-size: 1.3;
    color: #4156a6;
    font-family: 'Concert One', cursive;
    font-weight: bold;
  }
  .checkbox {
    margin-top: 10px;
    display: flex;
    input {
      display: inline;
      align-items: center;
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      margin: 2px;
    }
    span {
      font-size: 1.5rem;
      color: #4156a6;
      line-height: 2;
      white-space: nowrap;
      display: flex;
    }
    label {
      font-size: 1.5rem;
      color: #4156a6;
      line-height: 2;
      white-space: nowrap;
      display: flex;
    }
  }
`;
export const PixImg = styled.div`
  figure {
      margin-top: 10px;
      padding: 3.5px;
      border: 2.5px solid #e5e5e5;
      border-radius: 10px;
      padding-right: -15%;

  }

  figcaption{
    font-weight: bold;
    color: #4156a6;
    padding-top: 5%;
    padding-left: 18%;
  }
`;

interface NewCreditCardProps {
  hideNewCreditCard: boolean;
}

export const NewCreditCard2 = styled.div<NewCreditCardProps>`
  .checkbox {
    margin-top: 10px;
    display: flex;
    input {
      display: inline;
      align-items: center;
      min-height: 25px;
      min-width: 25px;
      max-height: 25px;
      max-width: 25px;
      margin: 2px;
    }
    label {
      font-size: 1.5rem;
      color: #4156a6;
      line-height: 2;
      white-space: nowrap;
      display: flex;
    }
  }

  h2 {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    font-size: 1.3;
    color: #4156a6;
    font-family: 'Concert One', cursive;
    font-weight: bold;
  }

  ${(props) =>
    props.hideNewCreditCard &&
    css`
      display: none;
    `}
`;

export const CancelButton = styled.button`
  position: relative;
  margin: 0px 25px;
  background: transparent;
  height: 44px;
  width: 200px;
  font-weight: bold;
  color: #9f1e49;
  border: 2px solid #9f1e49;
  border-radius: 20px;
  font-size: 16px;
  transition: background 0.2s;
  float: left;

  &:hover {
    color: #ffffff;
    background: ${darken(0.08, '#9f1e49')};
  }

  @media screen and (max-width: 1000px) {
    height: 34px;
    width: 100px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

export const RegisterButton = styled.button`
  position: relative;
  margin: 0px 25px;
  background: transparent;
  height: 44px;
  width: 200px;
  font-weight: bold;
  color: #62bb46;
  border: 2px solid #62bb46;
  border-radius: 20px;
  font-size: 16px;
  transition: background 0.2s;
  bottom: 0;
  float: right;

  &:hover {
    color: #ffffff;
    background: ${darken(0.08, '#62bb46')};
  }

  @media screen and (max-width: 1000px) {
    height: 34px;
    width: 100px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

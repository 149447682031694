import React, { useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { scrollPositionTop, valueParam } from '../../../utils/bibli';
import { PageTitle } from '../../../styles/page-title';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { CardBlank } from '../../../styles/card-blank';
import { useAuth } from '../../../hooks/auth';

interface IdRoute {
  id: string;
}

const ReturnAttendance: React.FC = () => {
  const { params } = useRouteMatch<IdRoute>();
  const { attendance, applicationData } = useAuth();
  const isUploadPage = valueParam(applicationData, 241);
  const history = useHistory();
  React.useEffect(() => {
    scrollPositionTop();
  });

  return (
    <>
      <Container className="pt-3">
        <CardBlank className="container pt-3 pb-3">
          <PageTitle>Atendimento de retorno</PageTitle>
          {/* <PageTitle>Fazer o retorno do seu atendimento?</PageTitle>
        <PageSubtitle textCenter>
          Obs: Não será feita nenhuma cobrança para este atendimento de retorno.
        </PageSubtitle> */}
        </CardBlank>
      </Container>

      <DrButtonsFooter
        buttonLeft={{
          title: 'Continuar',
          href:
            isUploadPage === 'S'
              ? `/upload-page`
              : `/attendance-options/${params.id}`,
        }}
        buttonRight={{
          title: 'Voltar',
          onClick: () => {
            if (
              attendance &&
              attendance.ie_dependente &&
              attendance.ie_dependente !== 'S'
            ) {
              history.push('/types-care');
            } else if (attendance.ie_retorno === 'S') {
              history.push('/dashboard');
            } else {
              history.push('/types-care');
            }
          },
        }}
      />
    </>
  );
};
export default ReturnAttendance;

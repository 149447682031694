import styled from 'styled-components';

export const BgConsulta = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 225px;
  object-fit: contain;
`;

import React, {
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLess } from 'react-icons/fa';
import moment from 'moment';
import Checkbox from '../../../components/dr-input-checkbox';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import DrModal from '../../../components/dr-modal';
import {
  isValidCPF,
  isValidPhone,
  isValidDateToRegister,
  isFutureDate,
} from '../../../utils/validations';
import {
  dateToStr,
  formatStrToDate,
  handleDifferenceYears,
} from '../../../utils/formatDates';
import onlyNumbers from '../../../utils/formatNumbers';
import Input from '../../../components/dr-input';
import Button from '../../../components/dr-button';
import HelmetComponent from '../../../components/dr-helmet-component';
import {
  validateNameComplete,
  scrollPositionTop,
  valueParam,
  verifyErrorYup,
} from '../../../utils/bibli';
import { AnchorStyled } from '../../../styles/link-styled';
import DrSelect from '../../../components/dr-input-select';
import DrButtonLoading from '../../../components/dr-button-loading';

interface IRouteParams {
  email: string;
  family_name: string;
  first_name: string;
  last_name: string;
  given_name: string;
  id: string;
  locale: string;
  name: string;
  picture: string;
  tipo: string;
  verified_email: boolean;
  nm_pessoa: string;
  nr_cpf: string;
  dt_nascimento: string;
  ie_sexo: string;
  nr_ddi_celular: string;
  nr_ddd_celular: string;
  nr_celular: string;
  ds_email: string;
  ds_senha: string;
  confirmar_email: string;
}
interface SignUpFormData {
  nm_pessoa: string;
  nr_cpf: string;
  dt_nascimento: string;
  ie_sexo: string;
  nr_ddi_celular: string;
  nr_ddd_celular: string;
  nr_celular: string;
  ds_email: string;
  ds_senha: string;
  social: IRouteParams;
}

interface DomainResponse {
  vl_dominio: string;
  ds_dominio_valor: string;
}
interface BoxSignUpProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}
type ArrayDomain = Array<DomainResponse>;
const etapas = [
  {
    id: 'dadosPessoais',
  },
  {
    id: 'dadosContato',
  },
];
const defaultArray = [
  {
    label: 'Selecione',
    value: '',
  },
  {
    label: 'Feminino',
    value: 'F',
  },
  {
    label: 'Masculino',
    value: 'M',
  },
  {
    label: 'Prefiro não informar',
    value: 'N',
  },
];
const DrBoxSignUp: React.FC<BoxSignUpProps> = (props: BoxSignUpProps) => {
  const { currentStep, setCurrentStep } = props;
  const formRef = useRef<FormHandles>(null);
  // const [genres, setGenres] = useState<any[]>(defaultArray);
  const [genres, setGenres] = useState<any>(() => {
    const respGender = [
      { label: 'Feminino', value: 'F' },
      { label: 'Masculino', value: 'M' },
      { label: 'Prefiro não informar', value: 'N' },
    ];

    respGender.unshift(defaultArray[0]);
    return respGender;
  });
  const [selectedDominio, setSelectedDominio] = useState('');
  const { requestAPI, getDomainsData, applicationData, sendError } = useAuth();
  const dataUserRef = useRef<any>({});
  const { addToast } = useToast();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation<IRouteParams>();
  const [name, setName] = useState<any>((prev) => {
    if (location.state && location.state.name) {
      prev = location.state.name;
      return prev;
    }
    if (
      location.state &&
      location.state.first_name &&
      location.state.last_name
    ) {
      const x = `${location.state.first_name} ${location.state.last_name}`;
      prev = x;
      return prev;
    }
    prev = '';
    return prev;
  });
  const [userData, setUserData] = useState(() => {
    const returnData: any = {};

    if (
      location &&
      location.state &&
      location.state.email &&
      !JSON.stringify(location.state.email).includes('private')
    ) {
      returnData.ds_email = location.state.email;
      returnData.confirmar_email = location.state.email;
    }
    if (location && location.state && location.state.name)
      returnData.nm_pessoa = location.state.name;
    if (location && location.state && location.state.last_name)
      returnData.nm_pessoa = `${location.state.first_name} ${location.state.last_name}`;

    return returnData;
  });

  const errorRef = React.useRef<any>({});

  function handleNext() {
    setCurrentStep((prevState: number) => {
      if (prevState < 1) prevState += 1;
      return prevState;
    });
  }

  useEffect(() => {
    if (location) {
      setUserData(Object.assign(userData, location));
    }
  }, []);

  const handleChangeNameInput = (value) => {
    setName(value);
  };

  useEffect(() => {
    scrollPositionTop();
    async function getDataDomain() {
      const respGender = getDomainsData('543').map((dominio) => ({
        label: dominio.ds_dominio_valor,
        value: dominio.vl_dominio,
      }));

      respGender.unshift(defaultArray[0]);
      setGenres(respGender);
    }

    getDataDomain();
  }, []);

  function handleSelectDominio(event: ChangeEvent<HTMLSelectElement>) {
    const dominio = event.target.value;
    setSelectedDominio(dominio);
  }

  const handleSubmit = useCallback(
    async (formData: SignUpFormData) => {
      const data = formData;

      try {
        let schema;
        if (currentStep === 0) {
          data.dt_nascimento = dateToStr(
            formatStrToDate(data.dt_nascimento, 'DD/MM/YYYY'),
            'YYYY-MM-DD'
          );

          data.nm_pessoa = name;
          // Passando os dados de usuário do Google e Facebook
          data.social = location.state;
          data.ie_sexo = selectedDominio;

          schema = Yup.object().shape({
            nm_pessoa: Yup.string()
              .trim()
              .test({
                message: 'É necessário o nome completo',
                test: (val) => {
                  if (val) return validateNameComplete(val);

                  return true;
                },
              })
              .required('O nome completo é obrigatório')
              .nullable(true),
            nr_cpf: Yup.string()
              .test({
                message: 'CPF inválido',
                test: (value) => isValidCPF(value),
              })
              .required('CPF é obrigatório')
              .trim(),
            dt_nascimento: Yup.string()

              .matches(
                /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g,
                'Data inválida'
              )
              .test({
                message: 'O titular da conta deve ter mais que 16 anos',
                test: (val) => {
                  if (handleDifferenceYears(val, 15)) {
                    return true;
                  }
                  return false;
                },
              })
              .test({
                message: 'Data inválida',
                test: (value) =>
                  isValidDateToRegister(value) && !isFutureDate(value),
              })
              .required('Data de nascimento obrigatório')
              .nullable(true)
              .trim(),
            ie_sexo: Yup.string()
              .test({
                message: 'Necessário informar o gênero',
                test: (val) => {
                  if (!val || val === 'Selecione') {
                    return false;
                  }
                  return true;
                },
              })
              .nullable(true)
              .trim(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setLoading(true);
          await requestAPI({
            method: 'PUT',
            url: '/tmUsuarioExiste',
            body: data,
            isPublic: true,
          });
          setLoading(false);
        }
        if (currentStep === 1) {
          data.nr_ddi_celular = '55';
          data.nr_celular = onlyNumbers(data.nr_celular);
          if (data.nr_celular) {
            data.nr_ddd_celular = data.nr_celular.substring(0, 2);
            data.nr_celular = data.nr_celular.substring(2);
          }
          schema = Yup.object().shape({
            nr_celular: Yup.string()
              .trim()
              .test({
                message: 'Número informado é inválido',
                test: (value) => isValidPhone(`${data.nr_ddd_celular}${value}`),
              })
              .required('O número do DDD + celular é obrigatório')
              // .min(8, 'Número inválido')
              // .max(9, 'Número inválido')
              .matches(/^[0-9]*$/g, 'Apenas números são permitidos'),
            ds_email: Yup.string()
              .trim()
              .email('Digite um e-mail válido')
              .required('E-mail obrigatório'),
            confirmar_email: Yup.string()
              .trim()
              .required('Confirmação de e-mail é obrigatório')
              .when('ds_email', {
                is: (val) => !!val.length,
                then: Yup.string()
                  .email('Digite um e-mail válido')
                  .required('Confirmação de E-mail é obrigatório'),
                otherwise: Yup.string(),
              })
              .oneOf(
                [Yup.ref('ds_email')],
                'E-mail e confirmação precisam ser iguais.'
              ),

            ds_senha: Yup.string()
              .required('Senha obrigatória')
              .min(6, 'Senha deve ter no mínimo 6 caracteres')
              .matches(
                /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/g,
                `A senha deverá conter: 1 letra maiúscula, 1 letra minúscula e 1 caractere numérico!`
              )
              .trim(),
            confirmar_senha: Yup.string()
              .required('Confirmação de senha é obrigatório')
              .when('ds_senha', {
                is: (val) => !!val.length,
                then: Yup.string().required(
                  'Confirmação de senha é obrigatório'
                ),
                otherwise: Yup.string(),
              })
              .oneOf(
                [Yup.ref('ds_senha')],
                'Senha e confirmação precisam ser iguais.'
              )
              .trim(),
            termos_aceite: Yup.string().matches(
              /S/g,
              'Necessário aceitar nossos termos de privacidade e termos de uso dos seus dados'
            ),
            dados_verdadeiros: Yup.string().matches(
              /S/g,
              'Necessário confirmar a declaração'
            ),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        dataUserRef.current = { ...dataUserRef.current, ...data };
        handleNext();
        if (currentStep === 1) {
          Object.keys(dataUserRef.current).forEach((key) => {
            if (typeof dataUserRef.current[key] === 'string') {
              dataUserRef.current[key] = dataUserRef.current[key].trim();
            }
          });

          setLoading(true);

          await requestAPI({
            method: 'POST',
            url: '/tmUsuario',
            body: dataUserRef.current,
            isPublic: true,
          });
          setLoading(false);

          addToast({
            type: 'success',
            title: 'Cadastro realizado',
            description:
              'Você deve confirmar o cadastro no seu e-mail antes entrar!',
          });

          history.push('/registration-success', {
            cpf: dataUserRef.current.nr_cpf,
            email: dataUserRef.current.ds_email,
            notSendEmail: true,
          });
        }
      } catch (err: any) {
        sendError(err);
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          verifyErrorYup(err, formRef, addToast);

          return;
        }

        if (
          err.message.toUpperCase().includes('JÁ CONSTA CADASTRADO') ||
          err.message.toUpperCase().includes('EXISTE UMA CONTA')
        ) {
          setModal(true);
          errorRef.current = err;
          setError(err.message);
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        }
      } finally {
        setLoading(false);
        if (data?.nr_celular) {
          data.nr_celular = `${data?.nr_ddd_celular}${data?.nr_celular}`;
        }
        if (
          data?.dt_nascimento?.includes('-') &&
          !data?.dt_nascimento.endsWith('-')
        ) {
          data.dt_nascimento = moment(data.dt_nascimento, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          );
        }
        setUserData(Object.assign(userData, data));
      }
    },
    [
      name,
      location.state,
      selectedDominio,
      currentStep,
      handleNext,
      requestAPI,
      addToast,
      history,
      sendError,
      userData,
    ]
  );
  const handleEnter = useCallback(() => {}, []);
  return (
    <>
      <HelmetComponent title="Cadastre-se" />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        id="form-1"
        className="form-login form-wizard"
        autoComplete="new-password"
      >
        <Container className="pb-3">
          <DrModal
            controllerModal={[modal, setModal]}
            title="Erro ao Cadastrar!"
            buttons={{
              confirm: {
                title: 'Recuperar Senha',
                onClick: () => {
                  history.push({
                    pathname: `/forgot-password`,
                    state: {
                      nr_cpf: errorRef.current.data.nr_cpf,
                      ds_email: errorRef.current.data.ds_email,
                    },
                  });
                },
              },
              cancel: {
                title: 'Voltar',
                onClick: () => setModal(false),
              },
            }}
            content={<h4 style={{ textAlign: 'center' }}>{error}</h4>}
          />

          {etapas[currentStep].id === 'dadosPessoais' && (
            <div id="dados-pessoais">
              <Row>
                <Col xl={12}>
                  <Input
                    icon
                    value={name}
                    getValue={handleChangeNameInput}
                    name="nm_pessoa"
                    label="Seu nome completo (Titular)"
                    customType="namePessoa"
                    validate
                    placeholder="Digite aqui seu nome completo"
                  />
                </Col>

                <Col xl={12}>
                  <Input
                    value={userData.nr_cpf}
                    icon
                    name="nr_cpf"
                    label="CPF"
                    customType="cpf"
                    inputMode="numeric"
                    validate
                  />
                </Col>

                <Col xl={12}>
                  <Input
                    value={userData.dt_nascimento}
                    icon
                    id="born_date"
                    name="dt_nascimento"
                    label="Data de Nascimento"
                    customType="dateText"
                    inputMode="numeric"
                    validate
                  />
                </Col>
                <Col xl={12}>
                  <DrSelect
                    initialValue={selectedDominio}
                    value={selectedDominio}
                    options={genres}
                    selectTitle="Gênero"
                    icon="wc"
                    name="ie_sexo"
                    onChange={(ev) => handleSelectDominio(ev)}
                  />
                </Col>
              </Row>
            </div>
          )}
          {etapas[currentStep].id === 'dadosContato' && (
            <div id="dados-contato" className="fields">
              <Row>
                <Col xl={12}>
                  <div className="contato-container">
                    <div className="input">
                      <Input
                        value={userData.nr_celular}
                        icon
                        name="nr_celular"
                        label="Celular ou Telefone"
                        customType="phoneNumber"
                        inputMode="numeric"
                        validate
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={12}>
                  <Input
                    icon
                    value={userData.ds_email || ''}
                    name="ds_email"
                    label="E-mail"
                    customType="email"
                    validate
                  />
                </Col>
                <Col xl={12}>
                  <Input
                    icon
                    value={userData.confirmar_email || ''}
                    name="confirmar_email"
                    label="Confirmar seu e-mail"
                    customType="email"
                    validate
                    cantPaste
                    autoComplete="new-password"
                  />
                </Col>
                <Col xl={12}>
                  <Input
                    value={userData.ds_senha}
                    icon
                    name="ds_senha"
                    showDisplayPass
                    label="Senha"
                    customType="password"
                    validate
                    autoComplete="new-password"
                  />
                </Col>
                <Col xl={12}>
                  <Input
                    value={userData.confirmar_senha}
                    icon
                    name="confirmar_senha"
                    label="Confirmar a senha"
                    customType="password"
                    validate
                    cantPaste
                    onKeyDown={handleEnter}
                    autoComplete="new-password"
                  />
                </Col>
                <Col xl={12}>
                  <Checkbox
                    noMargin
                    name="termos_aceite"
                    label={
                      <>
                        {`Ao se cadastrar em nosso sistema, você está aceitando nossos `}
                        <AnchorStyled
                          href={valueParam(applicationData, 173)}
                          target="_blank"
                        >
                          termos de privacidade
                        </AnchorStyled>
                        {` e `}
                        <AnchorStyled
                          href={valueParam(applicationData, 174)}
                          target="_blank"
                        >
                          termos de uso dos seus dados
                        </AnchorStyled>
                      </>
                    }
                  />
                </Col>
                <Col xl={12}>
                  <Checkbox
                    name="dados_verdadeiros"
                    label="Declaro que todas as informações fornecidas por mim são verdadeiras."
                  />
                </Col>
              </Row>
            </div>
          )}

          {currentStep < etapas.length - 1 && (
            <Button type="submit" title="Próximo passo" color="blue" />
          )}
          {currentStep === etapas.length - 1 && (
            <DrButtonLoading
              loading={isLoading}
              type="submit"
              title="Finalizar Cadastro"
              color="blue"
            />
          )}
        </Container>
      </Form>
    </>
  );
};

export default DrBoxSignUp;

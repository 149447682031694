import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { attendanceTypesType } from '../index';
import { AuthContextData, useAuth } from '../../../hooks/auth';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import DrButtonLoading from '../../../components/dr-button-loading';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { useToast } from '../../../hooks/toast';
import DrModal from '../../../components/dr-modal';
import { valueParam } from '../../../utils/bibli';
import { userRequirements } from '../../../utils/validations';

interface CardTypesCareProps {
  attendanceTypes: attendanceTypesType[];
  setModalLogin: (isOpen: any) => void;
}

function setRoute(
  setAttendanceData: AuthContextData['setAttendanceData'],
  history,
  attendanceType: attendanceTypesType,
  setArrLoadingBtn: React.Dispatch<React.SetStateAction<boolean[]>>,
  i: number,
  addToast: any,
  sendError: (message: any) => Promise<void>,
  user
) {
  setArrLoadingBtn((prev) => {
    // eslint-disable-next-line no-param-reassign
    prev[i] = true;
    return [...prev];
  });

  if (
    attendanceType &&
    attendanceType.atendimento &&
    attendanceType.atendimento.msg
  ) {
    setArrLoadingBtn((prev) => {
      // eslint-disable-next-line no-param-reassign
      prev[i] = false;
      return [...prev];
    });
    return `${attendanceType.atendimento.msg}`;
  }

  setAttendanceData(attendanceType)
    .then(() => {
      if (attendanceType.atendimento.ie_aberto === 'S') {
        if (user) {
          history.push({
            pathname: '/select-to-attendance/',
          });
        } else if (parseInt(attendanceType.ie_tipo_atendimento, 10) === 3) {
          history.push({
            pathname: `/pay-options`,
          });
        } else {
          history.push({
            pathname: `/select-specialty/eletivo`,
          });
        }
        return;
      }

      addToast({
        type: 'error',
        title: 'Atenção!',
        description:
          attendanceType &&
          attendanceType.atendimento &&
          attendanceType.atendimento.msg
            ? `${attendanceType.atendimento.msg}`
            : `Não foi possível obter o atendimento`,
      });
    })
    .catch((error: any) => {
      sendError(error);
    })
    .finally(() => {
      setArrLoadingBtn((prev) => {
        // eslint-disable-next-line no-param-reassign
        prev[i] = false;
        return [...prev];
      });
    });
  return false;
}

const CardTypesCare: React.FC<CardTypesCareProps> = ({
  attendanceTypes,setModalLogin
}: CardTypesCareProps) => {
  const history = useHistory();
  const { setAttendanceData, sendError, user, applicationData } = useAuth();
  const { addToast } = useToast();
  const [arrLoadingBtn, setArrLoadingBtn] = React.useState(() =>
    attendanceTypes.map(() => false)
  );
  let arrayRequirements: any = [];
  const paramsUser = valueParam(applicationData, 209);
  const [modal, setModal] = React.useState({
    showModal: false,
    msg: '',
  });

  if (paramsUser) {
    arrayRequirements = paramsUser.split(',');
    if (
      !arrayRequirements.some((field) => field === 'url_img') &&
      valueParam(applicationData, 210) === 'S'
    ) {
      arrayRequirements.push('url_img');
    }
  }

  function handleSetRoute(attendanceType,i){
    if(!user && parseInt(attendanceType.ie_tipo_atendimento, 10) === 3){
      setModalLogin({ showModal: true });
    }else{
      const msg = setRoute(
        setAttendanceData,
        history,
        attendanceType,
        setArrLoadingBtn,
        i,
        addToast,
        sendError,
        user
      );
      if (msg) {
        setModal((prev: any) => ({ showModal: true, msg }));
      }
    }
  }

  React.useEffect(() => {
    if (attendanceTypes.length === 1) {
      setRoute(
        setAttendanceData,
        history,
        attendanceTypes[0],
        setArrLoadingBtn,
        1,
        addToast,
        sendError,
        user
      );
    }
    if (user) {
      if (userRequirements(user, arrayRequirements)) {
        history.push({
          pathname: `/update-user-registration-datas/${user.idtm_usuario}`,
          state: 'new-attendance-incomplete-data',
        });

        addToast({
          type: 'error',
          title: 'Ops!',
          description: `É necessário completar o formulário de cadastro antes de continuar`,
        });
      }
    }
  }, []);

  return (
    <>
      <Container className="pt-3">
        <CardBlank className="pt-3 pb-3 p-1">
          <PageTitle>Tipos de Atendimento</PageTitle>

          <DrModal
            controllerModal={[modal.showModal, setModal]}
            title="Atenção"
            buttons={{
              cancel: {
                onClick: () => {
                  setModal((prev: any) => ({ showModal: false, msg: '' }));
                  return null;
                },
                title: 'OK',
              },
            }}
            content={
              <h4
                dangerouslySetInnerHTML={{
                  __html: modal?.msg?.replace(/\n/g, '<br />') || '',
                }}
              />
            }
          />

          {attendanceTypes.map(
            (attendanceType: attendanceTypesType, i: number) => (
              <Row
                className={`justify-content-center${i > 0 ? ' mt-3' : ''}`}
                key={attendanceType?.idestabelecimento_tipo_atendimento}
              >
                <Col xl={4}>
                  <DrButtonLoading
                    loading={arrLoadingBtn[i]}
                    title={attendanceType?.ds_button_acao}
                    type="button"
                    color="blue"
                    onClick={() => {
                      handleSetRoute(attendanceType,i);
                    }}
                  />
                </Col>
              </Row>
            )
          )}
        </CardBlank>
      </Container>

      <DrButtonsFooter />
    </>
  );
};

export default CardTypesCare;

import React, { useState, useEffect, VideoHTMLAttributes } from 'react';
import { useAuth } from '../../hooks/auth';
import { Video } from './styled';
import { valueParam } from '../../utils/bibli';

interface VideoProps extends Omit<VideoHTMLAttributes<HTMLVideoElement>, 'id'> {
  videoStream: MediaStream | undefined;
  id?: number;
  muted?: any;
  videoStyles?: any;
  videoType?: any;
  avatar?: any;
  videoMuted?: (video: MediaStream | undefined) => void;
  hidden?: boolean;
  className?: string;
  controlsConferencia?: boolean;
}

const VideoComp = React.forwardRef(
  (props: VideoProps, ref: React.ForwardedRef<HTMLVideoElement>) => {
    const {
      videoStream,
      id,
      muted,
      videoStyles,
      videoType,
      videoMuted,
      avatar,
      hidden,
      className,
      controlsConferencia,
      controls,
      onClick: onClickProps,
      ...rest
    } = props;

    const [videoVisible, setVideoVisible] = useState(true);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const { applicationData } = useAuth();

    const refVideo = ref || videoRef;

    const onClick = React.useCallback<
      React.MouseEventHandler<HTMLVideoElement>
    >(
      (event) => {
        if (onClickProps) {
          onClickProps(event);
        }
        // Desativa o click de pause e play no Chrome
        if (controlsConferencia) {
          event.preventDefault();
        }
      },
      [controlsConferencia, onClickProps]
    );

    useEffect(() => {
      if (videoStream && 'current' in refVideo && refVideo.current) {
        refVideo.current.srcObject = videoStream;
      }

      const videoTrack = videoStream && videoStream.getVideoTracks();
      if (videoType === 'remoteVideo' && videoTrack && videoTrack.length) {
        videoTrack[0].onmute = () => {
          setVideoVisible(false);
          if (videoMuted) videoMuted(videoStream);
        };

        videoTrack[0].onunmute = () => {
          setVideoVisible(true);
          if (videoMuted) videoMuted(videoStream);
        };
      }

      const audioTrack = videoStream && videoStream.getAudioTracks();
      if (videoType === 'remoteVideo' && audioTrack && audioTrack.length) {
        audioTrack[0].onmute = () => {
          alert('muted');
        };
      }
    }, [videoStream]);

    return (
      <Video
        id={id?.toString()}
        poster={avatar || valueParam(applicationData, 126)}
        muted={muted}
        autoPlay
        playsInline
        style={{
          visibility: (videoVisible && 'visible') || 'hidden',
          ...videoStyles,
        }}
        ref={refVideo}
        className={className}
        hidden={hidden}
        controls={controlsConferencia || controls}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );
  }
);

export default VideoComp;

import React from 'react';
import { useHistory } from 'react-router-dom';
import Screen from '../../components/dr-control-screen';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import CardPreEval from './card-pre-evaluation';
import { AuthContextData, useAuth } from '../../hooks/auth';
import { useIsMounted } from '../../hooks/is-mounted';
import { ToastMessage, useToast } from '../../hooks/toast';
import { dataPreEvaluationType } from '../../components/dr-render-grp-pre-eval';
import { valueParam } from '../../utils/bibli';

interface getPreEvaluationsParams {
  attendance: AuthContextData['attendance'];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  requestAPI: AuthContextData['requestAPI'];
  history: any;
  addToast: (message: Omit<ToastMessage, 'id'>) => void;
  isMountedRef: React.MutableRefObject<boolean>;
  dataPreEvaluationRef: React.MutableRefObject<
    dataPreEvaluationType | undefined
  >;
  sendError: (message: any) => Promise<void>;
}

function cbChooseScreen(dataPreEvaluation: dataPreEvaluationType | undefined) {
  let screen = <></>;
  if (dataPreEvaluation) {
    screen = <CardPreEval dataPreEvaluation={dataPreEvaluation} />;
  }

  return screen;
}

function getPreEvaluations({
  attendance,
  history,
  requestAPI,
  setLoading,
  dataPreEvaluationRef,
  addToast,
  isMountedRef,
  sendError,
}: getPreEvaluationsParams) {
  setLoading(true);

  if (attendance.idpessoa_paciente === 0 || undefined) {
    history.push('/select-to-attendance');
  } else if (attendance.ie_retorno === 'S') {
    history.push('/attendance-options');
  } else {
    const {
      idestabelecimento_tipo_atendimento,
      idespecialidade,
      idpessoa_medico,
      dt_agendamento,
      ie_retorno,
      idperfil,
      idpessoa_paciente,
    } = attendance;
    requestAPI({
      method: 'GET',
      url: `/preAvaliacaoAtendimento?${
        idestabelecimento_tipo_atendimento
          ? `idestabelecimento_tipo_atendimento=${idestabelecimento_tipo_atendimento}`
          : ''
      }${idperfil ? `&idperfil=${idperfil}` : ''}${
        idespecialidade ? `&idespecialidade=${idespecialidade}` : ''
      }${idpessoa_medico ? `&idpessoa_fisica_medico=${idpessoa_medico}` : ''}${
        dt_agendamento ? `&dt_consulta=${dt_agendamento}` : ''
      }${ie_retorno === 'S' ? `&ie_retorno='S'` : `&ie_retorno='N'`}${
        idpessoa_paciente ? `&idpessoa_paciente=${idpessoa_paciente}` : ''
      }`,
    })
      .then((resp) => {
        // eslint-disable-next-line no-param-reassign
        dataPreEvaluationRef.current = resp;
        if (isMountedRef.current) {
          setLoading(false);
        }
        if (!resp) {
          history.push('/attendance-options');
        }
      })
      .catch((error: any) => {
        sendError(error);
        if (isMountedRef.current) {
          history.push('/select-to-attendance');
          addToast({
            type: 'error',
            title: 'Por favor, Tente novamente.',
            description: error.message.replace('Validation error: ', ''),
          });
        }
      });
  }
}

const PreEvaluation: React.FC = () => {
  const { requestAPI, attendance, sendError, applicationData } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const isMountedRef = useIsMounted();
  const [isLoading, setLoading] = React.useState(true);
  const dataPreEvaluationRef = React.useRef<dataPreEvaluationType>();

  React.useEffect(() => {
    getPreEvaluations({
      attendance,
      requestAPI,
      setLoading,
      addToast,
      history,
      dataPreEvaluationRef,
      isMountedRef,
      sendError,
    });
  }, []);

  const chooseScreen = React.useCallback(
    () => cbChooseScreen(dataPreEvaluationRef.current),
    []
  );

  return (
    <>
      <Header />
      <Screen isLoading={isLoading} chooseScreen={chooseScreen} />
      <Footer />
    </>
  );
};

export default PreEvaluation;

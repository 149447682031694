import styled from 'styled-components';

export const CardBlogBody = styled.div`
  flex-grow: 1;
  padding: 15px;
  width: 100%;

  :after,
  :before {
    box-sizing: border-box;
  }
`;

export const CardBlogBtn = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 14px 20px;
  border-top: 1px solid #e5e5e5;

  :hover {
    transition: all 0.2s ease-in-out;
  }
`;

export const CardBlogDate = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #777;
  line-height: 1.66667em;
  margin: 0 0 10px;

  :after,
  :before {
    box-sizing: border-box;
  }
`;

export const CardBlogHead = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: #000;
    opacity: 0;
  }
`;

export const CardBlogLikes = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 370px;
  pointer-events: none;
`;

export const CardBlogTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #777;
  line-height: 1.44444em;
  min-height: 129px;
  max-height: 129px;
  overflow: hidden;

  :after,
  :before {
    box-sizing: border-box;
  }
`;

export const CardBlogUrl = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #3f56a5;
  height: 100%;
  float: left;

  :hover {
    box-sizing: border-box;
  }
`;

export const CardBlog = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #e5e5e5;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  a {
    text-decoration: none;
    color: #3f56a5;

    :hover {
      transition: all 0.2s ease-in-out;
      color: #0d6efd;
      text-decoration: underline;
    }

    :after,
    :before {
      box-sizing: border-box;
    }
  }
`;

export const BoxBlog = styled.div`
  position: relative;
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
`;
export const DrCardBlogContainer = styled.div`
  :hover {
    h3 {
      color: #ed145b;
    }

    .btn {
      transition: all 0.2s ease-in-out;
      background-color: #3f56a5;
    }

    .url {
      color: #fff;
    }

    ${CardBlogHead} {
      :before {
        transition: all 0.2s ease-in-out;
        height: 100%;
        opacity: 0.7;
      }
    }
  }
`;

import React, { useRef, useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'styled-components';
import NoSleep from 'nosleep.js';
import {
  PsWebRtc,
  sendToPeer,
  offerNewPc,
  bibliReact,
} from 'bibliotecas/bibli-front';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import {
  detectIOS,
  detectMobile,
  handleSocketConnection,
  valueParam,
} from '../../../utils/bibli';
import 'webrtc-adapter';

import { HeaderContainer } from '../../../components/dr-header/styled';
import Logo from '../../../assets/marca/logo.png';
import DrCardEquip from '../../../components/dr-card-equip';
import DrIcon from '../../../components/dr-icon-font';

import {
  ContainerStreamPage,
  RowFooter,
  DivDrBoxChat,
  ImgHeaderLogo,
  ColImgLogoHeader,
  ColHeadAtend,
  SpanHeadAtend,
  ColIconHeader,
  Header,
} from './styled';

import DrBoxChat, {
  userRoomI,
  DrBoxChatProps,
} from '../../../components/dr-box-chat';

import VideoConferencia from '../../../components/dr-video-conferencia';

import DrModalSelectDevice, {
  CurrentDeviceType,
} from '../../../components/dr-modal-select-device';

import DrModal, { DrModalProps } from '../../../components/dr-modal';
import DrCardLoading from '../../../components/dr-card-loading';
import { useIsMounted } from '../../../hooks/is-mounted';
import { BCLabelFile } from '../../../components/dr-box-chat/styled';

interface CreatePeerConnection {
  idPessoaFisicaRemoto: number;
  nomePessoaRemoto: string;
  boolAnfitriao: boolean;
  idSocketIORemoto: string;
  idUsuarioRemoto: string;
  polite: boolean;
}

interface OnlinePeers {
  idPessoaFisicaRemoto: number; // idpessoa_fisica do usuário do peer encontrado
  nomePessoaRemoto: string; // nm_pessoa do usuário do peer encontrado
  nrConselho: string;
  ufConselho: string;
  dsEspecialidade: string;
  boolAnfitriao: boolean; // bool indicando se o usuário do peer encontado é o anfitrião
  idSocketIORemoto: any; // idsocket_io do usuário do peer encontrado
  idUsuarioRemoto: any;
  urlImgRemoto: any;
}

interface FooterStreamPageProps
  extends Omit<
    DrBoxChatProps,
    'className' | 'hasIcon' | 'controllerChat' | 'classNameBCIcon' | 'cssBCBody'
  > {
  chatVisible: boolean;
  setChatVisible: React.Dispatch<boolean | 'toggle'>;
  btnMic: () => void;
  stateMicOn: boolean;
  btnCamera: () => void;
  stateCameraOn: boolean;
  btnGear: () => void;
  hasFacingMode: boolean | undefined;
  isCellRef: React.MutableRefObject<boolean>;
  btnSwitchCamera: () => void;
  turnBtnSwitch: string | undefined;
}

interface HeaderStreamPageProps {
  remoteVideoIsOn: boolean;
  remoteMicIsOn: boolean;
  dataDoctor: DataDoctor;
}

interface DataDoctor {
  name?: string;
  crm?: string;
  state?: string;
  specialty?: string;
}

const HeaderStreamPage: React.FC<HeaderStreamPageProps> = ({
  remoteMicIsOn,
  remoteVideoIsOn,
  dataDoctor,
}: HeaderStreamPageProps) => {
  const { name: nmDoctor, crm, state: stateCrm, specialty } = dataDoctor;

  const cssIcon = css`
    font-size: 2.5rem !important;

    @media (max-width: 768px) {
      font-size: 2rem !important;
    }

    &:after {
      height: 60px;
    }

    @media (max-width: 768px) {
      &:after {
        height: 40px;
      }
    }
  `;

  return (
    <HeaderContainer>
      <Header>
        <Container>
          <Row>
            <ColImgLogoHeader xs="auto" sm={2}>
              <ImgHeaderLogo src={Logo} alt="logo" />
            </ColImgLogoHeader>
            <Col xs={6} sm={8} className="head-atend">
              <Row className="justify-content-end">
                <Col
                  xl={4}
                  className="d-none d-xl-flex flex-row align-items-center justify-content-end"
                >
                  <span className="text-right">
                    <strong>
                      Voce está sendo
                      <br />
                      atendido por:
                    </strong>
                  </span>
                </Col>
                <ColHeadAtend
                  xl={8}
                  className="d-flex flex-column justify-content-center"
                >
                  <SpanHeadAtend>{nmDoctor || ''}</SpanHeadAtend>
                  <SpanHeadAtend>
                    {crm ? `CRM: ${crm}` : ''}
                    {stateCrm && crm ? ` - ${stateCrm}` : ''}
                  </SpanHeadAtend>
                  <SpanHeadAtend>
                    <span className="d-none d-xl-inline">Especialidade: </span>
                    {specialty}
                  </SpanHeadAtend>
                </ColHeadAtend>
              </Row>
            </Col>
            <ColIconHeader xs="auto" className="head-equip">
              {!remoteMicIsOn && (
                <DrCardEquip
                  icon={
                    <DrIcon
                      name="microphone"
                      toggle
                      disabled={!remoteMicIsOn}
                    />
                  }
                  cssIcon={cssIcon}
                />
              )}
            </ColIconHeader>
            <ColIconHeader xs="auto" className="head-equip">
              {!remoteVideoIsOn && (
                <DrCardEquip
                  icon={
                    <DrIcon name="video" toggle disabled={!remoteVideoIsOn} />
                  }
                  cssIcon={cssIcon}
                />
              )}
            </ColIconHeader>
          </Row>
        </Container>
      </Header>
    </HeaderContainer>
  );
};

const FooterStreamPage: React.FC<FooterStreamPageProps> = ({
  chatVisible,
  setChatVisible,
  btnMic,
  stateMicOn,
  btnCamera,
  stateCameraOn,
  btnGear,
  hasFacingMode,
  isCellRef,
  btnSwitchCamera,
  turnBtnSwitch,
  ...rest
}: FooterStreamPageProps) => {
  const classNameTitle = 'd-none d-xl-block d-xxl-block';
  const styleCardEquip = { cursor: 'pointer' };
  const inputFile = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    const divAnexo = document.querySelector('.input-anexo');
    if (divAnexo)
      divAnexo.addEventListener('click', (ev) => {
        inputFile.current?.click();
      });
    return () => {
      if (divAnexo) divAnexo?.removeEventListener('click', () => {});
    };
  }, []);

  return (
    <Container fluid className="pt-3">
      <RowFooter id="RowFooter">
        <DivDrBoxChat id="DivDrBoxChat">
          <DrBoxChat
            hasIcon
            controllerChat={[chatVisible, setChatVisible]}
            cssBCBody={css`
              bottom: 50px;

              @media (min-width: 1200px) {
                bottom: 110px;
              }
            `}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        </DivDrBoxChat>
        <Col xs="auto" className="ml-3">
          <DrCardEquip
            icon={<DrIcon name="attachment" />}
            title="Anexo"
            className="input-anexo"
            classNameTitle={classNameTitle}
            style={styleCardEquip}
            body={
              <BCLabelFile style={{ height: '100%', backgroundColor: 'red' }}>
                <input
                  name="input-file"
                  type="file"
                  ref={inputFile}
                  hidden
                  onChange={(e) => {
                    rest.sendFile(e.target.files);
                    setChatVisible(true);
                  }}
                />
              </BCLabelFile>
            }
            styleTitle={{ maxWidth: '5em' }}
          />
        </Col>

        <Col xs="auto" className="ml-3">
          <DrCardEquip
            icon={<DrIcon name="microphone" toggle disabled={!stateMicOn} />}
            title="Microfone"
            classNameTitle={classNameTitle}
            style={styleCardEquip}
            styleTitle={{ maxWidth: '5em' }}
            onClick={btnMic}
          />
        </Col>
        <Col xs="auto" className="ml-3">
          <DrCardEquip
            icon={<DrIcon name="video" toggle disabled={!stateCameraOn} />}
            title="Câmera"
            classNameTitle={classNameTitle}
            style={styleCardEquip}
            styleTitle={{ maxWidth: '5em' }}
            onClick={btnCamera}
          />
        </Col>
        {hasFacingMode && isCellRef.current && (
          <Col xs="auto" className="ml-3">
            <DrCardEquip
              icon={<DrIcon name="refresh" />}
              title="Virar Câmera"
              classNameTitle={classNameTitle}
              style={styleCardEquip}
              onClick={btnSwitchCamera}
              cssDivIcon={css`
                transform: rotate(${turnBtnSwitch});
              `}
            />
          </Col>
        )}
        {!hasFacingMode && !isCellRef.current && (
          <Col xs="auto" className="ml-3">
            <DrCardEquip
              icon={<DrIcon name="setting" />}
              title="Configurações"
              classNameTitle={classNameTitle}
              style={styleCardEquip}
              onClick={btnGear}
            />
          </Col>
        )}
      </RowFooter>
    </Container>
  );
};

const StreamPage: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const isMountedRef = useIsMounted();

  const {
    user,
    socketAuth,
    setSocketAuth,
    requestAPI,
    applicationData,
    attendance,
    setAttendanceData,
    sendError,
  } = useAuth();

  const [newMessage, setNewMessage] = useState(false);
  const [isLoadingVidHost, setIsLoadingVidHost] = useState(false);
  const [remoteVideoIsOn, setRemoteVideoIsOn] = useState(true);
  const [remoteMicIsOn, setRemoteMicIsOn] = useState(true);
  const [upload, setUpload] = useState(false);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [localStream, setLocalStream] = useState<any>();
  const [remoteStreams, setRemoteStreams] = useState<Map<any, any>>(new Map());
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [turnBtnSwitch, setTurnBtnSwitch] = useState<string>();
  const [dataDoctor, setDataDoctor] = useState<DataDoctor>({});
  const [hasFacingMode, setHasFacingMode] = useState<boolean>(false);
  const toConnectSocketRef = useRef(true);

  const [modal, setModal] = useState<
    Omit<DrModalProps, 'controllerModal'> & { showModal: boolean }
  >({ showModal: false });
  const users = applicationData?.valores_parametros
    ? valueParam(applicationData, 124).split(';')
    : [];

  if (applicationData?.valores_parametros)
    valueParam(applicationData, 83)
      .split(';')
      .forEach((userTurn) => {
        users.push(userTurn);
      });

  const keys = applicationData?.valores_parametros
    ? valueParam(applicationData, 125).split(';')
    : [];

  if (applicationData?.valores_parametros)
    valueParam(applicationData, 84)
      .split(';')
      .forEach((keyStun) => {
        keys.push(keyStun);
      });

  const urls = applicationData?.valores_parametros
    ? valueParam(applicationData, 81).split(';')
    : [];

  if (applicationData?.valores_parametros)
    valueParam(applicationData, 82)
      .split(';')
      .forEach((urlTurn) => {
        urls.push(urlTurn);
      });

  const iceServers: any[] = [];

  urls.forEach((url, idx) => {
    const objServer = {
      urls: url,
      username: users[idx] && users[idx] !== 'NULL' ? users[idx] : undefined,
      credential: keys[idx] && keys[idx] !== 'NULL' ? keys[idx] : undefined,
    };
    iceServers.push(objServer);
  });

  const pcConfig = useRef<any>({ iceServers });
  const isIOSRef = useRef(detectIOS());
  const cellRef = useRef(detectMobile());
  const localStreamRef = useRef<any>();
  const peerConnectionsRef = useRef(new Map());
  const mapPsWebRtcRef = useRef(new Map());
  const remoteStreamsRef = useRef(new Map());
  const sendChannelsRef = useRef<any[]>([]);
  const idSocketSalaParticipanteRef = useRef<any>();
  const sendersRef = useRef<any[]>([]);
  const cameraDisabledRef = useRef(false);
  const boolDisplayingScreenRef = useRef(false);
  const urlImgRemotoRef = useRef('');
  const remoteVideoHtmlRef = useRef<any>(null);
  const facingModeRef = useRef('user');
  const chatVisibleRef = useRef(false);

  const userRoomRef = useRef<userRoomI>({
    name: '',
    urlImg: '',
    idPhysicalPerson: 0,
  });
  const userRoomDataRef = useRef<any>(null);

  const sdpConstraints = useRef<any>({
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  });

  const currentDeviceRef = useRef<CurrentDeviceType>({
    video: '',
    audioInput: '',
    audioOutput: '',
  });

  const reducerChatVisible = React.useCallback(
    (prev: boolean, action: boolean | 'toggle') => {
      if (action === 'toggle') {
        chatVisibleRef.current = !prev;
        return !prev;
      }
      chatVisibleRef.current = action;
      return action;
    },
    []
  );

  const [chatVisible, setChatVisible] = useReducer(reducerChatVisible, false);

  const noSleep = new NoSleep();

  const notificationSound = new Audio(
    'https://assets.mixkit.co/sfx/preview/mixkit-alert-quick-chime-766.mp3'
  );

  function disconnectPeer(idPessoaFisicaPeer: any, idSocketIORemoto?: any) {
    const pcObj = peerConnectionsRef.current.get(idPessoaFisicaPeer);

    if (
      pcObj &&
      pcObj.pc &&
      (!idSocketIORemoto || idSocketIORemoto === pcObj.id_socketio)
    ) {
      const rVideo = remoteStreamsRef.current.get(idPessoaFisicaPeer);

      if (rVideo) {
        remoteStreamsRef.current.delete(idPessoaFisicaPeer);
        setRemoteStreams(new Map(remoteStreamsRef.current.values()));
      }

      pcObj.pc.close();
      peerConnectionsRef.current.delete(idPessoaFisicaPeer);
      mapPsWebRtcRef.current.delete(idPessoaFisicaPeer);
    }
  }

  function whoisOnline(socket) {
    sendToPeer(socket, 'onlinePeers');
  }

  function transformToControlledStream(stream: MediaStream): {
    controlledStream: MediaStream | undefined;
    gainNode: GainNode | undefined;
  } {
    const videoTracks = stream.getVideoTracks();
    const AudioContext = window.AudioContext || false;
    if (AudioContext) {
      try {
        const context = new AudioContext();
        const mediaStreamSource = context.createMediaStreamSource(stream);
        const mediaStreamDestination = context.createMediaStreamDestination();
        const gainNode = context.createGain();

        mediaStreamSource.connect(gainNode);
        gainNode.connect(mediaStreamDestination);

        const controlledStream = mediaStreamDestination.stream;

        videoTracks.forEach((videoTrack) => {
          controlledStream.addTrack(videoTrack);
        });

        return { controlledStream, gainNode };
      } catch (error: any) {
        sendError(error);
        return { controlledStream: undefined, gainNode: undefined };
      }
    }

    return { controlledStream: undefined, gainNode: undefined };
  }

  function checkMicMute() {
    if (localStreamRef.current.stream) {
      const arrAudioTracks = localStreamRef.current.stream.getAudioTracks();

      if (arrAudioTracks.length) {
        arrAudioTracks[0].enabled = !micOn;
      }
    }
  }

  async function getUserMediaAudio(
    constraints: Record<string, undefined>
  ): Promise<MediaStream | null> {
    try {
      const streamAudio = await navigator.mediaDevices.getUserMedia(
        constraints
      );

      return streamAudio;
    } catch (error: any) {
      sendError(error);

      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro ao obter microfone! ${error.message}`,
      });
      closeConnections();
      disconnectPeer(
        userRoomDataRef.current.idPessoaFisicaRemoto,
        userRoomDataRef.current.idSocketIORemoto
      );

      history.push({
        pathname: '/attendance-error',
        state: { microfone: `${error.message}` },
      });
      throw new Error(`Erro ao obter microfone! ${error.message}`);
    }
  }

  async function getUserMediaVideo(
    constraints: Record<string, undefined>
  ): Promise<MediaStream | null> {
    try {
      const streamVideo = await navigator.mediaDevices.getUserMedia(
        constraints
      );

      return streamVideo;
    } catch (error: any) {
      sendError(error);

      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro ao obter câmera! ${error.message}`,
      });
      closeConnections();
      disconnectPeer(
        userRoomDataRef.current.idPessoaFisicaRemoto,
        userRoomDataRef.current.idSocketIORemoto
      );

      history.push({
        pathname: '/attendance-error',
        state: { camera: `${error.message}` },
      });
      throw new Error(`Erro ao obter câmera! ${error.message}`);
    }
  }

  function removeTracksStream(stream, tipo?: string): boolean {
    let boolRemoved = false;
    if (stream) {
      stream.getTracks().forEach((track) => {
        let boolRemove = true;
        if (tipo) {
          boolRemove = false;
          if (tipo === track.kind) {
            boolRemove = true;
          }
        }

        if (boolRemove) {
          track.stop();
          stream.removeTrack(track);
          boolRemoved = true;
        }
      });
    }

    return boolRemoved;
  }

  function removeOldTracks(constraints): boolean {
    let boolTrackRemoved = false;

    let tipoRemove;
    if (constraints.audio) {
      tipoRemove = 'audio';
    }

    if (constraints.video) {
      if (!tipoRemove) {
        tipoRemove = 'video';
      } else {
        tipoRemove = undefined;
      }
    }

    if (localStreamRef.current && localStreamRef.current.stream) {
      if (!boolDisplayingScreenRef.current) {
        boolTrackRemoved = removeTracksStream(
          localStreamRef.current.stream,
          tipoRemove
        );

        removeTracksStream(localStreamRef.current.streamJVideo, tipoRemove);
        removeTracksStream(localStreamRef.current.streamNC, tipoRemove);
      } else if (!tipoRemove || tipoRemove === 'audio') {
        boolTrackRemoved = removeTracksStream(
          localStreamRef.current.stream,
          'audio'
        );

        removeTracksStream(localStreamRef.current.streamNC, 'audio');
      }
    }

    return boolTrackRemoved;
  }

  async function getUserMediasJoin(
    stream: MediaStream,
    constraints
  ): Promise<void> {
    try {
      const promises: Promise<MediaStream | null>[] = [];

      if (constraints.audio) {
        promises.push(getUserMediaAudio({ audio: constraints.audio }));
      }

      if (constraints.video) {
        promises.push(getUserMediaVideo({ video: constraints.video }));
      }

      await Promise.all(promises).then((values) => {
        values.forEach((streamValue) => {
          if (streamValue) {
            streamValue.getTracks().forEach((track) => {
              stream.addTrack(track);
              streamValue.removeTrack(track);
            });
          }
        });
      });
    } catch (err: any) {
      sendError(err);
      throw new Error(`Ocorreu um erro ao obter mídias ${err.message}`);
    }
  }

  function setNewTracks(
    stream: MediaStream,
    controlledStream: MediaStream | undefined
  ): Record<string, boolean> {
    let boolTrackAdded = false;
    let boolChangeVideo = false;
    let boolChangeAudio = false;

    stream.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        localStreamRef.current.trackVideoCamera = track;
      }

      if (!controlledStream) {
        if (track.kind !== 'video' || !boolDisplayingScreenRef.current) {
          localStreamRef.current.stream.addTrack(track);
          boolTrackAdded = true;

          if (track.kind === 'video') {
            localStreamRef.current.streamJVideo.addTrack(track);
            boolChangeVideo = true;
          } else {
            boolChangeAudio = true;
          }
        }
      }

      localStreamRef.current.streamNC.addTrack(track);
    });

    if (controlledStream) {
      controlledStream.getTracks().forEach((track) => {
        if (track.kind !== 'video' || !boolDisplayingScreenRef.current) {
          localStreamRef.current.stream.addTrack(track);
          boolTrackAdded = true;

          if (track.kind === 'video' && !boolDisplayingScreenRef.current) {
            localStreamRef.current.streamJVideo.addTrack(track);
            boolChangeVideo = true;
          } else {
            boolChangeAudio = true;
          }
        }
      });
    }

    return {
      boolChangeAudio,
      boolChangeVideo,
      boolTrackAdded,
    };
  }

  function setLocalStreamRef(
    stream: MediaStream,
    controlledStream: MediaStream | undefined,
    gainNode: GainNode | undefined
  ): void {
    localStreamRef.current = {
      id: userRoomRef.current.idPhysicalPerson,
      name: userRoomRef.current.name,
      stream: controlledStream || stream,
      streamNC: stream,
      gainNode,
      streamJVideo: stream
        ? new MediaStream(stream.getVideoTracks())
        : new MediaStream(),
      trackVideoCamera:
        stream && stream.getVideoTracks().length
          ? stream.getVideoTracks()[0]
          : undefined,
    };
  }

  function stateLocalTracks(): Record<string, MediaStreamTrack | undefined> {
    let trackAudio: MediaStreamTrack | undefined;
    let trackVideo: MediaStreamTrack | undefined;
    if (localStreamRef.current && localStreamRef.current.stream) {
      localStreamRef.current.stream.getTracks().forEach((track) => {
        if (track.kind === 'audio') {
          trackAudio = track;
          if (localStreamRef.current.speech) {
            localStreamRef.current.speech.stop();
          }
        } else if (track.kind === 'video') {
          trackVideo = track;
        }
      });
    }

    return {
      trackAudio,
      trackVideo,
    };
  }

  async function setTracksPeerConnection({
    pc,
    trackAudio,
    trackVideo,
    boolChangeAudio,
    boolChangeVideo,
  }: {
    pc: RTCPeerConnection;
    trackAudio: MediaStreamTrack | undefined;
    trackVideo: MediaStreamTrack | undefined;
    boolChangeAudio: boolean;
    boolChangeVideo: boolean;
  }): Promise<void> {
    let senderAudio: RTCRtpSender | undefined;
    let senderVideo: RTCRtpSender | undefined;

    pc.getSenders().forEach((sender: RTCRtpSender) => {
      if (sender.track) {
        if (sender.track.kind === 'audio') {
          senderAudio = sender;
        } else if (sender.track.kind === 'video') {
          senderVideo = sender;
        }
      }
    });

    if (trackVideo) {
      if (!senderVideo) {
        pc.addTrack(trackVideo, localStreamRef.current.stream);
      } else if (boolChangeVideo) {
        await senderVideo.replaceTrack(trackVideo);
      }
    } else if (senderVideo) {
      senderVideo.track?.stop();
      pc.removeTrack(senderVideo);
    }

    if (trackAudio) {
      if (!senderAudio) {
        pc.addTrack(trackAudio, localStreamRef.current.stream);
      } else if (boolChangeAudio) {
        await senderAudio.replaceTrack(trackAudio);
      }
    } else if (senderAudio) {
      senderAudio.track?.stop();
      pc.removeTrack(senderAudio);
    }
  }

  async function setTracksAllPeerConnections(
    trackAudio: MediaStreamTrack | undefined,
    trackVideo: MediaStreamTrack | undefined,
    boolChangeAudio: boolean,
    boolChangeVideo: boolean
  ): Promise<void> {
    const promises: Promise<void>[] = [];
    const valuesIt = peerConnectionsRef.current.values();
    let valueIt = valuesIt.next();
    while (!valueIt.done) {
      const pcObj = valueIt.value;

      promises.push(
        setTracksPeerConnection({
          pc: pcObj.pc,
          trackAudio,
          trackVideo,
          boolChangeAudio,
          boolChangeVideo,
        })
      );

      valueIt = valuesIt.next();
    }

    await Promise.all(promises);
  }

  async function getUserStream(constraints: any) {
    try {
      const stream = new MediaStream();
      let controlledStream: MediaStream | undefined;
      let gainNode: GainNode | undefined;
      // let boolTrackRemoved = false;

      if (constraints.audio || constraints.video) {
        removeOldTracks(constraints);

        try {
          await getUserMediasJoin(stream, constraints);

          if (stream.getAudioTracks().length) {
            const returnFunc = transformToControlledStream(stream);
            controlledStream = returnFunc.controlledStream;
            gainNode = returnFunc.gainNode;
          }
        } catch (error: any) {
          sendError(error);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Erro ao pegar mídia! ${error.message}`,
          });
        }
      }

      let boolChangeAudio = false;
      let boolChangeVideo = false;
      if (localStreamRef.current && localStreamRef.current.stream) {
        const returnFunc = setNewTracks(stream, controlledStream);

        boolChangeAudio = returnFunc.boolChangeAudio;
        boolChangeVideo = returnFunc.boolChangeVideo;
      } else {
        setLocalStreamRef(stream, controlledStream, gainNode);
      }

      const { trackAudio, trackVideo } = stateLocalTracks();

      setLocalStream({
        ...localStreamRef.current,
        streamJVideo: new MediaStream(localStreamRef.current.streamJVideo),
      });
      await setTracksAllPeerConnections(
        trackAudio,
        trackVideo,
        boolChangeAudio,
        boolChangeVideo
      );
    } catch (error: any) {
      sendError(error);

      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro ao pegar mídia! ${error.message}`,
      });
    }
  }

  async function getLocalStream() {
    const constraints = await bibliReact.getConstraints({
      boolIOS: isIOSRef.current,
      // maxWidth: 1280,
      // maxHeight: 720,
      windowOrMediaDevices: window,
    });

    await getUserStream(constraints);
  }

  function addTrackRemoteStream(idPessoaFisicaRemoto, nomePessoaRemoto, event) {
    const trackEvent = event.track;
    let rVideo = remoteStreamsRef.current.get(idPessoaFisicaRemoto);

    const streamRemote = rVideo ? rVideo.stream : new MediaStream();

    const remoteStreamJVideo = rVideo ? rVideo.streamJVideo : new MediaStream();
    if (streamRemote) {
      if (event.track.kind === 'audio') {
        streamRemote.getAudioTracks().forEach((track) => {
          track.stop();
          streamRemote.removeTrack(track);
        });
      } else if (event.track.kind === 'video') {
        streamRemote.getVideoTracks().forEach((track) => {
          track.stop();
          streamRemote.removeTrack(track);
        });

        remoteStreamJVideo.getVideoTracks().forEach((track) => {
          track.stop();
          remoteStreamJVideo.removeTrack(track);
        });

        remoteStreamJVideo.addTrack(event.track);
      }
      streamRemote.addTrack(event.track);
    }

    if (!rVideo) {
      rVideo = {
        id: idPessoaFisicaRemoto,
        name: nomePessoaRemoto,
        stream: streamRemote,
        streamJVideo: remoteStreamJVideo,
      };
      remoteStreamsRef.current.set(idPessoaFisicaRemoto, rVideo);
    }

    if (trackEvent.kind === 'video') {
      rVideo.trackVideo = trackEvent;
    }

    setRemoteStreams(
      (prev) => new Map([...prev, [idPessoaFisicaRemoto, rVideo]])
    );

    return [streamRemote, rVideo];
  }

  function pcOnTrack({ event, idPessoaFisicaRemoto, nomePessoaRemoto }) {
    try {
      addTrackRemoteStream(idPessoaFisicaRemoto, nomePessoaRemoto, event);
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro... ${error.message}`,
      });
    }
  }

  function getNomeAtrPcInfo(tipo) {
    let nomeAtr;

    switch (tipo) {
      case 'screen':
        nomeAtr = 'displayingScreen';
        break;
      default:
        nomeAtr = `${tipo}Muted`;
        break;
    }

    return nomeAtr;
  }

  function checkUserStatus(id, tipo, messageOptions) {
    const pcObj = peerConnectionsRef.current.get(id);

    const nomeAtr = getNomeAtrPcInfo(tipo);

    if (tipo === 'camera') {
      setRemoteVideoIsOn(!pcObj[nomeAtr]);
    }

    if (tipo === 'mic') {
      setRemoteMicIsOn(!pcObj[nomeAtr]);
    }

    if (
      tipo === 'camera' &&
      messageOptions &&
      !messageOptions.boolDisplayingScreen
    ) {
      remoteStreamsRef.current.forEach((remoteStream, key) => {
        if (key === id) {
          if (remoteStream && remoteStream.stream && remoteStream.trackVideo) {
            if (pcObj.cameraMuted) {
              remoteStream.stream.removeTrack(remoteStream.trackVideo);
              remoteStream.streamJVideo.removeTrack(remoteStream.trackVideo);
            } else {
              remoteStream.stream.addTrack(remoteStream.trackVideo);
              remoteStream.streamJVideo.addTrack(remoteStream.trackVideo);
            }
          }
        }
        setRemoteStreams((prev) => new Map([...prev, [key, remoteStream]]));
      });
    } else if (tipo === 'screen') {
      const remoteStream = remoteStreamsRef.current.get(id);

      if (
        remoteStream &&
        remoteStream.stream &&
        remoteStream.trackVideo &&
        pcObj
      ) {
        if (pcObj.displayingScreen) {
          remoteStream.stream.addTrack(remoteStream.trackVideo);
          remoteStream.streamJVideo.addTrack(remoteStream.trackVideo);
        } else if (messageOptions && !messageOptions.boolTrackVideoCamera) {
          remoteStream.stream.removeTrack(remoteStream.trackVideo);
          remoteStream.streamJVideo.removeTrack(remoteStream.trackVideo);
        }
      }
    }
  }

  function handleMessageInfoChange(
    senderId,
    boolStatus,
    tipo,
    messageOptions?: any
  ) {
    const pcObj = peerConnectionsRef.current.get(senderId);

    const nomeAtr = getNomeAtrPcInfo(tipo);

    if (pcObj) {
      pcObj[nomeAtr] = boolStatus;
    }

    checkUserStatus(senderId, tipo, messageOptions);
  }

  function handleMessageInfoPc({ senderId, messageData, messageOptions }) {
    if (senderId !== userRoomRef.current.idPhysicalPerson) {
      if (messageData === 'audio-muted' || messageData === 'audio-unmuted') {
        handleMessageInfoChange(senderId, messageData === 'audio-muted', 'mic');
      } else if (
        messageData === 'headphone-muted' ||
        messageData === 'headphone-unmuted'
      ) {
        handleMessageInfoChange(
          senderId,
          messageData === 'headphone-muted',
          'headphone'
        );
      } else if (
        messageData === 'camera-disabled' ||
        messageData === 'camera-enabled'
      ) {
        handleMessageInfoChange(
          senderId,
          messageData === 'camera-disabled',
          'camera',
          messageOptions
        );
      } else if (
        messageData === 'displaying-screen' ||
        messageData === 'not-displaying-screen'
      ) {
        handleMessageInfoChange(
          senderId,
          messageData === 'displaying-screen',
          'screen',
          messageOptions
        );
      }
    }
  }

  function handleMessageChat(params: {
    objetoMsg;
    senderId;
    senderName;
    messageData;
    boolLoading;
    elementMsg?;
    handleMessageChat?;
    messageIdBd?;
    messageTimestampSend?;
    boolAudioNewMessage?;
  }) {
    const { objetoMsg } = params;

    if (objetoMsg.type === 'text' || objetoMsg.type === 'file') {
      setMessages((prev) => [...prev, objetoMsg]);
    }
  }

  function handleReceiveMessage(objetoMsg, elementMsg?: any) {
    const params = {
      objetoMsg,
      senderId: objetoMsg.message.sender.uid,
      senderName: objetoMsg.message.sender.name,
      messageData: objetoMsg.message.data,
      messageOptions: objetoMsg.message.options,
      boolLoading: false,
      elementMsg,
    };

    const dataMsg = objetoMsg.message.data;

    if (objetoMsg.type !== 'pc-info') {
      if (
        objetoMsg.message.sender.uid !== userRoomRef.current.idPhysicalPerson &&
        chatVisibleRef.current === false
      ) {
        setNewMessage(true);

        if (!chatVisibleRef.current) {
          if (!notificationSound.paused) {
            notificationSound.currentTime = 0;
          } else {
            notificationSound.play();
          }

          addToast({
            title: 'Você recebeu uma mensagem',
            description:
              dataMsg.length > 18 ? `${dataMsg.substr(0, 15)}...` : dataMsg,
            type: 'chat',
            click: () => {
              setChatVisible(true);
            },
          });
        }
      }
    }

    if (objetoMsg.type === 'pc-info') {
      handleMessageInfoPc(params);

      if (objetoMsg.message.data === 'displaying-screen') {
        addToast({
          title: `Foi iniciado o compartilhamento de tela.`,
          description: `${objetoMsg.message.sender.name}, esta compartilhando a tela com você.`,
          type: 'info',
        });
      } else if (objetoMsg.message.data === 'not-displaying-screen') {
        addToast({
          title: `O compatilhamento de tela foi finalizado!`,
          description: `${objetoMsg.message.sender.name}, finalizou o compartilhamento de tela.`,
          type: 'info',
        });
      }
    } else {
      handleMessageChat(params);
    }
  }

  function addTracksToPC(pc: RTCPeerConnection) {
    if (
      pc &&
      localStreamRef &&
      localStreamRef.current &&
      localStreamRef.current.stream
    ) {
      localStreamRef.current.stream.getTracks().forEach((track) => {
        sendersRef.current.push(
          pc.addTrack(track, localStreamRef.current.stream)
        );
      });
    }
  }

  function createPeerConnection(
    {
      idPessoaFisicaRemoto,
      nomePessoaRemoto,
      boolAnfitriao,
      idSocketIORemoto,
      idUsuarioRemoto,
      polite,
    }: CreatePeerConnection,
    callback: (pc: RTCPeerConnection | null) => void
  ) {
    try {
      const pc = new RTCPeerConnection(pcConfig.current);

      const pcObj = {
        muted: false,
        nome: nomePessoaRemoto,
        idusuario: idUsuarioRemoto,
        pc,
        id_socketio: idSocketIORemoto,
        boolSpeaking: false,
        sendChannel: undefined,
        bool_host: boolAnfitriao,
        polite,
        makingOffer: false,
        srdAnswerPending: false,
      };

      peerConnectionsRef.current.set(idPessoaFisicaRemoto, pcObj);

      const psWebRtc = new PsWebRtc({
        socket: socketAuth.socket,
        pcObj,
        idRemotePhysicalPerson: idPessoaFisicaRemoto,
        sdpConstraints: sdpConstraints.current,
      });

      mapPsWebRtcRef.current.set(idPessoaFisicaRemoto, psWebRtc);

      pc.onnegotiationneeded = () => {
        try {
          psWebRtc.pcOnNegotiationNeeded();
        } catch (error: any) {
          sendError(error);
        }
      };

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          sendToPeer(
            socketAuth.socket,
            'candidate',
            event.candidate,
            idSocketIORemoto
          );
        }
      };

      // pc.addEventListener('icecandidateerror', (event) => {});

      pc.ontrack = (event) => {
        pcOnTrack({
          event,
          idPessoaFisicaRemoto,
          nomePessoaRemoto,
        });
      };

      pc.oniceconnectionstatechange = () => {
        switch (pc.iceConnectionState) {
          case 'closed':
            // eslint-disable-next-line no-console
            console.log('connectinoclosed');
            break;
          case 'failed':
            // eslint-disable-next-line no-console
            console.log('connection failed');
            break;
          default:
            break;
        }
      };

      pc.onsignalingstatechange = () => {
        switch (pc.signalingState) {
          case 'closed':
            disconnectPeer(idPessoaFisicaRemoto, idSocketIORemoto);
            break;
          default:
            break;
        }
      };

      pc.close = () => {
        // if (!remoteStreamOff) {
        //   setRemoteStreamOff(true);
        // }
      };

      // const handleSendChannelStatusChange = () => {};

      const sendChannel = pc.createDataChannel('sendChannel');
      sendChannel.onopen = () => {
        // if (micMute) {
        //   sendMessageAudioMuted(sendChannel);
        // }
      };
      // sendChannel.onclose = handleSendChannelStatusChange;

      sendChannelsRef.current.push(sendChannel);

      const handleReceiveChannelStatusChange = () => {
        // if (this.receiveChannel) {
        //
        //     'handleReceiveChannelStatusChange:',
        //     `receive channel's status has changed to ${this.receiveChannel.readyState}`
        //   );
        // }
      };

      const receiveChannelCallback = (event) => {
        const receiveChannel = event.channel;
        receiveChannel.onmessage = (event2) => {
          handleReceiveMessage(JSON.parse(event2.data));
        };
        receiveChannel.onopen = handleReceiveChannelStatusChange;
        receiveChannel.onclose = handleReceiveChannelStatusChange;
      };

      pc.ondatachannel = receiveChannelCallback;

      if (typeof callback === 'function') {
        callback(pc);
      }
    } catch (e: any) {
      sendError(e);
      if (typeof callback === 'function') {
        callback(null);
      }
    }
  }

  function setEventsNullPC(pcParam: any) {
    if (pcParam) {
      const pc = pcParam;

      pc.ontrack = null;
      pc.onremovetrack = null;
      pc.onremovestream = null;
      pc.onicecandidate = null;
      pc.oniceconnectionstatechange = null;
      pc.onsignalingstatechange = null;
      pc.onicegatheringstatechange = null;
      pc.onnegotiationneeded = null;
    }
  }

  function closeConnections() {
    peerConnectionsRef.current.forEach((pcObj) => setEventsNullPC(pcObj.pc));

    const exitRoomOptionsAux = {};

    if (socketAuth.socket) {
      socketAuth.socket.emit('exit-room', {
        boolDisconnectSocket: true,
        ...exitRoomOptionsAux,
      });

      socketAuth.socket.close();
      setSocketAuth({ socket: undefined });
    }

    if (localStreamRef.current.stream) {
      removeTracksStream(localStreamRef.current.stream);
    }

    if (localStreamRef.current.streamJVideo) {
      removeTracksStream(localStreamRef.current.streamJVideo);
    }

    if (localStreamRef.current.streamNC) {
      removeTracksStream(localStreamRef.current.streamNC);
    }

    if (localStreamRef.current.trackVideoCamera) {
      localStreamRef.current.trackVideoCamera.stop();
    }

    if (localStreamRef.current.streamScreen) {
      removeTracksStream(localStreamRef.current.streamScreen);
    }

    setLocalStream(undefined);
    localStreamRef.current = undefined;

    remoteStreamsRef.current.forEach((rVideo) => {
      removeTracksStream(rVideo.stream);
      removeTracksStream(rVideo.streamJVideo);
    });
    peerConnectionsRef.current.forEach((pcObj) => {
      pcObj.pc.close();
    });
  }

  function sendToChannels(params: {
    msg: any;
    boolHandleReceiveMessage: any;
    sendChannel?: any;
    elementMsg?: any;
  }) {
    const { msg, boolHandleReceiveMessage, sendChannel } = params;
    if (sendChannel && sendChannel.readyState === 'open') {
      sendChannel.send(JSON.stringify(msg));
    } else {
      sendChannelsRef.current.forEach((sendChannelForEach) => {
        if (sendChannelForEach.readyState === 'open') {
          sendChannelForEach.send(JSON.stringify(msg));
        }
      });
    }

    if (boolHandleReceiveMessage) {
      handleReceiveMessage(msg);
    }
  }

  function sendMessage(msgParam: any, sendChannel?: any) {
    const msg = msgParam;
    msg.message.id = userRoomRef.current.idPhysicalPerson;
    msg.message.sender = {
      uid: userRoomRef.current.idPhysicalPerson,
      name: userRoomRef.current.name,
    };

    sendToChannels({ msg, boolHandleReceiveMessage: false, sendChannel });
  }

  function getDownloadName(data, name, ext) {
    if (!data.startsWith('data:application/octet-stream')) {
      let extensao;
      let image = false;
      if (data.startsWith('data:application/pdf')) {
        extensao = 'pdf';
      } else if (data.startsWith('data:application/msword')) {
        extensao = 'doc';
      } else if (
        data.startsWith('data:application/vnd.ms-excel') ||
        data.startsWith(
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      ) {
        extensao = 'xls';
      } else if (
        data.startsWith('data:application/x-zip-compressed') ||
        data.startsWith('data:application/zip')
      ) {
        extensao = 'zip';
      } else if (data.startsWith('data:application/x-rar-compressed')) {
        extensao = 'rar';
      } else if (data.startsWith('data:text/plain')) {
        extensao = 'txt';
      } else if (data.startsWith('data:image/')) {
        image = true;
        extensao = data.substring(11, data.indexOf(';'));
      }

      let newName = '';
      if (extensao) {
        newName += `.${extensao}`;
      }

      return { name: newName, ext: extensao, image };
    }

    switch (ext) {
      case 'docx':
      case 'doc':
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'pdf':
      case 'zip':
      case 'rar':
      case 'txt':
        return { name: `${name}.${ext}`, ext, image: false };
      default:
        return { name: `${name}`, image: false };
    }
  }

  async function sendMessageChat(body: any) {
    let elementMsg;
    try {
      const msgChannel = {
        type: body.type,
        boolImage: false,
        message: {
          data: null,
          id: userRoomRef.current.idPhysicalPerson,
          ext: '',
          sender: {
            uid: userRoomRef.current.idPhysicalPerson,
            name: userRoomRef.current.name,
          },
          name: body.name,
          timestampSend: '',
          idBd: '',
        },
      };

      if (body.type === 'file') {
        const { ext, image } = getDownloadName(
          body.data_url,
          body.name,
          body.ext
        );

        msgChannel.message.ext = ext;
        msgChannel.boolImage = image;
      }

      const newBody = {
        ...body,
        codigosAcesso: { idperfil: null, idfuncao: 702 },
      };

      const resp = await requestAPI({
        method: 'POST',
        url: '/socketSalaInteracaoDataUrl',
        body: newBody,
      });

      msgChannel.message.ext = resp.ext;
      msgChannel.boolImage = resp.boolImage;
      msgChannel.message.data = body.ds_mensagem || resp.urlFile;
      msgChannel.message.idBd = resp.idSocketSalaInteracao;
      msgChannel.message.timestampSend = resp.timestampMessage;

      sendToChannels({
        msg: msgChannel,
        boolHandleReceiveMessage: true,
        elementMsg,
      });
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Erro ao enviar mensagem',
        description: `${error.message}`,
      });
    }
  }

  function sendMessagePcInfo(params: {
    tipo: string;
    status: string;
    sendChannel?: any;
    options: { boolDisplayingScreen: boolean };
  }) {
    const { tipo, status, sendChannel, options } = params;

    let data;

    if (status === 'on') {
      if (tipo === 'screen') {
        data = 'displaying-screen';
      } else if (tipo === 'camera') {
        data = 'camera-enabled';
      }
    } else if (tipo === 'screen') {
      data = 'not-displaying-screen';
    } else if (tipo === 'camera') {
      data = 'camera-disabled';
    }

    sendMessage(
      {
        type: 'pc-info',
        message: {
          data,
          options,
        },
      },
      sendChannel
    );
  }

  const selectDevice = React.useCallback(
    async (constraints: {
      video?: Record<string, unknown>;
      audio?: Record<string, unknown>;
      audioOutput?: string;
    }) => {
      const constraint = await bibliReact.getConstraints({
        videoCon: constraints.video,
        audioCon: constraints.audio,
        boolIOS: isIOSRef.current,
        // maxWidth: 1280,
        // maxHeight: 720,
        windowOrMediaDevices: window,
      });

      await getUserStream(constraint);

      if (
        constraints.audioOutput &&
        remoteVideoHtmlRef.current &&
        typeof remoteVideoHtmlRef.current.setSinkId === 'function'
      ) {
        await remoteVideoHtmlRef.current.setSinkId(constraints.audioOutput);
      }
    },
    [getUserStream]
  );

  const onSubmitSelecDev = React.useCallback(
    async (formData) => {
      const constraints: { video?: any; audio?: any; audioOutput?: string } =
        {};

      if (formData.video) {
        constraints.video = { deviceId: { exact: formData.video } };
        currentDeviceRef.current.video = formData.video;
      }

      if (formData.audio_input) {
        constraints.audio = { deviceId: { exact: formData.audio_input } };
        currentDeviceRef.current.audioInput = formData.audio_input;
      }

      if (formData.audio_output) {
        constraints.audioOutput = formData.audio_output;
        currentDeviceRef.current.audioOutput = formData.audio_output;
      }
      await selectDevice(constraints);
      setModal({ showModal: false });
    },
    [selectDevice]
  );

  const btnGear = React.useCallback(async () => {
    setModal({
      showModal: true,
      modalContent: (
        <DrModalSelectDevice
          currentDeviceRef={currentDeviceRef}
          setModal={setModal}
          onSubmit={onSubmitSelecDev}
        />
      ),
      backdrop: 'static',
      keyboard: false,
    });
  }, [setModal, onSubmitSelecDev]);

  async function btnCamera() {
    if (!isLoadingVidHost) {
      try {
        setIsLoadingVidHost(true);
        setCameraOn(!cameraOn);
        cameraDisabledRef.current = !cameraDisabledRef.current;

        if (!cameraDisabledRef.current) {
          const constraints = await bibliReact.getConstraints({
            audioCon: false,
            boolIOS: isIOSRef.current,
            // maxWidth: 1280,
            // maxHeight: 720,
            windowOrMediaDevices: window,
          });

          await getUserStream(constraints);
        } else {
          if (localStreamRef.current.stream) {
            removeTracksStream(localStreamRef.current.stream, 'video');
          }

          if (localStreamRef.current.streamJVideo) {
            removeTracksStream(localStreamRef.current.streamJVideo, 'video');
          }

          if (localStreamRef.current.streamNC) {
            removeTracksStream(localStreamRef.current.streamNC, 'video');
          }

          if (localStreamRef.current.trackVideoCamera) {
            localStreamRef.current.trackVideoCamera.stop();
            localStreamRef.current.trackVideoCamera = undefined;
          }

          setLocalStream({
            ...localStreamRef.current,
            streamJVideo: new MediaStream(localStreamRef.current.streamJVideo),
          });
        }

        if (cameraDisabledRef.current) {
          sendMessagePcInfo({
            tipo: 'camera',
            status: 'off',
            options: { boolDisplayingScreen: boolDisplayingScreenRef.current },
          });
        } else {
          sendMessagePcInfo({
            tipo: 'camera',
            status: 'on',
            options: { boolDisplayingScreen: boolDisplayingScreenRef.current },
          });
        }
      } catch (error: any) {
        sendError(error);
      } finally {
        setIsLoadingVidHost(false);
      }
    }
  }

  function sendMessageAudioMuted(sendChannel?: any) {
    sendMessage(
      {
        type: 'pc-info',
        message: {
          data: 'audio-muted',
        },
      },
      sendChannel
    );
  }

  function sendMessageAudioUnmuted(sendChannel?: any) {
    sendMessage(
      {
        type: 'pc-info',
        message: {
          data: 'audio-unmuted',
        },
      },
      sendChannel
    );
  }

  function btnMic() {
    setMicOn(!micOn);

    checkMicMute();

    if (micOn) {
      sendMessageAudioMuted();
    } else {
      sendMessageAudioUnmuted();
    }
  }

  function sendMsg(msg) {
    const body = {
      idsocket_sala: socketAuth.idSocketSala || attendance.idSocketSala,
      idpessoa_fisica: userRoomRef.current.idPhysicalPerson,
      type: 'text',
      idsocket_sala_participante: idSocketSalaParticipanteRef.current,
      ds_mensagem: msg.message.data,
    };

    setLoadingSendMessage(true);

    sendMessageChat(body)
      .catch((error: any) => {
        sendError(error);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: error.message,
        });
      })
      .finally(() => {
        setLoadingSendMessage(false);
      });
  }

  function downloadFileChat(event, data) {
    event.preventDefault();
    const elementA = document.createElement('a');
    elementA.href = `${data.message.data}`;
    elementA.target = '_blank';
    elementA.download = `${data.message.name}.${data.message.ext}`;
    document.body.appendChild(elementA);
    elementA.click();
    document.body.removeChild(elementA);
  }

  async function sendFileChat(files) {
    if (files && files.length > 0) {
      try {
        const name = files[0].name.split('.')[0];
        const ext = files[0].name.split('.')[1];

        const reader = new FileReader();
        reader.onerror = () => {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Erro ao tentar processar o arquivo "${name}"`,
          });
          setUpload(false);
        };

        reader.onload = (event2: any) => {
          try {
            if (event2) {
              const data = event2.target.result;
              const dataSize = event2.total;
              const maxFileMegaBytes = parseFloat(
                applicationData?.valores_parametros[120]
              );
              const maxFileBytes = maxFileMegaBytes * 1048576;

              // https://blog.mozilla.org/webrtc/large-data-channel-messages/
              // https://lgrahl.de/articles/demystifying-webrtc-dc-size-limit.html
              // const maximumMessageSize = 10000000; // 65535 <=== 64KiB // 16384 <=== 16KiB to be safe
              if (dataSize > maxFileBytes) {
                throw new Error(
                  `Tamanho do arquivo ultrapassou o limite permitido de ${maxFileMegaBytes} MB`
                );
              }

              if (data.startsWith('data:application/x-msdownload')) {
                throw new Error('Tipo de arquivo não permitido');
              }

              const body = {
                idsocket_sala:
                  socketAuth.idSocketSala || attendance.idSocketSala,
                idpessoa_fisica: userRoomRef.current.idPhysicalPerson,
                type: 'file',
                idsocket_sala_participante: idSocketSalaParticipanteRef.current,
                data_url: data,
                name,
                ext,
              };

              sendMessageChat(body)
                .catch((error: any) => {
                  sendError(error);
                  addToast({
                    type: 'error',
                    title: 'Ops!',
                    description: error.message,
                  });
                })
                .finally(() => {
                  setUpload(false);
                });
            }
          } catch (error: any) {
            sendError(error);
            addToast({
              type: 'error',
              title: 'Ops!',
              description: error.message,
            });
          } finally {
            setUpload(false);
          }
        };
        setUpload(true);

        reader.readAsDataURL(files[0]);
      } catch (error: any) {
        sendError(error);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: error.message,
        });
        setUpload(false);
      }
    }
  }

  const btnSwitchCamera = React.useCallback(async () => {
    if (!isLoadingVidHost) {
      setIsLoadingVidHost(true);

      facingModeRef.current =
        facingModeRef.current === 'user' ? 'environment' : 'user';

      setTurnBtnSwitch(facingModeRef.current === 'user' ? '1turn' : '-1turn');

      const constraints = await bibliReact.getConstraints({
        videoCon: { facingMode: { exact: facingModeRef.current } },
        audioCon: false,
        boolIOS: cellRef.current,
        // maxWidth: 1280,
        // maxHeight: 720,
        windowOrMediaDevices: window,
      });

      await getUserStream(constraints);
      setIsLoadingVidHost(false);
    }
  }, [getUserStream, isLoadingVidHost, setIsLoadingVidHost, setTurnBtnSwitch]);

  async function showOldMessages(idSocketSala: string) {
    async function request(query) {
      const msgsBd = await requestAPI({
        method: 'GET',
        url: `/socketSalaInteracao?${query}`,
      });

      return msgsBd.data;
    }

    const msgsChannel = await bibliReact.getOldMsgsSocketSala({
      idSocketSala,
      callbacks: {
        request,
      },
    });

    setMessages((prev) => [...prev, ...msgsChannel]);
  }

  async function connectionsDones() {
    if (socketAuth.idSocketSala) {
      await showOldMessages(socketAuth.idSocketSala);
    }
  }

  async function socketOnOffer({
    idPessoaFisicaRemoto,
    nomePessoaRemoto,
    boolAnfitriao,
    idSocketIORemoto,
    idUsuarioRemoto,
    sdp,
  }) {
    const pcObjAntigo = peerConnectionsRef.current.get(idPessoaFisicaRemoto);

    const psWebRtc = mapPsWebRtcRef.current.get(idPessoaFisicaRemoto);

    if (pcObjAntigo) {
      if (pcObjAntigo.id_socketio !== idSocketIORemoto) {
        disconnectPeer(idPessoaFisicaRemoto);
      } else {
        /** Se entrar aqui, não entra no if de baixo */
        psWebRtc.offerPcAlreadyExists(sdp);
      }
    }

    if (!pcObjAntigo || pcObjAntigo.id_socketio !== idSocketIORemoto) {
      createPeerConnection(
        {
          idPessoaFisicaRemoto,
          nomePessoaRemoto,
          boolAnfitriao,
          idSocketIORemoto,
          idUsuarioRemoto,
          polite: false,
        },
        (pc) => {
          offerNewPc({
            pc,
            sdp,
            sdpConstraints: sdpConstraints.current,
            socket: socketAuth.socket,
            localStream: localStreamRef.current,
            idSocketIORemoto,
            idPessoaFisicaRemoto,
          });
        }
      );
    }
  }

  function updateOnlineStatus() {
    const condition = navigator.onLine;
    if (condition) {
      addToast({
        type: 'success',
        title: 'Reconexão',
        description: 'Você voltou a ficar online, reconectando...',
      });
      window.location.reload();
    } else {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Você parece estar offline',
      });
    }
  }

  function addEventsRoomSocket(socket: any) {
    socket.on('Error', ({ msg, closeWindow }) => {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: msg,
      });
      if (msg.includes('45000')) {
        toConnectSocketRef.current = false;
        history.push('attendance-now/returnAttendanceError');
      }

      if (closeWindow) {
        closeConnections();
      }
    });

    socket.on('connect_error', (error: any) => {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Ocorreu um erro de conexao, reconectando...',
      });
    });

    socket.on('disconnect', (reason) => {
      if (!['io server disconnect', 'io client disconnect'].includes(reason)) {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Ocorreu um erro de conexão.',
        });
      }
    });

    // Será lançado pelo streaming server caso o usuário tente entrar em uma sala que necessita aprovação e ele ainda não foi aprovado. Sem parametro.
    socket.on('aguardando-aprovacao', () => {
      addToast({
        type: 'info',
        title: 'Aguardando aprovação',
        description: 'Aguarde até que você seja aprovado para entrar na sala.',
      });
    });

    // Será lançado quando o usuário entrar com sucesso na sala.
    socket.on(
      'enter-room-success',
      (propsFunc: {
        boolAnfitriao;
        nmPessoa: string;
        urlImg: string;
        idPessoaFisica: number;
        idSocketSalaParticipante;
        iePartSemSom;
        iePartIniciar;
        ieGravarReuniao;
        ieSalaEspera;
        idpessoa_fisica;
      }) => {
        const { idSocketSalaParticipante, idPessoaFisica } = propsFunc;
        userRoomDataRef.current = { idPessoaFisicaRemoto: idPessoaFisica };
        try {
          idSocketSalaParticipanteRef.current = idSocketSalaParticipante;

          userRoomRef.current = {
            urlImg: propsFunc.urlImg,
            idPhysicalPerson: propsFunc.idPessoaFisica,
            name: propsFunc.nmPessoa,
          };

          getLocalStream()
            .then(async () => {
              whoisOnline(socket);

              await connectionsDones();

              if (isMountedRef.current) {
                setIsLoadingScreen(false);
              }
            })
            .catch((er) => {
              sendError(er);
              if (isMountedRef.current) {
                setIsLoadingScreen(false);
              }
            });
        } catch (err: any) {
          sendError(err);

          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Erro ao obter media! ${err.message}`,
          });
        }
      }
    );

    // Será lançado pelo streaming-server quando um usuário que está na mesma sala do usuário do socket desconectar.
    socket.on(
      'peer-disconnected',
      ({
        boolCloseRoom,
        motivoCloseRoom,
        idPessoaFisicaRemoto,
        msgWarning,
        idSocketIORemoto, // string contendo uma mensagem de aviso para o usuário do socket
        dadosAtendimento,
      }) => {
        try {
          toConnectSocketRef.current = false;
          closeConnections();
          disconnectPeer(idPessoaFisicaRemoto, idSocketIORemoto);
          if (boolCloseRoom) {
            // addToast({
            //   type: 'info',
            //   title: 'Informação',
            //   description: `Sala fechada.${
            //     motivoCloseRoom ? ` ${motivoCloseRoom}` : ''
            //   }`,
            // });
            // window.location.replace('/attendance-end'); tenando limpar a rota atual da pilha para que o usuario nao retorne
            history.push({
              pathname: 'attendance-end',
              state: dadosAtendimento,
            });

            // history.go(-(history.length - 1));
          } else {
            history.push('attendance-error');
          }

          // if (msgWarning) {
          //   addToast({
          //     type: 'info',
          //     title: 'Atenção',
          //     description: msgWarning,
          //   });
          // }
        } catch (e: any) {
          sendError(e);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: e.message,
          });
        }
      }
    );

    // Será lançado pelo streaming server quando o client emitir a mensagem ‘onlinePeers’
    socket.on(
      'online-peer',
      ({
        idPessoaFisicaRemoto, // idpessoa_fisica do usuário do peer encontrado
        nomePessoaRemoto, // nm_pessoa do usuário do peer encontrado
        nrConselho,
        ufConselho,
        dsEspecialidade,
        boolAnfitriao, // bool indicando se o usuário do peer encontado é o anfitrião
        idSocketIORemoto, // idsocket_io do usuário do peer encontrado
        idUsuarioRemoto, // idusuario do usuário do peer encontrado
        urlImgRemoto,
      }: OnlinePeers) => {
        urlImgRemotoRef.current = urlImgRemoto;
        userRoomDataRef.current = { idPessoaFisicaRemoto, idSocketIORemoto };

        setDataDoctor({
          name: nomePessoaRemoto,
          crm: nrConselho,
          state: ufConselho,
          specialty: dsEspecialidade,
        });

        createPeerConnection(
          {
            idPessoaFisicaRemoto,
            nomePessoaRemoto,
            boolAnfitriao,
            idSocketIORemoto,
            idUsuarioRemoto,
            polite: false,
          },
          (pcParam) => {
            if (pcParam) {
              addTracksToPC(pcParam);
            }
          }
        );
      }
    );

    // Será lançado pelo streaming server quando um peer mandar uma offer para outro.
    socket.on(
      'offer',
      ({
        idPessoaFisicaRemoto, // idpessoa_fisica do peer que mandou a offer
        nomePessoaRemoto, // nm_pessoa do peer que mandou a offer
        boolAnfitriao, // bool indicando se o peer que mandou a offer é o anfitrião
        idSocketIORemoto, // idsocket_io  peer que mandou a offer
        idUsuarioRemoto, // idusuario do peer que mandou a offer
        sdp, // RTCSessionDescriptionInit gerado pelo peer que mandou a offer
      }) => {
        socketOnOffer({
          idPessoaFisicaRemoto,
          nomePessoaRemoto,
          boolAnfitriao,
          idSocketIORemoto,
          idUsuarioRemoto,
          sdp,
        });
      }
    );

    // Será lançado pelo streaming server quando um peer mandar uma answer para outro.
    socket.on(
      'answer',
      ({
        idPessoaFisicaRemoto, // idpessoa_fisica do peer que mandou a answer
        sdp, // RTCSessionDescriptionInit gerado pelo peer que mandou a answer
      }) => {
        try {
          const psWebRtc = mapPsWebRtcRef.current.get(idPessoaFisicaRemoto);

          if (psWebRtc) psWebRtc.socketOnAnswer(sdp);
        } catch (error: any) {
          sendError(error);
        }
      }
    );

    // Será lançado pelo streaming server quando um peer mandar uma mensagem de ‘candidate’ para outro.
    socket.on(
      'candidate',
      ({
        idPessoaFisicaRemoto, // idpessoa_fisica do peer que mandou a mensagem candidate
        candidate, // candidate gerado pelo peer que mandou a mensagem candidate
        idSocketIORemoto,
      }) => {
        try {
          const pcObj = peerConnectionsRef.current.get(idPessoaFisicaRemoto);

          if (pcObj && pcObj.pc && pcObj.id_socketio === idSocketIORemoto) {
            pcObj.pc
              .addIceCandidate(new RTCIceCandidate(candidate))
              .catch((error: any) => {
                sendError(error);
                addToast({
                  type: 'error',
                  title: 'Ops!',
                  description: `Erro ao realizar "IceCandidate"! ${error.message}`,
                });
              });
          }
        } catch (error: any) {
          sendError(error);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Erro em 'candidate' ${error.message}`,
          });
        }
      }
    );
  }

  async function socketConnected(socket: any) {
    const { idSocketSala, dsKey } = attendance;
    if (!idSocketSala && !dsKey) {
      await setAttendanceData({
        idSocketSala: socketAuth.idSocketSala,
        dsKey: socketAuth.dsKey,
      });
    }

    addEventsRoomSocket(socket);

    socket.emit('enter-room', {
      idSocketSala: socketAuth.idSocketSala || idSocketSala, // idsocket_sala da sala que o usuário deseja entrar
      keySocketSala: socketAuth.dsKey || dsKey, // ds_key da sala que o usuário deseja entrar,
    });
  }

  async function socketNotConnected() {
    const { idSocketSala, dsKey, idatendimento_paciente } = attendance;

    const socket = handleSocketConnection(
      idatendimento_paciente,
      user,
      true,
      history,
      addToast,
      sendError,
      applicationData
    );

    socket?.on('connection-success', () => {
      setSocketAuth({ socket, idSocketSala, dsKey });
    });
  }

  useEffect(() => {
    document.addEventListener(
      'offline',
      () => {
        updateOnlineStatus();
      },
      false
    );
    document.addEventListener(
      'online',
      () => {
        updateOnlineStatus();
      },
      false
    );

    window.addEventListener('popstate', () => {
      window.location.reload();
    });
    return () => {
      document.removeEventListener(
        'online',
        () => {
          updateOnlineStatus();
        },
        false
      );
      document.removeEventListener(
        'offline',
        () => {
          updateOnlineStatus();
        },
        false
      );
      window.removeEventListener('popstate', () => {}, false);
      // window.removeEventListener('popstate', (e: any) => {});
    };
  }, []);

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Atenção',
      description: 'Médico vai iniciar o atendimento agora!',
    });

    return () => {
      if (cellRef.current) {
        noSleep.disable();
      }

      if (localStreamRef.current) {
        removeTracksStream(localStreamRef.current.stream);
        removeTracksStream(localStreamRef.current.streamJVideo);
        removeTracksStream(localStreamRef.current.streamNC);
      }
    };
  }, []);

  useEffect(() => {
    async function init() {
      try {
        if (socketAuth.socket && socketAuth.socket.connected) {
          await socketConnected(socketAuth.socket);
        } else if (toConnectSocketRef.current) {
          socketNotConnected();
        }
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: `Erro ao estabelecer conexão! ${err.message}`,
        });
      }
    }

    init();

    return () => {
      if (socketAuth.socket) {
        socketAuth.socket.emit('exit-room', {
          boolDisconnectSocket: true,
          novoAnfitriao: false,
        });
      }
    };
  }, [applicationData, socketAuth?.socket]);

  useEffect(() => {
    toConnectSocketRef.current = true;
  }, []);

  return (
    <ContainerStreamPage id="CONTAINERSTREAMPAGE">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DrModal controllerModal={[modal.showModal, setModal]} {...modal} />

      <HeaderStreamPage
        remoteVideoIsOn={remoteVideoIsOn}
        remoteMicIsOn={remoteMicIsOn}
        dataDoctor={dataDoctor}
      />

      {isLoadingScreen ? (
        <DrCardLoading className="mt-4" />
      ) : (
        <>
          <VideoConferencia
            localStream={localStream}
            remoteStreams={remoteStreams}
            urlImgRemoto={urlImgRemotoRef.current}
            urlImgUser={userRoomRef.current.urlImg}
            stateRemoteCameraOn={remoteVideoIsOn}
            isLoadingVidHost={isLoadingVidHost}
            remoteVideoHtmlRef={remoteVideoHtmlRef}
          />

          <FooterStreamPage
            chatVisible={chatVisible}
            setChatVisible={setChatVisible}
            sendMessage={sendMsg}
            messages={messages}
            downloadFile={downloadFileChat}
            sendFile={sendFileChat}
            stateUpload={upload}
            loadingSendMessage={loadingSendMessage}
            userRoom={userRoomRef.current}
            btnMic={btnMic}
            stateMicOn={micOn}
            btnCamera={btnCamera}
            stateCameraOn={cameraOn}
            btnGear={btnGear}
            setNewMessage={setNewMessage}
            newMessage={newMessage}
            hasFacingMode={hasFacingMode}
            isCellRef={cellRef}
            btnSwitchCamera={btnSwitchCamera}
            turnBtnSwitch={turnBtnSwitch}
          />
        </>
      )}
    </ContainerStreamPage>
  );
};

export default StreamPage;

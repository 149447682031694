import axios from 'axios';

const api = (header?: any) => {
  const headers = {
    'Content-Type': 'application/json',
    version: process.env.REACT_APP_VERSION,
    buildnumber: process.env.REACT_APP_BUILD,
    systemname: 'web',
    idaplicacao: process.env.REACT_APP_IDAPLICACAO,
    ...header,
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_ADDRESS,
    headers,
  });
};

export default api;

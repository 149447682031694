import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import FacebookLogin from 'react-facebook-login';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { FaApple } from 'react-icons/fa';
import Google from '../../assets/png/logo-google.png';
import { ButtonsRedeSocial } from './styled';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import DrButton from '../dr-button';

interface SocialProps {
  linkSocial?: boolean;
  idtm_usuario?: number;
  showFacebook?: boolean;
  showGoogle?: boolean;
  showApple?: boolean;
  style?: any;
  loading: (param: any) => any;
  setUserData?: React.Dispatch<React.SetStateAction<any>>;
}

const DrLoginSocial: React.FC<SocialProps> = ({
  linkSocial,
  idtm_usuario,
  showFacebook = false,
  showGoogle = false,
  showApple = false,
  setUserData,
  style,
  loading,
}: SocialProps) => {
  const history = useHistory();
  const { requestAPI, setLogin, updateUser, sendError } = useAuth();
  const { addToast } = useToast();
  const nonceRef = React.useRef(v4());

  async function responseLogin(response, param) {
    try {
      if (!response.code && !response.accessToken && !response.authorization) {
        return;
      }

      let type;
      let url;

      switch (param) {
        case 'ie_facebook':
          url = `/${linkSocial ? 'getSocialUserFb' : 'tmUsuarioFbAuth'}?code=${
            response.accessToken
          }`;
          type = 'F';

          break;
        case 'ie_google':
          url = `/${
            linkSocial ? 'getSocialUserGoogle' : 'tmUsuarioGoogleAuth'
          }?code=${response.code}`;
          type = 'G';

          break;
        case 'ie_apple':
          url = `/${
            linkSocial ? 'getSocialUserApple' : 'tmUsuarioAppleAuth'
          }?id_token=${response.authorization.id_token}&nonce=${
            nonceRef.current
          }`;
          type = 'A';

          break;
        default:
          return;
      }
      let resp;
      loading(true);
      if (linkSocial) resp = await requestAPI({ method: 'GET', url });
      else resp = await requestAPI({ method: 'GET', url, isPublic: true });
      resp.tipo = type;

      if (linkSocial) {
        const userUpdated = await requestAPI({
          method: 'PUT',
          url: `/tmUsuario/${idtm_usuario}`,
          body: {
            social: resp,
          },
        });
        userUpdated[param] = 'S';

        updateUser(userUpdated);

        if (setUserData) {
          setUserData((prev: any) => {
            prev[param] = 'S';
            return { ...prev };
          });
        }

        loading(false);
        addToast({
          type: 'success',
          title: 'Concluído!',
          description: 'Conta vinculada com sucesso',
        });
      } else {
        const userAuth = resp.user;
        if (userAuth[param] === 'S') {
          await setLogin({
            user: userAuth,
            authUserToken: userAuth.jwt.token,
          });
          loading(false);
          history.push('/');
        } else {
          loading(false);
          history.push({
            pathname: `/sign-up/`,
            state: resp.user,
          });
        }
      }
    } catch (error: any) {
      sendError(error);
      loading(false);
      if (error.message && error.message.includes('msg')) {
        const json = error.message.replace(/'/g, '"');
        const parseJson = JSON.parse(json);
        history.push({ pathname: '/registration-success', state: parseJson });
      } else if (error && error.message && !error.message.includes('400')) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: error.message,
        });
      }
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      responseLogin(tokenResponse, 'ie_google');
    },
    flow: 'auth-code',
  });

  return (
    <ButtonsRedeSocial className="mb-3 ">
      <div className="facebook" style={style}>
        {showFacebook && (
          <FacebookLogin
            appId="450641229451470"
            autoLoad={false}
            fields="email"
            callback={(response) => {
              responseLogin(response, 'ie_facebook');
            }}
            icon="fa-facebook"
            isMobile={false}
            textButton=""
            buttonStyle={{
              background: '#32529f',
              color: 'white',
              height: '45px',
              width: '80px',
              border: '1px',
              borderColor: '#e5e5e5',
              borderRadius: '3px',
              fontSize: '26px',
              padding: 0,
            }}
          />
        )}
      </div>

      <div className="google">
        {showGoogle && (
          <button type="button" onClick={() => googleLogin()}>
            <img
              alt="GoogleIcon"
              src={Google}
              width={32}
              height={32}
              style={{ objectFit: 'cover' }}
            />
          </button>
        )}
      </div>

      <div className="apple" style={style}>
        {showApple && (
          // <DrButton
          //   type="submit"
          //   icon
          //   color="#00FF00"
          //   onClick={(resp) => {
          //     responseLogin(resp, 'ie_apple');
          //   }}
          // >
          //   <DrIcon name="avatar" color="#fff" />
          <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
              /** Client ID - eg: 'com.example.com' */
              clientId: 'com.apptelemedicinaweb',
              /** Requested scopes, seperated by spaces - eg: 'email name' */
              scope: 'email name',
              /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
              redirectURI: `https://${window.location.host}/sign-in`,
              /** State string that is returned with the apple response */
              // state: 'state',
              /** Nonce */
              nonce: nonceRef.current,
              /** Uses popup auth instead of redirection */
              usePopup: true,
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren=""
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(resp) => {
              responseLogin(resp, 'ie_apple');
            }} // default = undefined
            /** Called upon signin error */
            onError={(resp) => {
              responseLogin(resp, 'ie_apple');
            }} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** Apple image props */
            iconProp={{ size: '20px' }}
            render={(props) => (
              <DrButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                detailCircles={false}
                type="button"
                iconRight="avatar"
                color="#000"
                icon={<FaApple fontSize="35px" />}
              />
            )}
            // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          />
          // </DrButton>
        )}
      </div>
    </ButtonsRedeSocial>
  );
};

export default DrLoginSocial;
